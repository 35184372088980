import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { AccountContext, AppContext } from 'context/Context';
import { arrayToKeyedObject, timestampToDate, getCurrentPlansCost, getPaymentPeriod, successMessage, errorMessage } from '../../../../helpers/utils'
import { loadPlansAccounts } from '../../../../load/plans';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import API from '../../../../api/api';

const PlanCurrent = ({
  plans,
  loadedAccountPlans = false,
  doLoadPlansAccounts = false,
  period = '',
}) => {
  const { accountState } = useContext(AccountContext);
  const { appConfirm } = useContext(AppContext);
  const [accountPlans, setAccountPlans] = useState([]);
  const [cancelled, setCancelled] = useState(false);

  useEffect(() => {
    if( loadedAccountPlans ){
      setAccountPlans(loadedAccountPlans);
    } else {
      loadPlansAccounts(setAccountPlans);
    }
  }, []);

  const maybeCancel = (plan, cancellationCost) => {
    appConfirm({
      type: 'confirm',
      confirm: () => cancelPlan(plan),
      confirmText: 'Cancel Plan',
      confirmColor: 'danger',
      cancelText: 'Don\'t Cancel',
      cancelColor: '',
      header: 'Cancel ' + plan.name,
      body: ( parseInt(cancellationCost, 10) ? `Upon cancelling, you will be charged $${cancellationCost} for this period's overages. ` : '' )
        + 'Any connected emails beyond your new maximum allowed will be disconnected and deleted from your account.',
    });
  }

  const cancelPlan = (plan) => {
    API.doRequest('plans_accounts', 'cancel', 'update', {item: plan.id}).then(result => {
      successMessage('Plan was successfully cancelled');
      setCancelled(true);
      if(doLoadPlansAccounts){
        doLoadPlansAccounts();
      }
    }).catch(error => {
      errorMessage('Plan could not be cancelled');
    })
  }

  let modifiedAccountPlans = [];

  if(accountState.plans_accounts !== undefined && accountState.plans_accounts.length){
    const plansById = arrayToKeyedObject(plans);

    modifiedAccountPlans = accountPlans.map(each => {
      const planDetails = plansById[each.plan] !== undefined ? plansById[each.plan] : false;

      if( planDetails ){
        each.name = planDetails.name;
        each.period = planDetails.period;

        if( each.allowances_full !== undefined ){
          each.allowances_full = each.allowances_full.map(allow => {
            allow.name = planDetails.structure.reduce((acc, cur) => {
              if(cur.id === allow.allowance){
                acc = cur.name;
              }
              return acc;
            }, '');
            return allow;
          })
        }
        each.full_plan = planDetails;
      }

      return each;
    });
  }

  if( cancelled ) return null;

  modifiedAccountPlans = modifiedAccountPlans
    .filter(each => ! each.cancel_id)
    .filter(each => each.period !== undefined && ( ( period === 'lifetime' && each.period === 'lifetime' ) || ( period !== 'lifetime' && each.period !== 'lifetime' ) ) );

  if( ! modifiedAccountPlans.length ) return null;

  return (
    <Row id="current-plan">
      <Col className="mt-5 mb-3" md={12}>
        <hr className="border-bottom-0 m-0" />
      </Col>
      <Col className="mb-3" md={12}><strong>Current Plans</strong></Col>
      {
        modifiedAccountPlans
          .map(each => {
            //If there isn't the full plan details, we probably have pSubscribe set in the URL
            const showPlans = each.full_plan === undefined;

            let costs = {
              price: false,
              additional: false,
              base: false
            };

            if( ! showPlans ){
              costs = getCurrentPlansCost(each.full_plan, each);
            }

            const cost = costs.price;
            const additional = costs.additional;
            const periodName = getPaymentPeriod(each.period);

            return (
              <Col className="current-plan" md={6}>
                <div className="bg-100 p-2 mt-2 border border-200 rounded">
                  <div className="text-primary mb-2"><strong>{each.name}</strong></div>
                  {
                    each.allowances_full !== undefined ? each.allowances_full.map(allow => <div><FontAwesomeIcon className="text-primary me-2 fs--1" icon="plus" />{allow.overage} {allow.name}</div>) : null
                  }
                  { cost ? <div className="mt-2">${cost} {periodName}</div> : ( cost !== false ? <div className="mt-2">Free</div> : null ) }
                  <div className="mt-3 d-flex justify-content-between">
                    <em><small>Added: {timestampToDate(each.created)}</small></em>
                    {
                      //If cost equals false, then we're probably doing showPlans and we don't have accurate info about the cost
                      cost !== false && period !== 'lifetime' ? <em><small className="text-danger cursor-pointer" onClick={() => maybeCancel(each, additional)}>Cancel</small></em> : null
                    }
                  </div>
                </div>
              </Col>
            );
          })
      }
    </Row>
  );
};

export default PlanCurrent;
