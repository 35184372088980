/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-case-declarations */

export const adminReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'UPDATE':
      return {
        ...state,
        ...payload,
        plans: payload.plans,
        plansItems: payload.plansItems,
        plansStructure: payload.plansStructure,
        databaseUpdates: payload.databaseUpdates
      };
    case 'SET_USERS':
      return {
        ...state,
        users: payload
      }
    case 'SET_FULL_USER':
      return {
        ...state,
        full_user: payload
      }
    case 'SET_PLANS':
      return {
        ...state,
        plans: payload
      }
    case 'SET_PLANS_ITEMS':
      return {
        ...state,
        plansItems: payload
      }
    case 'SET_PLANS_STRUCTURE':
      return {
        ...state,
        plansStructure: payload
      }
    case 'SET_PLANS_ACCOUNTS':
      return {
        ...state,
        plansAccounts: payload
      }
    case 'SET_DATABASE_UPDATES':
      return {
        ...state,
        databaseUpdates: payload
      }
    case 'SET_DATABASE_INFO':
      return {
        ...state,
        databaseInfo: payload
      }
    case 'ADD_PLAN':
      return {
        ...state,
        plans: state.plans.concat(payload)
      }
    case 'DELETE_PLAN':
      return {
        ...state,
        plans: state.plans.filter(each => each.id !== payload)
      }
    case 'ADD_PLANS_ITEM':
      return {
        ...state,
        plansItems: state.plansItems.concat(payload)
      }
    case 'DELETE_PLANS_ITEM':
      return {
        ...state,
        plansItems: state.plansItems.filter(each => each.id !== payload)
      }
    case 'ADD_PLANS_STRUCTURE':
      return {
        ...state,
        plansStructure: state.plansStructure.concat(payload)
      }
    case 'DELETE_PLANS_STRUCTURE':
      return {
        ...state,
        plansStructure: state.plansStructure.filter(each => each.id !== payload)
      }
    case 'UPDATE_DATABASE':
      return {
        ...state,
        databaseUpdates: state.databaseUpdates.map(each => {
          if(
            each.category === payload.category
            && each.subcategory === payload.subcategory
            && each.metakey === payload.metakey
          ){
            return {
              ...each,
              value: payload.value
            }
          }
          return each;
        })
      }
    default:
      return state;
  }
};
