import React from 'react';
import { useEffect, useState } from 'react';
import Api from 'api/api';
import { errorMessage } from 'helpers/utils';

const SignInWithGoogle = () => {
  const [error, setError] = useState('');

  useEffect(() => {
    const handleCredentialResponse = response => {
      console.log(response);

      Api.doRequest('accounts', 'gcreate', 'create', {
        token: response.credential,
        src: '',
        camp: ''
      }).then(result => {
        window.location.href = '/' + window.location.search;
      }).catch(error => {
        console.log('error', error);
        setError(error.message);
        errorMessage(error.message);
      });
    };

    if (window.google !== undefined) {
      window.google.accounts.id.initialize({
        client_id:
          '5753695969-88fl8glsogrq12tar4r3ir12m8rae659.apps.googleusercontent.com',
        callback: handleCredentialResponse
      });
      window.google.accounts.id.renderButton(
        document.getElementById('google-login-button'),
        { theme: 'outline', size: 'large', text: 'continue_with' }
      );
    }
  }, []);

  return (
    <>
      <div id="google-login-button" className="d-flex justify-content-center" />
      { error ? <div className="text-center mt-2"><span className="badge badge-soft-danger animate fadeIn">{error}</span></div> : null }
    </>
  );
};

export default React.memo(SignInWithGoogle);
