import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import EmailPanel from '../common/EmailPanel';
import { AccountContext } from '../../context/Context';
import ConnectFormSection from '../main/connect/ConnectFormSection';
import Connect from '../main/Connect';
import API from '../../api/api';
import { toast } from 'react-toastify';
import { getConnectionFromEmail } from '../../helpers/utils';

const Reconnect = ({}) => {
  //saving status
  const [saving, setSaving] = useState(false);
  const [restart, setRestart] = useState(false);
  const { accountState } = useContext(AccountContext);
  const connectedEmail = accountState?.connected_email !== undefined ? accountState.connected_email : {};
  const { offering, provider } = connectedEmail;

  const doSave = (provider, offering, connection, setSaving) => {

    API.doRequest('connected_emails', 'update_full', 'update', {update: {
        provider,
        offering,
        ...connection
      }}).then(result => {
        toast.success('Email is connecting!');
        setSaving(false);
        //cookie.save(SSSettings.namelower + '_connected_email', result.id, { path: '/', maxAge: (1000 * 60 * 60 * 24) });
        //window.location.href = '/email/overview';
        setTimeout(() => {
          window.location.href = '/emails';
        }, 1000);
    }).catch(error => {
      toast.error('Email could not be connected');
      setSaving(false);
    })
  }

  let startingConnection = getConnectionFromEmail(connectedEmail);

  return (
    <>
      {
        restart ? (
          <Connect reconnect={true} startingConnection={startingConnection} email={ connectedEmail.email } connectedEmail={ connectedEmail } title="Reconnect" cancelRestart={ <span className="ms-3 cursor-pointer text-danger" onClick={() => setRestart(false)}>(Cancel...)</span> } />
        ) : (
          <EmailPanel title="Reconnect">
            <ConnectFormSection
              offering={offering}
              provider={provider}
              reconnect={1}
              connectedEmail={connectedEmail}
              doSave={doSave}
              setSaving={setSaving}
              email={connectedEmail.email}
              titleOverwrite={
                <>
                  <span className="d-inline-block">Let's Reconnect Your Email</span>
                  <span className="d-inline-block cursor-pointer ms-3 text-primary" onClick={() => setRestart(true)}>(More options...)</span>
                </>
              }
            />
          </EmailPanel>
        )
      }
    </>
  )
};

Reconnect.propTypes = {
  title: PropTypes.string,
};

export default Reconnect;
