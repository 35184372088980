export default {
  auto: {
    conversation: 70,
    important: 85,
    min: 2,
    max: 50,
    ramp: 3,
    startTime: 7,
    endTime: 21,
    deviation: 3
  },
  maintain: {
    conversation: 90,
    important: 85,
    min: 15,
    max: 25,
    ramp: 0,
    startTime: 7,
    endTime: 21,
    deviation: 10
  },
  distressed: {
    conversation: 90,
    important: 90,
    min: 35,
    max: 55,
    ramp: 3,
    startTime: 7,
    endTime: 21,
    deviation: 8
  },
  rehab: {
    conversation: 90,
    important: 85,
    min: 55,
    max: 85,
    ramp: 0,
    startTime: 7,
    endTime: 21,
    deviation: 15
  },
  custom: {
    conversation: 70,
    important: 85,
    min: 3,
    max: 30,
    ramp: 3,
    startTime: 7,
    endTime: 21,
    deviation: 3
  }
}