const API = {
  _wp: null,
  _route: null,
  _action: null,
  _method: null,
  _payload: null,
  _options: null,
  _account: null,
  _user: null,
  _methods: ['get', 'create', 'update', 'delete'],

  _settings: () => {
    return window.SSSettings;
  },

  _getAPIPrefix: (version = 1) => {
    return 'v' + version;
  },

  doRequest: (
    route,
    action = 'get',
    method = 'get',
    payload = null,
    options = {}
  ) => {
    API._wp = new window.WPAPI({
      endpoint:
        API._settings().root +
        (options.endpoint !== undefined ? options.endpoint : ''),
      nonce: API._settings().nonce
    });

    API._route = route;
    API._action = action;
    API._method = API._methods.includes(method) ? method : 'get';
    API._payload = payload;
    API._options = options;

    const apiPrefix = API._getAPIPrefix();

    API._wp[route] = API._wp.registerRoute(
      apiPrefix,
      `/${route}/(?P<action>)`
    );

    let theRoute = API._wp[route]().action(action);
    let cceOverwrite = false;

    //Add URL params if they've been passed in options
    //Pass as an array of param/value pairs {params: [{param: 'param', value: 'value'}]}
    if( API._options.params !== undefined && API._options.params.length ){
      API._options.params.forEach(each => {
        if( each.param === 'cce' ) cceOverwrite = each.value;
        else theRoute = theRoute.param(each.param, each.value);
      });
    }

    return theRoute
      .param('nonce', API._settings().nonce)
      .param('account', API._settings().account)
      .param('parent', API._settings().parent)
      .param('cce', cceOverwrite ? cceOverwrite : API._settings().connected_email)
      .param('user', API._settings().user)
      [API._method](payload);
  }
};

export default API;
