/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Main from '../components/main/Main';
import Emails from '../components/main/Emails';
import Connect from '../components/main/Connect';
import BulkCSV from '../components/main/BulkCSV';
import Verify from '../components/main/Verify';
import DomainChecker from '../components/main/tools/DomainChecker';
import SpamWordChecker from '../components/main/tools/SpamWordChecker';

const MainRoutes = () => (
  <Main>
    <Switch>
      <Route exact path="/emails">
        <Emails />
      </Route>
      <Route exact path="/tools/spam">
        <SpamWordChecker />
      </Route>
      <Route exact path="/tools/domain">
        <DomainChecker />
      </Route>
      <Route exact path="/connect">
        <Connect />
      </Route>
      <Route exact path="/bulk">
        <BulkCSV />
      </Route>
      <Route exact path="/verify">
        <Verify />
      </Route>
    </Switch>
  </Main>
);

export default MainRoutes;
