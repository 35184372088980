import React from 'react';
import PropTypes from 'prop-types';
import ProviderChoice from './ProviderChoice';

const Microsoft = ({
  offering,
  updateOffering
}) => {
  const providers = [
    {
      name: 'Outlook (Personal)',
      img: false,
      id: 'outlook'
    },
    {
      name: 'Microsoft 365 (Business)',
      img: false,
      id: 'microsoft365'
    }
  ];

  return (
    <>
      <h4>Choose Type of Microsoft Email</h4>
      <ProviderChoice value={offering} update={updateOffering} providers={providers} />
    </>
  );
}

Microsoft.propTypes = {
  offering: PropTypes.string.required,
  updateOffering: PropTypes.func.required
};

export default Microsoft;
