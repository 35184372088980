import React, {useState, useContext, useEffect} from 'react';
//import PropTypes from 'prop-types';
//import classNames from 'classnames';
import { Button, Form, Row, Col, Card } from 'react-bootstrap';
import EmailPanel from '../common/EmailPanel';
import FormInfo from '../common/FormInfo';
import Toggle from '../common/Toggle';
import Cycles from './Cycles';
import Api from '../../api/api';
import { AccountContext, AppContext } from 'context/Context';
import scheduleDefaults from '../../helpers/scheduleDefaults';
import Loader from '../common/Loader';
import { errorMessage, successMessage, timestampToDate } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import PlanMain from '../settings-panel/custom/plan/PlanMain';

const Schedule = ({

}) => {
  const { accountState } = useContext(AccountContext);
  const { appConfirm } = useContext(AppContext);
  const connectedEmail = accountState?.connected_email !== undefined ? accountState.connected_email : {};

  if(connectedEmail.status === 'bounce' || connectedEmail.status === 'senderBounce'){
    return (
      <EmailPanel title="Warmup">
        <p>This email has been disabled due to elevated bounce levels. Please contact support to re-enable warming for this email.</p>
      </EmailPanel>
    )
  }

  //If the connected email has an error, then don't show the full schedule page
  if(connectedEmail.status === 'reconnecting' || connectedEmail.status === 'error' || connectedEmail.status === 'authenticationError' || connectedEmail.status === 'smtpError' || connectedEmail.status === 'disconnected'){
    if( connectedEmail.status === 'reconnecting' ){
      return (
        <EmailPanel title="Warmup">
          <p>This email has experienced a temporary connection issue and is attempting to reconnect.</p>
        </EmailPanel>
      )
    }

    return (
      <EmailPanel title="Warmup">
        <p>You must reconnect this email to set a warmup schedule.</p>
      </EmailPanel>
    )
  }

  //Whether this email is being set up for the first time
  const [newEmail, setNewEmail] = useState(false);

  //The existing incomplete cycles
  const [cycles, setCycles] = useState([]);

  //The last cycle (either complete or incomplete)
  const [lastCycle, setLastCycle] = useState({limit: 0});

  //If any of the settings have been edited
  const [edited, setEdited] = useState(false);

  //"active" property of the connected_email
  const [warming, setWarming] = useState(true);

  //"status" property of the connected email
  const [status, setStatus] = useState('');

  //If there's no routine set when the email is loaded, this is set to true
  const [firstSave, setFirstSave] = useState(false);

  const [routine, setRoutine] = useState('auto');

  //Schedule settings
  const [conversation, setConversation] = useState(70);
  const [important, setImportant] = useState(70);
  const [min, setMin] = useState(3);
  const [max, setMax] = useState(50);
  const [startTime, setStartTime] = useState(7);
  const [endTime, setEndTime] = useState(21);
  const [ramp, setRamp] = useState(3);
  const [deviation, setDeviation] = useState(3);
  const [hold, setHold] = useState(1);
  const [holdMax, setHoldMax] = useState(4);
  const [holdDuration, setHoldDuration] = useState(5);
  const [lastUpdated, setLastUpdated] = useState(5);

  //Load state for save
  const [loading, setLoading] = useState(false);

  //Load state for schedule
  const [loaded, setLoaded] = useState(false);

  const updateWarming = (val) => {
    if( val && ( status === 'new' || status === 'connecting') ){
      errorMessage('Email is not yet synced. Please try again in 15 minutes.');
      return false;
    } else {
      setWarming(val);
    }
    return true;
  }

  //If we've already loaded the schedule and it changes, set edited to true
  useEffect(() => {
    if(loaded){
      setEdited(true);
    }
  }, [conversation,important,min,max,startTime,endTime,ramp,deviation,hold,holdMax,holdDuration]);

  //If we've already loaded the schedule and it changes, set edited to true
  useEffect(() => {
    if(warming && status === 'ready'){
      setStatus('auto');
    }
  }, [warming]);

  useEffect(() => {
    if( status === 'new' || status === 'connecting' || status === 'ready' ){

      //If the email hasn't connected yet, check its connection status
      if( status === 'new' || status === 'connecting' ){
        Api.doRequest('connected_emails', 'get_status', 'get', null, {params:[{param: 'id', value: connectedEmail.id}]}).then(result => {
          if(result.data === 'ready'){
            setStatus('auto');
          }
        }).catch(error => {
          errorMessage('Could not load schedule settings');
        });
      }
      return;
    }
    const newSchedule = scheduleDefaults[status];

    if(newSchedule !== undefined){
      setConversation(newSchedule.conversation);
      setImportant(newSchedule.important);
      setMin(newSchedule.min);
      setMax(newSchedule.max);
      setStartTime(newSchedule.startTime);
      setEndTime(newSchedule.endTime);
      setRamp(newSchedule.ramp);
      setDeviation(newSchedule.deviation);
    }
  }, [status]);

  useEffect(() => {
    Api.doRequest('connected_emails', 'get', 'get', null, {params:[{param: 'id', value: connectedEmail.id}]}).then(result => {
      setWarming(!!parseInt(result?.data?.active, 10));

      if(!!parseInt(result?.data?.active, 10) && result?.data?.status === 'ready'){
        setStatus('auto');
      } else {
        setStatus(result?.data?.status);
      }

      if(result?.data?.routine !== undefined && result?.data?.routine){
        setRoutine(result?.data?.routine);
      } else {
        setFirstSave(true);
      }

      Api.doRequest('schedule').then(result => {
        if( result?.data ){
          setConversation(parseInt(result?.data?.conversation_rate, 10));
          setImportant(parseInt(result?.data?.important_rate, 10));
          setMin(parseInt(result?.data?.min, 10));
          setMax(parseInt(result?.data?.max, 10));
          setStartTime(parseInt(result?.data?.window_start, 10));
          setEndTime(parseInt(result?.data?.window_end, 10));
          setRamp(parseInt(result?.data?.ramp, 10));
          setDeviation(parseInt(result?.data?.ramp_deviation, 10));
          setHold(parseInt(result?.data?.hold, 10));
          setHoldMax(parseInt(result?.data?.hold_max, 10));
          setHoldDuration(parseInt(result?.data?.hold_duration, 10));
          setLastUpdated(parseInt(result?.data?.updated, 10));
        } else {
          setNewEmail(true);
        }
        setTimeout(() => {
          setLoaded(true);
        }, 200)
      }).catch(error => {

      });
    }).catch(error => {
      errorMessage('Could not load schedule settings');
    });
  }, []);

  useEffect(() => {
    const params = [
      {param: 'limit', value: 1}
    ];

    Api.doRequest('cycle', 'get', 'get', null, {params}).then(cycles => {
      console.log('cycles result', cycles);
      if(cycles?.data.length && parseInt(cycles.data[0].complete, 10) === 0){
        setCycles(cycles.data);
      }
      if(cycles?.data.length){
        setLastCycle(cycles.data[0]);
      }
    }).catch(error => {

    });
  }, []);

  const save = () => {
    if( max - min < ramp ){
      errorMessage('Ramp cannot be greater than the difference between Minimum and Maximum')
      return;
    }

    if( max - min < deviation ){
      errorMessage('Ramp Deviation cannot be greater than the difference between Minimum and Maximum')
      return;
    }

    setLoading(true);

    Api.doRequest('schedule', 'create', 'create', {
      update: {
        connected_email: connectedEmail.id,
        conversation_rate: conversation,
        important_rate: important,
        min,
        max,
        ramp,
        window_start: startTime,
        window_end: endTime,
        ramp_deviation: deviation,
        hold: parseInt(hold, 10),
        hold_max: holdMax,
        hold_duration: holdDuration
      }
    }).then(result => {
      let update = {
        active: warming ? 1 : 0
      };

      if( warming ){
        update.status = 'warming';
        update.routine = routine;

        if( firstSave ) update.first_save = 1;
      }

      Api.doRequest('connected_emails', 'update', 'update', {
        update: update
      }).then(result => {
        setLoading(false);
        successMessage('Saved!');
      }).catch(error => {
        setLoading(false);
        errorMessage('Could not save')
      })
    }).catch(error => {
      setLoading(false);
      errorMessage('Could not save')
    })
  }

  const enforceLimit = (num, min, max) => {
    return num > max ? max : (num < min ? min : num);
  }

  let inputProps = {};

  if(status !== 'custom' && routine !== 'custom') inputProps.disabled = true;

  const saveDisabled = !(edited || !cycles.length) || ! warming;

  const updateStatus = (val) => {
    setStatus(val);
    setRoutine(val);
  }

  const initialSetup = (val) => {
    const updated = updateWarming(true);
    updateStatus(val);

    //If we're doing the auto routine, this is a new email and we should have a hold
    if(val === 'auto'){
      setHold(1);
      setHoldDuration(5);
      setHoldMax(3);
    } else {
      setHold(0);
      setHoldDuration(0);
      setHoldMax(5);
    }

    if( updated ) setNewEmail(false);
  }

  if( newEmail ){
    return (
      <EmailPanel title="Warmup" loading={loading} onSave={null}>
        <div className="ms-auto me-auto section-size-xsm">
          <Card className="text-center">
            <Card.Body>
              <h5>It looks like {connectedEmail.email} is being configured for the first time</h5>
              <p className="mt-3">Boxward can recommend a configuration based on the history of this email. What best describes {connectedEmail.email}:</p>
              <div className="list-group">
                <div className="list-group-item cursor-pointer hover-bg-100" onClick={() => initialSetup('auto')}>
                  Brand new email
                </div>
                <div className="list-group-item cursor-pointer hover-bg-100" onClick={() => initialSetup('maintain')}>
                  Existing email with good deliverability
                </div>
                <div className="list-group-item cursor-pointer hover-bg-100" onClick={() => initialSetup('distressed')}>
                  Existing email with poor deliverability
                </div>
                <div className="list-group-item cursor-pointer hover-bg-100" onClick={() => initialSetup('rehab')}>
                  Existing email that has been blacklisted
                </div>
              </div>
              <div className="mt-4 text-danger cursor-pointer" onClick={() => initialSetup('custom')}>
                I want to configure manually
              </div>
            </Card.Body>
          </Card>
        </div>
      </EmailPanel>
    )
  }

  return (
    <EmailPanel title="Warmup" loading={loading} onSave={save} saveText="Update Schedule" saveDisabled={saveDisabled}>
      <h5 className="mb-3">Warmup Schedule Settings</h5>
      <Form.Group>
        <Form.Label for="warming">
          Warming Active
          <FormInfo id="tooltip-warming">
            Enable/Disable warming for this email
          </FormInfo>
        </Form.Label>
        <Toggle value={warming} update={(value) => updateWarming(value)} />
      </Form.Group>
      {
        loaded && ! warming && status === 'ready' ? (
          <p className="alert alert-primary mt-3">To start warming this email, activate warming, above.</p>
        ) : null
      }
      {
        loaded && firstSave && warming ? (
          <p className="alert alert-success mt-3">Great! Don't forget to save your warming schedule by clicking "Update Schedule" at the bottom.</p>
        ) : null
      }
      {
        warming && loaded ? (
          <>
            <Form.Group className="mt-3">
              <Form.Label for="status">
                Warmup Routine
                <FormInfo id="tooltip-status">
                  Select the a routine for warming up this email from our prebuilt routines or choose "Custom"
                </FormInfo>
              </Form.Label>
              <Form.Select onChange={(e) => updateStatus(e.target.value)} value={routine}>
                <option value="auto">Auto</option>
                <option value="maintain">Maintain</option>
                <option value="distressed">Distressed</option>
                <option value="rehab">Rehab</option>
                <option value="custom">Custom</option>
              </Form.Select>
              <Form.Text id="status-help" muted className="d-block mt-1">
                Choose "Auto" for a newly created email. Choose "Maintain" for an email that has been in use for some time. Choose "Distressed" for an email that has seen a recent drop in open rates. Choose "Rehab" for an email that you believe has been blacklisted. Choose "Custom" to create a custom schedule.
              </Form.Text>
            </Form.Group>
            <Row>
              <Col>
                <Form.Group className="mt-3">
                  <Form.Label for="conversation">
                    Target Conversation Rate
                    <FormInfo id="tooltip-conversation">
                      What percentage of emails sent will be part of a back-and-forth conversation
                    </FormInfo>
                  </Form.Label>
                  <Form.Control
                    placeholder={70}
                    value={conversation}
                    name="conversation"
                    onChange={(e) => setConversation(enforceLimit(parseInt(e.target.value, 10), 0, 100))}
                    type="number"
                    {...inputProps}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mt-3">
                  <Form.Label for="important">
                    Target Important Rate
                    <FormInfo id="tooltip-important">
                      What percentage of emails that you send will be marked as "important" by the recipient
                    </FormInfo>
                  </Form.Label>
                  <Form.Control
                    placeholder={70}
                    value={important}
                    name="important"
                    onChange={(e) => setImportant(enforceLimit(parseInt(e.target.value, 10), 0, 100))}
                    type="number"
                    {...inputProps}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mt-3">
                  <Form.Label for="start-time">
                    Start Time
                    <FormInfo id="tooltip-start-time">
                      The approximate time to start sending emails (7:00 recommended)
                    </FormInfo>
                  </Form.Label>
                  <Form.Select onChange={(e) => setStartTime(e.target.value)} value={startTime} {...inputProps}>
                    {
                      [...Array(24)].map((each, i) => <option value={i}>{`${i}:00`}</option>)
                    }
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mt-3">
                  <Form.Label for="end-time">
                    End Time
                    <FormInfo id="tooltip-end-time">
                      The approximate time to stop sending emails (21:00 recommended)
                    </FormInfo>
                  </Form.Label>
                  <Form.Select onChange={(e) => setEndTime(e.target.value)} value={endTime} {...inputProps}>
                    {
                      [...Array(24)].map((each, i) => <option value={i}>{`${i}:00`}</option>)
                    }
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mt-3">
                  <Form.Label for="min">
                    Minimum
                    <FormInfo id="tooltip-min">
                      Minimum emails sent in a given day
                    </FormInfo>
                  </Form.Label>
                  <Form.Control
                    placeholder={3}
                    value={min}
                    name="min"
                    onChange={(e) => setMin(enforceLimit(parseInt(e.target.value, 10), 0, 100))}
                    type="number"
                    {...inputProps}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mt-3">
                  <Form.Label for="max">
                    Maximum
                    <FormInfo id="tooltip-max">
                      Maximum emails that will be sent in a given day
                    </FormInfo>
                  </Form.Label>
                  <Form.Control
                    placeholder={50}
                    value={max}
                    name="max"
                    onChange={(e) => setMax(enforceLimit(parseInt(e.target.value, 10), 0, 100))}
                    type="number"
                    {...inputProps}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mt-3">
                  <Form.Label for="ramp">
                    Ramp
                    <FormInfo id="tooltip-ramp">
                      Number of emails to increase by each day
                    </FormInfo>
                  </Form.Label>
                  <Form.Control
                    placeholder={3}
                    value={ramp}
                    name="ramp"
                    onChange={(e) => setRamp(enforceLimit(parseInt(e.target.value, 10), 0, 20))}
                    type="number"
                    {...inputProps}
                  />
                  { max - min < ramp ? <div className="mt-1 fs--2 text-danger">Cannot be more than difference between Minimum and Maximum</div> : null }
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mt-3">
                  <Form.Label for="deviation">
                    Ramp Deviation
                    <FormInfo id="tooltip-deviation">
                      So as not to look robotic, this adds deviation to the "Ramp" value
                    </FormInfo>
                  </Form.Label>
                  <Form.Control
                    placeholder={3}
                    value={deviation}
                    name="deviation"
                    onChange={(e) => setDeviation(enforceLimit(parseInt(e.target.value, 10), 0, 30))}
                    type="number"
                    {...inputProps}
                  />
                  { max - min < deviation ? <div className="mt-1 fs--2 text-danger">Cannot be more than difference between Minimum and Maximum</div> : null }
                </Form.Group>
              </Col>
            </Row>
            <div className="alert alert-gray-200 mt-3">
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label for="min">
                      Hold
                      <FormInfo id="tooltip-min">
                        Hold emails at or below a certain level for a certain number of days before increasing volume. Useful for new emails.
                      </FormInfo>
                    </Form.Label>
                    <Toggle value={hold} numeric update={(value) => setHold(value)} />
                  </Form.Group>
                </Col>
                {
                  parseInt(hold, 10) ? (
                    <>
                      <Col>
                        <Form.Group>
                          <Form.Label for="max">
                            Hold Max
                            <FormInfo id="tooltip-max">
                              Maximum emails for this hold
                            </FormInfo>
                          </Form.Label>
                          <Form.Control
                            placeholder={3}
                            value={holdMax}
                            name="max"
                            onChange={(e) => setHoldMax(enforceLimit(parseInt(e.target.value, 10), 1, 100))}
                            type="number"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label for="max">
                            Hold Duration
                            <FormInfo id="tooltip-max">
                              The number of days to hold the email at or below the Hold Max before continuing to increase sending volume
                            </FormInfo>
                          </Form.Label>
                          <Form.Control
                            placeholder={4}
                            value={holdDuration}
                            name="max"
                            onChange={(e) => setHoldDuration(enforceLimit(parseInt(e.target.value, 10), 1, 10))}
                            type="number"
                          />
                        </Form.Group>
                      </Col>
                    </>
                  ) : null
                }
              </Row>
            </div>
            <div className="mt-5">
              {
                edited || ! cycles.length ? (
                  <h5 className="mb-3">
                    <span>Example Warmup Schedule</span>
                    <FormInfo id="tooltip-schedule">
                      Schedule generated may differ from this example due to Ramp Deviation value. Schedule takes into account current progress in warmup routine.
                    </FormInfo>
                  </h5>
                ) : (
                  <h5 className="mb-3">
                    <span>Upcoming Warmup Schedule</span>
                    <FormInfo id="tooltip-schedule">
                      Schedule generated may differ from this example due to Ramp Deviation value. Schedule takes into account current progress in warmup routine.
                    </FormInfo>
                  </h5>
                )
              }
              <Cycles
                start={parseInt(lastCycle.limit, 10) ? parseInt(lastCycle.limit, 10) : min}
                min={min}
                max={max}
                ramp={ramp}
                deviation={deviation}
                conversation={conversation}
                important={important}
                hold={hold}
                holdMax={holdMax}
                holdDuration={holdDuration}
                cycles={edited ? [] : cycles}
              />
              { parseInt(lastUpdated, 10) ? <div className="mt-3 fs--2"><em>Last Updated: {timestampToDate(lastUpdated)}</em></div> : null }
            </div>
          </>
        ) : ( loaded ? null : <Loader /> )
      }
    </EmailPanel>
  )
};

Schedule.propTypes = {

};

export default Schedule;
