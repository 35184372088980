import { createContext } from 'react';
import { settings } from 'config';

export const AppContext = createContext(settings);

export const AccountContext = createContext({
  user: {},
  account: {},
  plan: {},
  utility: {},
  connected_email: {}
});

export const AdminContext = createContext();

export const EmailContext = createContext({ emails: [] });

export const ProductContext = createContext({ products: [] });

export const FeedContext = createContext({ feeds: [] });

export const AuthWizardContext = createContext({ user: {} });

export const ChatContext = createContext();

export const KanbanContext = createContext({
  KanbanColumns: [],
  kanbanTasks: []
});

export const SpamCheckerContext = createContext({});