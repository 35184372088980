import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Api from 'api/api';
import { errorMessage, successMessage } from 'helpers/utils';
import Loader from '../common/Loader';

const StatusTest = ({
  connectedId,
  title = 'Run Delivery Test',
  onCheckUpdate = false,
  onCheckFinish = false,
  removeTitleOnFinish = false,
  current,
  runNew = true,
  showSMTP = true,
  showIMAP = true
}) => {

  const [loading, setLoading] = useState(false);
  const [runNewTest, setRunNewTest] = useState(runNew);

  const [testing, setTesting] = useState(false);
  const [testId, setTestId] = useState('');
  const [checkId, setCheckId] = useState('');

  const checkIdRef = useRef(checkId);
  checkIdRef.current = checkId;

  const testIdRef = useRef(testId);
  testIdRef.current = testId;

  const [imap, setImap] = useState(false);
  const [imapError, setImapError] = useState(false);
  const [imapResult, setImapResult] = useState(false);

  const imapResultRef = useRef(imapResult);
  imapResultRef.current = imapResult;

  const imapRef = useRef(imap);
  imapRef.current = imap;

  const [smtp, setSMTP] = useState(false);
  const [smtpError, setSMTPError] = useState(false);
  const [smtpResult, setSMTPResult] = useState(false);
  const [smtpfull, setSMTPFull] = useState({});

  const [dkim, setDKIM] = useState(false);
  const [dkimRecord, setDKIMRecord] = useState(false);
  const [dkimMessage, setDKIMMessage] = useState(false);

  const [spf, setSPF] = useState(false);
  const [spfRecord, setSPFRecord] = useState(false);
  const [spfMessage, setSPFMessage] = useState(false);

  const [arc, setARC] = useState(false);
  const [arcMessage, setARCMessage] = useState(false);

  const [dmarc, setDMARC] = useState(false);
  const [dmarcRecord, setDMARCRecord] = useState(false);
  const [dmarcMessage, setDMARCMessage] = useState(false);
  
  const [bimi, setBIMI] = useState(false);
  const [bimiMessage, setBIMIMessage] = useState(false);

  const finish = (theSMTPResult) => {
    setTesting(false);

    let newStatus = false;
    theSMTPResult = theSMTPResult !== undefined ? theSMTPResult : smtpResult;

    const theIMAPResult = imapResultRef.current;

    if( onCheckUpdate === true ){
      if( theIMAPResult === 0 || theSMTPResult === 0 ){
        if( ['new', 'connecting', 'connected', 'ready', 'reconnecting'].includes(current) ){
          if( theIMAPResult === 0 ) newStatus = 'error';
          else if( theSMTPResult === 0 ) newStatus = 'smtpError';
        }
        if( ['error', 'authenticationError'].includes(current) && theSMTPResult === 0 && theIMAPResult !== 0 ) newStatus = 'smtpError';
        if( ['smtpError'].includes(current) && theIMAPResult === 0 ) newStatus = 'error';
        if( ['warming'].includes(current) ) newStatus = 'disconnected';
      } else if(imapRef.current === 'connected'){
        if( ['new', 'connecting', 'authenticationError', 'error', 'smtpError'].includes(current) ) newStatus = 'ready';
        if( ['disconnected', 'reconnecting'].includes(current) ) newStatus = 'warming';
      } else if(imapRef.current === 'connecting'){
        if( ['new'].includes(current) ) newStatus = 'connecting';
      }

      if( newStatus ){
        Api.doRequest('connected_emails', 'update_status', 'update', {update:{
          status: newStatus,
          id: connectedId
        }})
      }
      
      /*if( ( current === 'new' || current === 'connecting' ) ){
        if( result.data === 'connected' ){
          newStatus = 'ready';
        } else if( result.data === 'authenticationError' || result.data === 'error' ){
          newStatus = result.data
          success = false;
        }
      } else if( current === 'authenticationError' || current === 'error' ){
        if( result.data === 'connected' ){
          newStatus = 'ready';
        } else if( result.data === 'connecting' ){
          newStatus = 'connecting';
        } else {
          success = false;
        }
      } else if( current === 'disconnected' ){
        if( result.data === 'connected' ){
          newStatus = 'warming';
        } else if( result.data === 'connecting' ){
          newStatus = 'connecting';
        } else {
          success = false;
        }
      } else if( current === 'smtpError' ){
        if( result.data === 'connected' ){
          newStatus = 'ready';
        } else if( result.data === 'connecting' ){
          newStatus = 'ready';
        } else {
          success = false;
        }
      } else if( current === 'ready' ){
        if( result.data === 'authenticationError' || result.data === 'error' ){
          newStatus = result.data
          success = false;
        }
      } else if( current === 'warming' ){
        if( result.data === 'authenticationError' || result.data === 'error' ){
          newStatus = result.data
          success = false;
        }
      }*/
    }

    if( onCheckFinish !== false ){
      onCheckFinish({
        connectedId, newStatus, imap, smtp, theSMTPResult
      });
    }
  }

  const smtpTestResult = (iteration = 0, options = false) => {
    if( iteration > 5 ){
      setSMTP('Could not determine status');
      finish();
      return;
    }
    options = options ? options : {params: [
        {
          param: 'cce',
          value: connectedId
        },
        {
          param: 'test_id',
          value: testIdRef.current
        },
        {
          param: 'check_id',
          value: checkIdRef.current
        },
      ]}
    setTimeout(() => {
      Api.doRequest('connected_email_check', 'get_delivery_test', 'get', null, options).then(result => {
        if(result?.data && result.data !== 'wait'){
          setSMTPFull(result.data);

          let theSMTPResult = false;

          if( result.data?.smtp_result ){
            setSMTP(result.data.smtp_result);
            if( result.data.smtp_result === 'fail' ) {
              setSMTPResult(0);
              theSMTPResult = 0;
            } else {
              setSMTPResult(1);
              theSMTPResult = 1;
            }
          }
          if( result.data?.smtp_error ) setSMTPError(result.data.smtp_error);

          if( result.data?.dkim_result ) setDKIM(result.data.dkim_result);
          if( result.data?.dkim_message ) setDKIMMessage(result.data.dkim_message);
          if( result.data?.dkim_record ) setDKIMRecord(result.data.dkim_record);

          if( result.data?.spf_result ) setSPF(result.data.spf_result);
          if( result.data?.spf_message ) setSPFMessage(result.data.spf_message);
          if( result.data?.spf_record ) setSPFRecord(result.data.spf_record);

          if( result.data?.arc_result ) setARC(result.data.arc_result);
          if( result.data?.arc_message ) setARCMessage(result.data.arc_message);

          if( result.data?.dmarc_result ) setDMARC(result.data.dmarc_result);
          if( result.data?.dmarc_message ) setDMARCMessage(result.data.dmarc_message);
          if( result.data?.dmarc_record ) setDMARCRecord(result.data.dmarc_record);

          if( result.data?.bimi_result ) setBIMI(result.data.bimi_result);
          if( result.data?.bimi_message ) setBIMIMessage(result.data.bimi_message);

          if( !checkId && result.data?.check_id ) setCheckId(result.data.check_id);

          finish(theSMTPResult);
        } else if(runNewTest) {
          smtpTestResult(iteration + 1);
        }

        if( ! runNewTest ) setLoading(false);
      }).catch(error => {
        console.log('delivery test error', error);
        setTesting(false);
        setSMTP('failed');
        setSMTPResult(0);
        finish();
        if( ! runNewTest ) setLoading(false);
      })
    }, 3000);
  }

  const smtpTest = () => {
    Api.doRequest('connected_email_check', 'create_delivery_test', 'update', {update: {
        id: connectedId,
        check_id: checkId
      }}).then(result => {
      setTesting(true);
      setTestId(result.data);
      smtpTestResult();
    }).catch(error => {
      errorMessage(error.message);
      finish();
    })
  }

  //Create the check - 'connected_email_check', 'create'
  //Check imap - 'connected_email_check', 'check_status'
  //Create smtp test - 'connected_email_check', 'create_delivery_test'
  //Check smtp test results - 'connected_email_check', 'get_delivery_test'
  useEffect(() => {
    if( runNewTest ){
      createCheck();
    } else {
      setLoading(true);
      smtpTestResult(0, {params: [
          {
            param: 'cce',
            value: connectedId
          },
          {
            param: 'get_last',
            value: 1
          }
        ]});
    }
  }, []);

  const createCheck = () => {
    Api.doRequest('connected_email_check', 'create', 'update', {update: {
        id: connectedId
      }}).then(result => {
      setTesting(true);
      if(result?.data?.id){
        setCheckId(result?.data?.id);

        Api.doRequest('connected_email_check', 'check_status', 'get', null, {
          params: [
            {param: 'check_id', value: result.data.id},
            {param: 'cce', value: connectedId}
          ]
        }).then(result => {
          setImap(result.data);
          setImapResult(1);
          smtpTest();
        }).catch(error => {
          setImap('error');
          setImapError(error.message);
          setImapResult(0);
          smtpTest();
        })

      } else {
        errorMessage('Could not create test');
        finish();
      }
    }).catch(error => {
      errorMessage('', error);
      finish();
    })
  }

  const doNewTest = () => {
    setRunNewTest(true);
    createCheck();
  }

  const titleElement = title && ( ! removeTitleOnFinish || testing ) ? <div className="mb-2"><strong>{title}</strong></div> : null;

  if( ! runNewTest && loading ){
    return (
      <>
        { titleElement }
        <Loader />
      </>
    );
  } else if( ! runNewTest && ! loading && ! dkim ){
    return (
      <>
        { titleElement }
        <Button variant="primary" onClick={() => doNewTest()}>Check DNS</Button>
      </>
    )
  }

  return (
    <>
      { titleElement }
      {
        checkId ? (
          <div className="list-group">
            {
              showIMAP ? (
                <div className="list-group-item">
                  <div className="d-flex align-items-center justify-content-between">
                    <span>IMAP</span>
                    {
                      imap === false && testing ? (
                        <FontAwesomeIcon icon="sync-alt" className="fa-spin text-primary" />
                      ) : (
                        <div
                          className={classNames('badge', {
                            ['badge-soft-gray-300']: ! ['error', 'authenticationError', 'connecting', 'connected'].includes(imap),
                            ['badge-soft-danger']: imap === 'error' || imap === 'authenticationError',
                            ['badge-soft-success']: imap === 'connected',
                            ['badge-soft-primary']: imap === 'connecting'
                          })}
                        >{imap}</div>
                      )
                    }
                  </div>
                  { imapError ? <div className="mt-2 alert alert-danger text-wrap fs--1">{imapError}</div> : null }
                </div>
              ) : false
            }
            {
              showSMTP ? (
                <div className="list-group-item">
                  <div className="d-flex align-items-center justify-content-between">
                    <span>SMTP</span>
                    {
                      smtp === false && testing ? (
                        <FontAwesomeIcon icon="sync-alt" className="fa-spin text-primary" />
                      ) : (
                        <div
                          className={classNames('badge', {
                            ['badge-soft-gray-300']: ! ['success', 'fail'].includes(smtp),
                            ['badge-soft-danger']: smtp === 'fail',
                            ['badge-soft-success']: smtp === 'success'
                          })}
                        >{smtp}</div>
                      )
                    }
                  </div>
                  { smtpError ? <div className="mt-2 alert alert-danger text-wrap fs--1">{smtpError}</div> : null }
                </div>
              ) : null
            }
            <div className="list-group-item">
              <div className="d-flex align-items-center justify-content-between">
                <span>SPF</span>
                {
                  spf === false && testing ? (
                    <FontAwesomeIcon icon="sync-alt" className="fa-spin text-primary" />
                  ) : (
                    <div
                      className={classNames('badge', {
                        ['badge-soft-gray-300']: spf !== 'pass' && spf !== 'fail',
                        ['badge-soft-danger']: spf === 'fail',
                        ['badge-soft-success']: spf === 'pass'
                      })}
                    >{spf}</div>
                  )
                }
              </div>
              { spfMessage ? <div className="mt-2 alert alert-gray-200 text-wrap fs--1">{spfMessage}</div> : null }
              { spfRecord ? <div className="mt-2 alert alert-gray-200 text-wrap fs--1">{spfRecord}</div> : null }
            </div>
            <div className="list-group-item">
              <div className="d-flex align-items-center justify-content-between">
                <span>DKIM</span>
                {
                  dkim === false && testing ? (
                    <FontAwesomeIcon icon="sync-alt" className="fa-spin text-primary" />
                  ) : (
                    <div
                      className={classNames('badge', {
                        ['badge-soft-gray-300']: dkim !== 'pass' && dkim !== 'fail',
                        ['badge-soft-danger']: dkim === 'fail',
                        ['badge-soft-success']: dkim === 'pass'
                      })}
                    >{dkim}</div>
                  )
                }
              </div>
              { dkimMessage ? <div className="mt-2 alert alert-gray-200 text-wrap fs--1">{dkimMessage}</div> : null }
              { dkimRecord ? <div className="mt-2 alert alert-gray-200 text-wrap fs--1">{dkimRecord}</div> : null }
            </div>
            <div className="list-group-item">
              <div className="d-flex align-items-center justify-content-between">
                <span>DMARC</span>
                {
                  dmarc === false && testing ? (
                    <FontAwesomeIcon icon="sync-alt" className="fa-spin text-primary" />
                  ) : (
                    <div
                      className={classNames('badge', {
                        ['badge-soft-gray-300']: dmarc !== 'pass' && dmarc !== 'fail',
                        ['badge-soft-danger']: dmarc === 'fail',
                        ['badge-soft-success']: dmarc === 'pass'
                      })}
                    >{dmarc}</div>
                  )
                }
              </div>
              { dmarcMessage ? <div className="mt-2 alert alert-gray-200 text-wrap fs--1">{dmarcMessage}</div> : null }
              { dmarcRecord ? <div className="mt-2 alert alert-gray-200 text-wrap fs--1">{dmarcRecord}</div> : null }
            </div>
            {/*<div className="list-group-item">
              <div className="d-flex align-items-center justify-content-between">
                <span>ARC</span>
                <div
                  className={classNames('badge', {
                    ['badge-soft-gray-300']: arc !== 'pass' && arc !== 'fail',
                    ['badge-soft-danger']: arc === 'fail',
                    ['badge-soft-success']: arc === 'pass'
                  })}
                >{arc}</div>
              </div>
              { arcMessage ? <div className="mt-2 alert alert-gray-200 text-wrap fs--1">{arcMessage}</div> : null }
            </div>*/}
            <div className="list-group-item">
              <div className="d-flex align-items-center justify-content-between">
                <span>BIMI</span>
                {
                  bimi === false && testing ? (
                    <FontAwesomeIcon icon="sync-alt" className="fa-spin text-primary" />
                  ) : (
                    <div
                      className={classNames('badge', {
                        ['badge-soft-gray-300']: bimi !== 'pass' && bimi !== 'fail',
                        ['badge-soft-danger']: bimi === 'fail',
                        ['badge-soft-success']: bimi === 'pass'
                      })}
                    >{bimi}</div>
                  )
                }
              </div>
              { bimiMessage ? <div className="mt-2 alert alert-gray-200 text-wrap fs--1">{bimiMessage}</div> : null }
            </div>
          </div>
        ) : ( runNewTest ? <div>Starting test...</div> : null )
      }
    </>
  )
}

export default StatusTest;