import React, { useContext } from 'react';
import classNames from 'classnames';
import { Button, Form, InputGroup, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import API from 'api/api';
import TooltipItem from 'components/common/TooltipItem';
import FormInfo from 'components/common/FormInfo';
import { SpamCheckerContext } from 'context/Context';
import { getUserIP } from 'helpers/utils';

const SpamCheckerDomain = ({
  cardWrap = true
}) => {
  const { domainIP, setDomainIP, analyzingDomain, setAnalyzingDomain, domainHealth, setDomainHealth, domainHealthDefault, userIP, setUserIP, disabled, setDisabled } = useContext(SpamCheckerContext);

  const maxWidth = '800px';
  const maxWidthStyle = {maxWidth};

  if( disabled ){
    return (
      <div className="d-flex justify-content-center py-3">
        <div style={maxWidthStyle} className="alert alert-danger">Too many requests. Please check back in 15 minutes.</div>
      </div>
    )
  }

  if( ! userIP ){
    getUserIP().then((ip) => {
      setUserIP(ip); // logs the user's IP address
    }).catch((error) => {
      console.error(error); // logs the error message if there was a problem getting the IP address
    });
  }

  const analyze = (e = false) => {
    if(e) e.preventDefault();

    setDomainHealth(domainHealthDefault);
    setAnalyzingDomain(true);

    API.doRequest('projects', 'get', 'get', null, {
      params: [
        { param: 'project', value: 'domain_health' },
        { param: 'domainip', value: domainIP },
        { param: 'userip', value: userIP }
      ]
    }).then(result => {
      setDomainHealth({
        ...domainHealth,
        ...result.data
      });
      setAnalyzingDomain(false);
    }).catch(error => {
      if( error.code === 'rate_limit' ){
        setDisabled(true);
      }
      setAnalyzingDomain(false);
    })
  }

  let body = (
    <div className="width-100" style={maxWidthStyle}>
      <Form onSubmit={ (e) => analyze(e) }>
        <Form.Group className="mb-5">
          <Form.Label>Your Email, Domain, or IP Address</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control value={ domainIP } onChange={(e) => setDomainIP(e.target.value)} type="text" placeholder="Domain or IP" />
            <Button variant="primary" onClick={ analyze }>
              {
                analyzingDomain ? <FontAwesomeIcon icon="sync-alt" className="fa-spin me-2" /> : null
              }
              Analyze
            </Button>
          </InputGroup>
        </Form.Group>
      </Form>
      {
        domainHealth.has_records && ( domainHealth.is_domain || domainHealth.is_email ) ? (
          <div className="mt-4">
            <h6>DNS Check<FormInfo>Checks for the existence of records</FormInfo></h6>
            <ul className="list-group">
              {
                Object.keys(domainHealth.has_records).sort((a,b) => {
                  if (a === 'DKIM' && b !== 'DKIM') {
                    return 1;
                  } else if (b === 'DKIM' && a !== 'DKIM') {
                    return -1;
                  }

                  return 0;
                } ).map(each => {
                  const isDKIM = each === 'DKIM';

                  let content = (
                    <div className="d-flex align-items-center">
                      <div className={classNames("badge", {
                        ['badge-soft-success']: domainHealth.has_records[each],
                        ['badge-soft-danger']: ! domainHealth.has_records[each],
                      })}>{ domainHealth.has_records[each] ? <FontAwesomeIcon icon="check" className="me-2" /> : <FontAwesomeIcon icon="times" className="me-2" /> }{each}</div>
                    </div>
                  );

                  if( isDKIM ){
                    content = (
                      <div>
                        <div className="mb-3"><div className="badge badge-soft-primary me-3">{each}</div> Evaluating DKIM records requires knowing the DKIM selector, which typically looks something like this, "dk._domainkey." This can be found in your DNS records. DKIM records are not yet evaluated by this tool.</div>
                      </div>
                    )
                  }

                  return (
                    <li className={
                      classNames("list-group-item", {
                        ['mt-3']: isDKIM,
                        ['border-top'] : isDKIM
                      })
                    }>
                      { content }
                      {
                        each === 'SPF' ? <div className="mt-2 fs--1">SPF (Sender Policy Framework) is a type of DNS record that specifies which mail servers are authorized to send emails on behalf of a domain. It allows domain owners to specify which IP addresses are permitted to send emails from their domain, and it helps prevent spam and phishing attacks. When receiving mail servers receive an email, they check the SPF record to verify that the sending server is authorized to send mail from that domain. If the sending server is not authorized, the email may be marked as spam or rejected altogether.</div> : null
                      }
                      {
                        each === 'DMARC' ? <div className="mt-2 fs--1">DMARC (Domain-based Message Authentication, Reporting & Conformance) is a policy record that specifies how email receivers should handle messages that fail SPF or DKIM authentication checks. It provides domain owners with visibility into how their domain is being used for email, and it helps prevent fraudulent use of their domain for phishing and spam attacks. DMARC also provides guidance to receiving mail servers on how to handle messages that fail authentication checks, such as quarantining or rejecting them. Implementing DMARC can help improve email deliverability and protect a domain's reputation.</div> : null
                      }
                      {
                        each === 'DKIM' ? <div className="mt-2 fs--1">DKIM (DomainKeys Identified Mail) is another type of DNS record that adds a digital signature to email messages, allowing receiving mail servers to verify that the message came from the specified domain and that it has not been altered in transit. The DKIM signature provides an additional layer of security and helps prevent spoofing and phishing attacks. It also helps improve email deliverability by reducing the likelihood of legitimate emails being marked as spam.</div> : null
                      }
                    </li>
                  )
                })
              }
            </ul>
          </div>
        ) : null
      }
      {
        domainHealth.records && ( domainHealth.is_domain || domainHealth.is_email ) ? (
          <div className="mt-4">
            <h6>DNS Records</h6>
            <ul className="list-group">
              {
                domainHealth.records.filter(each => each !== null).map(each => {
                  let value = '';

                  if( each.type === 'A' ) value = each.ip;
                  if( each.type === 'MX' ) value = each.target;
                  if( each.type === 'TXT' ) value = each.txt;
                  if( each.type === 'SPF' ) value = each.txt;
                  if( each.type === 'DMARC' ) value = each.txt;
                  if( each.type === 'DKIM' ) value = each.txt;

                  if( ! value ) return null;

                  return (
                    <li className="list-group-item d-flex align-items-center">
                      <div className="badge badge-soft-primary me-3">{each.type}</div>
                      { each.type === 'SPF' ? <div className={classNames('badge me-3', {
                        ['badge-soft-success']: domainHealth.spf_match === 'pass',
                        ['badge-soft-danger']: domainHealth.spf_match === 'fail',
                        ['badge-soft-dark']: domainHealth.spf_match === 'unknown',
                      })}>{domainHealth.spf_match}</div> : null }
                      <pre style={{whiteSpace: 'pre-line'}}>{value}</pre>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        ) : null
      }
      {
        domainHealth.spf_domains && ( domainHealth.is_domain || domainHealth.is_email ) ? (
          <div className="mt-4">
            <h6>SPF References <FormInfo>SPF records often refer to domains that then refer to other domains in their SPF records</FormInfo></h6>
            <ul className="list-group">
              {
                domainHealth.spf_domains.map(each => {
                  return (
                    <li className="list-group-item">
                      <pre style={{whiteSpace: 'pre-line'}}>{each}</pre>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        ) : null
      }
      {
        domainHealth.errors && domainHealth.errors.length ? (
          <div className="mt-4">
            <h6>DNS Errors</h6>
            <ul className="list-group">
              {
                domainHealth.errors.map(each => {
                  return (
                    <li className="list-group-item">
                      <div className="alert alert-danger">{each}</div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        ) : null
      }
      {
        domainHealth.blacklists && (
          <div className="mt-4">
            <h6>Blacklist Check</h6>
            {
              domainHealth.blacklists.length ? (
                <ul className="list-group">
                  {
                    domainHealth.blacklists.sort((a,b) => {
                      return a.name.localeCompare(b.name);
                    }).sort((a,b) => {
                      // sort by value first (true before false)
                      if (a.result && !b.result) {
                        return -1;
                      } else if (!a.result && b.result) {
                        return 1;
                      }
                    }).map(each => {
                      return (
                        <li className="list-group-item d-flex align-items-center">
                          {each.result ? <TooltipItem tooltip="Listed"><FontAwesomeIcon icon="times" className="me-2 text-danger" /></TooltipItem> : <TooltipItem tooltip="Not listed"><FontAwesomeIcon icon="check" className="me-2 text-success" /></TooltipItem> }
                          <strong>{each.name}</strong>
                        </li>
                      )
                    })
                  }
                </ul>
              ) : <div>Not found on any blacklists</div>
            }
          </div>
        )
      }
      {
        domainHealth.whitelists && (
          <div className="mt-4">
            <h6>Whitelist Check <FormInfo>Being found on a whitelist is good for deliverability</FormInfo></h6>
            {
              domainHealth.whitelists.length ? (
                <ul className="list-group">
                  {
                    domainHealth.whitelists.sort((a,b) => {
                      return a.name.localeCompare(b.name);
                    }).sort((a,b) => {
                      // sort by value first (true before false)
                      if (a.result && !b.result) {
                        return -1;
                      } else if (!a.result && b.result) {
                        return 1;
                      }
                    }).map(each => {
                      return (
                        <li className="list-group-item d-flex align-items-center">
                          {each.result ? <TooltipItem tooltip="Listed"><FontAwesomeIcon icon="check" className="me-2 text-success" /></TooltipItem> : <TooltipItem tooltip="Not listed"><FontAwesomeIcon icon="minus" className="me-2 text-gray-400" /></TooltipItem> }
                          <strong>{each.name}</strong>
                        </li>
                      )
                    })
                  }
                </ul>
              ) : <div>Not found on any whitelists</div>
            }
          </div>
        )
      }
    </div>
  )

  if( cardWrap ){
    body = (
      <Card className="mb-3 width-100" style={ maxWidthStyle }>
        <Card.Body>
          { body }
        </Card.Body>
      </Card>
    )
  }

  return (
    <div>
      <div className="mt-5 d-flex justify-content-center">
        { body }
      </div>
    </div>
  );
};

export default SpamCheckerDomain;
