import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { AdminContext } from 'context/Context';
import { adminReducer } from 'reducers/adminReducer';

const AdminProvider = ({ children }) => {
  const initData = {
    plans: [],
    plansItems: [],
    plansStructure: [],
    databaseUpdates: []
  };

  const [adminState, adminDispatch] = useReducer(adminReducer, initData);

  useEffect(() => {
    //Load Admin Dashboard stuff
  }, []);

  return (
    <AdminContext.Provider
      value={{
        adminState,
        adminDispatch
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

AdminProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AdminProvider;
