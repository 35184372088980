import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import SettingsPage from './SettingsPage';
import { AccountContext } from 'context/Context';
import { errorMessage, getURLParameter, hasUserPermission, successMessage } from '../../../helpers/utils';
import API from '../../../api/api';

const Redeem = () => {
  const [code, setCode] = useState(getURLParameter('code'));
  const [loading, setLoading] = useState(false);

  if( ! hasUserPermission('plan') ){
    return (
      <SettingsPage title="Redeem" loading={loading} onSave={null}>
        <div className="alert alert-danger">You do not have permission to redeem credits for this account. Please speak with the account's admin.</div>
      </SettingsPage>
    )
  }

  //The empty array as the second argument makes this run like componentDidMount
  //https://reacttraining.com/blog/useEffect-is-not-the-new-componentDidMount/
  useEffect(() => {

  }, []);

  const redeem = (e) => {
    e.preventDefault();

    API.doRequest('plans_accounts', 'redeem', 'update', {update: {code}}).then(result => {
      successMessage('', result);
    }).catch(error => {
      errorMessage('', error);
    })
  }

  return (
    <SettingsPage title="Redeem" loading={loading} onSave={null}>
      <Row>
        <Col>
          <Form onSubmit={redeem}>
            <Form.Group>
              <Form.Label>Redemption Code</Form.Label>
              <InputGroup>
                <Form.Control
                  type="text"
                  defaultValue={ code ? code : '' }
                  onChange={(e) => setCode(e.target.value)}
                />
                <Button variant="primary" type="submit">
                  Redeem
                </Button>
              </InputGroup>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </SettingsPage>
  );
};

export default Redeem;
