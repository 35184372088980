import React, { useEffect, useContext } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { AdminContext } from '../context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import API from '../api/api';

const DatabaseUpdates = () => {
  const { adminState, adminDispatch } = useContext(AdminContext);

  //The empty array as the second argument makes this run like componentDidMount
  //https://reacttraining.com/blog/useEffect-is-not-the-new-componentDidMount/
  useEffect(() => {
    //Get super/database updates, merge with super/platform and update the adminReducer
    API.doRequest('super', 'get_updates', 'get', null, {
      params: [
        { param: 'super', value: 'database' }
      ]
    }).then(updates => {
      API.doRequest('super', 'get', 'get', null, {
        params: [
          { param: 'super', value: 'platform' },
          { param: 'category', value: 'database_update' }
        ]
      }).then(platform => {
        const arranged = {};

        platform.data.forEach(each => {
          if (arranged[each.category] === undefined) arranged[each.category] = {};
          if (arranged[each.category][each.subcategory] === undefined) arranged[each.category][each.subcategory] = {};

          arranged[each.category][each.subcategory][each.metakey] = parseInt(each.value, 10);
        })

        adminDispatch({
          type: 'SET_DATABASE_UPDATES',
          payload: updates.data.map(each => {
            return {
              category: 'database_update',
              subcategory: each.directory,
              metakey: each.name,
              value: arranged.database_update !== undefined
              && arranged.database_update[each.directory] !== undefined
                ? arranged.database_update[each.directory][each.name] === 1 : false
            }
          })
        });
      }).catch(error => {
        console.log('platform error', error);
      });
    });
  }, []);

  const runUpdate = (directory, name) => {
    API.doRequest('super', 'do_update', 'update', { directory, name }, {
      params: [
        { param: 'super', value: 'database' }
      ]
    }).then(result => {
      toast.success(result.message);
      adminDispatch({
        type: 'UPDATE_DATABASE',
        payload: {
          category: 'database_update',
          subcategory: directory,
          metakey: name,
          value: true
        }
      });
    }).catch(error => {
      console.log('run update error', error);
      toast.error(error.message);
    });
  };

  const columns = [
    {
      accessor: 'subcategory',
      Header: 'Directory',
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'metakey',
      Header: 'Update Name'
    },
    {
      accessor: 'value',
      Header: 'Has Run',
      Cell: ({ cell: { row: { values } } }) => {
        return values.value ? <FontAwesomeIcon className="text-success" icon="check-circle" /> :
          <FontAwesomeIcon className="text-danger" icon="times-circle" />
      }
    },
    {
      accessor: 'none',
      Header: '',
      disableSortBy: true,
      Cell: ({ cell: { row: { values } } }) => (
        <Button onClick={() => runUpdate(values.subcategory, values.metakey)}>Run</Button>
      )
    },
  ];

  console.log('adminState DB Updates', adminState);

  if (adminState.databaseUpdates === undefined || !adminState.databaseUpdates.length) return null;

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={adminState.databaseUpdates}
      sortable
      pagination
      perPage={200}
    >
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Database Updates</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default DatabaseUpdates;
