import React from 'react';
import PropTypes from 'prop-types';
import Sec from 'components/common/Sec';

const Main = ({ children }) => {
  return (
    <Sec size="xlg">
      {children}
    </Sec>
  );
};
Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
