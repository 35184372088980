import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import is from 'is_js';
import MainLayoutWrap from './MainLayoutWrap';
import SpamCheckerWrap from 'projects/spam/SpamCheckerWrap';
import SpamWordsWrap from 'projects/spamwords/SpamWordsWrap';
import ErrorLayout from './ErrorLayout';
import Registration from 'components/authentication/custom/Registration';
import Invite from 'components/authentication/custom/Invite';
import Login from 'components/authentication/custom/Login';
import Logout from 'components/authentication/custom/Logout';
import ForgetPassword from 'components/authentication/custom/ForgetPassword';
import PasswordReset from 'components/authentication/custom/PasswordReset';
import AppSumoLogin from 'components/authentication/custom/AppSumoLogin';
import { ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';
import ConfirmModal from 'components/common/ConfirmModal';
import PlatformNotice from 'components/common/PlatformNotice';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Switch>
        <Route path="/projects/spam" component={SpamCheckerWrap} />
        <Route path="/projects/spamwords" component={SpamWordsWrap} />
        <Route path="/errors" component={ErrorLayout} />
        <Route path="/register" component={Registration} />
        <Route path="/appsumo-register" component={AppSumoLogin} />
        <Route path="/invite" component={Invite} />
        <Route path="/signin" component={Login} />
        <Route path="/signout" component={Logout} />
        <Route path="/forgot-password" component={ForgetPassword} />
        <Route path="/reset-password" component={PasswordReset} />
        <Route component={MainLayoutWrap} />
        <Redirect to="/errors/404" />
      </Switch>
      <ConfirmModal />
      <ToastContainer
        closeButton={CloseButton}
        position={'bottom-right'}
      />
      <PlatformNotice />
    </>
  );
};

export default Layout;
