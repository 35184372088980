import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import ManagePlansItem from "./ManagePlansItem";
import classNames from 'classnames';
import Section from 'components/common/Sec';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { generateId } from '../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppContext, AdminContext } from "../context/Context";
import API from '../api/api';
import { loadPlans } from './load/plans';
import { toast } from 'react-toastify';
import DropdownFilter from '../components/common/DropdownFilter';

const ManagePlans = () => {
  const { adminDispatch } = useContext(AdminContext);
  const { appConfirm } = useContext(AppContext);

  const [items, setItems] = useState([
    {
      uniqueId: generateId('plan'),
      name: '',
      type: 'main',
      period: 'monthly',
      is_default: 0,
      show: 0,
      enabled: 1,
      price: 9900,
      full_price: 0,
      product_id: '',
      trigger: '',
      structure: [],
      open: false
    }
  ]);

  //console.log('items', items);

  const [search, setSearch] = useState('');
  const [type, setType] = useState('');
  const [period, setPeriod] = useState('');

  useEffect(() => {
    loadPlans(adminDispatch, setItems);
  }, [])

  const save = () => {
    const update = items.map((each, index) => {
      return {
        id: each.id !== undefined ? each.id : '',
        created: each.created !== undefined ? each.created : 0,
        name: each.name,
        max: each.max,
        type: each.type,
        period: each.period,
        is_default: each.is_default,
        show: each.show,
        enabled: each.enabled,
        price: each.price,
        full_price: each.full_price,
        product_id: each.product_id,
        trigger: each.trigger,
        order: index,
        permissions: each.structure.filter(eachS => eachS.type === 'permission'),
        allowances: each.structure.filter(eachS => eachS.type === 'allowance'),
        infos: each.structure.filter(eachS => eachS.type === 'info' || eachS.type === 'heading'),
        meta: each.meta
      }
    });

    API.doRequest('plans', 'create_bulk', 'update', { update }).then(result => {
      toast.success('Saved!');

      //Update the redux store
      loadPlans(adminDispatch, setItems);
    }).catch(error => {
      toast.error('Error');
    });
  };

  const handleAddPlan = () => {
    setItems([
      ...items,
      {
        uniqueId: generateId('plan'),
        name: '',
        type: 'main',
        period: 'monthly',
        is_default: 0,
        show: 0,
        price: 9900,
        full_price: 0,
        product_id: '',
        trigger: '',
        structure: [],
        open: false
      }
    ]);
  };
  const doRemove = (uniqueId, id) => {
    setItems(items.filter(item => item.uniqueId !== uniqueId));

    //Update the redux store
    adminDispatch({
      type: 'SET_PLANS',
      payload: items
    });

    if (id) {
      API.doRequest('plans', 'delete', 'delete', { item: id }).then(result => {
        toast.success('Plan Deleted');
      }).catch(error => {
        toast.error('Error');
      });
    }
  }

  const handleRemove = (uniqueId, id) => {
    if (!id) {
      setItems(items.filter(item => item.uniqueId !== uniqueId));
    } else {
      appConfirm({
        header: 'Really delete this plan?',
        body: 'This action cannot be undone',
        confirmWithText: 'DELETE',
        confirm: () => doRemove(uniqueId, id)
      });
    }

  };

  const handleChange = (uniqueId, name, value) => {
    setItems(items.map(each => {
      if (each.uniqueId === uniqueId) {
        each[name] = value;
      }
      return each;
    }));
  };

  const handleMetaChange = (uniqueId, metakey, value) => {
    setItems(items.map(each => {
      if (each.uniqueId === uniqueId) {
        if(each.meta === undefined) each.meta = {};
        each.meta[metakey] = value;
      }
      return each;
    }));
  }

  const handleStructureChange = (uniqueId, item, name, value) => {
    console.log('handleStructureChange', { items, uniqueId, item, name, value });
    setItems(items.map(each => {
      if (each.uniqueId === uniqueId) {
        each.structure = each.structure.map(struc => {
          if (parseInt(struc.item, 10) === parseInt(item)) {
            struc[name] = value;
          }
          return struc;
        });
      }
      return each;
    }));
  }

  const handleAddOverageTier = (uniqueId, structureItem) => {
    console.log({
      items, uniqueId, structureItem
    })
    setItems(items.map(each => {
      if (each.uniqueId === uniqueId) {
        each.structure = each.structure.map(struc => {
          if( parseInt(struc.item, 10) === parseInt(structureItem, 10) ){
            if( struc.overage_tiers === undefined ) struc.overage_tiers = [];

            struc.overage_tiers = struc.overage_tiers.concat({
              tempId: generateId('ot'),
              cost: 0,
              min: 0,
              max: 0
            })
          }

          return struc;
        })
      }
      return each;
    }));
  }

  const handleOverageTierChange = (uniqueId, structureItem, item, name, value) => {
    console.log({
      items, uniqueId, structureItem, item, name, value
    })
    setItems(items.map(each => {
      if (each.uniqueId === uniqueId) {
        each.structure = each.structure.map(struc => {
          if( parseInt(struc.item, 10) === parseInt(structureItem, 10) ){
            struc.overage_tiers.map(ot => {
              if( ot.id === item || ot.tempId === item ){
                ot[name] = value;
              }
              return ot;
            })
          }
          return struc;
        })
      }
      return each;
    }));
  }

  const handleOverageTierRemove = (uniqueId, structureItem, item) => {
    setItems(items.map(each => {
      if (each.uniqueId === uniqueId) {
        each.structure = each.structure.map(struc => {
          if( parseInt(struc.item, 10) === parseInt(structureItem, 10) ){
            struc.overage_tiers = struc.overage_tiers.filter(ot => {
              if( ot.id === item || ot.tempId === item ){
                return false;
              }
              return true;
            })
          }
          return struc;
        })
      }
      return each;
    }));
  }

  const reorder = (array, fromIndex, toIndex) => {
    const newArr = [...array];

    const chosenItem = newArr.splice(fromIndex, 1)[0];
    newArr.splice(toIndex, 0, chosenItem);

    return newArr;
  };

  const onDragEnd = result => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    setItems(reorder(items, source.index, destination.index));
  };

  const noDrag = search || period || type;
  const typeFilters = [
    {
      name: 'Any Type',
      value: '',
    },
    {
      name: 'Main',
      value: 'main'
    },
    {
      name: 'Add On',
      value: 'addOn'
    },
    {
      name: 'One Time',
      value: 'oneTime'
    },
    {
      name: 'Usage',
      value: 'usage'
    }
  ];

  const periodFilters = [
    {
      name: 'Any Period',
      value: ''
    },
    {
      name: 'Monthly',
      value: 'monthly'
    },
    {
      name: 'Annual',
      value: 'annual'
    },
    {
      name: 'Lifetime',
      value: 'lifetime'
    },
    {
      name: 'Expires',
      value: 'expires'
    },
  ]

  return (
    <Section size="lg">
      <Card className="mb-3">
        <Card.Header as="h5">Plans</Card.Header>
        <Card.Body className="bg-light">
          <Row className="mb-3">
            <Col md="4">
              <Form.Control
                type="text"
                placeholder="Search..."
                defaultValue={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Col>
            <Col md="4">
              <DropdownFilter
                variant="white"
                filters={typeFilters}
                currentFilter={type}
                handleFilter={(val) => setType(val)}
                block
              />
            </Col>
            <Col md="4">
              <DropdownFilter
                variant="white"
                filters={periodFilters}
                currentFilter={period}
                handleFilter={(val) => setPeriod(val)}
                block
              />
            </Col>
          </Row>
          <Form className="position-relative">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable1" type="DRAG">
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {items.filter(item => {
                      if (search) {
                        if (!item.name.toLowerCase().includes(search.toLowerCase())) return false;
                      }
                      if (type) {
                        if (item.type !== type) return false;
                      }
                      if (period) {
                        if (item.period !== period) return false;
                      }
                      return true;
                    }).map((item, index) => (
                      <Draggable
                        key={item.uniqueId}
                        draggableId={`drag${item.uniqueId}`}
                        index={index}
                      >
                        {provided => (
                          <div
                            style={{ position: 'relative' }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div
                              className={classNames({['d-none']: noDrag}, "text-center position-absolute z-index-1")}
                              style={{ width: "calc(100% - 100px)", marginLeft: "50px" }}
                              {...provided.dragHandleProps}
                            >
                              <FontAwesomeIcon icon="ellipsis-h" />
                            </div>
                            <ManagePlansItem
                              {...item}
                              index={index}
                              items={items}
                              handleRemove={handleRemove}
                              handleChange={handleChange}
                              handleMetaChange={handleMetaChange}
                              handleStructureChange={handleStructureChange}
                              handleOverageTierChange={handleOverageTierChange}
                              handleAddOverageTier={handleAddOverageTier}
                              handleOverageTierRemove={handleOverageTierRemove}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <IconButton
              onClick={handleAddPlan}
              variant="gray-200"
              size="sm"
              icon="plus"
              transform="shrink-3"
            >
              Add Item
            </IconButton>
          </Form>
          <div className="d-block mt-5">
            <Button
              size="sm"
              variant="primary"
              className="me-2"
              onClick={save}
            >
              Save
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Section>
  );
};

ManagePlans.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func
};

export default ManagePlans;
