import React, {useState} from 'react';
import Panel from 'components/common/Panel';
import { SpamCheckerContext } from 'context/Context';
import SpamCheckerDomain from 'projects/spam/SpamCheckerDomain';

const DomainChecker = ({}) => {
  const domainHealthDefault = {
    records: false,
    blacklists: false,
    whitelists: false,
    has_records: false,
    spf_domains: false,
    spf_match: false,
    errors: false,
    is_domain: false,
    is_email: false,
    is_ip: false
  };
  const [domainIP, setDomainIP] = useState('');
  const [analyzingDomain, setAnalyzingDomain] = useState(false);
  const [domainHealth, setDomainHealth] = useState(domainHealthDefault);
  const [userIP, setUserIP] = useState(false);
  const [disabled, setDisabled] = useState(false);

  return (
    <Panel title="Domain Checker">
      <SpamCheckerContext.Provider
        value={{
          domainIP, setDomainIP,
          analyzingDomain, setAnalyzingDomain,
          domainHealth, setDomainHealth, domainHealthDefault,
          userIP, setUserIP,
          disabled, setDisabled
        }}>
        <SpamCheckerDomain cardWrap={false} />
      </SpamCheckerContext.Provider>
    </Panel>
  )
};

DomainChecker.propTypes = {};

export default DomainChecker;