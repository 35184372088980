import React from 'react';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart, LineChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = (revenue, plans, usage, days) => {
  let otherLegend = [];

  const usages = Object.keys(usage).map((each, index) => {
    otherLegend.push(each);
    return {
      name: each,
      type: 'line',
      symbolSize: 6,
      itemStyle: {
        color: getColor((index + 4) * 100),
        borderColor: getColor((index + 4) * 100),
        borderWidth: 2
      },
      lineStyle: {
        color: getColor((index + 4) * 100)
      },
      symbol: 'circle',
      data: usage[each]
    }
  })

  return {
    color: [
      getColor('primary'),
      getColor('danger'),
    ],
    legend: {
      data: ['Plans', 'Revenue'].concat(otherLegend),
      left: 5,
      itemWidth: 10,
      itemHeight: 10,
      borderRadius: 0,
      icon: 'circle',
      inactiveColor: getColor('400'),
      textStyle: { color: getColor('700') },
      itemGap: 20
    },
    xAxis: {
      type: 'category',
      data: days,
      axisLine: {
        show: false
      },
      splitLine: {
        lineStyle: {
          color: getColor('200')
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: getColor('600'),
        formatter: value => value //value => 'Day: ' + value
      }
    },
    yAxis: {
      type: 'value',
      position: 'left',
      splitLine: {
        lineStyle: {
          color: getColor('200')
        }
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: true,
        color: getColor('600'),
        margin: 15
      }
    },
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      axisPointer: {
        type: 'none'
      },
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      textStyle: { color: getColor('700') },
      borderWidth: 1,
      transitionDuration: 0
      //formatter: tooltipFormatter
    },

    series: [
      {
        name: 'Plans',
        type: 'line',
        symbolSize: 6,
        itemStyle: {
          color: getColor('primary'),
          borderColor: getColor('primary'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('primary')
        },
        symbol: 'circle',
        data: plans
      },
      {
        name: 'Revenue',
        type: 'line',
        symbolSize: 6,
        itemStyle: {
          color: getColor('success'),
          borderColor: getColor('success'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('success')
        },
        symbol: 'circle',
        data: revenue
      }
    ].concat(usages),

    grid: {
      containLabel: true,
      right: '5px',
      left: 0,
      bottom: 0,
      top: '15%'
    }
  }
};

const RevenueChart = ({
  revenue, plans, usage, days
}) => {
  return (
    <>
      <ReactEChartsCore
        echarts={echarts}
        option={getOptions(revenue, plans, usage, days)}
        style={{ height: '21.875rem' }}
      />
    </>
  );
};

export default RevenueChart;
