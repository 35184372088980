import React, { useContext, useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import classNames from 'classnames';
import { AppContext } from '../../context/Context';

const ConfirmModal = () => {
  const { app: { confirmModal }, appConfirmDismiss } = useContext(AppContext);
  const [theConfirmText, setTheConfirmText] = useState('');
  const [confirmTextError, setConfirmTextError] = useState(false);

  let {
    type,
    confirm,
    confirmText,
    confirmColor,
    cancel,
    cancelText,
    cancelColor,
    header,
    subheader,
    body,
    size,
    centered,
    show,
    confirmWithText
  } = confirmModal;

  useEffect(() => {
    setTheConfirmText('');
  }, [show]);

  let cancelButton = true;

  if (type === 'destructive') {
    confirmText = confirmText || 'Delete';
    cancelText = cancelText || 'Cancel';
    confirmColor = confirmColor || 'danger';
    cancelColor = cancelColor || 'text';
  } else if (type === 'confirm') {
    confirmText = confirmText || 'Okay';
    cancelText = cancelText || 'Cancel';
    confirmColor = confirmColor || 'primary';
    cancelColor = cancelColor || 'text';
  } else if (type === 'message') {
    confirmText = confirmText || 'Okay';
    confirmColor = confirmColor || 'primary';
    cancelButton = false;
  }

  const doCancel = () => {
    appConfirmDismiss();

    if (cancel) cancel();
  }

  const doConfirm = () => {
    if (confirmWithText && theConfirmText !== confirmWithText) {
      setConfirmTextError(false);
      setTimeout(() => {
        setConfirmTextError(true);
      }, 300);
    } else {
      setConfirmTextError(false);
      appConfirmDismiss();

      if (confirm) confirm();
    }
  }

  return (
    <Modal
      show={show}
      onHide={doCancel}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered={centered}
    >
      {
        header && (
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{header}</Modal.Title>
          </Modal.Header>
        )
      }
      <Modal.Body>
        {
          subheader && (
            <h4>{subheader}</h4>
          )
        }
        <p>
          {typeof body === 'function' ? body() : body}
        </p>
        {
          confirmWithText && (
            <>
              <Form.Control
                type="text"
                placeholder={confirmWithText}
                value={theConfirmText}
                onChange={(e) => setTheConfirmText(e.target.value)} />
              <small className={classNames({ ['bg-danger']: confirmTextError, ['text-white']: confirmTextError }, 'p-2 d-block mt-2')}>Type {confirmWithText} to Confirm</small>
            </>
          )
        }
      </Modal.Body>
      <Modal.Footer>
        {
          cancelButton && (
            <Button variant={cancelColor} onClick={doCancel}>{cancelText}</Button>
          )
        }
        <Button variant={confirmColor} onClick={doConfirm}>{confirmText}</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
