import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import GoogleConnect from './GoogleConnect';
import MicrosoftConnect from './MicrosoftConnect';
import ZohoConnect from './ZohoConnect';
import SMTPConnect from './SMTPConnect';
import emailConfigurations from '../../../helpers/emailConfigurations';
import { getConnectionFromEmail } from '../../../helpers/utils';

const ConnectFormSection = ({
  provider,
  offering,
  doSave,
  titleOverwrite = '',
  reconnect = 0,
  connectedEmail = false,
  restart = null,
  email = ''
}) => {
  //Build the connection object from the connected_email meta
  let startingConnection = getConnectionFromEmail(connectedEmail);

  //connection settings
  const [connection, setConnection] = useState(startingConnection);

  //saving status
  const [saving, setSaving] = useState(false);

  //Page in the setup instructions
  const [page, setPage] = useState(1);

  const saveConnection = (e) => {
    e.preventDefault();

    setSaving(true);

    doSave(provider, offering, connection, setSaving);
  }

  const updateConnection = (key, val) => {
    setConnection({
      ...connection,
      [key]: val
    })
  }

  const updateSMTP = (key, val) => {
    setConnection({
      ...connection,
      connection: {
        ...connection.connection,
        smtp: {
          ...connection.connection.smtp,
          [key]: val
        }
      }
    })
  }

  const updateIMAP = (key, val) => {
    setConnection({
      ...connection,
      connection: {
        ...connection.connection,
        imap: {
          ...connection.connection.imap,
          [key]: val
        }
      }
    })
  }

  useEffect(() => {
    if( ! reconnect ){
      if(provider === 'smtp'){
        updateConnection('connection', emailConfigurations[provider] )
      } else {
        setConnection('');
      }
    }
  }, [provider]);

  useEffect(() => {
    if(emailConfigurations[provider] !== undefined && emailConfigurations[provider][offering] !== undefined && ! reconnect){
      updateConnection('connection', emailConfigurations[provider][offering] )
    }
  }, [offering]);

  const propsToPass = {
    updateConnection,
    connection,
    provider,
    offering,
    reconnect,
    saveConnection,
    saving,
    setSaving,
    updateSMTP,
    updateIMAP,
    titleOverwrite,
    email
  }

  return (
    <div className="provider-instructions">
      { provider === 'google' && offering ? <GoogleConnect {...propsToPass} /> : null }
      { provider === 'microsoft' && offering ? <MicrosoftConnect {...propsToPass} /> : null }
      { provider === 'zoho' && offering ? <ZohoConnect {...propsToPass} /> : null }
      { provider === 'smtp' ? <SMTPConnect {...propsToPass} /> : null }
    </div>
  );
}

ConnectFormSection.propTypes = {
  provider: PropTypes.string.isRequired,
  offering: PropTypes.string.isRequired,
  doSave: PropTypes.func.isRequired,
  titleOverwrite: PropTypes.mixed,
  connectedEmail: PropTypes.mixed
};

export default ConnectFormSection;
