import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Accordion, Card } from 'react-bootstrap';
import Section from 'components/common/Sec';
import FilterTable from 'components/common/FilterTable';
import { isJson, timestampToDate } from '../helpers/utils';
import TreeviewObject from 'components/common/TreeviewObject';
import API from '../api/api';
import CustomPagination from '../components/common/Pagination';

const Logs = () => {
  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [category, setCategory] = useState('');
  const [action, setAction] = useState('');
  const [account, setAccount] = useState('');
  const [user, setUser] = useState('');
  const [value, setValue] = useState('');
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  //The empty array as the second argument makes this run like componentDidMount
  //https://reacttraining.com/blog/useEffect-is-not-the-new-componentDidMount/
  useEffect(() => {
    const params = [
      {
        param: 'account',
        value: account
      },
      {
        param: 'user',
        value: user
      },
      {
        param: 'category',
        value: category
      },
      {
        param: 'log_action',
        value: action
      },
      {
        param: 'value',
        value: value
      },
      {
        param: 'limit',
        value: limit
      },
      {
        param: 'offset',
        value: limit * page - limit
      },
      {
        param: 'super',
        value: 'logs'
      }
    ]

    API.doRequest('super', 'get', 'get', false, {params}).then(result => {
      setData(result.data);
      setLoaded(true);
    }).catch(error => {
      setLoaded(true);
    });
  }, [account, user, category, action, value, limit, page]);

  useEffect(() => {
    const params = [
      {
        param: 'account',
        value: account
      },
      {
        param: 'user',
        value: user
      },
      {
        param: 'category',
        value: category
      },
      {
        param: 'log_action',
        value: action
      },
      {
        param: 'value',
        value: value
      },
      {
        param: 'super',
        value: 'logs'
      }
    ];

    API.doRequest('super', 'get_total', 'get', false, {params}).then(result => {
      setTotal(result.data);
    });
  }, [account, user, category, action, value]);

  const cols = [
    {
      id: 'timestamp',
      name: 'Time',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return timestampToDate(row.timestamp, false, 'YYYY-MM-DD HH:mm:ss')
      }
    },
    {
      id: 'account',
      name: 'Account',
      type: 'div',
      size: 1,
      filterable: 'search',
      onFilterUpdate: (val) => {
        setAccount(val);
        setPage(1);
      },
      valueFunction: (val, row) => {
        return <span className="fs--2">{val}</span>
      }
    },
    {
      id: 'user',
      name: 'User',
      type: 'div',
      size: 1,
      filterable: 'search',
      onFilterUpdate: (val) => {
        setUser(val);
        setPage(1);
      },
      valueFunction: (val, row) => {
        return <span className="fs--2">{val}</span>
      }
    },
    {
      id: 'category',
      name: 'Category',
      type: 'div',
      size: 1,
      filterable: 'search',
      onFilterUpdate: (val) => {
        setCategory(val);
        setPage(1);
      }
    },
    {
      id: 'action',
      name: 'Action',
      type: 'div',
      size: 1,
      filterable: 'search',
      onFilterUpdate: (val) => {
        setAction(val);
        setPage(1);
      }
    },
    {
      id: 'value',
      name: 'Value',
      type: 'div',
      size: 3,
      filterable: 'search',
      onFilterUpdate: (val) => {
        setValue(val);
        setPage(1);
      },
      valueFunction: (val, row) => {
        if(isJson(val)){
          let json = JSON.parse(val);

          if( typeof json !== 'object' ) return val;

          return (
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>JSON</Accordion.Header>
                <Accordion.Body>
                  <TreeviewObject
                    data={json}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )
        } else {
          return val;
        }
      }
    }
  ]

  return (
    <Section size="xxlg">
      <Card className="mb-3">
        <Card.Header as="h5">Logs</Card.Header>
        <Card.Body className="bg-light">
          {
            loaded ? (
              <>
                <FilterTable
                  data={data}
                  columns={cols}
                />
                <CustomPagination
                  total={ total }
                  limit={ 100 }
                  page={ page }
                  currentLimit={ limit }
                  updatePage={ (page) => setPage(page) }
                  updateLimit={ (limit) => setLimit(limit) }
                  margin="t"
                />
              </>
            ) : null
          }
        </Card.Body>
      </Card>
    </Section>
  );
};

Logs.propTypes = {};

export default Logs;
