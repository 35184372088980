import React, { useState } from 'react';
import { Nav, Collapse } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import CustomNavbarItemWrap from './CustomNavbarItemWrap';
import CustomNavbarItem from './CustomNavbarItem';
import classNames from 'classnames';

const CustomNavbarCollapse = ({
  route,
  roundedItem,
  navItemClass = 'text-700 text-900-hover p-2',
  navItemActiveClass,
  ...rest
}) => {
  const { pathname } = useLocation();

  const openCollapse = () => {
    let open = false;
    route.children.forEach(each => {
      if( each.to === pathname ) open = true;
    });

    return open;
  };

  const [open, setOpen] = useState(openCollapse());

  return (
    <Nav.Item as="li" className={classNames({
      rounded: roundedItem,
      [navItemActiveClass]: open
    })}>
      <Nav.Link
        onClick={() => {
          setOpen(!open);
        }}
        className={classNames('dropdown-indicator cursor-pointer nav-link', navItemClass)}
        aria-expanded={open}
      >
        <CustomNavbarItem route={route} {...rest} />
      </Nav.Link>
      <Collapse in={open}>
        <Nav className="flex-column nav p-2" as="ul">
          {route.children.map(each => <CustomNavbarItemWrap
            route={each}
            roundedItem={roundedItem}
            navItemClass={navItemClass}
            navItemActiveClass={navItemActiveClass}
            orientation="vertical"
            {...rest}
          />)}
        </Nav>
      </Collapse>
    </Nav.Item>
  );
};

export default CustomNavbarCollapse;