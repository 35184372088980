import React, { useState } from 'react';
import PropTypes from 'prop-types';
//import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col } from 'react-bootstrap';
import Divider from 'components/common/Divider';
import SignInWithGoogle from './SignInWithGoogle';
import Api from 'api/api';

const RegistrationForm = ({
  hasLabel,
  iframe = false,
  lockEmail = ''
}) => {
  // State
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: lockEmail,
    password: '',
    isAccepted: false
  });

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    if(!formData.email){
      toast.error('Please enter an email address');
    }

    if(!formData.password){
      toast.error('Please enter a password');
    }

    Api.doRequest('accounts', 'create', 'create', formData).then(result => {
      toast.success(`Successfully registered as ${formData.email}`);
      if( iframe ){
        window.top.location.href = window.SSSettings.appUrl;
      } else {
        window.location.href = '/' + window.location.search;
      }
    }).catch(error => {
      toast.error(error.message);
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <SignInWithGoogle />
      <Divider className="mt-4 mb-4">OR</Divider>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              {hasLabel && <Form.Label>First Name</Form.Label>}
              <Form.Control
                placeholder={!hasLabel ? 'First' : ''}
                value={formData.firstName}
                name="firstName"
                onChange={handleFieldChange}
                type="text"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              {hasLabel && <Form.Label>Last Name</Form.Label>}
              <Form.Control
                placeholder={!hasLabel ? 'Last' : ''}
                value={formData.lastName}
                name="lastName"
                onChange={handleFieldChange}
                type="text"
              />
            </Form.Group>
          </Col>
        </Row>

        {
          lockEmail ? (
            <div className="mb-3"><strong>{lockEmail}</strong></div>
          ) : (
            <Form.Group className="mb-3">
              {hasLabel && <Form.Label>Email address</Form.Label>}
              <Form.Control
                placeholder={!hasLabel ? 'Email address' : ''}
                value={formData.email}
                name="email"
                onChange={handleFieldChange}
                type="text"
              />
            </Form.Group>
          )
        }

        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Password' : ''}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
        {/*<Row className="g-2 mb-3">
          <Form.Group as={Col} sm={6}>
            {hasLabel && <Form.Label>Confirm Password</Form.Label>}
            <Form.Control
              placeholder={!hasLabel ? 'Confirm Password' : ''}
              value={formData.confirmPassword}
              name="confirmPassword"
              onChange={handleFieldChange}
              type="password"
            />
          </Form.Group>
        </Row>*/}

        {/*<Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            id="acceptCheckbox"
            className="form-check"
          >
            <Form.Check.Input
              type="checkbox"
              name="isAccepted"
              checked={formData.isAccepted}
              onChange={e =>
                setFormData({
                  ...formData,
                  isAccepted: e.target.checked
                })
              }
            />
            <Form.Check.Label className="form-label">
              I accept the <Link to="#!">terms</Link> and{' '}
              <Link to="#!">privacy policy</Link>
            </Form.Check.Label>
          </Form.Check>
        </Form.Group>*/}

        <Form.Group className="mb-4">
          <Button
            className="w-100"
            type="submit"
          >
            Register
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

RegistrationForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default RegistrationForm;
