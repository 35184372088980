import { useContext } from 'react';
import { AccountContext } from 'context/Context';
import API from '../api/api';

export const loadConnectedEmailSearch = (search = '', context = '', limit = 10, page = 1, addCurrent = true) => {
  const { accountState } = useContext(AccountContext);
  const connectedEmail = accountState?.connected_email !== undefined ? accountState.connected_email : false;

  return new Promise((resolve, reject) => {
    let params = [
      { param: 'limit', value: limit },
      { param: 'offset', value: (page - 1) * limit },
    ];

    if (search) params = params.concat({ param: 'search', value: search });

    /*resolve([
      { value: 'chocolate', label: 'Chocolate' },
      { value: 'strawberry', label: 'Strawberry' },
      { value: 'vanilla', label: 'Vanilla' }
    ]);

    return;*/

    API.doRequest('connected_emails', 'get', 'get', null, { params: params }).then(result => {

      if (result.data !== undefined) {
        const exists = connectedEmail ? result.data.find(each => each.id === connectedEmail.id) : false;

        if (!exists && connectedEmail?.id !== undefined && connectedEmail.id) {
          result.data.push(connectedEmail)
        }

        if (context === 'dropdown') {
          resolve(result.data.map(each => {
            return { value: each.id, label: each.email }
          }));
        } else {
          resolve(result.data);
        }
      }

      reject(false);

    }).catch(error => {
      reject(error);
    });
  });
}