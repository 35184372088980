import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { AccountContext } from 'context/Context';
import { accountReducer } from 'reducers/accountReducer';
import API from 'api/api';

const AccountProvider = ({ children }) => {
  const initData = {
    user: {},
    account: {},
    plan: {},
    utility: {},
    connected_email: {}
  };

  const [accountState, accountDispatch] = useReducer(accountReducer, initData);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    API.doRequest('accounts', 'load')
    .then(result => {
      accountDispatch({
        type: 'UPDATE',
        payload: {
          ...result.data
        }
      });
      if( result.data?.account?.id ) window.SSSettings.account = result.data?.account?.id;
      if( result.data?.user?.id ) window.SSSettings.user = result.data?.user?.id;
      if( result.data?.account?.parent ) window.SSSettings.parent = result.data?.account?.parent;
      if( result.data?.connected_email?.id ) window.SSSettings.connected_email = result.data?.connected_email?.id;
      setLoaded(true);
    })
    .catch(error => {
      if(error.data?.status === 401){
        window.location.href = '/signin' + window.location.search;
      }
      setLoaded(true);
    });
  }, []);

  if(!loaded) return null;

  return (
    <AccountContext.Provider
      value={{
        accountState,
        accountDispatch
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

AccountProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AccountProvider;
