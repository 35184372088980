import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
//import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { usePopper } from 'react-popper';

const TooltipItem = ({
  className = '',
  placement = 'top',
  id = '',
  tooltip,
  divClassName = '',
  children
}) => {

  //Need to control display with state and not CSS
  //https://github.com/floating-ui/popper.js.org/issues/17

  const [shown, setShown] = useState(false);
  const [hideAway, setHideAway] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const popper = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      { name: 'offset', options: { offset: [0, 10] } }
    ],
    placement: placement
  });

  const {styles, attributes} = popper;

  const show = () => {
    setShown(true);
  }

  /*const hide = () => {
    setHideAway(true);
    setTimeout(() => {
      setShown(false);
      setHideAway(false);
    }, 450);
  }*/

  const hide = () => {
    setShown(false);
  }

  useEffect(() => {
    if(referenceElement !== null){
      const showEvents = ['mouseenter', 'focus'];
      const hideEvents = ['mouseleave', 'blur'];

      showEvents.forEach((event) => {
        referenceElement.addEventListener(event, show);
      });

      hideEvents.forEach((event) => {
        referenceElement.addEventListener(event, hide);
      });
    }
  }, [referenceElement]);

  return (
    <div className={classNames('cursor-pointer info-tooltip', className)}>
      <div className={classNames('ss-tooltip-element', divClassName)} ref={setReferenceElement}>
        {children}
      </div>
      {
        shown ? (
          <div className={classNames('ss-tooltip')} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
            {tooltip}
            <div className="ss-tooltip-arrow" ref={setArrowElement} style={styles.arrow} />
          </div>
        ) : null
      }

    </div>
  );


  /*return (
    <span className={classNames('cursor-pointer', className)}>
      <OverlayTrigger
        key={id}
        placement={placement}
        overlay={
          <Tooltip id={id}>
            {tooltip}
          </Tooltip>
        }>
        <div className={divClassName}>
          {children}
        </div>
      </OverlayTrigger>
    </span>
  );*/
}

TooltipItem.propTypes = {
  className: PropTypes.string,
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  id: PropTypes.string,
  tooltip: PropTypes.string,
  children: PropTypes.any
};

export default TooltipItem