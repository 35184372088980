import React, {useState, useContext, useEffect} from 'react';
import {Form, Button} from 'react-bootstrap';
import classNames from 'classnames';
import SettingsPage from './SettingsPage';
import { AccountContext } from 'context/Context';
import { AppContext } from 'context/Context';
import Api from '../../../api/api';
import CustomPagination from '../../common/Pagination';
import FilterTable from '../../common/FilterTable';
import TooltipItem from '../../common/TooltipItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import API from '../../../api/api';
import { toast } from 'react-toastify';
import { ucFirst, isEmail, errorMessage, successMessage, hasUserPermission } from '../../../helpers/utils';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const ModalForm = () => {
  const [email, setEmail] = useState('');
  const [permission, setPermission] = useState('member');
  const [message, setMessage] = useState('');
  const [link, setLink] = useState('')

  const send = () => {
    if( ! isEmail(email) ){
      errorMessage('Please include a valid email address');
      return;
    }

    Api.doRequest('team_invites', 'create', 'update', {update: {
        email, permission
      }}).then(result => {
      successMessage('Invite created', result);
      setLink(result.data);
      setEmail('');
    }).catch(error => {
      errorMessage('Could not create invite', error);
    })
  }

  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label>Send invitation email to:</Form.Label>
        <Form.Control
          placeholder={'email@domain.com'}
          value={email}
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          type="email"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>User Role</Form.Label>
        <Form.Select
          value={permission}
          onChange={(e) => setPermission(e.target.value)}
        >
          <option value="member">Member</option>
          <option value="admin">Admin</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Custom Message (Optional)</Form.Label>
        <Form.Control
          placeholder={'Hey buddy, you\'re invited!'}
          value={message}
          name="message"
          style={{ height: '100px' }}
          onChange={(e) => setMessage(e.target.value)}
          as="textarea"
        />
      </Form.Group>
        { link ? (
          <div className="mb-3">
            <p>Here is the invitation link:</p>
            <p className="fs--2 cursor-pointer" style={{overflowWrap: 'anywhere'}}>
              <CopyToClipboard text={link} onCopy={() => toast.success('Copied!')}>
                <span>{ link }</span>
              </CopyToClipboard>
            </p>
          </div>
        ) : null }
      <Button variant="primary" onClick={send}>Send</Button>
    </Form>
  )
}

const Team = () => {
  const { accountState } = useContext(AccountContext);
  const { appConfirm, app } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState([]);
  const [invites, setInvites] = useState([]);
  const [inviteModal, setInviteModal] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');

  const launchInvite = () => {
    setInviteModal(true);

    appConfirm({
      type: 'message',
      confirmText: 'Done',
      confirmColor: 'text',
      header: 'Send Test Email',
      body: <ModalForm />,
    })
  }

  useEffect(() => {
    if( app.confirmModal.show === false ){
      API.doRequest('team_invites', 'get', 'get').then(results => {
        setInvites(results.data);
      }).catch(error => {

      });
    }
  }, [app.confirmModal.show]);

  useEffect(() => {
    let params = [
      { param: 'limit', value: limit },
      { param: 'offset', value: (page - 1) * limit },
    ];

    if (search) params = params.concat({ param: 'search', value: search });

    API.doRequest('users', 'get', 'get', null, { params: params }).then(results => {
      setTeam(results.data);
    }).catch(error => {

    });
  }, []);

  const onSave = () => {
    setLoading(true);
  }

  const deleteTeamMember = (id) => {
    API.doRequest('user', 'delete', 'delete', null, { params: [{param: 'item', value: id}] }).then(results => {
      successMessage('Team member deleted', results);
      setTeam(team.filter(each => each.id !== id));
    }).catch(error => {
      errorMessage('Team member could not be deleted', error);
    });
  }

  const maybeDeleteTeamMember = (user) => {
    appConfirm({
      type: 'confirm',
      confirm: () => deleteTeamMember(user.id),
      confirmText: 'Delete',
      confirmColor: 'danger',
      header: 'Delete ' + user.user_email + '?',
      body: 'Are you sure you want to delete this team member from your account?',
    });
  }

  const deleteInvite = (id) => {
    API.doRequest('team_invites', 'delete', 'delete', null, { params: [{param: 'item', value: id}] }).then(results => {
      successMessage('Invite deleted', results);
      setInvites(invites.filter(each => each.id !== id));
    }).catch(error => {
      errorMessage('Invite could not be deleted', error);
    });
  }

  const maybeDeleteInvite = (invite) => {
    appConfirm({
      type: 'confirm',
      confirm: () => deleteInvite(invite.id),
      confirmText: 'Delete',
      confirmColor: 'danger',
      header: 'Delete Invite?',
      body: 'Are you sure you want to delete this invite? ' + (parseInt(invite.activated, 10) ? 'The team member who activated this invite will NOT be deleted.' : 'The invitation will not be able to be used.'),
    });
  }

  const cols = [
    {
      id: 'first_name',
      name: 'First',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return val;
      }
    },
    {
      id: 'last_name',
      name: 'Last',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return val;
      }
    },
    {
      id: 'user_email',
      name: 'Email',
      type: 'div',
      size: 3,
      valueFunction: (val, row) => {
        return val;
      }
    },
    {
      id: 'permission',
      name: 'Role',
      type: 'div',
      minWidth: 100,
      valueFunction: (val, row) => {
        return <span className={classNames('badge', {
          ['badge-soft-success']: val === 'admin',
          ['badge-soft-primary']: val === 'member',
        })}>{ ucFirst(val) }</span>;
      }
    },
    {
      id: 'id',
      name: '',
      type: 'div',
      minWidth: 50,
      right: true,
      valueFunction: (val, row) => {
        return accountState?.user?.id !== val && hasUserPermission('deleteUser') ? (
          <TooltipItem tooltip="Delete team member">
            <FontAwesomeIcon className="text-danger cursor-pointer" icon="times-circle" onClick={() => maybeDeleteTeamMember(row)} />
          </TooltipItem>
        ) : null;
      }
    }
  ];

  const inviteCols = [
    {
      id: 'email',
      name: 'Email',
      type: 'div',
      valueFunction: (val, row) => {
        return val;
      }
    },
    {
      id: 'activated',
      name: 'Activated',
      type: 'div',
      minWidth: 150,
      valueFunction: (val, row) => {
        const activated = parseInt(val);
        return activated
          ? <FontAwesomeIcon className="text-success" icon="check" />
          : <FontAwesomeIcon className="text-gray-500" icon="minus" />
      }
    },
    {
      id: 'id',
      name: '',
      type: 'div',
      minWidth: 50,
      right: true,
      valueFunction: (val, row) => {
        return (
          <TooltipItem tooltip="Delete invite">
            <FontAwesomeIcon className="text-danger cursor-pointer" icon="times-circle" onClick={() => maybeDeleteInvite(row)} />
          </TooltipItem>
        );
      }
    }
  ];

  return (
    <SettingsPage title="Team" loading={ loading } onSave={null}>
      {team.length ? <FilterTable data={team} columns={cols} /> : null}
      {
        team.length > 10 ? (
          <CustomPagination
            total={ team }
            limit={ 10 }
            page={ page }
            currentLimit={ limit }
            updatePage={ (page) => setPage(page) }
            updateLimit={ (limit) => setLimit(limit) }
            margin="t"
          />
        ) : null
      }
      {
        hasUserPermission('invites') ? (
          <>
            <h4 className="mt-5">Invites</h4>
            {team.length ? <FilterTable data={invites} columns={inviteCols} /> : null}
            {
              team.length + invites.length < 25 ? (
                <Button className="mt-3" variant="primary" onClick={launchInvite}>Invite Teammate...</Button>
              ) : null
            }
          </>
        ) : null
      }
    </SettingsPage>
  );
};

export default Team;
