import React, { useEffect, useContext } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { AdminContext } from '../context/Context';
import API from '../api/api';

const DatabaseInfo = () => {
  const { adminState, adminDispatch } = useContext(AdminContext);

  //The empty array as the second argument makes this run like componentDidMount
  //https://reacttraining.com/blog/useEffect-is-not-the-new-componentDidMount/
  useEffect(() => {
    API.doRequest('super', 'get_table_sizes', 'get', null, {
      params: [
        { param: 'super', value: 'database' }
      ]
    }).then(result => {
      adminDispatch({
        type: 'SET_DATABASE_INFO',
        payload: result.data
      })
    });
  }, []);

  const columns = [
    {
      accessor: 'table',
      Header: 'Table'
    },
    {
      accessor: 'rows',
      Header: 'Rows'
    },
    {
      accessor: 'size',
      Header: 'Size (MB)'
    },
  ];

  if (adminState.databaseInfo === undefined || !adminState.databaseInfo.length) return null;

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={adminState.databaseInfo}
      sortable
      pagination
      perPage={50}
    >
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Database Size</h5>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default DatabaseInfo;
