import React from 'react';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, rgbaColor, getRandomNumber } from 'helpers/utils';
//import { tooltipFormatter } from 'helpers/echart-utils';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = (start, min, max, ramp, deviation, conversation, important, cycles, hold, holdMax, holdDuration) => {
  const days = 14;
  let conversations = [];
  let singles = [];
  let total = start;

  let cycleArray = cycles && cycles.length ? cycles : [...Array(days)];

  if( cycleArray.length < days ){
    cycleArray = cycleArray.concat([...Array(days - cycleArray.length)])
  }

  let prev = 0;

  cycleArray.forEach((each, i) => {
    total = each?.limit !== undefined ? parseInt(each.limit, 10) : total;
    let numCon = each?.conversations_limit !== undefined ? parseInt(each.conversations_limit, 10) : Math.floor(total * conversation / 100);
    let numSin = total - numCon;
    let numImportant = Math.floor(total * important / 100);

    conversations.push(numCon);
    singles.push(numSin);

    let newMax = max;

    if( parseInt(hold, 10) && parseInt( holdDuration, 10 ) ){
      newMax = parseInt( holdMax, 10 );
      holdDuration--;
    }

    let newTotal = total + ramp + getRandomNumber(0 - deviation, 0 + deviation);

    if( newTotal > newMax ) newTotal = total + ramp - deviation;
    if( newTotal > newMax ) newTotal = total - getRandomNumber(0, 5);
    if( newTotal > newMax ) newTotal = newMax;

    if( newTotal === prev && deviation && newTotal >= newMax - deviation ) newTotal = prev - getRandomNumber(0, deviation);

    //if( newTotal < min ) newTotal = total + getRandomNumber(0, 5);
    if( newTotal < min ) newTotal = min;
    if( newTotal > newMax ) newTotal = newMax;

    total = newTotal;
    prev = total;
  });

  return {
    color: [
      getColor('primary'),
      rgbaColor(getColor('primary'), 0.8)
    ],
    legend: {
      data: ['Single', 'Conversation'],
      left: 5,
      itemWidth: 10,
      itemHeight: 10,
      borderRadius: 0,
      icon: 'circle',
      inactiveColor: getColor('400'),
      textStyle: { color: getColor('700') },
      itemGap: 20
    },
    xAxis: {
      type: 'category',
      data: cycleArray.map((each, i) => each?.number ? each?.number : i + 1),
      axisLine: {
        show: false
      },
      splitLine: {
        lineStyle: {
          color: getColor('200')
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: getColor('600'),
        formatter: value => value //value => 'Day: ' + value
      }
    },
    yAxis: {
      type: 'value',
      position: 'left',
      splitLine: {
        lineStyle: {
          color: getColor('200')
        }
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: true,
        color: getColor('600'),
        margin: 15
      }
    },
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      axisPointer: {
        type: 'none'
      },
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      textStyle: { color: getColor('700') },
      borderWidth: 1,
      transitionDuration: 0
      //formatter: tooltipFormatter
    },

    series: [
      {
        name: 'Single',
        type: 'bar',
        stack: 'total',
        data: singles
      },
      {
        name: 'Conversation',
        type: 'bar',
        stack: 'total',
        data: conversations
      }
    ],

    grid: {
      containLabel: true,
      right: '5px',
      left: 0,
      bottom: 0,
      top: '15%'
    }
  }
};

const Cycles = ({
  start, min, max, ramp, deviation, conversation, important, cycles, hold, holdMax, holdDuration
}) => {
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOptions(start, min, max, ramp, deviation, conversation, important, cycles, hold, holdMax, holdDuration)}
      style={{ height: '21.875rem' }}
    />
  );
};

export default Cycles;
