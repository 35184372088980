import React, {useEffect, useContext, useState} from 'react';
//import PropTypes from 'prop-types';
import { ButtonGroup, Button } from 'react-bootstrap';
import EmailPanel from '../common/EmailPanel';
import Conversations from './history/Conversations';
import EmailsSentReceived from './history/EmailsSentReceived';

const History = ({

}) => {
  const [which, setWhich] = useState('conversations');

  return (
    <EmailPanel title="History">
      <div className="d-flex justify-content-center">
        <ButtonGroup size="lg" className="mb-3">
          <Button variant={ which === 'conversations' ? 'primary' : 'gray-400' } onClick={() => setWhich('conversations')}>Conversations</Button>
          <Button variant={ which === 'sent' ? 'primary' : 'gray-400' } onClick={() => setWhich('sent')}>Sent</Button>
          <Button variant={ which === 'received' ? 'primary' : 'gray-400' } onClick={() => setWhich('received')}>Received</Button>
        </ButtonGroup>
      </div>
      { which === 'conversations' ? <Conversations /> : null }
      { which === 'sent' ? <EmailsSentReceived /> : null }
      { which === 'received' ? <EmailsSentReceived which="received" /> : null }
    </EmailPanel>
  )
};

History.propTypes = {

};

export default History;
