import React, { useState, useEffect, useContext } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import Section from 'components/common/Sec';
import { AppContext } from '../context/Context';
import API from '../api/api';
import { errorMessage, successMessage } from '../helpers/utils';

const DatabasePrefix = () => {
  const { appConfirm } = useContext(AppContext);
  const [oldPrefix, setOldPrefix] = useState('');
  const [newPrefix, setNewPrefix] = useState('');
  const [tables, setTables] = useState([])

  useEffect(() => {
    //Get super/database updates, merge with super/platform and update the adminReducer
    API.doRequest('super', 'get_table_prefixes', 'get', null, {
      params: [
        { param: 'super', value: 'database' }
      ]
    }).then(result => {
      setNewPrefix(result?.data?.new);
      setOldPrefix(result?.data?.old);
      setTables(result?.data?.tables);
    }).catch(error => {
      console.log('error', error);
      errorMessage('', error);
    });
  }, []);

  const maybeChangePrefix = (name) => {
    appConfirm({
      type: 'confirm',
      confirm: () => changePrefix(name),
      confirmText: 'Change',
      confirmColor: 'danger',
      header: 'Change database prefix to ' + name,
      body: 'This cannot be undone. SSTART_DB_PREFIX should be changed to match. SSTART_DB_PREFIX_NEW can be deleted.',
    });
  }

  const changePrefix = (prefix) => {
    console.log('prefix', prefix);

    API.doRequest('super', 'update_table_prefix', 'update', null, {
      params: [
        { param: 'super', value: 'database' }
      ]
    }).then(result => {
      successMessage('', result);
    }).catch(error => {
      console.log('error', error);
      errorMessage('', error);
    });
  }

  return (
    <Section size="lg">
      <Card className="mb-3">
        <Card.Header>
          <h5>Database Prefix</h5>
        </Card.Header>
        <Card.Body>
          <p>Current Prefix: {oldPrefix}</p>
          <p>Current Tables:</p>
          <ul className="list-group mb-3">
            {
              tables.map(each => {
                return <li className="list-group-item">{each}</li>
              })
            }
          </ul>
          <p>New Prefix: {newPrefix}</p>
          <Button className="mt-3" onClick={() => maybeChangePrefix(newPrefix)}>Change</Button>
        </Card.Body>
      </Card>
    </Section>
  );
};

export default DatabasePrefix;
