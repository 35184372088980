/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Settings from 'components/settings-panel/custom/Settings';
import Profile from 'components/settings-panel/custom/Profile';
import Plan from 'components/settings-panel/custom/Plan';
import Billing from 'components/settings-panel/custom/Billing';
import Team from 'components/settings-panel/custom/Team';
import Redeem from 'components/settings-panel/custom/Redeem';
import Lifetime from 'components/settings-panel/custom/Lifetime';
import Embed from 'components/settings-panel/custom/Embed';

const SettingsRoutes = () => (
  <Settings>
    <Switch>
      <Route exact path="/settings/profile">
        <Profile />
      </Route>
      <Route exact path="/settings/plan">
        <Plan />
      </Route>
      <Route exact path="/settings/billing">
        <Billing />
      </Route>
      <Route exact path="/settings/team">
        <Team />
      </Route>
      <Route exact path="/settings/lifetime">
        <Lifetime />
      </Route>
      <Route exact path="/settings/redeem">
        <Redeem />
      </Route>
      <Route exact path="/settings/embed-code">
        <Embed />
      </Route>
    </Switch>
  </Settings>
);

export default SettingsRoutes;
