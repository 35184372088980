import React from 'react';
import PropTypes from 'prop-types';
import TooltipItem from '../common/TooltipItem';
import { getEmailStatusMessage, getEmailStatusColor } from '../../helpers/utils';
import CircleChart from '../common/CircleChart';

const HealthScore = ({
  connectedEmail,
  size = '50px',
  tooltipPlacement = 'right',
  lineWidth = 3,
  titleFontSize = 10
}) => {
  const tooltip = getEmailStatusMessage(connectedEmail);
  const color = getEmailStatusColor(connectedEmail);
  const opt = {
    score: parseInt(connectedEmail.score, 10),
    text: connectedEmail.score_status,
    color: color,
    lineWidth: lineWidth,
    titleColor: color,
    titleFontSize: titleFontSize,
    showScore: false
  }
  return (
    <TooltipItem tooltip={ tooltip } placement={ tooltipPlacement }>
      <CircleChart opt={opt} size={ size } />
    </TooltipItem>
  )
};

HealthScore.propTypes = {
  connectedEmail: PropTypes.shape({
    score_status: PropTypes.string,
    score: PropTypes.oneOf(['int', 'string'])
  }).isRequired,
  size: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  lineWidth: PropTypes.int,
  titleFontSize: PropTypes.int
};

export default HealthScore;
