import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Spinner } from 'react-bootstrap';

const Loader = ({
  isFluid = false,
  full = false
}) => {

  let style = {height: '100px', width: '100%'};

  if( full ){
    style = {height:'100vh', width:'100vw'};
  }

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      <div className={classNames('d-flex align-items-center justify-content-center', {['position-fixed']: full})} style={style}>
        <Spinner animation="grow" variant="primary" />
      </div>
    </div>
  );
}

Loader.propTypes = {
  isFluid: PropTypes.bool,
  full: PropTypes.bool
};

export default Loader;
