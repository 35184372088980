import { useState } from 'react';
import cookie from 'react-cookies';

/**
 * Will save the limit of FilterTable display in a cookie so that it can persist
 *
 * @param id
 * @returns {[*,updateLimit]}
 */
export const usePaginationLimit = (id) => {
  const initialLimit = parseInt(cookie.load(SSSettings.namelower + '_pagination_limit_' + id), 10);

  const [limit, setLimit] = useState(initialLimit ? initialLimit : 10);

  const updateLimit = (newLimit) => {
    cookie.save(SSSettings.namelower + '_pagination_limit_' + id, newLimit, { path: '/', maxAge: ( 1000 * 60 * 60 * 24 ) });
    setLimit(newLimit);
  }

  return [limit, updateLimit];
};