import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
//import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col, Form, Button } from 'react-bootstrap';
import EmailPanel from '../common/EmailPanel';
import TimezoneInput from '../common/TimezoneInput';
import FormInfo from '../common/FormInfo';
import { generateId, isSuper } from 'helpers/utils';
import { AccountContext, AppContext } from 'context/Context';
import Api from '../../api/api';
import { toast } from 'react-toastify';
import industries from '../../data/industries';
import Toggle from '../common/Toggle';

const Settings = ({}) => {
  const { appConfirm } = useContext(AppContext);
  const { accountState } = useContext(AccountContext);
  const connectedEmail = accountState?.connected_email !== undefined ? accountState.connected_email : {};
  const user = accountState?.user !== undefined ? accountState.user : {};

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [position, setPosition] = useState('');
  const [industry, setIndustry] = useState('');
  const [timezone, setTimezone] = useState('');
  const [administrator, setAdministrator] = useState('');
  const [ignoreBounce, setIgnoreBounce] = useState(0);
  const [ignoreSMTP, setIgnoreSMTP] = useState(0);
  const [thresholdNotification, setThresholdNotification] = useState(0);
  const [thresholdValue, setThresholdValue] = useState(70);
  const [code, setCode] = useState(generateId('', 10));
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);
  const [initialSetupDone, setInitialSetupDone] = useState(false);

  useEffect(() => {
    Api.doRequest('connected_emails_meta').then(result => {
      setFirstName(result?.data?.first_name !== undefined ? result.data.first_name : '');
      setLastName(result?.data?.last_name !== undefined ? result.data.last_name : '');
      setCompany(result?.data?.company !== undefined ? result.data.company : '');
      setPosition(result?.data?.position !== undefined ? result.data.position : '');
      setIndustry(result?.data?.industry !== undefined ? result.data.industry : '');
      setIgnoreBounce(result?.data?.ignore_bounce !== undefined ? result.data.ignore_bounce : 0);
      setIgnoreSMTP(result?.data?.ignore_smtp_errors !== undefined ? result.data.ignore_smtp_errors : 0);
      setTimezone(result?.data?.timezone !== undefined ? result.data.timezone : 'US/Eastern');
      setAdministrator(result?.data?.administrator !== undefined ? result.data.administrator : user.user_email !== undefined ? user.user_email : '');
      setCode(result?.data?.content_code !== undefined ? result.data.content_code : code);
      setThresholdNotification(result?.data?.threshold_notification !== undefined ? result.data.threshold_notification : 0);
      setThresholdValue(result?.data?.threshold_value !== undefined ? result.data.threshold_value : 70);
    }).catch(error => {

    })
  }, []);

  const maybeSave = () => {
    if( ! administrator ){
      appConfirm({
        type: 'message',
        confirmText: 'Okay',
        confirmColor: 'text',
        confirm: null,
        header: 'Set Administrator Email',
        body: `The administrator will receive notices about this email, in the case that it is disconnected or experiences any other issue.`,
      });
      return;
    }

    const items = {
      ['First Name']: firstName,
      ['Last Name']: lastName,
      ['Company']: company,
      ['Position']: position,
      ['Industry']: industry,
      ['Administrator Email']: administrator
    };

    let emptyItems = Object.keys(items).reduce((acc, cur) => {
      if( ! items[cur] ){
        acc.push(cur)
      }
      return acc;
    }, []);

    emptyItems = emptyItems.join(', ');

    if(emptyItems){
      appConfirm({
        type: 'confirm',
        confirm: save,
        confirmText: 'Skip',
        confirmColor: 'danger',
        header: 'Skip Completing Profile?',
        body: `The following items are blank: ${emptyItems}. Completing your profile is important for more realistic warmup emails.`,
      });
    } else {
      save();
    }
  };

  const save = () => {
    setLoading(true);
    const newMeta = [
      {
        item: connectedEmail.id,
        metakey: 'first_name',
        value: firstName
      },
      {
        item: connectedEmail.id,
        metakey: 'last_name',
        value: lastName
      },
      {
        item: connectedEmail.id,
        metakey: 'company',
        value: company
      },
      {
        item: connectedEmail.id,
        metakey: 'position',
        value: position
      },
      {
        item: connectedEmail.id,
        metakey: 'industry',
        value: industry
      },
      {
        item: connectedEmail.id,
        metakey: 'timezone',
        value: timezone
      },
      {
        item: connectedEmail.id,
        metakey: 'content_code',
        value: code
      },
      {
        item: connectedEmail.id,
        metakey: 'administrator',
        value: administrator
      },
      {
        item: connectedEmail.id,
        metakey: 'ignore_bounce',
        value: parseInt(ignoreBounce, 10)
      },
      {
        item: connectedEmail.id,
        metakey: 'ignore_smtp_errors',
        value: parseInt(ignoreSMTP, 10)
      },
      {
        item: connectedEmail.id,
        metakey: 'threshold_notification',
        value: parseInt(thresholdNotification, 10)
      },
      {
        item: connectedEmail.id,
        metakey: 'threshold_value',
        value: parseInt(thresholdValue, 10)
      },
    ];
    Api.doRequest('connected_emails_meta', 'create_bulk', 'update', {update: newMeta}).then(result => {
      setLoading(false);
      toast.success('Saved!');
    }).catch(error => {
      setLoading(false);
      toast.error('Could not save')
    });

    if( ! parseInt(connectedEmail.setup, 10) ){
      Api.doRequest('connected_emails', 'update', 'update', {update: {setup: 1}})
      setInitialSetupDone(true);
    }

    setSaved(true);
  }

  const deleteConnectedEmail = () => {
    Api.doRequest('connected_emails', 'delete', 'delete', null, {
      params: [{
        param: 'id',
        value: connectedEmail.id
      }]
    }).then(result => {
      toast.success('Email has been deleted from your account');
      window.location.href = '/emails';
    }).catch(error => {
      toast.error('Email could not be deleted from your account')
    })
  }

  const maybeDelete = () => {
    appConfirm({
      type: 'confirm',
      confirm: deleteConnectedEmail,
      confirmText: 'Delete',
      confirmColor: 'danger',
      header: 'Delete ' + connectedEmail.email,
      body: 'Are you sure you want to delete this email? This will stop warming this email and remove it from your account.',
    });
  }

  return (
    <EmailPanel title="Settings" onSave={maybeSave} loading={loading}>
      {
        ! parseInt(connectedEmail.setup, 10) && ! saved ? (
          <div className="alert alert-primary">To complete setup, please fill out as many fields as are relevant and then save</div>
        ) : null
      }
      <h5 className="mt-3 mb-3">General Info</h5>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label for="firstName">
              First Name
              <FormInfo id="firstName">
                The first name of the owner of this email for personalizing the emails
              </FormInfo>
            </Form.Label>
            <Form.Control
              placeholder=""
              value={firstName}
              name="firstName"
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label for="lastName">
              Last Name
              <FormInfo id="lastName">
                The last name of the owner of this email for personalizing the emails
              </FormInfo>
            </Form.Label>
            <Form.Control
              placeholder=""
              value={lastName}
              name="lastName"
              onChange={(e) => setLastName(e.target.value)}
              type="text"
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Form.Group>
            <Form.Label for="company">
              Company
              <FormInfo id="company">
                The company name of the owner of this email for personalizing the emails
              </FormInfo>
            </Form.Label>
            <Form.Control
              placeholder=""
              value={company}
              name="company"
              onChange={(e) => setCompany(e.target.value)}
              type="text"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label for="position">
              Position
              <FormInfo id="position">
                The position of the owner of this email for personalizing the emails
              </FormInfo>
            </Form.Label>
            <Form.Control
              placeholder=""
              value={position}
              name="position"
              onChange={(e) => setPosition(e.target.value)}
              type="text"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label for="industry">
              Industry
              <FormInfo id="industry">
                Choose the industry that best fits this email address. It does not have to be a perfect match.
              </FormInfo>
            </Form.Label>
            <Form.Select
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
            >
              <option value="">Choose Industry...</option>
              {
                industries.map(each => <option value={each}>{each}</option>)
              }
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="mt-3">
        <Form.Label for="timezone">
          Timezone
          <FormInfo id="timezone">
            The timezone for sending emails to make your warmup sending habits look more human
          </FormInfo>
        </Form.Label>
        <TimezoneInput
          value={timezone}
          className="timezone-picker"
          onChange={timezone => setTimezone(timezone)}
          inputProps={{
            placeholder: 'Select Timezone...',
            name: 'timezone'
          }}
        />
      </Form.Group>

      <h5 className="mt-5 mb-3">Email Notifications</h5>
      <Form.Group className="mt-3">
        <Form.Label for="administrator">
          Administrator Email
          <FormInfo id="administrator">
            This is the email Boxward will use to contact you with any notices about this email's status, such as if the email becomes disconnected
          </FormInfo>
        </Form.Label>
        <Form.Control
          placeholder={`youremail@domain.com`}
          value={administrator}
          name="administrator"
          onChange={(e) => setAdministrator(e.target.value)}
          type="email"
        />
        { ! administrator ? <div className="mt-2 text-danger fs--1">Please set an administrator email.</div> : null }
      </Form.Group>
      <Row>
        <Col md={4}>
          <Form.Group className="mt-3">
            <Form.Label for="threshold-notification">
              Score Threshold Notification
              <FormInfo id="threshold-notification">
                If enabled, the Administrator Email will receive notifications when this email's warmup score crosses above or below this threshold
              </FormInfo>
            </Form.Label>
            <Toggle value={thresholdNotification} numeric update={(value) => setThresholdNotification(value)} />
          </Form.Group>
        </Col>
        <Col md={8}>
          {
            parseInt(thresholdNotification, 10) === 1 ? (
              <Form.Group className="mt-3">
                <Form.Label for="threshold-value">
                  Score Threshold Value
                  <FormInfo id="threshold-value">
                    If this email's score crosses above or below this number, the Administrator Email will receive a notification
                  </FormInfo>
                </Form.Label>
                <Form.Control
                  min={0}
                  max={95}
                  placeholder={ 70 }
                  value={thresholdValue}
                  name="threshold-value"
                  onChange={(e) => setThresholdValue(e.target.value)}
                  type="number"
                />
              </Form.Group>
            ) : null
          }
        </Col>
      </Row>

      <h5 className="mt-5 mb-3">Connection Details</h5>
      <Form.Group className="mt-3">
        <Form.Label for="delete">
          Edit Email
          <FormInfo id="delete">
            Edit the connection details of this account
          </FormInfo>
        </Form.Label>
        <div>
          <Button as={Link} variant="light" to="/email/reconnect">Edit Connection Details...</Button>
        </div>
      </Form.Group>

      <h5 className="mt-5 mb-3">Advanced</h5>
      <Row>
        <Col>
          <Form.Group className={ classNames("mt-3 alert alert-danger", {
            ['d-none']: false && ! isSuper()
          })}>
            <Form.Label for="code">
              Content Code
              <FormInfo id="code">
                This code is included in emails that you receive so that you can filter them manually. DO SO ONLY IF EMAILS ARE BEING PROPERLY LABELED BUT STILL REMAIN IN YOUR INBOX!
              </FormInfo>
            </Form.Label>
            <Form.Control
              placeholder="as83nkSDu2"
              value={code}
              name="code"
              onChange={(e) => setCode(e.target.value)}
              type="text"
            />
            <div className="mt-2 fs--1">Filters that automatically delete or move messages may interfere with your email's ability to warm properly. <strong>Please only set up a filter if emails are already being properly labeled, but are still visible in your inbox!</strong></div>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className={ classNames("mt-3 alert alert-danger", {
            ['d-none']: ! isSuper()
          })}>
            <Form.Label for="ignore-bounce">
              Ignore Bounces (Not Recommended)
              <FormInfo id="ignore-bounce">
                If enabled, this option will ignore bounces to other emails in the warmup network and continue to send warmup emails.
              </FormInfo>
            </Form.Label>
            <Toggle value={ignoreBounce} numeric update={(value) => setIgnoreBounce(value)} />
            <div className="mt-2 fs--1">By default, if this email experiences an elevated level of bounces when sending to other inboxes in the network, Boxward will disable warmup and send a notification to the administrator's address. <strong>Ignoring these bounces is not recommended!</strong></div>
          </Form.Group>
          <Form.Group className={ classNames("mt-3 alert alert-danger", {
            ['d-none']: ! isSuper()
          })}>
            <Form.Label for="ignore-smtp-errors">
              Ignore SMTP Errors (Not Recommended)
              <FormInfo id="ignore-smtp-errors">
                If enabled, this option will ignore SMTP errors this account experiences and continue to attempt to send emails.
              </FormInfo>
            </Form.Label>
            <Toggle value={ignoreSMTP} numeric update={(value) => setIgnoreSMTP(value)} />
            <div className="mt-2 fs--1">By default, if this email experiences 2 SMTP errors within a 12 hour period, it will be disabled so that any underlying issues can be diagnosed. <strong>Ignoring these SMTP errors is not recommended!</strong></div>
          </Form.Group>
        </Col>
      </Row>

      <Form.Group className="mt-3">
        <Form.Label for="delete">
          Delete Email
          <FormInfo id="delete">
            Stop warming this email and delete it from your account
          </FormInfo>
        </Form.Label>
        <div>
          <Button variant="Danger" onClick={maybeDelete}>Delete Email...</Button>
        </div>
      </Form.Group>
      {
        initialSetupDone ? (
          <div className="mt-3 alert alert-success">Great! Finally, <Link to="/email/warmup">visit the warmup page</Link> to set your warmup schedule</div>
        ) : null
      }
    </EmailPanel>
  )
};

Settings.propTypes = {};

export default Settings;
