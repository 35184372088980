import React from 'react';
import { Card } from 'react-bootstrap';
import PlanMain from './PlanMain';

const PlanAddon = ({
  ...rest
}) => {
  return (
    <Card className="text-center mb-3 height-100--3">
      <Card.Body className="plan-items">
        <PlanMain buttonText="Add It!" {...rest} />
      </Card.Body>
    </Card>
  );
};

export default PlanAddon;
