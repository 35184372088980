import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import logo from 'assets/img/logo-icon.png';

const Logo = ({
  width = 40,
  className = '',
  imageClass = '',
  ...rest
}) => {
  return (
    <div
      className={classNames(
        'd-flex align-items-center',
        className
      )}
    >
      <img className={classNames(imageClass)} src={logo} alt="Logo" width={width} />
    </div>
  );
};

Logo.propTypes = {
  width: PropTypes.number,
  className: PropTypes.string,
  imageClass: PropTypes.string
};

export default Logo;
