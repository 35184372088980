import React from 'react';
import PropTypes from 'prop-types';
import { Form, Pagination } from 'react-bootstrap';

const CustomPagination = ({
  items,
  total,
  page,
  updatePage,
  limit,
  updateLimit,
  currentLimit,
  displayGroups,
  pagesToShow,
  margin,
}) => {
  const totalItems = total !== undefined ? total : items.length;

  const firstPage = (e) => {
    e.preventDefault();
    updatePage(1);
  }

  const lastPage = (e) => {
    e.preventDefault();
    updatePage(Math.ceil(totalItems / currentLimit));
  }

  const prevPage = (e) => {
    e.preventDefault();
    updatePage(page > 1 ? page - 1 : page);
  }

  const nextPage = (e) => {
    e.preventDefault();
    updatePage(page < Math.ceil(totalItems / currentLimit) ? page + 1 : page);
  }

  const changePage = (e, num) => {
    e.preventDefault();
    updatePage(num);
  }

  const getDisplayGroups = () => {
    return displayGroups !== undefined && displayGroups.length ? displayGroups : [limit,50,100,250];
  }

  const pages = Math.ceil(totalItems / currentLimit);
  let count = pages;
  let start = 1;
  let includeFirst = false;
  let includeFirstEllipses = false;
  let includeLast = false;
  let includeLastEllipses = false;

  //Number of pages before, after, and including the current page to show
  pagesToShow = pagesToShow !== undefined && pagesToShow ?
    ( pagesToShow % 2 == 0 ? pagesToShow + 1 : pagesToShow )
    : 9;

  const halfBig = Math.ceil(pagesToShow / 2);
  const halfSmall = Math.floor(pagesToShow / 2);

  if( pages > pagesToShow ){
    count = pagesToShow;

    if( page > halfBig ) {
      start = page - halfSmall;
    }

    //Don't create more page tabs than there are pages
    if( start + count > pages ) start = pages - count;


    if( start > 1 ) includeFirst = true;
    if( start > 2 ) includeFirstEllipses = true;

    //Let's say there are 15 pages
    //We never have a start higher than 6 (which technically means that includeLast will always be TRUE)
    //if we have a start of 6 or less (page of 10 or less, end page of 14 or less), we include the last page
    if( start + pagesToShow - 1 < pages ) includeLast = true;
    //if we have a start of 5 or less (page of 9 or less, end page of 13 or less), we include the ellipsis
    if( start + pagesToShow < pages ) includeLastEllipses = true;
  }

  let pageArray = Array(count).fill().map((x,i)=> start + i);

  return (
    <div className={ `m${margin}-3 d-flex align-items-center` }>
      <Pagination className="mb-0">
        {
          includeFirst ? (
            <Pagination.First onClick={ (e) => firstPage(e) } />
          ) : null
        }
        <Pagination.Prev onClick={ (e) => prevPage(e) } />
        {
          includeFirstEllipses ? (
            <Pagination.Ellipsis onClick={ null } />
          ) : null
        }
        {
          pageArray.map((i) => (
            <Pagination.Item className={ page === i ? 'active' : '' } onClick={ (e) => changePage(e, i) }>
              { i }
            </Pagination.Item>
          ))
        }
        {
          includeLastEllipses ? (
            <Pagination.Ellipsis onClick={ null } />
          ) : null
        }
        <Pagination.Next onClick={ (e) => nextPage(e) } />
        {
          includeLast ? (
            <Pagination.Last className={ page === pages ? 'active' : '' } onClick={ (e) => lastPage(e) } />
          ) : null
        }
      </Pagination>
      <div className="d-flex align-items-center ms-auto" style={{maxWidth: '200px'}}>
        <div className="me-3">Show:</div>
        <Form.Select className="ms-auto" value={ currentLimit } onChange={ (e) => {
          updateLimit(e.target.value);
          updatePage(1);
        } }>
          {
            getDisplayGroups().map(each => <option value={ each }>{ each }</option>)
          }
        </Form.Select>
      </div>
    </div>
  );

}

CustomPagination.propTypes = {
  items: PropTypes.array,
  page: PropTypes.int,
  total: PropTypes.int,
  updatePage: PropTypes.func,
  limit: PropTypes.int,
  updateLimit: PropTypes.func,
  currentLimit: PropTypes.int,
  displayGroups: PropTypes.array,
  pagesToShow: PropTypes.int,
  margin: PropTypes.string,
};

export default CustomPagination;