import React from 'react';
import AuthCardLayout from 'components/authentication/custom/AuthCardLayout';
import appsumologo from 'assets/img/logo-appsumo-icon.png';
import logo from 'assets/img/logo-icon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PasswordResetForm from './PasswordResetForm';
import { getURLParameter } from 'helpers/utils';

const AppSumoLogin = () => {
  return (
    <AuthCardLayout
      leftSideContentTop={
        <div className="z-index-1 position-relative light">
          <div className="d-flex align-items-center justify-content-center mb-5">
            <img src={logo} alt="Logo" width={ 40 } />
            <FontAwesomeIcon className="ms-3 me-3" icon="plus" />
            <img src={appsumologo} alt="AppSumo Logo" width={ 40 } />
          </div>
          <p>
            Your emails are about to love the primary inbox like Sumo-lings love tacos!
          </p>
        </div>
      }
      leftSideContent={null}
      footer={false}
    >
      <h3>Hey Sumo-ling!</h3>
      <p>Please set your name and password.</p>
      <p><strong>{getURLParameter('rplogin')}</strong></p>
      {
        window.SSSettings.migrationWindowInterface
          ? <div className="alert alert-primary">{ window.SSSettings.migrationWindowMessage }</div>
          : <PasswordResetForm
            layout="card"
            addName={true}
            buttonText="Activate"
            autoLogin={true}
            hasLabel
          />
      }
    </AuthCardLayout>
  );
};

export default AppSumoLogin;
