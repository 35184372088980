import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import CustomNavbar from 'components/navbar/custom/CustomNavbar';
import Sec from 'components/common/Sec';
import routes from 'routes/settingsRoutes';

const Settings = ({ children }) => {
  return (
    <Sec size="xlg">
      <Row className="g-0">
        <Col md={12} xl={3} className="py-3">
          <CustomNavbar
            routes={routes}
            id="settings-sidebar"
            bg="transparent"
            orientation="vertical"
            roundedItem={true}
            navbarClass=""
            navClass=""
            collapse={false}
            navItemClass="text-700 text-900-hover p-2 bg-white-hover"
            navItemActiveClass="bg-white border border-color-200"
          />
        </Col>
        <Col md={12} xl={9}>
          <Card>
            {children}
          </Card>
        </Col>
      </Row>
    </Sec>
  );
};
Settings.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Settings;
