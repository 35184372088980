import React from 'react';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart, LineChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, isSuper } from 'helpers/utils';
//import { tooltipFormatter } from 'helpers/echart-utils';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = (sent, categories, spam, received, conversations, inbox, other, days) => {
  /*const days = [1, 2, 3, 4, 5, 6];
  const sent = [5, 10, 13, 18, 24, 35];
  const spam = [1, 1, 2, 3, 3, 5];*/

  return {
    color: [
      getColor('primary'),
      getColor('danger'),
    ],
    legend: {
      data: ['Sent', 'Received', 'Conversations', 'Inbox', 'Categories', 'Spam', 'Other'],
      left: 5,
      itemWidth: 10,
      itemHeight: 10,
      borderRadius: 0,
      icon: 'circle',
      inactiveColor: getColor('400'),
      textStyle: { color: getColor('700') },
      itemGap: 20
    },
    xAxis: {
      type: 'category',
      data: days,
      axisLine: {
        show: false
      },
      splitLine: {
        lineStyle: {
          color: getColor('200')
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: getColor('600'),
        formatter: value => value //value => 'Day: ' + value
      }
    },
    yAxis: {
      type: 'value',
      position: 'left',
      splitLine: {
        lineStyle: {
          color: getColor('200')
        }
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: true,
        color: getColor('600'),
        margin: 15
      }
    },
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      axisPointer: {
        type: 'none'
      },
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      textStyle: { color: getColor('700') },
      borderWidth: 1,
      transitionDuration: 0
      //formatter: tooltipFormatter
    },

    series: [
      {
        name: 'Sent',
        type: 'bar',
        data: sent
      },
      {
        name: 'Other',
        type: 'line',
        symbolSize: 6,
        itemStyle: {
          color: getColor('gray-500'),
          borderColor: getColor('gray-500'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('gray-500')
        },
        symbol: 'circle',
        data: other
      },
      {
        name: 'Received',
        type: 'line',
        symbolSize: 6,
        itemStyle: {
          color: getColor('secondary'),
          borderColor: getColor('secondary'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('secondary')
        },
        symbol: 'circle',
        data: received
      },
      {
        name: 'Conversations',
        type: 'line',
        symbolSize: 6,
        itemStyle: {
          color: getColor('info'),
          borderColor: getColor('info'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('info')
        },
        symbol: 'circle',
        data: conversations
      },
      {
        name: 'Inbox',
        type: 'line',
        symbolSize: 6,
        itemStyle: {
          color: getColor('success'),
          borderColor: getColor('success'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('success')
        },
        symbol: 'circle',
        data: inbox
      },
      {
        name: 'Categories',
        type: 'line',
        symbolSize: 6,
        itemStyle: {
          color: getColor('warning'),
          borderColor: getColor('warning'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('warning')
        },
        symbol: 'circle',
        data: categories
      },
      {
        name: 'Spam',
        type: 'line',
        symbolSize: 6,
        itemStyle: {
          color: getColor('danger'),
          borderColor: getColor('danger'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('danger')
        },
        symbol: 'circle',
        data: spam
      },
    ],

    grid: {
      containLabel: true,
      right: '5px',
      left: 0,
      bottom: 0,
      top: '15%'
    }
  }
};

const getOptionsInbox = (sent, categories, spam, received, conversations, inbox, other, days) => {
  const newInbox = inbox.map((val, i) => {
    return parseInt(sent[i], 10) - parseInt(other[i], 10) ? Math.round(val / (sent[i] - other[i]) * 100) : 0;
  });

  const newCategories = categories.map((val, i) => {
    return parseInt(sent[i], 10) - parseInt(other[i], 10) ? Math.round(val / (sent[i] - other[i]) * 100) : 0;
  });

  const newSpam = spam.map((val, i) => {
    return parseInt(sent[i], 10) - parseInt(other[i], 10) ? Math.round(val / (sent[i] - other[i]) * 100) : 0;
  })

  return {
    color: [
      getColor('primary'),
      getColor('danger'),
    ],
    legend: {
      data: ['Inbox Percentage', 'Categories Percentage', 'Spam Percentage'],
      left: 5,
      itemWidth: 10,
      itemHeight: 10,
      borderRadius: 0,
      icon: 'circle',
      inactiveColor: getColor('400'),
      textStyle: { color: getColor('700') },
      itemGap: 20
    },
    xAxis: {
      type: 'category',
      data: days,
      axisLine: {
        show: false
      },
      splitLine: {
        lineStyle: {
          color: getColor('200')
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: getColor('600'),
        formatter: value => value //value => 'Day: ' + value
      }
    },
    yAxis: {
      type: 'value',
      position: 'left',
      splitLine: {
        lineStyle: {
          color: getColor('200')
        }
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: true,
        color: getColor('600'),
        margin: 15
      }
    },
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      axisPointer: {
        type: 'none'
      },
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      textStyle: { color: getColor('700') },
      borderWidth: 1,
      transitionDuration: 0
      //formatter: tooltipFormatter
    },

    series: [
      {
        name: 'Inbox Percentage',
        type: 'line',
        symbolSize: 6,
        itemStyle: {
          color: getColor('success'),
          borderColor: getColor('success'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('success')
        },
        symbol: 'circle',
        data: newInbox
      },
      {
        name: 'Categories Percentage',
        type: 'line',
        symbolSize: 6,
        itemStyle: {
          color: getColor('warning'),
          borderColor: getColor('warning'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('warning')
        },
        symbol: 'circle',
        data: newCategories
      },
      {
        name: 'Spam Percentage',
        type: 'line',
        symbolSize: 6,
        itemStyle: {
          color: getColor('danger'),
          borderColor: getColor('danger'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('danger')
        },
        symbol: 'circle',
        data: newSpam
      },
    ],

    grid: {
      containLabel: true,
      right: '5px',
      left: 0,
      bottom: 0,
      top: '15%'
    }
  }
};

const getOptionsScores = (scores, days) => {
  let fill = days.length - scores.length;

  if( fill < 0 ) fill = 0;

  let newScores = new Array(fill).fill(0);
  newScores = newScores.concat(scores);

  console.log('newScores', newScores);

  return {
    color: [
      getColor('primary'),
      getColor('danger'),
    ],
    legend: {
      data: ['Score'],
      left: 5,
      itemWidth: 10,
      itemHeight: 10,
      borderRadius: 0,
      icon: 'circle',
      inactiveColor: getColor('400'),
      textStyle: { color: getColor('700') },
      itemGap: 20
    },
    xAxis: {
      type: 'category',
      data: days,
      axisLine: {
        show: false
      },
      splitLine: {
        lineStyle: {
          color: getColor('200')
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: getColor('600'),
        formatter: value => value //value => 'Day: ' + value
      }
    },
    yAxis: {
      type: 'value',
      position: 'left',
      min: 0,
      max: 100,
      splitLine: {
        lineStyle: {
          color: getColor('200')
        }
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: true,
        color: getColor('600'),
        margin: 15
      }
    },
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      axisPointer: {
        type: 'none'
      },
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      textStyle: { color: getColor('700') },
      borderWidth: 1,
      transitionDuration: 0
      //formatter: tooltipFormatter
    },

    series: [
      {
        name: 'Score',
        type: 'line',
        symbolSize: 6,
        itemStyle: {
          color: getColor('success'),
          borderColor: getColor('success'),
          borderWidth: 2
        },
        lineStyle: {
          color: getColor('success')
        },
        symbol: 'circle',
        data: newScores
      }
    ],

    grid: {
      containLabel: true,
      right: '5px',
      left: 0,
      bottom: 0,
      top: '15%'
    }
  }
};

const OverviewChart = ({
  sent,
  categories,
  received,
  spam,
  conversations,
  inbox,
  other,
  days,
  scores
}) => {
  return (
    <>
      <ReactEChartsCore
        echarts={echarts}
        option={getOptions(sent, categories, spam, received, conversations, inbox, other, days)}
        style={{ height: '21.875rem' }}
      />
      <ReactEChartsCore
        className="mt-5"
        echarts={echarts}
        option={getOptionsInbox(sent, categories, spam, received, conversations, inbox, other, days)}
        style={{ height: '21.875rem' }}
      />
      {
        scores.length && isSuper() ? (
          <ReactEChartsCore
            className="mt-5"
            echarts={echarts}
            option={getOptionsScores(scores, days)}
            style={{ height: '21.875rem' }}
          />
        ) : null
      }
    </>
  );
};

export default OverviewChart;
