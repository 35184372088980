import React, {useEffect, useContext, useState} from 'react';
//import PropTypes from 'prop-types';\
import { Col, Row, ProgressBar } from 'react-bootstrap';
import API from '../../../api/api';
import { AccountContext } from '../../../context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomPagination from '../../common/Pagination';
import TooltipItem from '../../common/TooltipItem';
import { ucFirst } from '../../../helpers/utils';
import FilterTable from '../../common/FilterTable';
//import ConversationTimeline from './ConversationTimeline';

const Conversations = ({

}) => {
  const { accountState } = useContext(AccountContext);

  const connectedEmail = accountState?.connected_email !== undefined ? accountState.connected_email : {};
  const connectedEmailMeta = connectedEmail?.meta;

  const timezoneObj = connectedEmailMeta !== undefined ? connectedEmailMeta.find(each => each.metakey === 'timezone') : undefined;
  const timezone = timezoneObj !== undefined ? timezoneObj.value : 'US/Eastern';

  const [conversations, setConversations] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    //Get super/database updates, merge with super/platform and update the adminReducer
    API.doRequest('conversations', 'get', 'get', null, {
      params: [
        { param: 'limit', value: limit },
        { param: 'offset', value: (page - 1) * limit },
        { param: 'with_emails', value: 1 }
      ]
    }).then(results => {
      setConversations(results.data);
    }).catch(error => {

    });
  }, [page, limit]);

  useEffect(() => {
    //Get super/database updates, merge with super/platform and update the adminReducer
    API.doRequest('conversations', 'get_pages').then(results => {
      setTotalPages(results.data);
    }).catch(error => {

    });
  }, []);

  const cols = [
    {
      id: 'limit',
      name: '',
      type: 'div',
      minWidth: 50,
      valueFunction: (val, row) => {
        const tooltip = parseInt(val, 10) === 1 ? 'Single email' : 'Back-and-forth conversation';
        const conversationIcon = parseInt(val, 10) === 1 ? 'comment' : 'comments';
        return (
          <TooltipItem tooltip={ tooltip } placement="right">
            <FontAwesomeIcon icon={ conversationIcon } className="text-primary fs-2" />
          </TooltipItem>
        )
      }
    },
    {
      id: 'id',
      name: 'Conversation With',
      type: 'div',
      size: 2,
      valueFunction: (val, row) => row.initiator === connectedEmail.id ? row.responder_email : row.initiator_email
    },
    {
      id: 'provider',
      name: 'Provider',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        let provider = '';

        if( row.emails === undefined ) return '-';

        row.emails.forEach(each => {
          if(each.from === connectedEmail.id){
            provider = ucFirst(each.to_provider);
          } else {
            provider = ucFirst(each.from_provider);
          }
        });

        return provider;
      }
    },
    {
      id: 'id',
      name: 'Sent',
      type: 'div',
      size: 2,
      valueFunction: (val, row) => {
        let inbox = 0;
        let categories = 0;
        let junk = 0;
        let other = 0;

        if( row.emails === undefined ) return '-';

        row.emails.forEach(each => {
          if(each.from === connectedEmail.id){
            if(parseInt(each.is_junk, 10)) junk++;
            else if(parseInt(each.is_categories, 10)) categories++;
            else if(each.result === 'inbox' || each.result === 'primary') inbox++;
            else other++;
          }
        });

        const total = row.initiator === connectedEmail.id ? Math.round(row.limit / 2 ) : -Math.round(-row.limit / 2 );
        const unsent = total - inbox - categories - junk - other;
        const sent = inbox + categories + junk + other;

        return (
          <div className="d-flex align-items-center">
            <TooltipItem className="flex-grow-1" tooltip={ `Inbox: ${inbox}, Categories: ${categories}, Junk: ${junk}, Other: ${other}, Total: ${total}`}>
              <ProgressBar className="flex-grow-1">
                <ProgressBar variant="success" now={inbox} max={total} key={1} />
                <ProgressBar variant="warning" now={categories} max={total} key={2} />
                <ProgressBar variant="danger" now={junk} max={total} key={3} />
                <ProgressBar variant="gray-400" now={other} max={total} key={4} />
                <ProgressBar variant="gray-200" now={unsent} max={total} key={5} />
              </ProgressBar>
            </TooltipItem>
            <span className="ms-2 text-gray-400">{sent} / {total}</span>
          </div>
        )
      }
    },
    {
      id: 'id',
      name: 'Received',
      type: 'div',
      size: 2,
      valueFunction: (val, row) => {
        let inbox = 0;
        let categories = 0;
        let junk = 0;
        let other = 0;

        if( row.emails === undefined ) return '-';

        row.emails.forEach(each => {
          if(each.from !== connectedEmail.id){
            if(parseInt(each.is_junk, 10)) junk++;
            else if(parseInt(each.is_categories, 10)) categories++;
            else if(each.result === 'inbox' || each.result === 'primary') inbox++;
            else other++;
          }
        });

        const total = row.initiator !== connectedEmail.id ? Math.round(row.limit / 2 ) : -Math.round(-row.limit / 2 );
        const unsent = total - inbox - categories - junk - other;
        const sent = inbox + categories + junk + other;

        return (
          <div className="d-flex align-items-center">
            <TooltipItem className="flex-grow-1" tooltip={ `Inbox: ${inbox}, Categories: ${categories}, Junk: ${junk}, Other: ${other}, Total: ${total}`}>
              <ProgressBar className="flex-grow-1">
                <ProgressBar variant="success" now={inbox} max={total} key={1} />
                <ProgressBar variant="warning" now={categories} max={total} key={2} />
                <ProgressBar variant="danger" now={junk} max={total} key={3} />
                <ProgressBar variant="gray-400" now={other} max={total} key={4} />
                <ProgressBar variant="gray-200" now={unsent} max={total} key={5} />
              </ProgressBar>
            </TooltipItem>
            <span className="ms-2 text-gray-400">{sent} / {total}</span>
          </div>
        )
      }
    },
    {
      id: 'complete',
      name: 'Complete',
      type: 'div',
      size: 1,
      minWidth: 100,
      right: true,
      valueFunction: (val, row) => {
        return parseInt(val, 10) ? <FontAwesomeIcon className="text-success fs-2" icon="check" /> : <FontAwesomeIcon className="text-gray-400 fs-2" icon="ellipsis-h" />
      }
    }
  ];

  return (
    <>
      <FilterTable data={conversations} columns={cols} />
      {/*<ConversationTimeline conversations={conversations} connectedEmail={connectedEmail} timezone={timezone} />*/}
      <CustomPagination
        total={ totalPages }
        limit={ 10 }
        page={ page }
        currentLimit={ limit }
        updatePage={ (page) => setPage(page) }
        updateLimit={ (limit) => setLimit(limit) }
        margin="t"
      />
    </>
  )
};

Conversations.propTypes = {

};

export default Conversations;
