import React, { useState, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { ProgressBar, Button, Dropdown } from 'react-bootstrap';
import Panel from '../common/Panel';
import FilterTable from '../common/FilterTable';
import cookie from 'react-cookies';
import { AccountContext, AppContext } from '../../context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipItem from '../common/TooltipItem';
import HealthScore from '../email/HealthScore';
import CustomPagination from '../common/Pagination';
import API from '../../api/api';
import {
  errorMessage, getStatusArray,
  getURLParameter,
  shouldNotToggleWarming,
  successMessage, timestampInRangeRelative,
  timestampToDate,
  ucFirst
} from '../../helpers/utils';
import { Link } from 'react-router-dom';
import FormInfo from '../common/FormInfo';
import StatusTest from './StatusTest';
import HealthCheck from './HealthCheck';
import EmailModal from '../common/EmailModal';
import { usePaginationLimit } from '../../hooks/usePaginationLimit';

const Emails = ({}) => {
  const { accountState } = useContext(AccountContext);
  const { appConfirm } = useContext(AppContext);

  const user = accountState?.user !== undefined ? accountState.user : {};
  const userEmail = user?.user_email !== undefined ? user.user_email : '';
  const userFirst = user?.first_name !== undefined ? user.first_name : '';
  const userLast = user?.last_name !== undefined ? user.last_name : '';
  const [paginationLimit, setPaginationLimit] = usePaginationLimit('emails');
  const [data, setData] = useState([]);
  const [, setTestEmail] = useState('');
  const [testEmailTo, setTestEmailTo] = useState(user?.user_email !== undefined ? user.user_email : '');
  const [page, setPage] = useState(1);
  const [totalConnectedEmails, setTotalConnectedEmails] = useState(1);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState(getURLParameter('status'));
  const [provider, setProvider] = useState(getURLParameter('provider'));
  const [loading, setLoading] = useState(true);
  const [loadingFolder, setLoadingFolder] = useState(false);
  const [, setLoadingCheckError] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(false);
  const [, setHealthCheck] = useState(false);
  const [statusCheck, setStatusCheck] = useState(false);
  const [, setStatusCheckResult] = useState(false);
  const defaultPagination = 10;

  //console.log('paginationLimit', paginationLimit);

  let params = [
    { param: 'limit', value: paginationLimit },
    { param: 'offset', value: (page - 1) * paginationLimit },
    { param: 'cycle', value: 1 },
    { param: 'meta', value: 1 },
    { param: 'latest_bounce', value: 1 },
    { param: 'stats', value: 1 },
    { param: 'status', value: status },
    { param: 'provider', value: provider },
    { param: 'search', value: search }
  ];

  //Get emails
  useEffect(() => {
    API.doRequest('connected_emails', 'get', 'get', null, { params }).then(result => {
      if (result.data !== undefined) {
        setData(result.data);
      }
      setLoading(false);
    }).catch(error => {
      errorMessage('Could not load');
      setLoading(false);
    });
  }, [paginationLimit, page, status, provider, search]);

  //Get email count
  useEffect(() => {
    //Get super/database updates, merge with super/platform and update the adminReducer
    API.doRequest('connected_emails', 'get_total', 'get', null, { params }).then(results => {
      setTotalConnectedEmails(parseInt(results.data, 10));
    }).catch(error => {

    });
  }, [status, provider, search]);

  const hasEmails = totalConnectedEmails || status || provider || search;

  //If we're not finding any emails and we've loaded, show indication to connect first email
  if (!hasEmails && !loading) {
    return (
      <Panel title="Emails">
        <p>Connect your first email account to start warming!</p>
        <Link className="text-white text-decoration-none" to="/connect">
            <span className="btn btn-primary">
              Connect Email
            </span>
        </Link>
      </Panel>
    )
  }

  /**
   * When an email is clicked, set cookie and redirect
   *
   * @param id
   * @param setup
   * @param href
   */
  const handleClick = (id, setup = false, href = '') => {
    cookie.save(SSSettings.namelower + '_connected_email', id, { path: '/', maxAge: (1000 * 60 * 60 * 24) });

    if (href) {
      window.location.href = href;
    } else if (setup) {
      window.location.href = '/email/settings';
    } else {
      window.location.href = '/email/overview';
    }
  }

  /**
   * Toggle warmup on and off
   *
   * @param connectedEmail
   * @param val
   */
  const toggleWarming = (connectedEmail, val) => {
    if (val && (connectedEmail.status === 'new' || connectedEmail.status === 'connecting')) {
      errorMessage('Email is not ready. Please try again in 15 minutes or Retry Status Check from the "more" menu to force an update.');
      return;
    }
    if (shouldNotToggleWarming(connectedEmail.status)) {
      errorMessage('Please click the Retry Status Check option from the "more" menu to make sure that the email is connected.');
      return;
    }
    setData(data.map(each => {
      if (each.id === connectedEmail.id) each.active = parseInt(val, 10) ? 1 : 0;
      return each;
    }));
    API.doRequest('connected_emails', 'update', 'update', {
      update: {
        id: connectedEmail.id,
        active: parseInt(val, 10) ? 1 : 0,
        status: parseInt(val, 10) ? 'warming' : 'ready'
      },
      where: {
        id: connectedEmail.id
      }
    }).then(result => {
      successMessage('Status switched ' + (val ? 'on' : 'off'));
      setData(data.map(each => {
        if (each.id === connectedEmail.id) each.status = 'warming';
        return each;
      }));
    }).catch(error => {
      errorMessage('', error);
      setData(data.map(each => {
        if (each.id === connectedEmail.id) each.active = parseInt(val, 10) ? 0 : 1;
        return each;
      }));
    })
  }

  /**
   * If a connected email has an error, will display more information from the meta
   *
   * @param connectedEmail
   */
  const checkError = (connectedEmail) => {
    setLoadingCheckError(connectedEmail.id);

    const smtpError = connectedEmail.meta?.smtp_error !== undefined ? connectedEmail.meta.smtp_error : false;
    const smtpErrorLast = connectedEmail.meta?.last_smtp_error !== undefined ? connectedEmail.meta.last_smtp_error : false;

    if (connectedEmail.status === 'bounce') {
      appConfirm({
        type: 'message',
        confirmText: 'Okay',
        confirmColor: 'text',
        header: 'Bounce Error',
        body: (
          <>
            <div className="mb-2">This email has rejected too many warmup emails from our network.</div>
            {
              connectedEmail.latest_bounce !== undefined && connectedEmail.latest_bounce ? (
                <>
                  <div className="mb-2">Latest bounce message:</div>
                  <div className="mb-2 alert alert-danger">
                    <pre>{connectedEmail.latest_bounce.code !== undefined ? connectedEmail.latest_bounce.code : ''}</pre>
                    <pre
                      style={{ whiteSpace: 'pre-line' }}>{connectedEmail.latest_bounce.message !== undefined ? connectedEmail.latest_bounce.message : ''}</pre>
                  </div>
                </>
              ) : <div className="mb-2"><em>Could not retrieve latest bounce error...</em></div>
            }
            <div><a className="btn btn-primary"
                    href={`https://boxward.com/docs/my-email-was-disabled-due-to-bounces/?email_status=bounce&disabled_email=${connectedEmail.email}&account_email=${userEmail}&user_first=${userFirst}&user_last=${userLast}`}
                    target="_blank">Fix This Email</a></div>
          </>
        ),
      })
    } else if (connectedEmail.status === 'senderBounce') {
      appConfirm({
        type: 'message',
        confirmText: 'Okay',
        confirmColor: 'text',
        header: 'Bounce Error',
        body: (
          <>
            <div className="mb-2">This email has had too many warmup emails rejected by accounts in our network.</div>
            {
              connectedEmail.latest_bounce !== undefined ? (
                <>
                  <div className="mb-2">Latest bounce message:</div>
                  <div className="alert alert-danger">
                    <pre>{connectedEmail.latest_bounce.code !== undefined ? connectedEmail.latest_bounce.code : ''}</pre>
                    <pre>{connectedEmail.latest_bounce.message !== undefined ? connectedEmail.latest_bounce.message : ''}</pre>
                  </div>
                </>
              ) : <div className="mb-2"><em>Could not retrieve latest bounce error...</em></div>
            }
            <div><a className="btn btn-primary"
                    href={`https://boxward.com/docs/my-email-was-disabled-due-to-bounces/?email_status=senderBounce&disabled_email=${connectedEmail.email}&account_email=${userEmail}&user_first=${userFirst}&user_last=${userLast}`}
                    target="_blank">Fix This Email</a></div>
          </>
        ),
      })
    } else if (smtpError && timestampInRangeRelative(smtpErrorLast, -1 * 60 * 60 * 24 * 3)) {
      const errorMessage = connectedEmail.meta?.smtp_error !== undefined ? connectedEmail.meta.smtp_error : 'There was an smtp error when Boxward tried to send an email';

      appConfirm({
        type: 'message',
        confirmText: 'Okay',
        confirmColor: 'text',
        header: 'SMTP Error',
        body: (
          <>
            <div className="mb-2">
              <div className="alert alert-danger" dangerouslySetInnerHTML={{ __html: errorMessage }} />
            </div>
            <div className="mb-2">Please refer to <a href="https://boxward.com/docs/what-to-do-with-an-smtp-error/"
                                                     target="_blank" rel="noopener noreferrer">this help doc</a> for
              information on how to fix this SMTP error.
            </div>
            <div className="mb-2 fs--1"><em>Important: Clicking "Retry Status Check" only checks for IMAP connection.
              Since this is an SMTP connection error, your email may appear to reconnect, but then may disconnect again
              as SMTP errors are only found when the account tries to send an email.</em></div>
          </>
        ),
      })
    } else {
      API.doRequest('connected_emails', 'get_error', 'update', { update: { id: connectedEmail.id } }).then(result => {
        appConfirm({
          type: 'message',
          confirmText: 'Okay',
          confirmColor: 'text',
          header: 'Error',
          body: (
            <>
              {result.data.response !== undefined ? <div className="mb-2"><em>{result.data.response}</em></div> : null}
              {result.data.description !== undefined ? <div className="mb-2"><em>{result.data.description}</em></div> : null}
              {result.data.recommendation !== undefined ? <div className="mb-2">{result.data.recommendation}</div> : null}
            </>
          ),
        })

        setLoadingCheckError(false);
      }).catch(error => {
        errorMessage('', error);
        setLoadingCheckError(false);
      });
    }

  }

  /**
   * Will create the warmup folder
   *
   * @param id
   */
  const createFolder = (id) => {
    setLoadingFolder(id);
    API.doRequest('connected_emails', 'create_folder', 'update', { update: { id } }).then(result => {
      setData(data.map(each => {
        if (each.id === id) {
          each.folder = result.data;
        }
        return each;
      }));

      successMessage('', result);
      setLoadingFolder(false);
    }).catch(error => {
      errorMessage('', error);
      setLoadingFolder(false);
    });
  }

  /**
   * Will check for the warmup folder and sync it if found, create it if not
   *
   * @param id
   * @param create
   */
  const syncFolder = (id, create = false) => {
    setLoadingFolder(id);
    API.doRequest('connected_emails', 'sync_folder', 'update', { update: { id } }).then(result => {
      setData(data.map(each => {
        if (each.id === id) {
          each.folder = result.data;
        }
        return each;
      }));

      successMessage('', result);
      setLoadingFolder(false);
    }).catch(error => {
      if (create) {
        createFolder(id);
      } else {
        errorMessage('', error);
        setLoadingFolder(false);
      }
    });
  }

  /**
   * Launches modal to send test email
   *
   * @param id
   */
  const testEmailModal = (id) => {
    setTestEmail(id);

    appConfirm({
      type: 'message',
      confirmText: 'Done',
      confirmColor: 'text',
      header: 'Send Test Email',
      //body: <EmailModal testEmailTo={testEmailTo} setTestEmailTo={setTestEmailTo} connectedId={id} />,
      body: (
        <div>
          <EmailModal testEmailTo={testEmailTo} setTestEmailTo={setTestEmailTo} connectedId={id} />
        </div>
      )
    })
  }

  /**
   * Updates the state to reflect the results of the status check
   *
   * @param result
   */
  const handleStatusTestResult = (result) => {
    setStatusCheckResult(result);
    setStatusCheck(false);

    setData(data.map(each => {
      if (each.id === result.connectedId && result.newStatus) {
        each.status = result.newStatus;

        if (result.newStatus === 'warming') each.active = 1;
      }

      return each;
    }));
  }

  /**
   * If an email has a bounce status, we don't need to do a status check so throw an error
   * If an email has an SMTP error, warn the user that a status check may not actually reflect the true state of SMTP connection
   * Otherwise, run the status check
   *
   * @param connectedEmail
   */
  const maybeStatusTest = (connectedEmail) => {
    //If the email has a bounce status, we aren't running checks
    if (connectedEmail.status === 'senderBounce' || connectedEmail.status === 'bounce') {
      errorMessage('This address has been disabled due to its high bounce rate. Please contact support.');
      return;
    }

    const smtpError = connectedEmail.meta?.smtp_error !== undefined ? connectedEmail.meta.smtp_error : false;
    const smtpErrorLast = connectedEmail.meta?.last_smtp_error !== undefined ? connectedEmail.meta.last_smtp_error : false;

    //If there's an smtp error, warn about the limits of the status test
    if (smtpError && timestampInRangeRelative(smtpErrorLast, -1 * 60 * 60 * 24 * 3)) {
      appConfirm({
        type: 'confirm',
        confirm: () => statusTest(connectedEmail),
        confirmText: 'Continue Check',
        header: 'Status Test Will Not Check SMTP Errors',
        body: 'Your email has experienced an SMTP error. Clicking "Retry Status Check" only checks for IMAP connection. Since this is an SMTP connection error, your email may appear to reconnect, but then may disconnect again as SMTP errors are only found when the account tries to send an email.',
      })
    } else {
      statusTest(connectedEmail)
    }
  }

  /**
   * Check the status of the connected email's IMAP connection
   *
   * @param connectedEmail
   */
  const statusTest = (connectedEmail) => {
    setStatusCheck(connectedEmail.id);

    appConfirm({
      type: 'message',
      confirmText: 'Done',
      confirmColor: 'text',
      confirm: () => {
        setStatusCheck(false);
      },
      header: 'Status Check',
      body: (
        <div>
          <StatusTest
            connectedId={connectedEmail.id}
            current={connectedEmail.status}
            title="Checking status..."
            removeTitleOnFinish={true}
            onCheckUpdate={true}
            onCheckFinish={handleStatusTestResult} />
        </div>
      )
    })
  }

  /**
   * Run a health check on a connected email
   *
   * @param connectedEmail
   */
  const doHealthCheck = (connectedEmail) => {
    setHealthCheck(connectedEmail.id);

    appConfirm({
      type: 'message',
      confirmText: 'Done',
      confirmColor: 'text',
      confirm: () => {
        setHealthCheck(false);
      },
      header: 'Health Check',
      //body: <EmailModal testEmailTo={testEmailTo} setTestEmailTo={setTestEmailTo} connectedId={id} />,
      body: (
        <div>
          <HealthCheck
            connectedId={connectedEmail.id}
            current={connectedEmail.status}
            title={false}
            onCheckUpdate={false}
            onCheckFinish={false} />
        </div>
      )
    })
  }

  /**
   * Delete a connected email from the account
   *
   * @param connectedEmail
   */
  const deleteConnectedEmail = (connectedEmail) => {
    API.doRequest('connected_emails', 'delete', 'delete', null, {
      params: [{
        param: 'id',
        value: connectedEmail.id
      }]
    }).then(result => {
      successMessage('Email has been deleted from your account');
      window.location.href = '/emails';
    }).catch(error => {
      errorMessage('Email could not be deleted from your account')
    })
  }

  /**
   * Check to see if the user really wants to delete the connected email
   *
   * @param connectedEmail
   */
  const maybeDelete = (connectedEmail) => {
    appConfirm({
      type: 'confirm',
      confirm: () => deleteConnectedEmail(connectedEmail),
      confirmText: 'Delete',
      confirmColor: 'danger',
      header: 'Delete ' + connectedEmail.email,
      body: 'Are you sure you want to delete this email? This will stop warming this email and remove it from your account.',
    });
  }

  /**
   * Configuration for the filter table
   *
   * @type {[{valueFunction: (function(*, *)), name: string, minWidth: number, id: string, type: string},{valueFunction: (function(*, *)), name: string, minWidth: number, id: string, type: string},{valueFunction: (function(*, *)), size: number, name: string, id: string, type: string},{valueFunction: ((function(*, *): (string|*))|*), size: number, name: string, tooltip: string, id: string, type: string},{filterable: boolean, itemNameFunc: ((function(*): (string|*))|*), valueFunction: (function(*, *): *), size: number, filterDefaultValue: *, name: string, onFilterUpdate: onFilterUpdate, filterDefaults: string[], id: string, type: string},null,null,null]}
   */
  const cols = [
    {
      id: 'active',
      name: '',
      type: 'div',
      minWidth: 100,
      valueFunction: (val, row) => {
        const active = parseInt(val, 10);

        let toggleFunc = () => toggleWarming(row, (active ? 0 : 1));

        /*if( row.status === 'smtpError' && ! active ){
          toggleFunc = () => maybeToggleWarming(row, (active ? 0 : 1));
        }*/

        return (
          <TooltipItem tooltip={active ? 'Turn off warmup' : 'Turn on warmup'} placement="right">
            <FontAwesomeIcon onClick={toggleFunc} className={classNames('cursor-pointer fs-3', {
              ['text-success']: active,
              ['text-danger']: !active
            })
            } icon={active ? 'toggle-on' : 'toggle-off'} />
          </TooltipItem>
        )
      }
    },
    {
      id: 'score',
      name: 'Score',
      type: 'div',
      minWidth: 100,
      valueFunction: (val, row) => {
        return <HealthScore connectedEmail={row} lineWidth={3} size="60px" titleFontSize={10} tooltipPlacement="right" />
      }
    },
    {
      id: 'email',
      name: 'Email',
      type: 'div',
      size: 2,
      valueFunction: (val, row) => {
        return (
          <>
            <div><span className="cursor-pointer text-primary" onClick={() => handleClick(row.id)}>{val}</span></div>
            <div className="fs--1 mt-2">
              <strong>Sent Today</strong>: {row.cycle?.sent !== undefined ? row.cycle?.sent : 0}/{row.cycle?.limit !== undefined ? row.cycle?.limit : 0}
            </div>
            <div className="fs--1">
              {
                window.SSSettings.migrationWindowSend ? (
                  <>
                    <strong>Next</strong>: Sending Paused <FormInfo>{window.SSSettings.migrationWindowMessage}</FormInfo>
                  </>
                ) : (
                  <>
                    <strong>Next</strong>: {row.cycle?.next !== undefined && !parseInt(row.cycle?.complete, 10) ? timestampToDate(row.cycle?.next, row.meta?.timezone, 'YYYY-MM-DD HH:mm') : (row.status === 'warming' ? 'Upcoming Sending Window' : '-')}
                  </>
                )
              }
            </div>
            <div className="fs--1">
              <strong>Provider</strong>: {row.provider.split('_')[0]}
            </div>
            {
              !parseInt(row.setup, 10)
                ? <Button variant="primary mt-2" onClick={() => handleClick(row.id, true)}>Complete Setup...</Button>
                : null
            }
          </>
        )
      }
    },
    {
      id: 'stats',
      name: 'Inbox Placement',
      type: 'div',
      tooltip: 'Shows most recent 7 active days. Updated once per day.',
      size: 2,
      valueFunction: (val, row) => {
        if (row.sent === undefined || !parseInt(row.sent, 10)) {
          return 'Gathering data...'
        }

        const percentage = Math.round(parseInt(row.inbox, 10) / parseInt(row.sent, 10) * 100);
        const lastUpdate = timestampToDate(row.stats_updated, row.meta?.timezone, 'YYYY-MM-DD HH:mm')

        return (
          <div className="d-flex align-items-center">
            <span className="me-2">{percentage}%</span>
            <TooltipItem className="flex-grow-1"
                         tooltip={`Sent: ${row.sent}, Inbox: ${row.inbox}, Categories: ${row.categories}, Junk: ${row.junk}, Other: ${row.other}, Last Updated: ${lastUpdate}`}>
              <ProgressBar className="flex-grow-1">
                <ProgressBar variant="success" now={row.inbox} max={row.sent} key={1} />
                <ProgressBar variant="warning" now={row.categories} max={row.sent} key={2} />
                <ProgressBar variant="danger" now={row.junk} max={row.sent} key={3} />
                <ProgressBar variant="gray-400" now={row.other} max={row.sent} key={4} />
              </ProgressBar>
            </TooltipItem>
          </div>
        )
      }
    },
    {
      id: 'provider',
      name: 'Provider',
      type: 'div',
      size: 0,
      filterable: true,
      onFilterUpdate: (val) => {
        setProvider(val);
        setPage(1);
      },
      filterDefaults: ['google', 'microsoft', 'zoho', 'smtp'],
      itemNameFunc: (each) => {
        if (each === 'smtp') return 'Other';

        return ucFirst(each);
      },
      filterDefaultValue: provider,
      valueFunction: (val, row) => val.split('_')[0]
    },
    {
      id: 'status',
      name: 'Status',
      type: 'div',
      size: 1,
      minWidth: 115,
      filterable: true,
      onFilterUpdate: (val) => {
        setStatus(val);
        setPage(1);
      },
      filterDefaults: getStatusArray(),
      itemNameFunc: (each) => {
        if (each === 'smtpError') return 'SMTP Error';

        return ucFirst(each);
      },
      filterDefaultValue: status,
      valueFunction: (val, row) => {
        let color;

        switch (val) {
          case 'error':
            color = 'danger';
            break;
          case 'bounce':
            color = 'danger';
            break;
          case 'senderBounce':
            color = 'danger';
            break;
          case 'authenticationError':
            color = 'danger';
            break;
          case 'smtpError':
            color = 'danger';
            break;
          case 'disconnected':
            color = 'danger';
            break;
          case 'reconnecting':
            color = 'warning';
            break;
          case 'connecting':
            color = 'primary';
            break;
          case 'ready':
            color = 'primary';
            break;
          default:
            color = 'success';
            break;
        }

        let onClick = null;
        let cursor = '';

        if (color === 'danger') {
          onClick = () => checkError(row);
          cursor = ' cursor-pointer';
        }

        let info = null;

        if (val === 'smtpError' || val === 'disconnected' || val === 'bounce' || val === 'error' || val === 'authenticationError') {
          info = <span className="d-block fs--2 text-danger cursor-pointer">(More Info...)</span>
        }

        return (
          <span onClick={onClick}>
            <span className={`fs--2 badge badge-soft-${color}${cursor}`}>{val}</span>
            {info}
          </span>
        )
      }
    },
    {
      id: 'routine',
      name: 'Routine',
      type: 'div',
      size: 1,
      minWidth: 100,
      valueFunction: (val, row) => {
        return <span className="badge badge-soft-primary fs--2">{val}</span>
      }
    },
    {
      id: 'id',
      name: '',
      type: 'div',
      size: 1,
      minWidth: 50,
      right: true,
      valueFunction: (val, row) => {
        /*let checkStatusFunc = () => checkConnectionStatus(val);

        if( row.status === 'smtpError' ){
          checkStatusFunc = () => maybeCheckConnectionStatus(row);
        }

        const oldCheck = (
            <TooltipItem tooltip="Check this email's connection status"  placement="left">
              <FontAwesomeIcon
                className={classNames('text-primary cursor-pointer ms-2', {['fa-spin']: loadingRefresh === val})}
                icon="sync-alt" onClick={checkStatusFunc} />
            </TooltipItem>
        );*/

        let folder = null;

        if (!row.folder && row.status !== 'new' && row.status !== 'connecting') {
          folder = (
            <TooltipItem
              tooltip="A folder for organizing inbox emails hasn't been created yet. Click to try to create that folder."
              placement="left">
              <FontAwesomeIcon
                className={classNames('text-danger cursor-pointer me-2', { ['fa-beat-fade']: loadingFolder === val })}
                icon="exclamation-triangle" onClick={() => createFolder(val)} />
            </TooltipItem>
          );
        } else if (row.folder === '1') {
          folder = (
            <TooltipItem
              tooltip="Boxward has detected that your folder for warmup emails needs to be synced. Click to sync."
              placement="left">
              <FontAwesomeIcon
                className={classNames('text-danger cursor-pointer me-2', { ['fa-beat-fade']: loadingFolder === val })}
                icon="exclamation-triangle" onClick={() => syncFolder(val)} />
            </TooltipItem>
          );
        }

        const old = (
          <>
            <TooltipItem tooltip="Send a test email from this email address to verify connection..." placement="left">
              <FontAwesomeIcon className="text-primary cursor-pointer" icon="envelope"
                               onClick={() => testEmailModal(val)} />
            </TooltipItem>
            <TooltipItem tooltip="Check this email's status" placement="left">
              <FontAwesomeIcon
                className={classNames('text-primary cursor-pointer ms-2', { ['fa-spin']: statusCheck === val })}
                icon="sync-alt" onClick={() => statusTest(row)} />
            </TooltipItem>
          </>
        )

        return (
          <div className="d-flex align-items-center">
            {folder}

            <Dropdown
              align="end"
              className="font-sans-serif btn-reveal-trigger">
              <Dropdown.Toggle
                variant="white"
                size="md"
                data-boundary="viewport"
                className={classNames('text-600', 'btn-sstart-square', 'border-gray-300')}
              >
                <FontAwesomeIcon icon="ellipsis-h" className="fs--2" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  <Dropdown.Item>
                    <span onClick={() => testEmailModal(val)}>
                      Send Test Email...
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <span onClick={() => maybeStatusTest(row)}>
                      <FontAwesomeIcon
                        className={classNames('text-primary cursor-pointer ms-2', {
                          ['fa-spin']: statusCheck === val,
                          ['d-none']: statusCheck !== val,
                        })}
                        icon="sync-alt" /> Retry Status Check
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <span onClick={() => doHealthCheck(row)}>
                      Health Check
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <span onClick={() => syncFolder(val, true)}>
                      Retry Keep Warm Folder Check
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <span onClick={() => handleClick(row.id, false, '/email/reconnect')}>
                      Edit Connection Details
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item className="text-danger" onClick={() => maybeDelete(row)}>Delete
                    Email...</Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      }
    }
  ];

  return (
    <Panel title="Emails">
      <FilterTable data={data}
                   columns={cols}
                   hasSearch={(totalConnectedEmails > defaultPagination || search)}
                   onSearchUpdate={(val) => {
                     if (searchTimeout) {
                       clearTimeout(searchTimeout);
                     }
                     setSearchTimeout(setTimeout(() => {
                       setSearch(val);
                       setPage(1);
                     }, 500));
                   }} />

      {
        totalConnectedEmails > paginationLimit || true ? (
          <div className="mt-3"><small>Total: {totalConnectedEmails}</small></div>
        ) : null
      }
      {
        totalConnectedEmails > defaultPagination || true ? (
          <CustomPagination
            total={totalConnectedEmails}
            limit={defaultPagination}
            page={page}
            currentLimit={paginationLimit}
            updatePage={(page) => setPage(page)}
            updateLimit={(limit) => setPaginationLimit(limit)}
            margin="t"
          />
        ) : null
      }
    </Panel>
  )
};

Emails.propTypes = {};

export default Emails;
