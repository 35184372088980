import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row, Button, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const ManagePlansStructureItem = ({
  index,
  handleRemove,
  handleChange,
  id,
  uniqueId,
  name,
  type,
  description,
  value,
  calculate_function,
  calculateFunctions,
  open
}) => {
  return (
    <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
      <div className="mb-3 d-flex align-items-center justify-content-between border-bottom border-gray-200">
        <small>{value ? value : '-'}{ id ? ` (id: ${id})` : ''}</small>
        <Button
          size="sm"
          variant="link"
          className="p-0"
          onClick={() => handleRemove(uniqueId, id)}
        >
          <FontAwesomeIcon className="text-danger" icon="times" />
        </Button>
      </div>
      <div className="d-flex align-items-center">
        <Button
          size="sm"
          variant="link"
          className="p-0"
          onClick={() => handleChange(uniqueId, 'open', !open)}
        >
          <FontAwesomeIcon icon={ open ? 'minus' : 'plus' } />
        </Button>
        <strong className="d-inline-block ms-3">{name}</strong>
        <Badge className="ms-3" pill bg={classNames({
          primary: type === 'allowance',
          danger: type === 'permission',
          info: type === 'info'
        })}>
          {type}
        </Badge>
      </div>
      {open && (
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group controlId={`name${index}`}>
              <Form.Label>
                <OverlayTrigger placement="top" overlay={<Tooltip id={`nameTip${index}`}>The name of the pricing table row</Tooltip>}>
                  <span>Name<FontAwesomeIcon icon="info-circle" className="ms-2" /></span>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                defaultValue={name}
                onChange={(e) => handleChange(uniqueId, 'name', e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId={`value${index}`}>
              <Form.Label>
                <OverlayTrigger placement="top" overlay={<Tooltip id={`valueTip${index}`}>The admin name of the permission, allowance, or info item</Tooltip>}>
                  <span>Value<FontAwesomeIcon icon="info-circle" className="ms-2" /></span>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Value"
                defaultValue={value}
                onChange={(e) => handleChange(uniqueId, 'value', e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId={`type${index}`}>
              <Form.Label>Type</Form.Label>
              <Form.Select
                defaultValue={type}
                onChange={(e) => handleChange(uniqueId, 'type', e.target.value)}
              >
                <option value="allowance">Allowance</option>
                <option value="permission">Permission</option>
                <option value="info">Info</option>
                <option value="heading">Heading</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId={`calc-${index}`}>
              <Form.Label>Calculate Function</Form.Label>
              <Form.Select
                defaultValue={calculate_function}
                onChange={(e) => handleChange(uniqueId, 'calculate_function', e.target.value)}
              >
                <option value="">Select One...</option>
                {
                  calculateFunctions.map(each => <option value={each}>{each}</option>)
                }
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group controlId={`description${index}`}>
              <Form.Label>
                <OverlayTrigger placement="top" overlay={<Tooltip id={`descriptionTip${index}`}>A description of the pricing table row</Tooltip>}>
                  <span>Description<FontAwesomeIcon icon="info-circle" className="ms-2" /></span>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                as="textarea" rows={2}
                placeholder="Description"
                defaultValue={description}
                onChange={(e) => handleChange(uniqueId, 'description', e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ManagePlansStructureItem;

ManagePlansStructureItem.propTypes = {
  index: PropTypes.number,
  handleRemove: PropTypes.func,
  handleChange: PropTypes.func,
  id: PropTypes.string,
  uniqueId: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.string,
  open: PropTypes.bool
};
