import React, { useState, useEffect, useContext } from 'react';
//import { Card } from 'react-bootstrap';
import LoaderButton from '../../../common/LoaderButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlanLine from './PlanLine';
//import SoftBadge from '../../../common/SoftBadge';
import { getPaymentPeriod, getURLParameter, getAccountState, isEmail, getPeriodText } from '../../../../helpers/utils'
import { loadPlans, loadPlansAccounts } from '../../../../load/plans';
import { AccountContext } from '../../../../context/Context';

const PlanMain = ({
  accountPlans,
  buttonText = 'Get It!',
  price,
  full_price,
  max = 1,
  enabled,
  id,
  name,
  period,
  product_id,
  trigger,
  loading,
  structure,
  overages,
  overage_tiers,
  meta,
  ...rest
}) => {
  const { accountState } = useContext(AccountContext);
  const [quantity, setQuantity] = useState(1);
  max = parseInt(max, 0);
  enabled = parseInt(enabled, 0);

  useEffect(() => {
    window.PKCHECKOUTISREADY = true;
  }, []);

  const getPlan = (quantity = 1, iteration = 1) => {
    let iframe = document.getElementById(`pk-widget-${trigger}`);
    
    if( iframe !== undefined && iframe !== null ){
      let src = iframe.getAttribute('data-original-src');
      let firstName = getURLParameter('first_name', src) ? getURLParameter('first_name', src) : getAccountState(accountState, 'user', 'first_name');
      let lastName = getURLParameter('last_name', src) ? getURLParameter('last_name', src) : getAccountState(accountState, 'user', 'last_name');
      let email = getURLParameter('email', src) ? getURLParameter('email', src) : getAccountState(accountState, 'user', 'user_email');
      let cc = getURLParameter('cc', src) ? getURLParameter('cc', src) : getURLParameter('pCc');
      let account = getURLParameter('account', src) && getURLParameter('account', src) ? getURLParameter('account', src) : getAccountState(accountState, 'account', 'parent');
      let plan = getURLParameter('plan', src) && getURLParameter('plan', src) ? getURLParameter('plan', src) : id;

      let srcParts = src.split('?');

      src = srcParts[0];
      src += '?first_name=' + firstName;
      src += '&last_name=' + lastName;
      src += '&email=' + ( isEmail(email) ? email : '' );
      src += '&plan=' + plan;
      src += '&account=' + account;
      src += '&cc=' + cc;

      if( quantity > 1 ){
        src += '&qt=' + quantity;
      }

      iframe.setAttribute('data-original-src', src);

      window.PKWIDGET.openModal(document.getElementById(`trigger-${product_id}`));
    } else {
      if( iteration <= 3 ){
        setTimeout(() => getPlan(quantity, iteration + 1), 500);
      } else {
        window.PKWIDGET.openModal(document.getElementById(`trigger-${product_id}`));
      }
    }

  }

  price = quantity * price / 100;
  let additional = 0;

  structure = structure.map(each => {
    let overageUnitCost = parseInt(each.overage_cost, 10) / 100;
    if(overages && overages[id] !== undefined && overages[id][each.item] !== undefined){
      const num = parseInt(overages[id][each.item], 10);
      additional = num * overageUnitCost;
      if( each.overage_tiers !== undefined && each.overage_tiers.length ){
        each.overage_tiers.forEach(ot => {
          const min = parseInt(ot.min, 10);
          const max = parseInt(ot.max, 10);
          if(num >= min && num <= max){
            overageUnitCost = parseInt(ot.cost, 10) / 100
            additional = num * overageUnitCost;
          }
        });
      }
      price = price + additional;
    }
    each.overage_unit_cost = overageUnitCost;
    return each;
  });

  //Number of plans of this type that the user currently has
  const subscribed = accountPlans.filter(each => ! each.cancel_id).reduce((acc, cur) => {
    if(cur.plan === id){
      acc++;
    }
    return acc;
  }, 0);

  const thisPlan = accountPlans.filter(each => {
    return ! each.cancel_id && each.plan === id;
  });

  const thisPlanFirst = thisPlan !== undefined && thisPlan && thisPlan.length ? thisPlan[0] : {};

  return (
    <div className="h100 mb-5">
      <div className="text-center p-4">
        <h3 className="fw-normal mt-0 mb-3">{name}</h3>
        {/*<p className="mt-3">{subTitle}</p>*/}
        {
          parseInt(price, 10) ? (
            <h2 className="fw-medium mt-3 mb-4">
              <sup className="fw-normal fs-2 me-1">$</sup>
              {price}
              { getPaymentPeriod(period) }
              { meta?.afterPricingPeriod !== undefined ? getPeriodText(meta?.afterPricingPeriod) : null }
            </h2>
          ) : null
        }
        {
          max > 1 && subscribed < max && price > 0 && enabled ? (
            <div className="d-flex align-items-center ms-auto me-auto mb-4 bg-100 border border-200 rounded">
              <button className="btn-sstart-square btn-square-danger rounded" onClick={() => quantity - 1 >= 1 ? setQuantity(quantity - 1) : setQuantity(1)}><FontAwesomeIcon icon="minus" /></button>
              <div className="flex-fill fs-2 fw-bold text-center">{quantity}</div>
              <button className="btn-sstart-square btn-square-success rounded" onClick={() => quantity + 1 <= (max - subscribed) ? setQuantity(quantity + 1) : setQuantity(max - subscribed)}><FontAwesomeIcon icon="plus" /></button>
            </div>
          ) : null
        }
        {
          subscribed < max && price > 0 && enabled ? (
            max > 1 ? (
              subscribed ? (
                <>
                  <div className="fs-2 badge badge-soft-success d-block mb-3">Current: {subscribed}</div>
                  <LoaderButton loading={loading} onClick={() => getPlan(quantity)}>
                    Add {quantity}
                  </LoaderButton>
                </>
              ) : (
                <LoaderButton loading={loading} onClick={() => getPlan(quantity)}>
                  Get It!
                </LoaderButton>
              )
            ) : (
              <LoaderButton loading={loading} onClick={() => getPlan()}>
                Get It!
              </LoaderButton>
            )
          ) : ( subscribed ? ( max > 1 ? <div className="fs-2 badge badge-soft-success">Current: {subscribed}</div> : <div className="fs-2 badge badge-soft-success">Subscribed</div> ) : null )
        }
        {
          thisPlanFirst.product_id !== undefined && thisPlanFirst.subscription_id !== undefined && thisPlanFirst.subscription_id && thisPlanFirst.product_id.includes('appsumo_tier') ? (
            <a className="btn btn-primary d-inline-block mt-3" target="_blank" rel="noopener noreferrer" href={ `https://appsumo.com/account/redemption/${thisPlanFirst.subscription_id}/change-plan/`}>Manage Plan on AppSumo</a>
          ) : null
        }
      </div>
      <hr className="border-bottom-0 m-0" />
      <div className="text-start px-sm-4 py-4">
        {/*<h5 className="fw-medium fs-0">{featureTitle}</h5>*/}
        <ul className="list-unstyled mt-3">
          {
            structure.map(each => <PlanLine {...rest} overages={overages} quantity={quantity} planId={id} {...each} />)
          }
        </ul>
      </div>
    </div>
  );
};

export default PlanMain;
