import React, {useContext} from 'react';
import { useLocation, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Card, Col } from 'react-bootstrap';
import Background from './Background';
import createMarkup from 'helpers/createMarkup';
import LoaderButton from './LoaderButton';
import { errorMessage, getURLParameter, isVerified, successMessage } from '../../helpers/utils';
import { AppContext } from 'context/Context';
import Api from '../../api/api';

const Panel = ({
  title,
  preTitle,
  rightTitle,
  titleTag: TitleTag,
  description,
  image,
  col,
  children,
  onSave = null,
  saveText = 'Save',
  saveDisabled = false,
  loading = false,
  bypassVerification = false,
  ...rest
}) => {

  const {
    config: { embed }
  } = useContext(AppContext);

  const plan = getURLParameter('pSubscribe');
  const couponCode = getURLParameter('pCc');
  const planType = getURLParameter( 'pType' );

  const location = useLocation();
  const currentPage = location.pathname;

  if( plan && planType ){
    let redirectUrl = `/settings/${planType}?pSubscribe=${plan}`;

    if( couponCode ) redirectUrl += `&pCc=${couponCode}`;
    return <Redirect to={ redirectUrl } />;
  }

  let saveProps = {};

  if(saveDisabled) saveProps.disabled = true;

  if( embed ){
    return (
      <>
        {children}
        {
          onSave && (
            <div className="mt-3">
              <LoaderButton loading={loading} onClick={onSave} {...saveProps}>
                {saveText}
              </LoaderButton>
            </div>
          )
        }
      </>
    )
  }

  const resendVerification = () => {
    Api.doRequest('accounts', 'resend_verification').then(result => {
      successMessage(false, result);
    }).catch(error => {
      errorMessage(false, error)
    })
  }

  if( ! isVerified() && ! bypassVerification && ! plan ){
    return (
      <>
        <div className="mb-3">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <TitleTag className="mb-0">Verify Account</TitleTag>
            </div>
          </div>
        </div>
        <Card {...rest}>
          <Card.Body className="position-relative">
            <div className="d-flex align-items-center">
              <span>Please check your email to verify your account.</span>
              <Button className="ms-auto" onClick={resendVerification}>Resend Verification</Button>
            </div>
          </Card.Body>
        </Card>
      </>
    )
  }

  return (
    <>
      <div className="mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            {preTitle && <h6 className="text-600">{preTitle}</h6>}
            <TitleTag className="mb-0">{title}</TitleTag>
            {description && (
              <p
                className={classNames('mt-2', { 'mb-0': !children })}
                dangerouslySetInnerHTML={createMarkup(description)}
              />
            )}
          </div>
          <div>
            {rightTitle && <h5 className="text-600">{rightTitle}</h5>}
          </div>
        </div>
      </div>
      <Card {...rest}>
        {
          image ? (
            <Background
              image={image}
              className="bg-card"
              style={{
                borderTopRightRadius: '0.375rem',
                borderBottomRightRadius: '0.375rem'
              }}
            />
          ) : null
        }
        <Card.Body className="position-relative">
          {children}
        </Card.Body>
        {
          onSave && (
            <Card.Footer>
              <LoaderButton loading={loading} onClick={onSave} {...saveProps}>
                {saveText}
              </LoaderButton>
            </Card.Footer>
          )
        }
      </Card>
    </>
  )
};

Panel.propTypes = {
  title: PropTypes.string.isRequired,
  preTitle: PropTypes.string,
  rightTitle: PropTypes.string,
  titleTag: PropTypes.string,
  description: PropTypes.string,
  col: PropTypes.shape(Col.propTypes),
  image: PropTypes.string,
  className: PropTypes.string,
  saveText: PropTypes.string,
  saveDisabled: PropTypes.bool,
  children: PropTypes.node,
  onSave: PropTypes.func
};

Panel.defaultProps = { col: { lg: 8 }, titleTag: 'h3' };

export default Panel;
