import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card, Row, Col } from 'react-bootstrap';
import Section from 'components/common/Sec';
import { AppContext } from "../context/Context";
import cookie from 'react-cookies';
import FilterTable from '../components/common/FilterTable';
import API from '../api/api';
import CustomPagination from '../components/common/Pagination';
import { timestampToDate } from '../helpers/utils';

const Accounts = () => {
  const { appConfirm } = useContext(AppContext);
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [searchTimeout, setSearchTimeout] = useState(false);

  let params = [
    { param: 'super', value: 'accounts' },
    { param: 'limit', value: limit },
    { param: 'offset', value: (page - 1) * limit },
    { param: 'search', value: search }
  ];

  useEffect(() => {
    API.doRequest('super', 'get', 'get', null, {params}).then(result => {
      setItems(result.data);
    });
  }, [limit, page, search]);

  useEffect(() => {
    API.doRequest('super', 'get_total', 'get', null, {params}).then(result => {
      setTotal(result.data);
    });
  }, [search]);

  const chooseAccount = (val) => {
    cookie.save(SSSettings.namelower + '_admin_user', val, { path: '/', maxAge: ( 1000 * 60 * 60 * 24 ) });

    window.location.href = '/';
  }

  console.log(items);

  const cols = [
    {
      id: 'id',
      name: 'ID',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return <div className="fs--1">{ val }</div>;
      }
    },
    {
      id: 'created',
      name: 'Created',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return timestampToDate(val);
      }
    },
    {
      id: 'plans',
      name: 'Plans',
      type: 'div',
      size: 2,
      valueFunction: (val, row) => {
        return val.map(each => {
          return (
            <div className="mb-3">
              <div><strong>{ each.name }</strong></div>
              <div>Created: { timestampToDate(each.created) }</div>
              <div>Cancelled: { parseInt(each.cancelled, 10) ? timestampToDate(each.cancelled) : '-' }</div>
            </div>
          )
        })
      }
    },
    {
      id: 'users',
      name: 'Users',
      type: 'div',
      size: 3,
      valueFunction: (val, row) => {
        return val.map(each => {
          return (
            <div className="d-flex align-items-center mt-2">
              <span>{ each.user_email }</span>
              <button className="btn btn-primary ms-auto" onClick={() => chooseAccount(each.wpid)}>Login</button>
            </div>
          )
        })
      }
    }
  ];

  return (
    <Section size="xxlg">
      <Card className="mb-3">
        <Card.Header as="h5">Accounts</Card.Header>
        <Card.Body className="bg-light">
          <Row className="mb-3">
            <Col>
              <FilterTable
                data={items}
                columns={cols}
                hasSearch={true}
                onSearchUpdate={(val) => {
                 if( searchTimeout ){
                   clearTimeout(searchTimeout);
                 }
                 setSearchTimeout(setTimeout(() => {
                   setSearch(val);
                   setPage(1);
                 }, 500));
                }}
              />
              <CustomPagination
                total={ total }
                limit={ 10 }
                page={ page }
                currentLimit={ limit }
                updatePage={ (page) => setPage(page) }
                updateLimit={ (limit) => setLimit(limit) }
                margin="t"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Section>
  );
};

Accounts.propTypes = {};

export default Accounts;
