import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipItem from './TooltipItem';

const FormInfo = ({
  children,
  color = false,
  ...rest
}) => (
  <TooltipItem tooltip={children} className="ms-2 d-inline-block position-relative" {...rest}>
    <FontAwesomeIcon {...rest} icon="info-circle"
     className={classNames({
       ['text-gray-300']: !color,
       [`text-${color}`]: color
     })} />
  </TooltipItem>
);

FormInfo.propTypes = {
  children: PropTypes.oneOf(['string', 'node']).isRequired,
  id: PropTypes.string,
  color: PropTypes.string
};

export default FormInfo;
