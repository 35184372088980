import React from 'react';
import classNames from 'classnames';
import PlanLinePermission from './PlanLinePermission';
import PlanLineAllowance from './PlanLineAllowance';
import PlanLineHeading from './PlanLineHeading';
import PlanLineInfo from './PlanLineInfo';
import FormInfo from '../../../common/FormInfo';

const PlanLine = ({
  type,
  tooltip,
  style,
  overage,
  overage_controls,
  ...rest
}) => {

  return (
    <li className={classNames(`text-center mb-2 plan-line-${type}`, {
      [`plan-line-style-${style}`]: style
    })}>
      { type === 'permission' ? <PlanLinePermission style={style} {...rest} /> : null }
      { type === 'allowance' ? <PlanLineAllowance overage={overage} overage_controls={overage_controls} tooltip={tooltip} style={style} {...rest} /> : null }
      { type === 'heading' ? <PlanLineHeading style={style} {...rest} /> : null }
      { type === 'info' ? <PlanLineInfo style={style} {...rest} /> : null }
      { tooltip && ( ! overage || overage_controls !== 'usage' ) ? <FormInfo>{tooltip}</FormInfo> : null }
    </li>
  );
};

export default PlanLine;
