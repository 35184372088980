import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
//import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import { timestampToDate } from 'helpers/utils';
import API from 'api/api';

const ConnectedEmail = ({
  connectedEmailId
}) => {
  const [cycle, setCycle] = useState({});
  const [schedule, setSchedule] = useState({});
  const [times, setTimes] = useState([]);

  //The empty array as the second argument makes this run like componentDidMount
  //https://reacttraining.com/blog/useEffect-is-not-the-new-componentDidMount/
  useEffect(() => {
    API.doRequest('super', 'project_cycle', 'get', null, {
      params: [
        { param: 'super', value: 'connected_email' },
        { param: 'connected_email', value: connectedEmailId }
      ]
    }).then(result => {
      setCycle(result.data.cycle);
      setSchedule(result.data.schedule);
      setTimes(result.data.times);
      console.log('ConnectedEmailCycle', result.data);
    });
  }, []);

  return (
    <div className="p-3 bg-100">
      <Row className="mb-3">
        <Col>
          <h5>Cycle</h5>
          <ul className="list-group">
            {
              Object.keys(cycle).map(each => {
                let val = cycle[each];

                if( each === 'next' || each === 'start' ) val = timestampToDate(cycle[each], false, 'YYYY-MM-DD HH:mm');

                return <li className="list-group-item"><strong>{each}</strong>: {val}</li>
              })
            }
          </ul>
        </Col>
        <Col>
          <h5>Schedule</h5>
          <ul className="list-group">
            {
              Object.keys(schedule).map(each => {
                let val = schedule[each];

                return <li className="list-group-item"><strong>{each}</strong>: {val}</li>
              })
            }
          </ul>
        </Col>
        <Col>
          <h5>Times</h5>
          <ol>
            {
              times.map(each => <li>{ timestampToDate(each, false, 'YYYY-MM-DD HH:mm') }</li>)
            }
          </ol>
        </Col>
      </Row>
    </div>
  );
};

ConnectedEmail.propTypes = {
  connectedEmailId: PropTypes.string
};

export default ConnectedEmail;
