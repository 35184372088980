import React from 'react';
import PropTypes from 'prop-types';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { GaugeChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  GaugeChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = (opt) => {
  const defaults = {
    score: 100,
    text: false,
    color: 'primary',
    lineWidth: 8,
    titleFontSize: 20,
    titleColor: 'primary',
    showScore: true
  }

  const options = Object.assign(defaults, opt);

  let {score, text, color, lineWidth, titleFontSize, titleColor, showScore} = options;

  score = parseInt(score, 10);

  const data = {
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        radius: '90%',
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: getColor(color)
          }
        },
        axisLine: {
          lineStyle: {
            width: lineWidth,
            color: [[1, getColor('200')]]
          }
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        data: [
          {
            value: score,
            name: text.toUpperCase()
          }
        ],
        title: {
          fontSize: titleFontSize,
          offsetCenter: ['0%', '0%'],
          color: getColor(titleColor)
        },
        detail: {
          show: showScore,
          width: 50,
          height: 14,
          fontSize: 28,
          fontWeight: 500,
          color: getColor('500'),
          formatter: '{value}%',
          offsetCenter: ['0%', '0%']
        }
      }
    ]
  }

  return data;
};

const CircleChart = ({
  opt,
  size = '10.3rem'
}) => {
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOptions(opt)}
      style={{ height: size, width: size }}
    />
  );
};

CircleChart.propTypes = {
  size: PropTypes.string
};

export default CircleChart;
