import React, { useContext } from 'react';
import { Row, Col, ButtonGroup, Button, Form, ProgressBar, Card } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { GaugeChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";
import { CanvasRenderer } from 'echarts/renderers';
import { spamWords } from './spamWords';
import { getColor, getUserIP, htmlToPlaintext } from 'helpers/utils';
import FormInfo from 'components/common/FormInfo';
import { SpamCheckerContext } from 'context/Context';


const SpamCheckerContent = ({ variant, validation, progressBar }) => {
  const { textHTML, setTextHTML, emailContent, setEmailContent, emailHTML, setEmailHTML, userIP, setUserIP } = useContext(SpamCheckerContext);

  if( ! userIP ){
    getUserIP().then((ip) => {
      setUserIP(ip); // logs the user's IP address
    }).catch((error) => {
      console.error(error); // logs the error message if there was a problem getting the IP address
    });
  }

  const replacePhrasesWithWord = (phraseArray, string) => {
    //Organize phrases with phrases with the most words first
    phraseArray = phraseArray.sort((a, b) => {
      const aNumWords = a.split(" ").length;
      const bNumWords = b.split(" ").length;

      return bNumWords - aNumWords;
    });

    for (let i = 0; i < phraseArray.length; i++) {
      const regex = new RegExp("(^|\\s)\\b" + phraseArray[i] + "\\b($|\\s|\\W)", "gi");
      let score = spamWords[phraseArray[i]] !== undefined ? spamWords[phraseArray[i]].score : 0;
      if( score === 1 ) score = 0;
      if( score === 2) score = 1;


      string = string.replace(regex, (match) => {
        const startSpace = match.startsWith(' ') ? ' ' : '';
        const endSpace = match.endsWith(' ') ? ' ' : '';
        return startSpace + '<spam class="spam-token-' + score + '">' + match.trim() + '</spam>' + endSpace;
      });
    }
    return string;
  }

  const evaluateEmail = () => {
    // First, validate the HTML of the email
    let validationResult = textHTML === 'html' ? validateHTML() : {valid: true, error: ''};

    // Check for spam words in the email
    let possibleSpam = Object.keys(spamWords);
    let words = findWordsInArray(emailContent.toLowerCase(), possibleSpam);

    // Return the evaluation results
    return {
      validHTML: validationResult.valid,
      validationErrors: validationResult.error,
      containsSpam: words.length,
      replacedText: replacePhrasesWithWord(words, emailContent),
      spamWords: words
    };
  };

  function findWordsInArray(string, array) {
    const matches = []; // Initialize an empty array to store the matches

    // Loop through each phrase in the array and check if it is in the string
    array.forEach(phrase => {
      let myReg = new RegExp("(^|\\s)\\b" + phrase + "\\b($|\\s|\\W)")

      if (string.match(myReg)) {
        matches.push(phrase); // If the phrase is in the string, add it to the matches array
      }
    });

    return removeSmallPhrases(matches); // Return the array of matches
  }

  //Remove matches that are part of larger matches
  //Ex: we don't want "buy" when we already have "buy now"
  const removeSmallPhrases = (phrases) => {
    const result = [];
    for (let i = 0; i < phrases.length; i++) {
      let isSubstring = false;
      for (let j = 0; j < phrases.length; j++) {
        if (i !== j && phrases[j].includes(phrases[i])) {
          isSubstring = true;
          break;
        }
      }
      if (!isSubstring) {
        result.push(phrases[i]);
      }
    }
    return result;
  }

  const validateHTML = () => {
    let newEmailHTML = emailHTML

    newEmailHTML = "<div>" + newEmailHTML
    newEmailHTML = newEmailHTML + "</div>";

    console.log(newEmailHTML);

    let parser = new DOMParser();
    let doc = parser.parseFromString(newEmailHTML, "application/xml");
    let errorNode = doc.querySelector('parsererror');
    let valid = true;

    if (errorNode) {
      valid = false;
    }

    console.log('error', {errorNode, valid});

    return {
      valid,
      error: errorNode && errorNode !== null ? errorNode.children[1].innerHTML : ''
    };
  }

  const getErrorLocation = (errorText) => {
    let newEmailHTML = emailHTML

    newEmailHTML = "<div>" + newEmailHTML
    newEmailHTML = newEmailHTML + "</div>";

    let parser = new DOMParser();
    let doc = parser.parseFromString(newEmailHTML, "application/xml");

    console.log('doc', errorText, doc);

    return null;
  }

  function getSpamColor(num) {
    let colorStops = [
      [106, 168, 79], // green
      [182, 215, 168], // light green
      [255, 229, 153], // yellow
      [241, 194, 50], // orange-yellow
      [230, 145, 56], // orange
      [204, 0, 0], // red-orange
      [153, 0, 0], // red
    ];

    let startIndex, endIndex, fraction;

    if (num <= 8) {
      startIndex = 0;
      endIndex = 1;
      fraction = (num - 1) / 7;
    } else if (num <= 15) {
      startIndex = 1;
      endIndex = 2;
      fraction = (num - 8) / 7;
    } else if (num <= 18) {
      startIndex = 2;
      endIndex = 3;
      fraction = (num - 15) / 3;
    } else if (num <= 23) {
      startIndex = 3;
      endIndex = 4;
      fraction = (num - 18) / 5;
    } else if (num <= 35) {
      startIndex = 4;
      endIndex = 5;
      fraction = (num - 23) / 12;
    } else {
      startIndex = 5;
      endIndex = 6;
      fraction = (num - 35) / 14;
    }

    let startColor = colorStops[startIndex];
    let endColor = colorStops[endIndex];

    let r = startColor[0] + Math.round((endColor[0] - startColor[0]) * fraction);
    let g = startColor[1] + Math.round((endColor[1] - startColor[1]) * fraction);
    let b = startColor[2] + Math.round((endColor[2] - startColor[2]) * fraction);

    return darkenColor(`rgb(${r}, ${g}, ${b})`);
  }

  const darkenColor = (color) => {
    // Extract the red, green, and blue values from the input string
    const values = color.match(/\d+/g);
    let red = parseInt(values[0]);
    let green = parseInt(values[1]);
    let blue = parseInt(values[2]);

    // Darken each color channel by 30%
    red = Math.round(red * 0.7);
    green = Math.round(green * 0.7);
    blue = Math.round(blue * 0.7);

    // Construct and return the new color string
    return `rgb(${red}, ${green}, ${blue})`;
  }

  const emailContentEval = evaluateEmail();

  const spamScore = emailContentEval.spamWords.reduce((acc,cur) => {
    let score = spamWords[cur].score !== undefined ? spamWords[cur].score : 0;
    if( score === 1 ) score = 0;
    if( score === 2) score = 1;

    return acc + score
  }, 0)

  // Helper function to convert a decimal component to a two-digit hex string
  const componentToHex = (c) => {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  const updateEmailContent = (value) => {
    setEmailHTML(value);
    setEmailContent(htmlToPlaintext(value));
  }

  const toggleTextHTML = () => {
    setTextHTML( textHTML === 'text' ? 'html' : 'text' );
  }

  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    GaugeChart,
    CanvasRenderer,
    LegendComponent
  ]);

  const getOptions = (score, color) => ({
    series: [
      {
        type: 'gauge',
        startAngle: -188,
        endAngle: 0,
        //radius: '90%',
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: color
          }
        },
        axisLine: {
          lineStyle: {
            width: 20,
            color: [[1, getColor('200')]]
          }
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        data: [
          {
            value: score,
            detail: {
              offsetCenter: [0, '-8%']
            }
          }
        ],
        max: 50,
        detail: {
          width: 50,
          height: 14,
          fontSize: 28,
          fontWeight: 'bold',
          color: getColor('500'),
          formatter: (value) => {
            return value;
          }
        }
      }
    ]
  });

  const highlightWithLineNumbers = (input, language) =>
    highlight(input, language)
    .split("\n")
    .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
    .join("\n");

  const maxWidth = '800px';
  const maxWidthStyle = {maxWidth};

  return (
    <div>
      <div className="d-flex justify-content-center">
        <Card className="mb-3 width-100" style={ maxWidthStyle }>
          <Card.Header className="bg-light">
            <div className="d-flex justify-content-center">
              <ButtonGroup className="mb-3" aria-label="Text or HTML">
                <Button onClick={toggleTextHTML} variant={classNames({
                  white: textHTML === 'text',
                  "gray-200": textHTML === 'html'
                })}>Text</Button>
                <Button onClick={toggleTextHTML} variant={classNames({
                  white: textHTML === 'html',
                  "gray-200": textHTML === 'text'
                })}>HTML</Button>
              </ButtonGroup>
            </div>
          </Card.Header>
          <Card.Body>
            <Form.Group className="mb-3 width-100" style={ maxWidthStyle }>
              <Form.Label>{ textHTML === 'text' ? 'Email Content' : 'Email HTML' }</Form.Label>
              {
                textHTML === 'text' ? (
                  <Form.Control value={ textHTML === 'text' ? emailContent : emailHTML } onChange={(e) => updateEmailContent(e.target.value)} as="textarea" rows={10} />
                ) : (
                  <Editor
                    value={emailHTML}
                    onValueChange={code => updateEmailContent(code)}
                    highlight={code => highlightWithLineNumbers(code, languages.js)}
                    padding={10}
                    className="prism-code-editor"
                    style={{
                      fontFamily: '"Fira code", "Fira Mono", monospace',
                      fontSize: 18,
                      outline: 0
                    }}
                  />
                )
              }
            </Form.Group>
            { textHTML === 'html' && emailContentEval.validationErrors && (
              <div className="alert alert-danger">
                <div><strong>HTML Error</strong></div>
                <div style={{fontFamily: 'monospace'}} dangerouslySetInnerHTML={{__html: emailContentEval.validationErrors}} />
                <div>{ getErrorLocation(emailContentEval.validationErrors) }</div>
              </div>
            ) }
          </Card.Body>
        </Card>
      </div>
      <div className="d-flex justify-content-center">
        <div className="width-100" style={ maxWidthStyle }>
          <div className="my-3">
            <Row>
              <Col lg={4}>
                <Card className="mb-3 width-100">
                  <Card.Header className="bg-light">
                    <h6>Spam Check</h6>
                  </Card.Header>
                  <Card.Body>
                    <div className="d-flex justify-content-center">
                      <div>
                        <strong>Spam Score<FormInfo>Try to keep this score under 10. Longer messages can get away with higher scores.</FormInfo>:</strong>
                        <ReactEChartsCore
                          echarts={echarts}
                          option={getOptions(spamScore, getSpamColor(spamScore))}
                          style={{ height: '10.3rem', width: '10.3rem' }}
                        />
                        <strong>Spam Words<FormInfo>Try not to use these words in your copy. Using a few words with scores of 3 or less is generally okay.</FormInfo>:</strong>
                        <ul className="mt-3 list-group">
                          {
                            emailContentEval.spamWords.filter(each => {
                              return spamWords[each].score !== undefined && spamWords[each].score > 1
                            }).map(each => {
                              let score = spamWords[each].score !== undefined ? spamWords[each].score : 0;
                              if( score === 1 ) score = 0;
                              if( score === 2) score = 1;

                              return (
                                <li className="list-group-item fs--1 d-flex align-items-center justify-content-between">
                                  <span>{each}</span>
                                  <span className={`ms-2 fs--2 spam-token-${score} circle-number circle-number-sm`}>{score}</span>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={8}>
                <Card className="mb-3 width-100">
                  <Card.Header className="bg-light">
                    <h6>Content Check<FormInfo>This will show words or phrases in your content that might need improving</FormInfo></h6>
                  </Card.Header>
                  <Card.Body>
                    <div style={{whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{__html: emailContentEval.replacedText}} />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpamCheckerContent;
