export default {
  google: {
    gmail: {
      imap: {
        host: 'imap.gmail.com',
        port: 993
      },
      smtp: {
        host: 'smtp.gmail.com',
        port: 587
      }
    },
    workspace: {
      imap: {
        host: 'imap.gmail.com',
        port: 993
      },
      smtp: {
        host: 'smtp.gmail.com',
        port: 587
      }
    }
  },
  microsoft: {
    outlook: {
      imap: {
        host: 'outlook.office365.com',
        port: 993
      },
      smtp: {
        host: 'smtp.office365.com',
        port: 587
      }
    },
    microsoft365: {
      imap: {
        host: 'outlook.office365.com',
        port: 993
      },
      smtp: {
        host: 'smtp.office365.com',
        port: 587
      }
    },
    hotmail: {
      imap: {
        host: 'outlook.office365.com',
        port: 993
      },
      smtp: {
        host: 'smtp.office365.com',
        port: 587
      }
    },
    live: {
      imap: {
        host: 'outlook.office365.com',
        port: 993
      },
      smtp: {
        host: 'smtp.office365.com',
        port: 587
      }
    },
    msn: {
      imap: {
        host: 'imap-mail.outlook.com',
        port: 993
      },
      smtp: {
        host: 'smtp-mail.outlook.com',
        port: 587
      }
    }
  },
  zoho: {
    personal: {
      imap: {
        host: 'imap.zoho.com',
        port: 993
      },
      smtp: {
        host: 'smtp.zoho.com',
        port: 587
      }
    },
    business: {
      imap: {
        host: 'imappro.zoho.com',
        port: 993
      },
      smtp: {
        host: 'smtppro.zoho.com',
        port: 587
      }
    }
  },
  smtp: {
    imap: {
      username: '',
      password: '',
      host: '',
      port: 993
    },
    smtp: {
      username: '',
      password: '',
      host: '',
      port: 587
    }
  }
}