import React, {useState, useContext, useEffect} from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import SettingsPage from './SettingsPage';
import { AccountContext } from 'context/Context';
//import FormInfo from '../../common/FormInfo';
import Api from '../../../api/api';
import {successMessage, errorMessage} from '../../../helpers/utils';
import API from '../../../api/api';
import FormInfo from '../../common/FormInfo';

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [usages, setUsages] = useState([]);
  const { accountState } = useContext(AccountContext);

  useEffect(() => {
    Api.doRequest('user', 'get', 'get', null, {params:[{param: 'item', value: accountState?.user?.id}]}).then(result => {
      const data = result.data;
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setEmail(data.user_email);
    }).catch(error => {
      errorMessage('Could not load user', error);
    });

    API.doRequest('usage', 'get', 'get', null, {params:[{param: 'full', value: 1}]}).then(result => {
      setUsages(result.data);
    }).catch(error => {

    });
  }, []);

  const onSave = () => {
    setLoading(true);
    const update = {
      first_name,
      last_name
    }
    Api.doRequest('user', 'update', 'update', {update}).then(result => {
      setLoading(false);
      successMessage('Saved!', result);
    }).catch(error => {
      setLoading(false);
      errorMessage('Could not save', error);
    })
  }

  const use = accountState.usage;

  return (
    <SettingsPage title="Profile" loading={ loading } onSave={onSave}>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label for="first_name">
              First Name
            </Form.Label>
            <Form.Control
              placeholder="First Name"
              value={first_name}
              name="first_name"
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label for="last_name">
              Last Name
            </Form.Label>
            <Form.Control
              placeholder="Last Name"
              value={last_name}
              name="last_name"
              onChange={(e) => setLastName(e.target.value)}
              type="text"
            />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="mt-3">
        <Form.Label for="last_name">
          Email
        </Form.Label>
        <div>
          { email }
        </div>
      </Form.Group>
      {
        Object.keys(use).length ? (
          <div className="mt-3">
            <h5 className="mt-5 mb-3">Your Plan:</h5>
            <ul className="list-group">
              {
                Object.keys(use).map(each => {
                  let peak = null;

                  if( use[each].amount !== use[each].peak ){
                    peak = (
                      <>
                        <span>({use[each].peak} used this period)</span>
                        <FormInfo>Peak usage is calculated as the maximum usage for a given period</FormInfo>
                      </>
                    )
                  }

                  return (
                    <li className="list-group-item">
                      {use[each].name}: {use[each].amount}/{use[each].max} {peak}
                    </li>
                  )
                })
              }
            </ul>
          </div>
        ) : null
      }
    </SettingsPage>
  );
};

export default Profile;
