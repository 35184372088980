import React from 'react';

const PlanLineHeading = ({
  display,
  item,
  name,
  overage = 0,
  overage_controls = 0,
  overage_cost = 0,
  overage_per = '',
  plan,
  style,
  tooltip,
  type,
  value
}) => {
  return (
    <strong>{ value }</strong>
  );
};

export default PlanLineHeading;
