import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const StatusDropdown = ({
  update,
  type,
  value,
  statuses = [],
  all,
  allValue = '',
  allName = 'All'
}) => {

  const [open, setOpen] = useState(false);

  const doUpdate = val => {
    setOpen(false);
    update(val)
  }

  const getStages = () => {
    return [
      {
        name: 'Awaiting Response',
        color: 'primary',
        icon: 'envelope-open',
        value: 'awaiting response'
      },
      {
        name: 'Needs Action',
        color: 'danger',
        icon: 'envelope',
        value: 'needs action'
      },
      {
        name: 'Queued',
        color: 'info-6',
        icon: 'pause-circle-o',
        value: 'queued'
      },
      {
        name: 'Forwarded',
        color: 'info-7',
        icon: 'arrow-circle-o-right',
        value: 'forwarded'
      },
      {
        name: 'Closed',
        color: 'info-8',
        icon: 'stop-circle-o',
        value: 'closed'
      }
    ];
  }

  const getStatuses = () => {
    return [
      {
        name: 'Won',
        color: 'success',
        icon: 'thumbs-up',
        value: 'won'
      },
      {
        name: 'Open',
        color: 'primary',
        icon: 'circle',
        value: 'open'
      },
      {
        name: 'Lost',
        color: 'danger',
        icon: 'thumbs-down',
        value: 'lost'
      }
    ];
  }

  const getSentiments = () => {
    return [
      {
        name: 'Positive',
        color: 'success',
        icon: 'thumbs-up',
        value: 10
      },
      {
        name: 'Paid',
        color: 'warning',
        icon: 'usd',
        value: 5
      },
      {
        name: 'Negative',
        color: 'danger',
        icon: 'thumbs-down',
        value: 1
      }
    ];
  }

  if( type !== undefined && ! statuses.length ){
    switch( type ){
      case 'stage':
        statuses = getStages();
        break;
      case 'sentiment':
        statuses = getSentiments();
        break;
      default:
        statuses = getStatuses();
        break;
    }
  }

  if( all !== undefined && all ){
    statuses = [{
      name: allName,
      color: 'soft-info',
      icon: '',
      value: allValue
    }].concat(statuses)
  }

  let status = statuses.find(each => each.value === value);

  if( status === undefined ){
    status = statuses[0];
  }

  const typeClass = type !== undefined ? 'type-' + type : 'type-default';
  const statusIcon = status.icon ? <FontAwesomeIcon icon={status.icon} className="mr-2" /> : null;

  if( typeof statuses === 'object' && ! Array.isArray(statuses) ) statuses = [statuses];

  return (
    <Dropdown isOpen={open} toggle={() => setOpen(!open)}>
      <Dropdown.Toggle
        as="span"
        data-toggle="dropdown"
        aria-expanded={open}
        className={ `status-dropdown ${status.color} ${typeClass} d-inline-flex align-items-center` }
      >
        <div className={ `d-inline-flex justify-content-between align-items-center me-auto` }>
          <span>{statusIcon}{status.name}</span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu right>
        {
          statuses.map(each => {

            let eachIcon = status.icon ? <FontAwesomeIcon icon={status.icon} className="mr-2" /> : null;

            return (
              <Dropdown.Item className={ `status-dropdown-status ${each.color} ${typeClass}` } onClick={() => doUpdate(each.value)}>
                {eachIcon}{each.name}
              </Dropdown.Item>
            )
          })
        }
      </Dropdown.Menu>
    </Dropdown>
  );
}

StatusDropdown.propTypes = {
  update: PropTypes.func,
  type: PropTypes.string,
  value: PropTypes.string,
  statuses: PropTypes.array,
  all: PropTypes.bool,
  allValue: PropTypes.string,
  allName: PropTypes.string
};

export default StatusDropdown;