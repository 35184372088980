import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Button, Form } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import ManagePlansStructureItem from "./ManagePlansStructureItem";
import { useForm } from 'react-hook-form';
import Section from 'components/common/Sec';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { generateId } from '../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import API from '../api/api';
import { toast } from 'react-toastify';
import { AppContext, AdminContext } from '../context/Context';
import { loadCalculateFunctions, loadPlansStructure } from './load/plans';

const ManagePlansStructure = () => {
  const { adminState: { plansStructure }, adminDispatch } = useContext(AdminContext);
  const { appConfirm } = useContext(AppContext);
  const [calculateFunctions, setCalculateFunctions] = useState([]);

  const { handleSubmit } = useForm();

  const defaultItem = {
    uniqueId: generateId('struc'),
    name: '',
    type: 'allowance',
    description: '',
    value: '',
    open: true
  };

  const [items, setItems] = useState([defaultItem]);

  //The empty array as the second argument makes this run like componentDidMount
  //https://reacttraining.com/blog/useEffect-is-not-the-new-componentDidMount/
  useEffect(() => {
    loadPlansStructure(adminDispatch, setItems);
    loadCalculateFunctions(setCalculateFunctions)
  }, []);

  const onSubmit = () => {
    const update = items.map((each, index) => {
      return {
        name: each.name,
        type: each.type,
        description: each.description,
        value: each.value,
        calculate_function: each.calculate_function,
        order: index
      }
    });

    API.doRequest('plans_structure', 'create_bulk', 'update', { update }).then(result => {
      toast.success('Saved!');

      //Update the redux store
      loadPlansStructure(adminDispatch, setItems)
    }).catch(error => {
      toast.error('Error');
    });
  };

  const handleAdd = () => {
    setItems([
      ...items,
      {
        ...defaultItem,
        uniqueId: generateId('struc')
      }
    ]);
  };

  const doRemove = (uniqueId, id) => {
    setItems(items.filter(item => item.uniqueId !== uniqueId));

    //Update the redux store
    adminDispatch({
      type: 'SET_PLANS_STRUCTURE',
      payload: items
    });

    if( id ){
      API.doRequest('plans_structure', 'delete', 'delete', { item: id }).then(result => {
        toast.success('Plan Item Deleted');
      }).catch(error => {
        toast.error('Error');
      });
    }
  }

  const handleRemove = (uniqueId, id) => {
    if( ! id ){
      setItems(items.filter(item => item.uniqueId !== uniqueId));
    } else {
      appConfirm({
        header: 'Really delete this item?',
        body: 'This action cannot be undone',
        confirmWithText: 'DELETE',
        confirm: () => doRemove(uniqueId, id)
      });
    }
  };

  const handleChange = (uniqueId, name, value) => {
    setItems(items.map(each => {
      if (each.uniqueId === uniqueId) {
        each[name] = value;
      }
      return each;
    }));
  };

  const reorder = (array, fromIndex, toIndex) => {
    const newArr = [...array];

    const chosenItem = newArr.splice(fromIndex, 1)[0];
    newArr.splice(toIndex, 0, chosenItem);

    return newArr;
  };

  const onDragEnd = result => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    setItems(reorder(items, source.index, destination.index));
  };

  return (
    <Section size="md">
      <Card className="mb-3">
        <Card.Header as="h5">Plan Items</Card.Header>
        <Card.Body className="bg-light">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable1" type="DRAG">
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {items.map((item, index) => (
                      <Draggable
                        key={item.uniqueId}
                        draggableId={`drag${item.uniqueId}`}
                        index={index}
                      >
                        {provided => (
                          <div
                            style={{ position: 'relative' }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div
                              className="text-center position-absolute z-index-1"
                              style={{ width: "calc(100% - 100px)", marginLeft: "50px" }}
                              {...provided.dragHandleProps}
                            >
                              <FontAwesomeIcon icon="ellipsis-h" />
                            </div>
                            <ManagePlansStructureItem
                              {...item}
                              index={index}
                              handleRemove={handleRemove}
                              handleChange={handleChange}
                              calculateFunctions={calculateFunctions}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <IconButton
              onClick={handleAdd}
              variant="gray-200"
              size="sm"
              icon="plus"
              transform="shrink-3"
            >
              Add Item
            </IconButton>
            <div className="d-block mt-5">
              <Button
                size="sm"
                variant="primary"
                className="me-2"
                type="submit"
              >
                Save
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Section>
  );
};

ManagePlansStructure.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func
};

export default ManagePlansStructure;