import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Section from 'components/common/Section';
import Logo from 'components/common/Logo';

const AuthCardLayout = ({
  leftSideContent,
  leftSideContentTop = false,
  children,
  footer = true
}) => {
  return (
    <Section fluid className="py-0">
      <Row className="g-0 min-vh-100 flex-center">
        <Col lg={8} xxl={5} className="py-3 position-relative">
          <Card className="overflow-hidden z-index-1">
            <Card.Body className="p-0">
              <Row className="h-100 g-0">
                <Col
                  md={7}
                  as={Flex}
                  alignItems="center"
                  justifyContent="center"
                >
                  <div className="p-4 p-md-5 flex-grow-1">{children}</div>
                </Col>
                <Col md={5} className="text-center bg-card-gradient">
                  <div className="position-relative p-4 pt-md-5 pb-md-7">
                    {
                      leftSideContentTop ? leftSideContentTop : (
                        <>
                          {/*<Background
                            image={halfCircle}
                            className="bg-auth-card-shape"
                          />*/}
                          <div className="z-index-1 position-relative light">
                            <Logo width={45} textClass="fs-4" className="justify-content-center mb-3" />
                            <p>
                              Ensure better deliverability for all of your outbound emails
                            </p>
                          </div>
                        </>
                      )
                    }
                  </div>
                  <div className="mt-3 mb-4 mt-md-4 mb-md-5 light">
                    {leftSideContent}

                    {footer && (
                      <p className="mb-0 mt-4 mt-md-5 fs--1 fw-semi-bold opacity-75">
                        Read our{' '}
                        <Link className="text-decoration-underline" to="#!">
                          terms
                        </Link>{' '}
                        and{' '}
                        <Link className="text-decoration-underline" to="#!">
                          conditions{' '}
                        </Link>
                      </p>
                    )}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};
AuthCardLayout.propTypes = {
  leftSideContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  footer: PropTypes.bool
};

export default AuthCardLayout;
