import React, {useState, useContext, useEffect} from 'react';
import { Link } from 'react-router-dom';
import CSVReader from "react-csv-reader";
import Panel from '../common/Panel';
import API from 'api/api';
import { AppContext, AccountContext } from '../../context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { successMessage, errorMessage, getURLParameter, costPerMonthFromNumberEmails } from '../../helpers/utils';
import LoaderButton from '../common/LoaderButton';
import FormInfo from '../common/FormInfo';
import TooltipItem from '../common/TooltipItem';

const BulkCSV = ({
  title = 'Bulk Connect',
  cancelRestart = null
}) => {
  const { accountState } = useContext(AccountContext);
  const { appConfirm } = useContext(AppContext);

  const {
    config: { embed }
  } = useContext(AppContext);

  //whether the user has acknowledged adding an email
  const [adding, setAdding] = useState(false);

  const [csvData, setCSVData] = useState([]);

  const [current, setCurrent] = useState(false);

  const [successes, setSuccesses] = useState([]);
  const [failures, setFailures] = useState([]);

  //saving status
  const [saving, setSaving] = useState(false);

  let overageAllowed = accountState?.usage?.emails?.overage;
  overageAllowed = overageAllowed !== undefined ? parseInt(overageAllowed, 10) : 0;

  let max = accountState?.usage?.emails?.max;
  max = max !== undefined ? parseInt(max, 10) : 0;

  let amount = accountState?.usage?.emails?.amount;
  amount = amount !== undefined ? parseInt(amount, 10) : 0;

  let peak = accountState?.usage?.emails?.peak;
  peak = peak !== undefined ? parseInt(peak, 10) : 0;

  const frag = max ? 'additional ' : '';

  const csvColumns = ['imap_email','imap_username','imap_host','imap_port','imap_password',
    'smtp_email','smtp_username','smtp_host','smtp_port','smtp_password',
    'first_name', 'last_name', 'company', 'position'];

  const requiredColumns = ['imap_email','imap_username','imap_host','imap_port','imap_password',
    'smtp_email','smtp_username','smtp_host','smtp_port','smtp_password'];

  const maybeSaveConnections = () => {
    let validRows = 0;

    csvData.forEach(row => {
      if( isValid(row) ) validRows++;
    });

    const cost = costPerMonthFromNumberEmails( validRows + amount )

    appConfirm({
      type: 'confirm',
      confirm: () => saveConnections(),
      confirmText: 'I Agree',
      header: 'Connect ' + validRows + ' Emails?',
      body: 'Are you sure you want to connect ' + validRows + ' new emails? Your new monthly charge will be $' + cost + '/mo. You agree to be charged for these emails on your next billing date, or at cancellation, should you cancel before your next billing date.',
    });
  }

  const saveConnections = () => {
    setSaving(true);
    saveConnection();
    setCurrent(0);
    setSuccesses([]);
    setFailures([]);
  }

  useEffect(() => {
    saveConnection();
  }, [current]);

  const saveConnection = () => {
    if( ! saving || ! csvData || ! csvData.length ) return;

    if( csvData[current] === undefined ){
      if( ! failures.length ){
        successMessage(successes.length + ' emails are connecting!');
      } else {
        if( successes.length ){
          errorMessage(`${successes.length} emails are connecting! However, ${failures.length} could not be connected.`, false, {autoClose: false, type: 'info'});
        } else {
          errorMessage(`Emails could not be connected. Please review connection details.`);
        }
      }

      setSaving(false);

      return;
    }
    
    const row = csvData[current];

    let newCSVData = csvData;
    newCSVData[current].connecting = 0;

    if( ! isValid(row) ){
      setFailures(failures.concat(csvData[current]));
      setCSVData(newCSVData);
      setCurrent(current + 1);
      return;
    }
    
    const update = {
      provider: 'smtp',
      offering: '',
      reconnect: 0,
      firstName: row.first_name !== undefined ? row.first_name : '',
      lastName: row.last_name !== undefined ? row.last_name : '',
      company: row.company !== undefined ? row.company : '',
      position: row.position !== undefined ? row.position : '',
      setup: 1,
      email: row.smtp_email !== undefined ? row.smtp_email : '',
      connection: {
        imap: {
          email: row.imap_email !== undefined ? row.imap_email : '',
          username: row.imap_username !== undefined ? row.imap_username : '',
          password: row.imap_password !== undefined ? row.imap_password : '',
          host: row.imap_host !== undefined ? row.imap_host : '',
          port: row.imap_port !== undefined ? row.imap_port : '',
        },
        smtp: {
          username: row.smtp_username !== undefined ? row.smtp_username : '',
          password: row.smtp_password !== undefined ? row.smtp_password : '',
          host: row.smtp_host !== undefined ? row.smtp_host : '',
          port: row.smtp_port !== undefined ? row.smtp_port : '',
        }
      }
    }

    API.doRequest('connected_emails', 'create', 'create', {update}).then(result => {
      newCSVData[current].connecting = 1;
      setSuccesses(successes.concat(csvData[current]))
      setCSVData(newCSVData);
      setCurrent(current + 1);
    }).catch(error => {
      setFailures(failures.concat(csvData[current]))
      setCSVData(newCSVData);
      setCurrent(current + 1);
    })
  }

  const handle = (data, fileInfo) => {

    if( data && data.length ){

      data = cleanEmptiesAndValues(data);

      let missingColumns = [];

      csvColumns.forEach(col => {
        if( data[0][col] === undefined && requiredColumns.includes(col) ) missingColumns.push(col);
      })

      if( missingColumns.length ){
        errorMessage('CSV missing the following columns: ' + missingColumns.join(', '));
        return;
      }

      let validRows = 0;

      data.forEach(row => {
        if( isValid(row) ) validRows++;
      })

      if( ! validRows ){
        errorMessage('There are no valid rows');
        return;
      }

      if( validRows === 1 ){
        errorMessage('There is only one valid row. Please connect using the Connect button.');
        return;
      }

      if( ! overageAllowed ){
        if( amount + validRows > max ){
          const allowanceLeft = max - amount;
          const toDelete = validRows - allowanceLeft;
          errorMessage(`You can only connect ${allowanceLeft} more email(s). Please delete ${toDelete} row(s) in your CSV.`);

          return;
        }
      } else {
        if( amount + validRows > 100 ){
          errorMessage(`Please contact support if you'd like to add over 100 emails to your account`);

          return;
        }
      }

      setCSVData(data);
    }
  }

  const cleanEmptiesAndValues = (data) => {
    let maybeEmpty = [];
    let altVersions = [];

    //Delete empty rows and find empty columns
    data = data.filter((row, index) => {
      let totalVal = '';

      if( index === 0 ){
        Object.keys(row).forEach((key, keyIndex) => {
          if( ! row[key] || ! row[key].trim() ){
            maybeEmpty = maybeEmpty.concat(keyIndex);
          }
          totalVal += row[key].trim();
        })
      } else {
        Object.keys(row).forEach((key, keyIndex) => {
          if( row[key].trim() && maybeEmpty.includes(keyIndex) ){
            maybeEmpty = maybeEmpty.filter( each => each !== keyIndex );
          }
          totalVal += row[key].trim();
        })
      }

      return totalVal;
    });

    //Delete empty columns and trim values
    data = data.map(row => {
      Object.keys(row).forEach((key, keyIndex) => {
        if( maybeEmpty.length && maybeEmpty.includes(keyIndex) ){
          delete row[key];
        } else {
          //Trim all values
          if( row[key] !== undefined && row[key] && typeof row[key] === 'string' ){
            row[key] = row[key].trim();
          }
        }
      });
      return row;
    });

    return data;
  }

  const papaparseOptions = {
    header: true,
    //dynamicTyping: true,
    keepEmptyRows: false,
    skipEmptyLines: 'greedy',
    transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
  };

  const isValid = (row) => {
    let valid = true;

    csvColumns.forEach(col => {
      if( ( row[col] === undefined || ! row[col] ) && requiredColumns.includes(col) ) valid = false;
    })

    return valid;
  }

  return (
    <Panel title={title}>
      <h4>
        <span className="d-inline-block">Upload a CSV with Connection Details</span>
        { cancelRestart }
      </h4>
      <p>To connect multiple emails at the same time, upload a CSV with the following columns:</p>
      <table className="table fs--2">
        <thead className="bg-100">
          {
            csvColumns.map(each => <td className="p-2 text-center">{each}</td> )
          }
        </thead>
      </table>
      {
        amount >= max && ! overageAllowed && ! embed ? (
          <>
            <h4>Insufficient Credits</h4>
            <p>Please visit the <Link to="/settings/plan">plans</Link> page and choose a plan. That will allow you to connect an {frag}email.</p>
          </>
        ) : null
      }
      {
        amount >= max && ! overageAllowed && embed ? (
          <>
            <h4>There was an issue</h4>
            <p>Please contact support.</p>
          </>
        ) : null
      }
      {
        overageAllowed || amount < max ? (
          <CSVReader
            cssClass="csv-input mt-5 mb-3"
            label={ 'Upload CSV' }
            onFileLoaded={handle}
            parserOptions={papaparseOptions}
          />
        ) : null
      }
      {
        csvData.length ? (
          <div className="mb-3" style={{maxWidth: '100%', overflowX: 'scroll'}}>
            <table className="table fs--2">
              <tr className="bg-200">
                <td className="text-center"><TooltipItem tooltip="Each row must contain values for every column.">valid</TooltipItem></td>
                <td className="text-center"><span>connecting</span></td>
                { csvColumns.map(each => <td className="text-center"><span>{each}</span></td>)}
              </tr>
              {
                csvData.map((each, index) => {
                  return (
                    <tr>
                      <td className="text-center">{ isValid(each) ? <FontAwesomeIcon className="text-success" icon="check" /> : <FontAwesomeIcon className="text-danger" icon="times" /> }</td>
                      <td className="text-center">{
                        current === index
                          ? <FontAwesomeIcon className="text-primary fa-spin" icon="sync-alt" />
                          : ( each.connecting !== undefined && each.connecting ? <FontAwesomeIcon className="text-success" icon="check" /> : <FontAwesomeIcon className="text-danger" icon="times" /> )
                      }</td>
                      { csvColumns.map(col => <td><span>{each[col] !== undefined ? each[col] : ''}</span></td>) }
                    </tr>
                  )
                })
              }
            </table>
          </div>
        ) : null
      }
      {
        csvData.length ? (
          <LoaderButton onClick={maybeSaveConnections} loading={saving}>
            Connect All...
          </LoaderButton>
        ) : null
      }
    </Panel>
  );
}

BulkCSV.propTypes = {

};

export default BulkCSV;
