export default [
  'Advertising',
  'Aerospace & Defense',
  'Agricultural',
  'Airline',
  'Apparel Manufacturing',
  'Asset Management',
  'Automotive',
  'Auto Manufacturing',
  'Banking',
  'Biotech',
  'Broadcasting',
  'Building Materials',
  'Clothing',
  'Computer Hardware',
  'Construction',
  'Consumer Electronics',
  'Credit Services',
  'Education','Entertainment',
  'Energy',
  'Food',
  'Health Information Services',
  'Healthcare',
  'Home Improvement',
  'Insurance',
  'Manufacturing',
  'Medical Devices',
  'Mining',
  'Mortgage',
  'PaaS',
  'Professional Services',
  'Publishing',
  'Real Estate',
  'Restaurant',
  'SaaS',
  'Solar',
  'Technology',
  'Telecom',
  'Transportation',
  'Travel'
];
