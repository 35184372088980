export const spamWords = {
  "access": {
    "score": 2,
    "reason": "urgency"
  },
  "access now": {
    "score": 4,
    "reason": "urgency"
  },
  "act": {
    "score": 3,
    "reason": "urgency"
  },
  "act immediately": {
    "score": 7,
    "reason": "urgency"
  },
  "act now": {
    "score": 8,
    "reason": "urgency"
  },
  "action": {
    "score": 3,
    "reason": "urgency"
  },
  "action required": {
    "score": 7,
    "reason": "urgency"
  },
  "apply here": {
    "score": 2,
    "reason": "urgency"
  },
  "apply now": {
    "score": 4,
    "reason": "urgency"
  },
  "apply online": {
    "score": 2,
    "reason": "urgency"
  },
  "become a member": {
    "score": 2,
    "reason": "urgency"
  },
  "before it's too late": {
    "score": 8,
    "reason": "urgency"
  },
  "being a member": {
    "score": 2,
    "reason": "urgency"
  },
  "buy": {
    "score": 2,
    "reason": "urgency"
  },
  "buy direct": {
    "score": 2,
    "reason": "urgency"
  },
  "buy now": {
    "score": 5,
    "reason": "urgency"
  },
  "buy today": {
    "score": 5,
    "reason": "urgency"
  },
  "call": {
    "score": 2,
    "reason": "urgency"
  },
  "call free": {
    "score": 3,
    "reason": "urgency"
  },
  "call me": {
    "score": 2,
    "reason": "urgency"
  },
  "call now": {
    "score": 5,
    "reason": "urgency"
  },
  "can we have a minute of your time?": {
    "score": 5,
    "reason": "urgency"
  },
  "cancel now": {
    "score": 5,
    "reason": "urgency"
  },
  "cancellation required": {
    "score": 7,
    "reason": "urgency"
  },
  "claim now": {
    "score": 5,
    "reason": "urgency"
  },
  "click": {
    "score": 2,
    "reason": "urgency"
  },
  "click below": {
    "score": 4,
    "reason": "urgency"
  },
  "click here": {
    "score": 2,
    "reason": "urgency"
  },
  "click me to download": {
    "score": 4,
    "reason": "urgency"
  },
  "click now": {
    "score": 4,
    "reason": "urgency"
  },
  "click this link": {
    "score": 4,
    "reason": "urgency"
  },
  "click to get": {
    "score": 4,
    "reason": "urgency"
  },
  "click to remove": {
    "score": 4,
    "reason": "urgency"
  },
  "contact us immediately": {
    "score": 6,
    "reason": "urgency"
  },
  "deal ending soon": {
    "score": 7,
    "reason": "urgency"
  },
  "do it now": {
    "score": 7,
    "reason": "urgency"
  },
  "do it today": {
    "score": 6,
    "reason": "urgency"
  },
  "don't delete": {
    "score": 2,
    "reason": "urgency"
  },
  "don't hesitate": {
    "score": 3,
    "reason": "urgency"
  },
  "don't waste time": {
    "score": 4,
    "reason": "urgency"
  },
  "exclusive deal": {
    "score": 6,
    "reason": "urgency"
  },
  "expire": {
    "score": 4,
    "reason": "urgency"
  },
  "expires today": {
    "score": 6,
    "reason": "urgency"
  },
  "final call": {
    "score": 8,
    "reason": "urgency"
  },
  "for instant access": {
    "score": 5,
    "reason": "urgency"
  },
  "for only": {
    "score": 3,
    "reason": "urgency"
  },
  "for you": {
    "score": 2,
    "reason": "urgency"
  },
  "get it away": {
    "score": 4,
    "reason": "urgency"
  },
  "get it now": {
    "score": 6,
    "reason": "urgency"
  },
  "get now": {
    "score": 5,
    "reason": "urgency"
  },
  "get paid": {
    "score": 2,
    "reason": "urgency"
  },
  "get started": {
    "score": 2,
    "reason": "urgency"
  },
  "get started now": {
    "score": 4,
    "reason": "urgency"
  },
  "great offer": {
    "score": 4,
    "reason": "urgency"
  },
  "hurry up": {
    "score": 5,
    "reason": "urgency"
  },
  "immediately": {
    "score": 4,
    "reason": "urgency"
  },
  "info you requested": {
    "score": 3,
    "reason": "urgency"
  },
  "information you requested": {
    "score": 3,
    "reason": "urgency"
  },
  "instant": {
    "score": 4,
    "reason": "urgency"
  },
  "limited time": {
    "score": 5,
    "reason": "urgency"
  },
  "new customers only": {
    "score": 7,
    "reason": "urgency"
  },
  "now": {
    "score": 2,
    "reason": "urgency"
  },
  "now only": {
    "score": 5,
    "reason": "urgency"
  },
  "offer expires": {
    "score": 7,
    "reason": "urgency"
  },
  "once in a lifetime": {
    "score": 8,
    "reason": "urgency"
  },
  "only": {
    "score": 2,
    "reason": "urgency"
  },
  "order now": {
    "score": 5,
    "reason": "urgency"
  },
  "order today": {
    "score": 5,
    "reason": "urgency"
  },
  "please read": {
    "score": 2,
    "reason": "urgency"
  },
  "purchase now": {
    "score": 5,
    "reason": "urgency"
  },
  "sign up free": {
    "score": 2,
    "reason": "urgency"
  },
  "sign up free today": {
    "score": 4,
    "reason": "urgency"
  },
  "supplies are limited": {
    "score": 6,
    "reason": "urgency"
  },
  "take action": {
    "score": 4,
    "reason": "urgency"
  },
  "take action now": {
    "score": 6,
    "reason": "urgency"
  },
  "this won't last": {
    "score": 6,
    "reason": "urgency"
  },
  "time limited": {
    "score": 5,
    "reason": "urgency"
  },
  "today": {
    "score": 3,
    "reason": "urgency"
  },
  "top urgent": {
    "score": 7,
    "reason": "urgency"
  },
  "trial": {
    "score": 4,
    "reason": "urgency"
  },
  "urgent": {
    "score": 7,
    "reason": "urgency"
  },
  "what are you waiting for?": {
    "score": 5,
    "reason": "urgency"
  },
  "while supplies last": {
    "score": 6,
    "reason": "urgency"
  },
  "you are a winner": {
    "score": 6,
    "reason": "urgency"
  },
  "0 down": {
    "score": 2,
    "reason": "shady"
  },
  "4U": {
    "score": 4,
    "reason": "shady"
  },
  "all natural": {
    "score": 3,
    "reason": "shady"
  },
  "all new": {
    "score": 3,
    "reason": "shady"
  },
  "all-natural": {
    "score": 3,
    "reason": "shady"
  },
  "all-new": {
    "score": 3,
    "reason": "shady"
  },
  "allowance": {
    "score": 2,
    "reason": "shady"
  },
  "as seen on": {
    "score": 3,
    "reason": "shady"
  },
  "as seen on oprah": {
    "score": 5,
    "reason": "shady"
  },
  "at no cost": {
    "score": 3,
    "reason": "shady"
  },
  "auto email removal": {
    "score": 6,
    "reason": "shady"
  },
  "avoid": {
    "score": 4,
    "reason": "shady"
  },
  "beneficial offer": {
    "score": 6,
    "reason": "shady"
  },
  "beneficiary": {
    "score": 2,
    "reason": "shady"
  },
  "brand new pager": {
    "score": 4,
    "reason": "shady"
  },
  "bulk email": {
    "score": 8,
    "reason": "shady"
  },
  "buying judgements": {
    "score": 9,
    "reason": "shady"
  },
  "buying judgments": {
    "score": 9,
    "reason": "shady"
  },
  "cable converter": {
    "score": 4,
    "reason": "shady"
  },
  "calling creditors": {
    "score": 6,
    "reason": "shady"
  },
  "can you help us?": {
    "score": 5,
    "reason": "shady"
  },
  "cancel at any time": {
    "score": 2,
    "reason": "shady"
  },
  "cannot be combined": {
    "score": 3,
    "reason": "shady"
  },
  "celebrity": {
    "score": 4,
    "reason": "shady"
  },
  "cell phone cancer scam": {
    "score": 9,
    "reason": "shady"
  },
  "certified": {
    "score": 3,
    "reason": "shady"
  },
  "chance": {
    "score": 4,
    "reason": "shady"
  },
  "cheap": {
    "score": 4,
    "reason": "shady"
  },
  "cheap meds": {
    "score": 9,
    "reason": "shady"
  },
  "cialis": {
    "score": 10,
    "reason": "shady"
  },
  "claims": {
    "score": 5,
    "reason": "shady"
  },
  "claims not to be selling anything": {
    "score": 6,
    "reason": "shady"
  },
  "claims to be in accordance with some spam law": {
    "score": 7,
    "reason": "shady"
  },
  "claims to be legal": {
    "score": 2,
    "reason": "shady"
  },
  "clearance": {
    "score": 3,
    "reason": "shady"
  },
  "collect": {
    "score": 1,
    "reason": "shady"
  },
  "collect child support": {
    "score": 7,
    "reason": "shady"
  },
  "compare": {
    "score": 1,
    "reason": "shady"
  },
  "compare now": {
    "score": 5,
    "reason": "shady"
  },
  "compare online": {
    "score": 4,
    "reason": "shady"
  },
  "compare rates": {
    "score": 4,
    "reason": "shady"
  },
  "compete for your business": {
    "score": 5,
    "reason": "shady"
  },
  "confidentiality": {
    "score": 3,
    "reason": "shady"
  },
  "congratulations": {
    "score": 1,
    "reason": "shady"
  },
  "consolidate debt and credit": {
    "score": 6,
    "reason": "shady"
  },
  "consolidate your debt": {
    "score": 5,
    "reason": "shady"
  },
  "copy accurately": {
    "score": 2,
    "reason": "shady"
  },
  "copy dvds": {
    "score": 6,
    "reason": "shady"
  },
  "covid": {
    "score": 5,
    "reason": "shady"
  },
  "cures": {
    "score": 5,
    "reason": "shady"
  },
  "cures baldness": {
    "score": 9,
    "reason": "shady"
  },
  "diagnostic": {
    "score": 4,
    "reason": "shady"
  },
  "diet": {
    "score": 4,
    "reason": "shady"
  },
  "dig up dirt on friends": {
    "score": 9,
    "reason": "shady"
  },
  "direct email": {
    "score": 4,
    "reason": "shady"
  },
  "direct marketing": {
    "score": 4,
    "reason": "shady"
  },
  "eliminate debt": {
    "score": 7,
    "reason": "shady"
  },
  "explode your business": {
    "score": 8,
    "reason": "shady"
  },
  "fast viagra delivery": {
    "score": 9,
    "reason": "shady"
  },
  "finance": {
    "score": 3,
    "reason": "shady"
  },
  "financial": {
    "score": 3,
    "reason": "shady"
  },
  "financial advice": {
    "score": 4,
    "reason": "shady"
  },
  "financial independence": {
    "score": 6,
    "reason": "shady"
  },
  "financially independent": {
    "score": 6,
    "reason": "shady"
  },
  "for new customers only": {
    "score": 4,
    "reason": "shady"
  },
  "foreclosure": {
    "score": 7,
    "reason": "shady"
  },
  "free": {
    "score": 7,
    "reason": "shady"
  },
  "free access": {
    "score": 6,
    "reason": "shady"
  },
  "free bonus": {
    "score": 6,
    "reason": "shady"
  },
  "free cell phone": {
    "score": 7,
    "reason": "shady"
  },
  "free gift": {
    "score": 7,
    "reason": "shady"
  },
  "free grant money": {
    "score": 8,
    "reason": "shady"
  },
  "free information": {
    "score": 6,
    "reason": "shady"
  },
  "free installation": {
    "score": 6,
    "reason": "shady"
  },
  "free instant": {
    "score": 6,
    "reason": "shady"
  },
  "free iphone": {
    "score": 7,
    "reason": "shady"
  },
  "free laptop": {
    "score": 7,
    "reason": "shady"
  },
  "free leads": {
    "score": 6,
    "reason": "shady"
  },
  "free macbook": {
    "score": 7,
    "reason": "shady"
  },
  "free offer": {
    "score": 6,
    "reason": "shady"
  },
  "free priority mail": {
    "score": 6,
    "reason": "shady"
  },
  "free sample": {
    "score": 6,
    "reason": "shady"
  },
  "free website": {
    "score": 6,
    "reason": "shady"
  },
  "free!": {
    "score": 7,
    "reason": "shady"
  },
  "gift card": {
    "score": 5,
    "reason": "shady"
  },
  "gift certificate": {
    "score": 5,
    "reason": "shady"
  },
  "gift included": {
    "score": 5,
    "reason": "shady"
  },
  "give it away": {
    "score": 6,
    "reason": "shady"
  },
  "giving away": {
    "score": 6,
    "reason": "shady"
  },
  "giving it away": {
    "score": 6,
    "reason": "shady"
  },
  "gold": {
    "score": 4,
    "reason": "shady"
  },
  "great": {
    "score": 1,
    "reason": "shady"
  },
  "great deal": {
    "score": 3,
    "reason": "shady"
  },
  "greetings of the day": {
    "score": 3,
    "reason": "shady"
  },
  "growth hormone": {
    "score": 8,
    "reason": "shady"
  },
  "guarantee": {
    "score": 5,
    "reason": "shady"
  },
  "guaranteed deposit": {
    "score": 6,
    "reason": "shady"
  },
  "guaranteed income": {
    "score": 6,
    "reason": "shady"
  },
  "guaranteed payment": {
    "score": 6,
    "reason": "shady"
  },
  "have you been turned down?": {
    "score": 6,
    "reason": "shady"
  },
  "hello (with no name included)": {
    "score": 3,
    "reason": "shady"
  },
  "hidden charges": {
    "score": 6,
    "reason": "shady"
  },
  "hidden costs": {
    "score": 6,
    "reason": "shady"
  },
  "hidden fees": {
    "score": 6,
    "reason": "shady"
  },
  "high score": {
    "score": 3,
    "reason": "shady"
  },
  "home based business": {
    "score": 6,
    "reason": "shady"
  },
  "home mortgage": {
    "score": 5,
    "reason": "shady"
  },
  "human": {
    "score": 1,
    "reason": "shady"
  },
  "human growth hormone": {
    "score": 8,
    "reason": "shady"
  },
  "if only it were that easy": {
    "score": 5,
    "reason": "shady"
  },
  "important information": {
    "score": 4,
    "reason": "shady"
  },
  "important notification": {
    "score": 4,
    "reason": "shady"
  },
  "instant weight loss": {
    "score": 8,
    "reason": "shady"
  },
  "insurance lose weight": {
    "score": 7,
    "reason": "shady"
  },
  "internet marketing": {
    "score": 5,
    "reason": "shady"
  },
  "investment decision": {
    "score": 4,
    "reason": "shady"
  },
  "invoice": {
    "score": 3,
    "reason": "shady"
  },
  "it's effective": {
    "score": 4,
    "reason": "shady"
  },
  "job alert": {
    "score": 4,
    "reason": "shady"
  },
  "junk": {
    "score": 5,
    "reason": "shady"
  },
  "lambo": {
    "score": 9,
    "reason": "shady"
  },
  "laser printer": {
    "score": 5,
    "reason": "shady"
  },
  "last day": {
    "score": 4,
    "reason": "shady"
  },
  "legal": {
    "score": 2,
    "reason": "shady"
  },
  "legal notice": {
    "score": 4,
    "reason": "shady"
  },
  "life": {
    "score": 4,
    "reason": "shady"
  },
  "life insurance": {
    "score": 5,
    "reason": "shady"
  },
  "lifetime access": {
    "score": 5,
    "reason": "shady"
  },
  "lifetime deal": {
    "score": 5,
    "reason": "shady"
  },
  "limited": {
    "score": 4,
    "reason": "shady"
  },
  "limited amount": {
    "score": 5,
    "reason": "shady"
  },
  "limited number": {
    "score": 5,
    "reason": "shady"
  },
  "limited offer": {
    "score": 5,
    "reason": "shady"
  },
  "limited supply": {
    "score": 4,
    "reason": "shady"
  },
  "limited time offer": {
    "score": 4,
    "reason": "shady"
  },
  "limited time only": {
    "score": 3,
    "reason": "shady"
  },
  "loan": {
    "score": 3,
    "reason": "shady"
  },
  "long distance phone number": {
    "score": 2,
    "reason": "shady"
  },
  "long distance phone offer": {
    "score": 3,
    "reason": "shady"
  },
  "lose weight": {
    "score": 6,
    "reason": "shady"
  },
  "lose weight fast": {
    "score": 8,
    "reason": "shady"
  },
  "lose weight spam": {
    "score": 10,
    "reason": "shady"
  },
  "lottery": {
    "score": 7,
    "reason": "shady"
  },
  "lower interest rate": {
    "score": 4,
    "reason": "shady"
  },
  "lower interest rates": {
    "score": 4,
    "reason": "shady"
  },
  "lower monthly payment": {
    "score": 4,
    "reason": "shady"
  },
  "lower your mortgage rate": {
    "score": 6,
    "reason": "shady"
  },
  "lowest insurance rates": {
    "score": 6,
    "reason": "shady"
  },
  "lowest interest rate": {
    "score": 6,
    "reason": "shady"
  },
  "lowest rate": {
    "score": 3,
    "reason": "shady"
  },
  "lowest rates": {
    "score": 3,
    "reason": "shady"
  },
  "luxury": {
    "score": 2,
    "reason": "shady"
  },
  "luxury car": {
    "score": 2,
    "reason": "shady"
  },
  "mail in order form": {
    "score": 2,
    "reason": "shady"
  },
  "mark this as not junk": {
    "score": 3,
    "reason": "shady"
  },
  "mass email": {
    "score": 7,
    "reason": "shady"
  },
  "medical": {
    "score": 3,
    "reason": "shady"
  },
  "medicine": {
    "score": 3,
    "reason": "shady"
  },
  "meet girls": {
    "score": 8,
    "reason": "shady"
  },
  "meet me": {
    "score": 6,
    "reason": "shady"
  },
  "meet singles": {
    "score": 8,
    "reason": "shady"
  },
  "meet women": {
    "score": 8,
    "reason": "shady"
  },
  "member": {
    "score": 2,
    "reason": "shady"
  },
  "member stuff": {
    "score": 2,
    "reason": "shady"
  },
  "message contains disclaimer": {
    "score": 1,
    "reason": "shady"
  },
  "message from": {
    "score": 1,
    "reason": "shady"
  },
  "millionaire": {
    "score": 5,
    "reason": "shady"
  },
  "millions": {
    "score": 3,
    "reason": "shady"
  },
  "mlm": {
    "score": 7,
    "reason": "shady"
  },
  "multi-level marketing": {
    "score": 8,
    "reason": "shady"
  },
  "name": {
    "score": 1,
    "reason": "shady"
  },
  "near you": {
    "score": 4,
    "reason": "shady"
  },
  "never before": {
    "score": 2,
    "reason": "shady"
  },
  "new": {
    "score": 2,
    "reason": "shady"
  },
  "new domain extensions": {
    "score": 4,
    "reason": "shady"
  },
  "nigerian": {
    "score": 9,
    "reason": "shady"
  },
  "no age restrictions": {
    "score": 4,
    "reason": "shady"
  },
  "no catch": {
    "score": 2,
    "reason": "shady"
  },
  "no claim forms": {
    "score": 2,
    "reason": "shady"
  },
  "no cost": {
    "score": 2,
    "reason": "shady"
  },
  "no credit check": {
    "score": 3,
    "reason": "shady"
  },
  "no credit experience": {
    "score": 3,
    "reason": "shady"
  },
  "no deposit required": {
    "score": 3,
    "reason": "shady"
  },
  "no disappointment": {
    "score": 2,
    "reason": "shady"
  },
  "no experience": {
    "score": 3,
    "reason": "shady"
  },
  "no fees": {
    "score": 2,
    "reason": "shady"
  },
  "no gimmick": {
    "score": 2,
    "reason": "shady"
  },
  "no hidden": {
    "score": 2,
    "reason": "shady"
  },
  "no hidden fees": {
    "score": 2,
    "reason": "shady"
  },
  "no hidden costs": {
    "score": 2,
    "reason": "shady"
  },
  "no interest": {
    "score": 2,
    "reason": "shady"
  },
  "no interests": {
    "score": 2,
    "reason": "shady"
  },
  "no inventory": {
    "score": 2,
    "reason": "shady"
  },
  "no investment": {
    "score": 2,
    "reason": "shady"
  },
  "no investment required": {
    "score": 3,
    "reason": "shady"
  },
  "no medical exams": {
    "score": 3,
    "reason": "shady"
  },
  "no middleman": {
    "score": 2,
    "reason": "shady"
  },
  "no obligation": {
    "score": 2,
    "reason": "shady"
  },
  "no payment required": {
    "score": 2,
    "reason": "shady"
  },
  "no purchase necessary": {
    "score": 2,
    "reason": "shady"
  },
  "no questions asked": {
    "score": 2,
    "reason": "shady"
  },
  "no selling": {
    "score": 2,
    "reason": "shady"
  },
  "no strings attached": {
    "score": 2,
    "reason": "shady"
  },
  "no-obligation": {
    "score": 2,
    "reason": "shady"
  },
  "nominated bank account": {
    "score": 3,
    "reason": "shady"
  },
  "not intended": {
    "score": 1,
    "reason": "shady"
  },
  "not junk": {
    "score": 1,
    "reason": "shady"
  },
  "not scam": {
    "score": 1,
    "reason": "shady"
  },
  "not spam": {
    "score": 1,
    "reason": "shady"
  },
  "notspam": {
    "score": 1,
    "reason": "shady"
  },
  "number 1": {
    "score": 2,
    "reason": "shady"
  },
  "obligation": {
    "score": 2,
    "reason": "shady"
  },
  "off": {
    "score": 2,
    "reason": "shady"
  },
  "off everything": {
    "score": 2,
    "reason": "shady"
  },
  "off shore": {
    "score": 3,
    "reason": "shady"
  },
  "offer extended": {
    "score": 2,
    "reason": "shady"
  },
  "offers": {
    "score": 2,
    "reason": "shady"
  },
  "offshore": {
    "score": 3,
    "reason": "shady"
  },
  "one hundred percent": {
    "score": 2,
    "reason": "shady"
  },
  "one-time": {
    "score": 2,
    "reason": "shady"
  },
  "online biz opportunity": {
    "score": 6,
    "reason": "shady"
  },
  "online degree": {
    "score": 5,
    "reason": "shady"
  },
  "online income": {
    "score": 6,
    "reason": "shady"
  },
  "online job": {
    "score": 5,
    "reason": "shady"
  },
  "open": {
    "score": 1,
    "reason": "shady"
  },
  "opportunity": {
    "score": 3,
    "reason": "shady"
  },
  "opt-in": {
    "score": 2,
    "reason": "shady"
  },
  "order": {
    "score": 2,
    "reason": "shady"
  },
  "order shipped by": {
    "score": 2,
    "reason": "shady"
  },
  "order status": {
    "score": 2,
    "reason": "shady"
  },
  "orders shipped by": {
    "score": 2,
    "reason": "shady"
  },
  "orders shipped by shopper": {
    "score": 8,
    "reason": "shady"
  },
  "outstanding value": {
    "score": 2,
    "reason": "shady"
  },
  "outstanding values": {
    "score": 2,
    "reason": "shady"
  },
  "password": {
    "score": 5,
    "reason": "shady"
  },
  "passwords": {
    "score": 7,
    "reason": "shady"
  },
  "pay your bills": {
    "score": 2,
    "reason": "shady"
  },
  "per year": {
    "score": 3,
    "reason": "shady"
  },
  "per month": {
    "score": 2,
    "reason": "shady"
  },
  "perfect": {
    "score": 2,
    "reason": "shady"
  },
  "performance": {
    "score": 2,
    "reason": "shady"
  },
  "phone": {
    "score": 2,
    "reason": "shady"
  },
  "please": {
    "score": 1,
    "reason": "shady"
  },
  "please open": {
    "score": 4,
    "reason": "shady"
  },
  "presently": {
    "score": 2,
    "reason": "shady"
  },
  "print form signature": {
    "score": 4,
    "reason": "shady"
  },
  "print from signature": {
    "score": 4,
    "reason": "shady"
  },
  "print out and fax": {
    "score": 4,
    "reason": "shady"
  },
  "priority mail": {
    "score": 4,
    "reason": "shady"
  },
  "privately owned funds": {
    "score": 3,
    "reason": "shady"
  },
  "prizes": {
    "score": 6,
    "reason": "shady"
  },
  "problem with shipping": {
    "score": 5,
    "reason": "shady"
  },
  "problem with your order": {
    "score": 5,
    "reason": "shady"
  },
  "produced and sent out": {
    "score": 3,
    "reason": "shady"
  },
  "profit": {
    "score": 2,
    "reason": "shady"
  },
  "promise you": {
    "score": 7,
    "reason": "shady"
  },
  "purchase": {
    "score": 2,
    "reason": "shady"
  },
  "pure profits": {
    "score": 5,
    "reason": "shady"
  },
  "quotes": {
    "score": 2,
    "reason": "shady"
  },
  "rate": {
    "score": 2,
    "reason": "shady"
  },
  "real thing": {
    "score": 3,
    "reason": "shady"
  },
  "rebate": {
    "score": 6,
    "reason": "shady"
  },
  "reduce debt": {
    "score": 7,
    "reason": "shady"
  },
  "refinance home": {
    "score": 7,
    "reason": "shady"
  },
  "refinanced home": {
    "score": 7,
    "reason": "shady"
  },
  "refund": {
    "score": 6,
    "reason": "shady"
  },
  "regarding": {
    "score": 2,
    "reason": "shady"
  },
  "removal instructions": {
    "score": 6,
    "reason": "shady"
  },
  "removes": {
    "score": 8,
    "reason": "shady"
  },
  "removes wrinkles": {
    "score": 8,
    "reason": "shady"
  },
  "replica watches": {
    "score": 10,
    "reason": "shady"
  },
  "request": {
    "score": 2,
    "reason": "shady"
  },
  "request now": {
    "score": 3,
    "reason": "shady"
  },
  "request today": {
    "score": 3,
    "reason": "shady"
  },
  "requires initial investment": {
    "score": 8,
    "reason": "shady"
  },
  "requires investment": {
    "score": 8,
    "reason": "shady"
  },
  "reverses aging": {
    "score": 10,
    "reason": "shady"
  },
  "risk free": {
    "score": 5,
    "reason": "shady"
  },
  "rolex": {
    "score": 10,
    "reason": "shady"
  },
  "round the world": {
    "score": 2,
    "reason": "shady"
  },
  "safeguard notice": {
    "score": 4,
    "reason": "shady"
  },
  "sale": {
    "score": 2,
    "reason": "shady"
  },
  "sales": {
    "score": 2,
    "reason": "shady"
  },
  "save": {
    "score": 2,
    "reason": "shady"
  },
  "save big": {
    "score": 3,
    "reason": "shady"
  },
  "save big month": {
    "score": 3,
    "reason": "shady"
  },
  "save money": {
    "score": 2,
    "reason": "shady"
  },
  "save now": {
    "score": 2,
    "reason": "shady"
  },
  "score with babes": {
    "score": 10,
    "reason": "shady"
  },
  "search engine optimisation": {
    "score": 4,
    "reason": "shady"
  },
  "section 301": {
    "score": 4,
    "reason": "shady"
  },
  "see for yourself": {
    "score": 3,
    "reason": "shady"
  },
  "seen on": {
    "score": 3,
    "reason": "shady"
  },
  "serious": {
    "score": 2,
    "reason": "shady"
  },
  "serious case": {
    "score": 5,
    "reason": "shady"
  },
  "serious offer": {
    "score": 5,
    "reason": "shady"
  },
  "serious only": {
    "score": 5,
    "reason": "shady"
  },
  "sex": {
    "score": 10,
    "reason": "shady"
  },
  "shop now": {
    "score": 2,
    "reason": "shady"
  },
  "shopper": {
    "score": 4,
    "reason": "shady"
  },
  "shopping spree": {
    "score": 3,
    "reason": "shady"
  },
  "snoring": {
    "score": 5,
    "reason": "shady"
  },
  "social security number": {
    "score": 8,
    "reason": "shady"
  },
  "soon": {
    "score": 2,
    "reason": "shady"
  },
  "spam": {
    "score": 10,
    "reason": "shady"
  },
  "spam free": {
    "score": 2,
    "reason": "shady"
  },
  "special deal": {
    "score": 4,
    "reason": "shady"
  },
  "special discount": {
    "score": 4,
    "reason": "shady"
  },
  "special for you": {
    "score": 4,
    "reason": "shady"
  },
  "special offer": {
    "score": 4,
    "reason": "shady"
  },
  "stainless steel": {
    "score": 5,
    "reason": "shady"
  },
  "stock alert": {
    "score": 6,
    "reason": "shady"
  },
  "stock disclaimer statement": {
    "score": 6,
    "reason": "shady"
  },
  "stock pick": {
    "score": 6,
    "reason": "shady"
  },
  "stocks/stock pick/stock alert": {
    "score": 6,
    "reason": "shady"
  },
  "stop calling me": {
    "score": 6,
    "reason": "shady"
  },
  "stop emailing me": {
    "score": 6,
    "reason": "shady"
  },
  "stop further distribution": {
    "score": 7,
    "reason": "shady"
  },
  "stop snoring": {
    "score": 8,
    "reason": "shady"
  },
  "strong buy": {
    "score": 6,
    "reason": "shady"
  },
  "stuff on sale": {
    "score": 2,
    "reason": "shady"
  },
  "subject to": {
    "score": 2,
    "reason": "shady"
  },
  "subject to cash": {
    "score": 7,
    "reason": "shady"
  },
  "subscribe": {
    "score": 2,
    "reason": "shady"
  },
  "subscribe for free": {
    "score": 3,
    "reason": "shady"
  },
  "subscribe now": {
    "score": 3,
    "reason": "shady"
  },
  "super promo": {
    "score": 4,
    "reason": "shady"
  },
  "supplies": {
    "score": 2,
    "reason": "shady"
  },
  "this is an ad": {
    "score": 5,
    "reason": "shady"
  },
  "terms": {
    "score": 2,
    "reason": "shady"
  },
  "the best rates": {
    "score": 3,
    "reason": "shady"
  },
  "the following form": {
    "score": 4,
    "reason": "shady"
  },
  "they make a claim or claims that they're in accordance with spam law": {
    "score": 7,
    "reason": "shady"
  },
  "they try to keep your money no refund": {
    "score": 9,
    "reason": "shady"
  },
  "they're just giving it away": {
    "score": 7,
    "reason": "shady"
  },
  "this isn't junk": {
    "score": 2,
    "reason": "shady"
  },
  "this isn't spam": {
    "score": 2,
    "reason": "shady"
  },
  "this isn't a scam": {
    "score": 2,
    "reason": "shady"
  },
  "timeshare": {
    "score": 6,
    "reason": "shady"
  },
  "timeshare offers": {
    "score": 7,
    "reason": "shady"
  },
  "traffic": {
    "score": 3,
    "reason": "shady"
  },
  "trial unlimited": {
    "score": 3,
    "reason": "shady"
  },
  "u.s. dollars": {
    "score": 2,
    "reason": "shady"
  },
  "undisclosed": {
    "score": 6,
    "reason": "shady"
  },
  "undisclosed recipient": {
    "score": 6,
    "reason": "shady"
  },
  "university diplomas": {
    "score": 8,
    "reason": "shady"
  },
  "unsecured credit": {
    "score": 6,
    "reason": "shady"
  },
  "unsecured debt": {
    "score": 6,
    "reason": "shady"
  },
  "unsolicited": {
    "score": 9,
    "reason": "shady"
  },
  "unsubscribe": {
    "score": 2,
    "reason": "shady"
  },
  "urgent response": {
    "score": 7,
    "reason": "shady"
  },
  "euros": {
    "score": 2,
    "reason": "shady"
  },
  "vacation": {
    "score": 6,
    "reason": "shady"
  },
  "vacation offers": {
    "score": 7,
    "reason": "shady"
  },
  "valium": {
    "score": 10,
    "reason": "shady"
  },
  "viagra": {
    "score": 10,
    "reason": "shady"
  },
  "vicodin": {
    "score": 10,
    "reason": "shady"
  },
  "vip": {
    "score": 6,
    "reason": "shady"
  },
  "visit our website": {
    "score": 4,
    "reason": "shady"
  },
  "wants credit card": {
    "score": 8,
    "reason": "shady"
  },
  "warranty expired": {
    "score": 6,
    "reason": "shady"
  },
  "we hate spam": {
    "score": 2,
    "reason": "shady"
  },
  "we honor all": {
    "score": 3,
    "reason": "shady"
  },
  "website visitors": {
    "score": 4,
    "reason": "shady"
  },
  "weekend getaway": {
    "score": 5,
    "reason": "shady"
  },
  "weight loss": {
    "score": 7,
    "reason": "shady"
  },
  "what's keeping you?": {
    "score": 5,
    "reason": "shady"
  },
  "while available": {
    "score": 4,
    "reason": "shady"
  },
  "while in stock": {
    "score": 4,
    "reason": "shady"
  },
  "while stocks last": {
    "score": 5,
    "reason": "shady"
  },
  "while you sleep": {
    "score": 6,
    "reason": "shady"
  },
  "who really wins?": {
    "score": 6,
    "reason": "shady"
  },
  "win": {
    "score": 3,
    "reason": "shady"
  },
  "winner": {
    "score": 7,
    "reason": "shady"
  },
  "winning": {
    "score": 4,
    "reason": "shady"
  },
  "won": {
    "score": 5,
    "reason": "shady"
  },
  "xanax": {
    "score": 10,
    "reason": "shady"
  },
  "xxx": {
    "score": 10,
    "reason": "shady"
  },
  "you have been chosen": {
    "score": 7,
    "reason": "shady"
  },
  "you have been selected": {
    "score": 7,
    "reason": "shady"
  },
  "your chance": {
    "score": 7,
    "reason": "shady"
  },
  "your status": {
    "score": 4,
    "reason": "shady"
  },
  "zero chance": {
    "score": 4,
    "reason": "shady"
  },
  "zero percent": {
    "score": 4,
    "reason": "shady"
  },
  "zero risk": {
    "score": 4,
    "reason": "shady"
  },
  "#1": {
    "score": 3,
    "reason": "overpromise"
  },
  "%": {
    "score": 3,
    "reason": "overpromise"
  },
  "% free": {
    "score": 4,
    "reason": "overpromise"
  },
  "% satisfied": {
    "score": 3,
    "reason": "overpromise"
  },
  "0%": {
    "score": 3,
    "reason": "overpromise"
  },
  "0% risk": {
    "score": 4,
    "reason": "overpromise"
  },
  "100%": {
    "score": 3,
    "reason": "overpromise"
  },
  "100% free": {
    "score": 6,
    "reason": "overpromise"
  },
  "100% more": {
    "score": 5,
    "reason": "overpromise"
  },
  "100% off": {
    "score": 5,
    "reason": "overpromise"
  },
  "100% satisfied": {
    "score": 5,
    "reason": "overpromise"
  },
  "99.90%": {
    "score": 3,
    "reason": "overpromise"
  },
  "99%": {
    "score": 3,
    "reason": "overpromise"
  },
  "access for free": {
    "score": 4,
    "reason": "overpromise"
  },
  "additional income": {
    "score": 5,
    "reason": "overpromise"
  },
  "amazed": {
    "score": 3,
    "reason": "overpromise"
  },
  "amazing": {
    "score": 3,
    "reason": "overpromise"
  },
  "amazing offer": {
    "score": 8,
    "reason": "overpromise"
  },
  "amazing stuff": {
    "score": 7,
    "reason": "overpromise"
  },
  "be amazed": {
    "score": 6,
    "reason": "overpromise"
  },
  "be surprised": {
    "score": 4,
    "reason": "overpromise"
  },
  "be your own boss": {
    "score": 7,
    "reason": "overpromise"
  },
  "believe me": {
    "score": 6,
    "reason": "overpromise"
  },
  "best bargain": {
    "score": 7,
    "reason": "overpromise"
  },
  "best deal": {
    "score": 6,
    "reason": "overpromise"
  },
  "best offer": {
    "score": 6,
    "reason": "overpromise"
  },
  "best price": {
    "score": 6,
    "reason": "overpromise"
  },
  "best rates": {
    "score": 6,
    "reason": "overpromise"
  },
  "big bucks": {
    "score": 7,
    "reason": "overpromise"
  },
  "bonus": {
    "score": 5,
    "reason": "overpromise"
  },
  "boss": {
    "score": 5,
    "reason": "overpromise"
  },
  "can't live without": {
    "score": 6,
    "reason": "overpromise"
  },
  "cancel": {
    "score": 3,
    "reason": "overpromise"
  },
  "consolidate debt": {
    "score": 6,
    "reason": "overpromise"
  },
  "double your cash": {
    "score": 6,
    "reason": "overpromise"
  },
  "double your income": {
    "score": 6,
    "reason": "overpromise"
  },
  "drastically reduced": {
    "score": 5,
    "reason": "overpromise"
  },
  "earn extra cash": {
    "score": 6,
    "reason": "overpromise"
  },
  "earn money": {
    "score": 5,
    "reason": "overpromise"
  },
  "eliminate bad credit": {
    "score": 6,
    "reason": "overpromise"
  },
  "expect to earn": {
    "score": 4,
    "reason": "overpromise"
  },
  "extra": {
    "score": 2,
    "reason": "overpromise"
  },
  "extra cash": {
    "score": 3,
    "reason": "overpromise"
  },
  "extra income": {
    "score": 3,
    "reason": "overpromise"
  },
  "fantastic": {
    "score": 3,
    "reason": "overpromise"
  },
  "fantastic deal": {
    "score": 4,
    "reason": "overpromise"
  },
  "fantastic offer": {
    "score": 4,
    "reason": "overpromise"
  },
  "fast": {
    "score": 5,
    "reason": "overpromise"
  },
  "fast cash": {
    "score": 6,
    "reason": "overpromise"
  },
  "financial freedom": {
    "score": 5,
    "reason": "overpromise"
  },
  "free consultation": {
    "score": 3,
    "reason": "overpromise"
  },
  "free hosting": {
    "score": 3,
    "reason": "overpromise"
  },
  "free info": {
    "score": 4,
    "reason": "overpromise"
  },
  "free investment": {
    "score": 6,
    "reason": "overpromise"
  },
  "free membership": {
    "score": 6,
    "reason": "overpromise"
  },
  "free money": {
    "score": 10,
    "reason": "overpromise"
  },
  "free preview": {
    "score": 4,
    "reason": "overpromise"
  },
  "free quote": {
    "score": 7,
    "reason": "overpromise"
  },
  "free trial": {
    "score": 5,
    "reason": "overpromise"
  },
  "full refund": {
    "score": 2,
    "reason": "overpromise"
  },
  "get out of debt": {
    "score": 6,
    "reason": "overpromise"
  },
  "giveaway": {
    "score": 3,
    "reason": "overpromise"
  },
  "guaranteed": {
    "score": 7,
    "reason": "overpromise"
  },
  "increase sales": {
    "score": 3,
    "reason": "overpromise"
  },
  "increase traffic": {
    "score": 3,
    "reason": "overpromise"
  },
  "incredible deal": {
    "score": 5,
    "reason": "overpromise"
  },
  "join billions": {
    "score": 3,
    "reason": "overpromise"
  },
  "join millions": {
    "score": 3,
    "reason": "overpromise"
  },
  "join millions of americans": {
    "score": 3,
    "reason": "overpromise"
  },
  "join thousands": {
    "score": 3,
    "reason": "overpromise"
  },
  "lower rates": {
    "score": 3,
    "reason": "overpromise"
  },
  "lowest price": {
    "score": 3,
    "reason": "overpromise"
  },
  "make money": {
    "score": 5,
    "reason": "overpromise"
  },
  "million": {
    "score": 5,
    "reason": "overpromise"
  },
  "million dollars": {
    "score": 6,
    "reason": "overpromise"
  },
  "miracle": {
    "score": 7,
    "reason": "overpromise"
  },
  "money back": {
    "score": 4,
    "reason": "overpromise"
  },
  "month trial offer": {
    "score": 4,
    "reason": "overpromise"
  },
  "more internet traffic": {
    "score": 4,
    "reason": "overpromise"
  },
  "number one": {
    "score": 4,
    "reason": "overpromise"
  },
  "one hundred percent guaranteed": {
    "score": 6,
    "reason": "overpromise"
  },
  "one time": {
    "score": 3,
    "reason": "overpromise"
  },
  "pennies a day": {
    "score": 3,
    "reason": "overpromise"
  },
  "potential earnings": {
    "score": 4,
    "reason": "overpromise"
  },
  "prize": {
    "score": 5,
    "reason": "overpromise"
  },
  "promise": {
    "score": 5,
    "reason": "overpromise"
  },
  "pure profit": {
    "score": 4,
    "reason": "overpromise"
  },
  "risk-free": {
    "score": 6,
    "reason": "overpromise"
  },
  "satisfaction guaranteed": {
    "score": 4,
    "reason": "overpromise"
  },
  "save big money": {
    "score": 4,
    "reason": "overpromise"
  },
  "save up to": {
    "score": 3,
    "reason": "overpromise"
  },
  "special promotion": {
    "score": 4,
    "reason": "overpromise"
  },
  "the best": {
    "score": 3,
    "reason": "overpromise"
  },
  "thousands": {
    "score": 3,
    "reason": "overpromise"
  },
  "unbeatable offer": {
    "score": 5,
    "reason": "overpromise"
  },
  "unbelievable": {
    "score": 6,
    "reason": "overpromise"
  },
  "unlimited": {
    "score": 3,
    "reason": "overpromise"
  },
  "unlimited trial": {
    "score": 4,
    "reason": "overpromise"
  },
  "wonderful": {
    "score": 3,
    "reason": "overpromise"
  },
  "you will not believe your eyes": {
    "score": 5,
    "reason": "overpromise"
  },
  "$$$": {
    "score": 6,
    "reason": "money"
  },
  "50% off": {
    "score": 5,
    "reason": "money"
  },
  "a few bob": {
    "score": 2,
    "reason": "money"
  },
  "accept cash cards": {
    "score": 3,
    "reason": "money"
  },
  "accept credit cards": {
    "score": 3,
    "reason": "money"
  },
  "affordable": {
    "score": 2,
    "reason": "money"
  },
  "affordable deal": {
    "score": 2,
    "reason": "money"
  },
  "avoid bankruptcy": {
    "score": 4,
    "reason": "money"
  },
  "bad credit": {
    "score": 5,
    "reason": "money"
  },
  "bank": {
    "score": 2,
    "reason": "money"
  },
  "bankruptcy": {
    "score": 6,
    "reason": "money"
  },
  "bargain": {
    "score": 4,
    "reason": "money"
  },
  "billing": {
    "score": 2,
    "reason": "money"
  },
  "billing address": {
    "score": 2,
    "reason": "money"
  },
  "billion": {
    "score": 6,
    "reason": "money"
  },
  "billion dollars": {
    "score": 7,
    "reason": "money"
  },
  "billionaire": {
    "score": 4,
    "reason": "money"
  },
  "card accepted": {
    "score": 2,
    "reason": "money"
  },
  "cards accepted": {
    "score": 2,
    "reason": "money"
  },
  "cash": {
    "score": 3,
    "reason": "money"
  },
  "cash bonus": {
    "score": 3,
    "reason": "money"
  },
  "cash out": {
    "score": 3,
    "reason": "money"
  },
  "cash-out": {
    "score": 3,
    "reason": "money"
  },
  "cashcashcash": {
    "score": 6,
    "reason": "money"
  },
  "casino": {
    "score": 5,
    "reason": "money"
  },
  "cents on the dollar": {
    "score": 4,
    "reason": "money"
  },
  "check": {
    "score": 2,
    "reason": "money"
  },
  "check or money order": {
    "score": 4,
    "reason": "money"
  },
  "claim your discount": {
    "score": 3,
    "reason": "money"
  },
  "cost": {
    "score": 2,
    "reason": "money"
  },
  "costs": {
    "score": 2,
    "reason": "money"
  },
  "credit": {
    "score": 4,
    "reason": "money"
  },
  "credit bureaus": {
    "score": 5,
    "reason": "money"
  },
  "credit card": {
    "score": 5,
    "reason": "money"
  },
  "credit card offers": {
    "score": 5,
    "reason": "money"
  },
  "credit or debit": {
    "score": 4,
    "reason": "money"
  },
  "deal": {
    "score": 2,
    "reason": "money"
  },
  "debt": {
    "score": 5,
    "reason": "money"
  },
  "discount": {
    "score": 3,
    "reason": "money"
  },
  "dollars": {
    "score": 3,
    "reason": "money"
  },
  "double your": {
    "score": 4,
    "reason": "money"
  },
  "double your wealth": {
    "score": 4,
    "reason": "money"
  },
  "earn": {
    "score": 4,
    "reason": "money"
  },
  "earn $": {
    "score": 4,
    "reason": "money"
  },
  "earn cash": {
    "score": 4,
    "reason": "money"
  },
  "earn extra income": {
    "score": 4,
    "reason": "money"
  },
  "earn from home": {
    "score": 4,
    "reason": "money"
  },
  "earn monthly": {
    "score": 4,
    "reason": "money"
  },
  "earn per month": {
    "score": 4,
    "reason": "money"
  },
  "earn per week": {
    "score": 4,
    "reason": "money"
  },
  "earn your degree": {
    "score": 4,
    "reason": "money"
  },
  "easy income": {
    "score": 3,
    "reason": "money"
  },
  "easy terms": {
    "score": 3,
    "reason": "money"
  },
  "f r e e": {
    "score": 3,
    "reason": "money"
  },
  "for free": {
    "score": 3,
    "reason": "money"
  },
  "for just $": {
    "score": 3,
    "reason": "money"
  },
  "for just $ (amount)": {
    "score": 3,
    "reason": "money"
  },
  "for just $xxx": {
    "score": 3,
    "reason": "money"
  },
  "get money": {
    "score": 4,
    "reason": "money"
  },
  "get your money": {
    "score": 4,
    "reason": "money"
  },
  "hidden assets": {
    "score": 6,
    "reason": "money"
  },
  "huge discount": {
    "score": 4,
    "reason": "money"
  },
  "income": {
    "score": 4,
    "reason": "money"
  },
  "income from home": {
    "score": 4,
    "reason": "money"
  },
  "increase revenue": {
    "score": 4,
    "reason": "money"
  },
  "increase sales/traffic": {
    "score": 4,
    "reason": "money"
  },
  "increase your chances": {
    "score": 4,
    "reason": "money"
  },
  "initial investment": {
    "score": 4,
    "reason": "money"
  },
  "instant earnings": {
    "score": 4,
    "reason": "money"
  },
  "instant income": {
    "score": 4,
    "reason": "money"
  },
  "insurance": {
    "score": 4,
    "reason": "money"
  },
  "investment": {
    "score": 4,
    "reason": "money"
  },
  "investment advice": {
    "score": 4,
    "reason": "money"
  },
  "lifetime": {
    "score": 3,
    "reason": "money"
  },
  "loans": {
    "score": 4,
    "reason": "money"
  },
  "make $": {
    "score": 4,
    "reason": "money"
  },
  "money": {
    "score": 3,
    "reason": "money"
  },
  "money making": {
    "score": 4,
    "reason": "money"
  },
  "money-back guarantee": {
    "score": 4,
    "reason": "money"
  },
  "money-making": {
    "score": 4,
    "reason": "money"
  },
  "monthly payment": {
    "score": 2,
    "reason": "money"
  },
  "mortgage": {
    "score": 5,
    "reason": "money"
  },
  "mortgage rates": {
    "score": 5,
    "reason": "money"
  },
  "offer": {
    "score": 2,
    "reason": "money"
  },
  "one hundred percent free": {
    "score": 3,
    "reason": "money"
  },
  "only $": {
    "score": 3,
    "reason": "money"
  },
  "price": {
    "score": 2,
    "reason": "money"
  },
  "price protection": {
    "score": 3,
    "reason": "money"
  },
  "prices": {
    "score": 2,
    "reason": "money"
  },
  "profits": {
    "score": 4,
    "reason": "money"
  },
  "quote": {
    "score": 2,
    "reason": "money"
  },
  "rates": {
    "score": 2,
    "reason": "money"
  },
  "refinance": {
    "score": 5,
    "reason": "money"
  },
  "save $": {
    "score": 3,
    "reason": "money"
  },
  "serious cash": {
    "score": 4,
    "reason": "money"
  },
  "subject to credit": {
    "score": 4,
    "reason": "money"
  },
  "us dollars": {
    "score": 2,
    "reason": "money"
  },
  "why pay more?": {
    "score": 3,
    "reason": "money"
  },
  "your income": {
    "score": 4,
    "reason": "money"
  },
  "acceptance": {
    "score": 2,
    "reason": "unnatural"
  },
  "accordingly": {
    "score": 1,
    "reason": "unnatural"
  },
  "account-based marketing": {
    "score": 3,
    "reason": "unnatural"
  },
  "accounts": {
    "score": 2,
    "reason": "unnatural"
  },
  "addresses": {
    "score": 2,
    "reason": "unnatural"
  },
  "addresses on cd": {
    "score": 5,
    "reason": "unnatural"
  },
  "beverage": {
    "score": 1,
    "reason": "unnatural"
  },
  "confidentiality on all orders": {
    "score": 2,
    "reason": "unnatural"
  },
  "confidentially on all orders": {
    "score": 2,
    "reason": "unnatural"
  },
  "content marketing": {
    "score": 3,
    "reason": "unnatural"
  },
  "dear": {
    "score": 2,
    "reason": "unnatural"
  },
  "dear,": {
    "score": 4,
    "reason": "unnatural"
  },
  "digital marketing": {
    "score": 3,
    "reason": "unnatural"
  },
  "dormant": {
    "score": 2,
    "reason": "unnatural"
  },
  "email extractor": {
    "score": 8,
    "reason": "unnatural"
  },
  "email harvest": {
    "score": 8,
    "reason": "unnatural"
  },
  "email marketing": {
    "score": 7,
    "reason": "unnatural"
  },
  "extract email": {
    "score": 8,
    "reason": "unnatural"
  },
  "form": {
    "score": 2,
    "reason": "unnatural"
  },
  "freedom": {
    "score": 2,
    "reason": "unnatural"
  },
  "friend": {
    "score": 2,
    "reason": "unnatural"
  },
  "here": {
    "score": 1,
    "reason": "unnatural"
  },
  "hidden": {
    "score": 3,
    "reason": "unnatural"
  },
  "home": {
    "score": 1,
    "reason": "unnatural"
  },
  "home based": {
    "score": 2,
    "reason": "unnatural"
  },
  "home employment": {
    "score": 2,
    "reason": "unnatural"
  },
  "home-based": {
    "score": 2,
    "reason": "unnatural"
  },
  "home-based business": {
    "score": 2,
    "reason": "unnatural"
  },
  "homebased business": {
    "score": 2,
    "reason": "unnatural"
  },
  "if you no longer wish to receive": {
    "score": 8,
    "reason": "unnatural"
  },
  "important information regarding": {
    "score": 6,
    "reason": "unnatural"
  },
  "in accordance with laws": {
    "score": 3,
    "reason": "unnatural"
  },
  "increase your sales": {
    "score": 5,
    "reason": "unnatural"
  },
  "internet market": {
    "score": 3,
    "reason": "unnatural"
  },
  "leave": {
    "score": 2,
    "reason": "unnatural"
  },
  "lose": {
    "score": 3,
    "reason": "unnatural"
  },
  "maintained": {
    "score": 2,
    "reason": "unnatural"
  },
  "marketing": {
    "score": 3,
    "reason": "unnatural"
  },
  "marketing solution": {
    "score": 3,
    "reason": "unnatural"
  },
  "marketing solutions": {
    "score": 3,
    "reason": "unnatural"
  },
  "medium": {
    "score": 2,
    "reason": "unnatural"
  },
  "message contains": {
    "score": 6,
    "reason": "unnatural"
  },
  "multi level marketing": {
    "score": 8,
    "reason": "unnatural"
  },
  "never": {
    "score": 2,
    "reason": "unnatural"
  },
  "one time mailing": {
    "score": 7,
    "reason": "unnatural"
  },
  "online marketing": {
    "score": 3,
    "reason": "unnatural"
  },
  "online pharmacy": {
    "score": 9,
    "reason": "unnatural"
  },
  "opt in": {
    "score": 5,
    "reason": "unnatural"
  },
  "per day": {
    "score": 2,
    "reason": "unnatural"
  },
  "per week": {
    "score": 2,
    "reason": "unnatural"
  },
  "pre-approved": {
    "score": 5,
    "reason": "unnatural"
  },
  "problem": {
    "score": 2,
    "reason": "unnatural"
  },
  "removal": {
    "score": 4,
    "reason": "unnatural"
  },
  "remove": {
    "score": 4,
    "reason": "unnatural"
  },
  "reserves the right": {
    "score": 3,
    "reason": "unnatural"
  },
  "reverses": {
    "score": 2,
    "reason": "unnatural"
  },
  "sample": {
    "score": 2,
    "reason": "unnatural"
  },
  "satisfaction": {
    "score": 2,
    "reason": "unnatural"
  },
  "score": {
    "score": 2,
    "reason": "unnatural"
  },
  "search engine": {
    "score": 2,
    "reason": "unnatural"
  },
  "search engine listings": {
    "score": 3,
    "reason": "unnatural"
  },
  "search engines": {
    "score": 2,
    "reason": "unnatural"
  },
  "sent in compliance": {
    "score": 3,
    "reason": "unnatural"
  },
  "solution": {
    "score": 2,
    "reason": "unnatural"
  },
  "stop": {
    "score": 2,
    "reason": "unnatural"
  },
  "success": {
    "score": 2,
    "reason": "unnatural"
  },
  "teen": {
    "score": 1,
    "reason": "unnatural"
  },
  "terms and conditions": {
    "score": 3,
    "reason": "unnatural"
  },
  "warranty": {
    "score": 2,
    "reason": "unnatural"
  },
  "web traffic": {
    "score": 3,
    "reason": "unnatural"
  },
  "wife": {
    "score": 1,
    "reason": "unnatural"
  },
  "work at home": {
    "score": 3,
    "reason": "unnatural"
  },
  "work from home": {
    "score": 3,
    "reason": "unnatural"
  }
}