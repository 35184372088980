import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment-timezone';

const TimezoneInput = ({
  inputProps,
  onChange,
  className,
  style,
  value
}) => {
  const [focus, setFocus] = useState(null);
  const [query, setQuery] = useState('');
  let theInput = null;

  const timezones = () => {
    return moment.tz.names().filter(zone => {
      return zone
      .toLowerCase()
      .replace(/\s+/g, '')
      .includes(query.toLowerCase().replace(/\s+/g, ''))
    })
  }

  const handleFocus = e => {
    setFocus(0);

    if (inputProps.onFocus) {
      inputProps.onFocus(e);
    }
  };

  const handleBlur = e => {
    setFocus(null);
    setQuery('');

    if (inputProps.onBlur) {
      inputProps.onBlur(e);
    }
  };

  const handleChange = e => {
    setFocus(0);
    setQuery(e.currentTarget.value);

    if (onChange) {
      onChange(e);
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'ArrowDown') {
      e.stopPropagation();
      e.preventDefault();

      const ulElement = e.currentTarget.parentElement.querySelector('ul');
      const zones = timezones();
      const newFocus = focus === zones.length - 1 ? 0 : focus + 1;
      scrollToElement(ulElement.children[newFocus]);
      setFocus(newFocus);
    } else if (e.key === 'ArrowUp') {
      e.stopPropagation();
      e.preventDefault();

      const ulElement = e.currentTarget.parentElement.querySelector('ul');
      const zones = timezones();
      const newFocus = state.focus === 0 ? zones.length - 1 : state.focus - 1;
      scrollToElement(ulElement.children[newFocus]);
      setFocus(newFocus);
    } else if (e.key === 'Escape' && this.input) {
      e.stopPropagation();
      e.preventDefault();

      this.input.blur();
    } else if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      e.currentTarget.blur();

      const zones = timezones();
      if (zones[focus]) {
        handleChangeZone(zones[focus]);
      }
    }

    if (inputProps.onKeyDown) {
      inputProps.onKeyDown(e);
    }
  };

  const handleHoverItem = index => {
    if (index === focus) return;

    setFocus(index);
  };

  const handleChangeZone = zone => {
    onChange(zone);

    theInput.blur();
  };

  const scrollToElement = element => {
    const ulElement = element.parentElement;

    const topDifference = element.offsetTop - ulElement.scrollTop;
    const bottomDifference =
      ulElement.clientHeight + ulElement.scrollTop - (element.offsetTop + element.offsetHeight);

    if (topDifference < 0) {
      // Scroll top
      ulElement.scrollTop = element.offsetTop;
    }

    if (bottomDifference < 0) {
      // Scroll bottom
      ulElement.scrollTop -= bottomDifference;
    }
  };

  const open = focus !== null;

  return (
    <div className={className} style={style}>
      <Form.Control
        type="text"
        autoComplete="off"
        {...inputProps}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={value && !open ? value : query}
        ref={input => {
          theInput = input;
        }}
      />

      <ul className={open ? 'open' : ''}>
        {timezones().map((zone, index) => (
          <li key={zone}>
            <button
              title={zone}
              onMouseDown={() => handleChangeZone(zone)}
              onMouseOver={() => handleHoverItem(index)}
              onFocus={() => handleHoverItem(index)}
              className={focus === index ? 'focus' : ''}
            >
              { zone }
            </button>
          </li>
        ))}
      </ul>

    </div>
  );
}

export default TimezoneInput;