import React, {useState} from 'react';
import SpamCheckerContent from '../spam/SpamCheckerContent';
import { SpamCheckerContext } from 'context/Context';

const SpamChecker = () => {
  const [textHTML, setTextHTML] = useState('text');
  const [emailContent, setEmailContent] = useState('');
  const [emailHTML, setEmailHTML] = useState('');
  const [disabled, setDisabled] = useState(false);

  return (
    <div>
      <SpamCheckerContext.Provider
        value={{
          textHTML, setTextHTML,
          emailContent, setEmailContent,
          emailHTML, setEmailHTML,
          disabled, setDisabled
        }}>
        <SpamCheckerContent />
      </SpamCheckerContext.Provider>
    </div>
  );
};

export default SpamChecker;
