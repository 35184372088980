import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import classNames from 'classnames';
import CustomNavbarItemWrap from './CustomNavbarItemWrap';
import Logo from '../../common/Logo';
import TopNavRightSideNavItem from '../top/TopNavRightSideNavItem';
import CustomNavbarCollapse from './CustomNavbarCollapse';
import CustomNavbarDropdown from './CustomNavbarDropdown';
import { hasPermission } from 'helpers/utils';
import CustomNavbarRight from './CustomNavbarRight';

const CustomNavbar = ({
  routes,
  id,
  bg = 'white',
  orientation = 'horizontal',
  rounded = false,
  logo = false,
  logoClass = '',
  navbarClass = '',
  navClass = '',
  navbarBreakPoint = 'lg',
  isMain = false,
  collapse = true,
  sticky = false,
  ...rest
}) => {

  const CollapseComponent = collapse ? Navbar.Collapse : 'div';

  return (
    <Navbar expand={collapse ? navbarBreakPoint : null} bg={bg} className={classNames(navbarClass, {
      ['sticky-kit']: sticky
    })}>
      {
        logo && (
          <Navbar.Brand as={ Link } to="/">
            <Logo className={logoClass} />
          </Navbar.Brand>
        )
      }
      {
        collapse && (
          <Navbar.Toggle
            className={classNames('toggle-icon-wrapper me-md-3 me-2', {
              [`d-${navbarBreakPoint}-none`]: navbarBreakPoint
            })}
            as="div"
            aria-controls={id}
          >
            <button
              className="navbar-toggler-hamburger-icon btn btn-link d-flex flex-center"
            >
              <span className="navbar-toggle-icon">
                <span className="toggle-line" />
              </span>
            </button>
          </Navbar.Toggle>
        )
      }
      <CollapseComponent className={classNames('scrollbar pb-3 pb-lg-0', {
        ['width-100']: orientation === 'vertical'
      })} id={id}>
        <Nav className={classNames(navClass, {
          rounded,
          ['flex-column']: orientation === 'vertical'
        })} as="ul">
          {
            routes.filter(route => route.permission === undefined || hasPermission(route.permission)).map(route => {
              if (!route.children) {
                return <CustomNavbarItemWrap route={route} orientation={orientation} {...rest} />;
              }

              return orientation === 'vertical'
                ? <CustomNavbarCollapse route={route} {...rest} />
                : <CustomNavbarDropdown route={route} {...rest} />;
            })
          }
        </Nav>
      </CollapseComponent>
      {isMain ? <CustomNavbarRight /> : null}
    </Navbar>
  );
};

CustomNavbar.propTypes = {
  routes: PropTypes.object,
  id: PropTypes.string,
  bg: PropTypes.string,
  orientation: PropTypes.string,
  rounded: PropTypes.bool,
  logo: PropTypes.bool,
  logoClass: PropTypes.string,
  navbarClass: PropTypes.string,
  navClass: PropTypes.string,
  navbarBreakPoint: PropTypes.string,
  isMain: PropTypes.bool,
  collapse: PropTypes.bool,
  sticky: PropTypes.bool
};

export default CustomNavbar;
