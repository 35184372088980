/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Emails from 'components/email/Emails';
import Overview from '../components/email/Overview';
import Schedule from '../components/email/Schedule';
import History from '../components/email/History';
import Settings from '../components/email/Settings';
import Reconnect from '../components/email/Reconnect';

const EmailRoutes = () => {
  let route = useRouteMatch();

  return (
    <Emails>
      <Switch>
        <Route path={ `/email/overview` }>
          <Overview />
        </Route>
        <Route path={ `/email/warmup` }>
          <Schedule />
        </Route>
        <Route path={ `/email/history` }>
          <History />
        </Route>
        <Route path={ `/email/settings` }>
          <Settings />
        </Route>
        <Route path={ `/email/reconnect` }>
          <Reconnect />
        </Route>
      </Switch>
    </Emails>
  );
}

export default EmailRoutes;
