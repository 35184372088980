import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import SettingsPage from './SettingsPage';
import { AccountContext } from 'context/Context';
import { loadPlans, loadPlansAccounts } from 'load/plans'
//import LoaderButton from '../../common/LoaderButton';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasUserPermission, getURLParameter } from '../../../helpers/utils';
import PlanAddon from './plan/PlanAddon';
import PlanMain from './plan/PlanMain';
import PlanCurrent from './plan/PlanCurrent';

const Plan = () => {
  const [overages, setOverages] = useState({});
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [accountPlans, setAccountPlans] = useState([]);
  const [newPlanAdded, setNewPlanAdded] = useState(false);

  const { accountState } = useContext(AccountContext);

  const updateItems = (items) => {
    setItems(items.filter(each => each.period !== 'lifetime'));
  }

  const newProductId = getURLParameter('newPlan')
    ? getURLParameter('newPlan')
    : '';

  const checkNewPlan = (productIds, iteration = 0) => {
    if(newProductId && !productIds.includes(newProductId)){
      if( iteration < 3 ){
        setTimeout(() => {
          loadPlansAccounts(setAccountPlans).then(result => {
            const accountProductIds = result.filter(each => ! each.cancel_id).reduce((acc, cur) => {
              acc.push(cur.product_id);
              return acc;
            }, []);
            checkNewPlan(accountProductIds, iteration + 1)
          });
        }, 3000);
      }
    } else {
      setNewPlanAdded(true);
    }
  };

  const doLoadPlansAccounts = () => loadPlansAccounts(setAccountPlans);

  //The empty array as the second argument makes this run like componentDidMount
  //https://reacttraining.com/blog/useEffect-is-not-the-new-componentDidMount/
  useEffect(() => {
    loadPlansAccounts(setAccountPlans).then(result => {
      const productIds = [];

      const accountPlanIds = result.filter(each => ! each.cancel_id).reduce((acc, cur) => {
        acc.push(cur.plan);
        productIds.push(cur.product_id);
        return acc;
      }, []);

      if(newProductId && !accountPlanIds.includes(newProductId)){
        checkNewPlan(productIds);
      }

      loadPlans(updateItems, accountPlanIds).then(result2 => {
        setLoading(false);
      }).catch(error => {
        setLoading(false);
      })
    }).catch(error => {
      loadPlans(updateItems).then(result2 => {
        setLoading(false);
      }).catch(error => {
        setLoading(false);
      })
    });
  }, []);

  const updateOverage = (planId, overageItem, val) => {
    setOverages({
      ...overages,
      [planId]: {
        ...overages[planId],
        [overageItem]: val
      }
    });
  }

  if( ! hasUserPermission('plan') ){
    return (
      <SettingsPage title="Plan" loading={loading} onSave={null}>
        <div className="alert alert-danger">You do not have permission to edit the plan for your account. Please speak with the account's admin.</div>
      </SettingsPage>
    )
  }

  if( ! loading && ! items.length ){
    return (
      <SettingsPage title="Plan" loading={loading} onSave={null}>
        <div>{ getURLParameter('pSubscribe') ? 'This plan is not available' : 'There are no plans available for sale.' }</div>
      </SettingsPage>
    )
  }

  const planTitle = items.length && accountPlans.filter(each => ! each.cancel_id && each.period !== 'lifetime').length
    ? <span>Plan <a className="fs--2" href="#current-plan">(View Current Plan)</a></span>
    : 'Plan';

  return (
    <SettingsPage title={ planTitle } loading={loading} onSave={null}>
      { newProductId && ! newPlanAdded ? <div className="alert alert-primary">If you do not see your plan updated after 10 seconds, please refresh this page.</div> : null }
      <Row>
        {
          items.map(each => {
            switch(each.type){
              case 'addOn':
                return <Col md={6}><PlanAddon updateOverage={updateOverage} overages={overages} accountPlans={accountPlans} {...each} /></Col>;
              case 'main':
                return <Col md={6}><PlanMain updateOverage={updateOverage} overages={overages} accountPlans={accountPlans} {...each} /></Col>;
            }
          })
        }
      </Row>
      { items.length && accountPlans.filter(each => ! each.cancel_id).length ? <PlanCurrent plans={items} accountPlans={accountPlans} doLoadPlansAccounts={doLoadPlansAccounts} /> : null }
    </SettingsPage>
  );
};

export default Plan;
