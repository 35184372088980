import React, { useState, useEffect, useContext } from 'react';
//import PropTypes from 'prop-types';
//import classNames from 'classnames';
import { Button, Card, Form, Row, Col } from 'react-bootstrap';
import Section from 'components/common/Sec';
import { AdminContext, AppContext } from "context/Context";
import { loadUsers, loadFullUser, loadUsersSearch } from './load/users';
import FilterTable from 'components/common/FilterTable';
import { errorMessage, successMessage, timestampToDate } from 'helpers/utils';
import { loadPlansStructure, loadUsage } from './load/plans';
import cookie from 'react-cookies';
import Api from 'api/api';
import ConnectedEmail from './ConnectedEmail';

const UserInfo = () => {
  const { appConfirm } = useContext(AppContext);

  const { adminDispatch } = useContext(AdminContext);
  const [search, setSearch] = useState('');
  const [user, setUser] = useState(false);
  const [userEmail, setUserEmail] = useState(false);
  const [users, setUsers] = useState([]);
  const [fullUser, setFullUser] = useState(false);
  const [usage, setUsage] = useState(false);
  const [connectedEmail, setConnectedEmail] = useState(false);
  const [structure, setStructure] = useState([]);

  //The empty array as the second argument makes this run like componentDidMount
  //https://reacttraining.com/blog/useEffect-is-not-the-new-componentDidMount/
  useEffect(() => {
    loadUsers(adminDispatch, setUsers);
    loadPlansStructure(adminDispatch, setStructure);
  }, []);

  let searchTimer = null;

  const loadSearch = (search) => {
    setSearch(search);
    clearTimeout(searchTimer);

    if(!search){
      searchTimer = setTimeout(() => {
        loadUsers(adminDispatch, setUsers);
      }, 500);
    } else {
      searchTimer = setTimeout(() => {
        loadUsersSearch(adminDispatch, setUsers, search);
      }, 500);
    }

  }

  const chooseAccount = (val) => {
    setUser(val.id);
    setUserEmail(val.user_email);

    loadFullUser(adminDispatch, setFullUser, val.id);

    const params = [
      {
        param: 'user',
        value: val.id
      }
    ];

    loadUsage(adminDispatch, setUsage, params);
  }

  const accountLogin = (val) => {
    cookie.save(SSSettings.namelower + '_admin_user', val, { path: '/', maxAge: ( 1000 * 60 * 60 * 24 ) });

    window.location.href = '/';
  }

  const maybeDeleteAccount = (id) => {
    appConfirm({
      type: 'confirm',
      confirm: () => deleteAccount(id),
      confirmText: 'Delete',
      confirmColor: 'danger',
      header: 'Delete ' + id,
      body: 'Are you sure you want to delete this account? This cannot be undone.',
    });
  }

  const deleteAccount = (id) => {
    Api.doRequest('accounts', 'delete', 'delete', null, {
      params: [{
        param: 'id',
        value: id
      }]
    }).then(result => {
      successMessage(false, result);
    }).catch(error => {
      errorMessage(false, error)
    })
  }

  const cols = [
    {
      id: 'first_name',
      name: 'First Name',
      type: 'div',
      size: 1
    },
    {
      id: 'last_name',
      name: 'Last Name',
      type: 'div',
      size: 1
    },
    {
      id: 'user_email',
      name: 'Email',
      type: 'div',
      size: 1
    },
    {
      id: 'id',
      name: '',
      type: 'div',
      size: 1,
      right: true,
      valueFunction: (val, row) => {
        return (
          <>
            <button className="btn btn-outline-primary" onClick={() => chooseAccount(row)}>Info</button>
            <button className="btn btn-primary ms-3" onClick={() => accountLogin(row.wpid)}>Login</button>
          </>
        )
      }
    }
  ];

  const parentCols = [
    {
      id: 'id',
      name: 'Account',
      type: 'div',
      size: 3
    },
    {
      id: 'created',
      name: 'Created',
      type: 'div',
      size: 2,
      valueFunction: (val, row) => {
        return timestampToDate(val);
      }
    },
    {
      id: 'api_key',
      name: 'API Key',
      type: 'div',
      size: 3
    },
    {
      id: 'billing_email',
      name: 'Billing Email',
      type: 'div',
      size: 2
    }
  ];

  const accountCols = [
    {
      id: 'id',
      name: 'Account',
      type: 'div',
      size: 3
    },
    {
      id: 'created',
      name: 'Created',
      type: 'div',
      size: 2,
      valueFunction: (val, row) => {
        return timestampToDate(val);
      }
    },
    {
      id: 'api_key',
      name: 'API Key',
      type: 'div',
      size: 3
    },
    {
      id: 'id',
      name: '',
      type: 'div',
      right: true,
      size: 2,
      valueFunction: (val, row) => {
        return (
            <button className="btn btn-danger" onClick={() => maybeDeleteAccount(val)}>Delete</button>
        )
      }
    }
  ];

  const teamCols = [
    {
      id: 'id',
      name: 'User',
      type: 'div',
      size: 3
    },
    {
      id: 'created',
      name: 'Created',
      type: 'div',
      size: 2,
      valueFunction: (val, row) => {
        return timestampToDate(val);
      }
    },
    {
      id: 'user_email',
      name: 'Email',
      type: 'div',
      size: 3
    },
    {
      id: 'first_name',
      name: 'First',
      type: 'div',
      size: 2
    },
    {
      id: 'last_name',
      name: 'Last',
      type: 'div',
      size: 2
    },
    {
      id: 'permission',
      name: 'Permission',
      type: 'div',
      size: 2
    },
    {
      id: 'verified',
      name: 'Verified',
      type: 'div',
      size: 1
    }
  ];

  const usageCols = [
    {
      id: 'account',
      name: 'Account',
      type: 'div',
      size: 3
    },
    {
      id: 'user',
      name: 'User',
      type: 'div',
      size: 3
    },
    {
      id: 'allowance',
      name: 'Allowance',
      type: 'div',
      size: 2,
      valueFunction: (val, row) => {
        console.log('allowance', row, structure);
        const name = structure.reduce((acc, cur) => {
          if(cur.id === row.allowance){
            acc = cur.name;
          }

          return acc;
        }, '')
        return name;
      }
    },
    {
      id: 'type',
      name: 'Type',
      type: 'div',
      size: 1
    },
    {
      id: 'amount',
      name: 'Amount',
      type: 'div',
      size: 1
    },
    {
      id: 'peak',
      name: 'Peak',
      type: 'div',
      size: 1
    },
    {
      id: 'max',
      name: 'Max',
      type: 'div',
      size: 1
    },
  ];

  const connectedEmailCols = [
    {
      id: 'id',
      name: 'ID',
      type: 'div',
      size: 3,
      valueFunction: (val, row) => {
        return <span className="cursor-pointer text-primary" onClick={() => setConnectedEmail(val)}>{val}</span>
      }
    },
    {
      id: 'timestamp',
      name: 'Created',
      type: 'div',
      size: 2,
      valueFunction: (val, row) => {
        return timestampToDate(val);
      }
    },
    {
      id: 'email',
      name: 'Email',
      type: 'div',
      size: 3
    },
    {
      id: 'provider',
      name: 'Provider',
      type: 'div',
      size: 1
    },
    {
      id: 'offering',
      name: 'Offering',
      type: 'div',
      size: 2
    },
    {
      id: 'status',
      name: 'Status',
      type: 'div',
      size: 1
    },
    {
      id: 'active',
      name: 'Active',
      type: 'div',
      size: 1
    },
    {
      id: 'score',
      name: 'Score',
      type: 'div',
      size: 1
    },
    {
      id: 'score_status',
      name: 'Score Status',
      type: 'div',
      size: 2
    },
    {
      id: 'id',
      name: '',
      type: 'div',
      size: 'full',
      valueFunction: (val, row) => {
        if( val !== connectedEmail ) return null

        return (
          <div className="">
            <ConnectedEmail connectedEmailId={row.id} />
            <Button onClick={() => setConnectedEmail(false)} className="mt-3" variant="primary">Done</Button>
          </div>
        );
      }
    },
  ];

  console.log('fullUser', fullUser);

  return (
    <Section size="xxlg">
      <Card className="mb-3">
        <Card.Header as="h5">Load User</Card.Header>
        <Card.Body className="bg-light">
          <Row className="mb-3">
            <Col>
              <Form.Control
                type="text"
                placeholder="Search..."
                defaultValue={search}
                onChange={(e) => loadSearch(e.target.value)}
              />
              <div className="mt-5">
                <FilterTable data={ users } columns={ cols } />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {
        fullUser ? (
          <>
            <h3>Showing {userEmail}</h3>
            <Card className="mb-3">
              <Card.Header as="h5">Parent Account</Card.Header>
              <Card.Body className="bg-light">
                <Row className="mb-3">
                  <Col>
                    <FilterTable data={ fullUser.parents } columns={ parentCols } />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {
              fullUser.accounts.map(each => {
                return (
                  <>
                    <Card className="mb-3">
                      <Card.Header as="h5">Account</Card.Header>
                      <Card.Body className="bg-light">
                        <Row className="mb-3">
                          <Col>
                            <FilterTable data={ [each.account] } columns={ accountCols } />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <Card className="mb-3">
                      <Card.Header as="h5">Team</Card.Header>
                      <Card.Body className="bg-light">
                        <Row className="mb-3">
                          <Col>
                            <FilterTable data={ each.team } columns={ teamCols } />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <Card className="mb-3">
                      <Card.Header as="h5">Connected Emails</Card.Header>
                      <Card.Body className="bg-light">
                        <Row className="mb-3">
                          <Col>
                            <FilterTable data={ each.connected_emails } columns={ connectedEmailCols } />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </>
                )
              })
            }
            {
              usage ? (
                <Card className="mb-3">
                  <Card.Header as="h5">Usage</Card.Header>
                  <Card.Body className="bg-light">
                    <Row className="mb-3">
                      <Col>
                        <FilterTable data={ usage } columns={ usageCols } />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              ) : null
            }
          </>
        ) : null
      }
    </Section>
  );
};

UserInfo.propTypes = {};

export default UserInfo;
