import React, { useState } from 'react';
import { Nav, Card, Dropdown } from 'react-bootstrap';
import CustomNavbarItem from './CustomNavbarItem';
import CustomNavbarItemWrap from './CustomNavbarItemWrap';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

const CustomNavbarDropdown = ({
  route,
  roundedItem,
  navItemClass = 'text-700 text-900-hover p-2',
  navItemActiveClass,
  ...rest
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { pathname } = useLocation();

  const openCollapse = () => {
    let open = false;
    route.children.forEach(each => {
      if( each.to === pathname ) open = true;
    });

    return open;
  };

  const isActive = openCollapse();

  return (
    <Dropdown
      show={dropdownOpen}
      onToggle={() => setDropdownOpen(!dropdownOpen)}
      as="li"
    >
      <Dropdown.Toggle as="div" className={classNames('nav-link d-inline-block cursor-pointer', navItemClass, {
        rounded: roundedItem,
        [navItemActiveClass]: isActive
      })}>
        <div className="d-inline-block">
          <CustomNavbarItem
            route={route}
            {...rest}
          />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-card mt-0">
        {/* {children} */}
        <Card
          className={classNames('shadow-none dark__bg-1000', route.dropdownClasses !== undefined ? route.dropdownClasses : '')}
        >
          <Card.Body
            className={classNames('scrollbar max-h-dropdown', route.dropdownBodyClasses !== undefined ? route.dropdownBodyClasses : '')}
          >
            <Nav className="flex-column" as="ul">
              {
                route.children.map(each => <CustomNavbarItemWrap
                  route={each}
                  roundedItem={roundedItem}
                  navItemClass={navItemClass}
                  navItemActiveClass={navItemActiveClass}
                  orientation="horizontal"
                  {...rest}
                />)
              }
            </Nav>
          </Card.Body>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CustomNavbarDropdown;
