import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormInfo from './FormInfo';

const StatCard = ({
  icon,
  label,
  color = 'primary',
  iconColor = 'white',
  tooltip,
  groups = []
}) => {
  return (
    <>
      { label !== undefined ? (
        <h6 className={ `text-${color} text-uppercase` }>
          <span>{ label }</span>
          { tooltip !== undefined ? (
            <FormInfo>
              { tooltip }
            </FormInfo>
          ) : null }
        </h6>
      ): null }
      <div className="d-flex align-items-center stat-card bg-light p-3">
        { icon !== undefined ? (
          <div className={ `stat-card-icon me-3 text-center bg-${color} text-${iconColor}` }>
            <FontAwesomeIcon icon={icon} />
          </div>
        ) : null }
        <div className="width-100">
          <div className="d-flex">
            {
              groups.map(each => (
                <Col className="stat-card-group stat-card-number-group me-3">
                  { each.number !== undefined ? <div className="stat-card-group-number">{each.number}</div> : null }
                  { each.label !== undefined ? <div className="stat-card-group-label text-gray-400">{each.label}</div> : null }
                </Col>
                )
              )
            }
          </div>
        </div>
      </div>
    </>
  )
};

StatCard.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  iconColor: PropTypes.string,
  tooltip: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    number: PropTypes.oneOf(['number', 'string'])
  })).isRequired
};

export default StatCard;
