import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const Toggle = ({
    trueText,
    falseText,
    trueColor,
    falseColor,
    value,
    danger,
    size,
    className,
    id,
    update,
    numeric,
    register,
    setValue,
    defaultValue,
    registerName,
    star,
  ban,
  single
  }) => {

  if(numeric !== undefined) value = parseInt(value, 10);

  trueColor = value && trueColor !== undefined ? trueColor : (value ? 'primary' : 'default');
  falseColor = !value && falseColor !== undefined ? falseColor : (value ? 'default' : 'danger');

  let addClass = ['button-toggle'];
  let addInnerClass = ['button-toggle-inner'];
  let isText = false;

  if (trueText !== undefined && falseText !== undefined) {
    isText = true;
    addInnerClass = addInnerClass.concat('button-toggle-inner-text');
    trueText = value ? <span><FontAwesomeIcon className="mr-2" icon="checkCircle" />{trueText}</span> :
      <span><FontAwesomeIcon className="mr-2" icon="circle" />{trueText}</span>;
    falseText = value ? <span><FontAwesomeIcon className="mr-2" icon="circle" />{falseText}</span> :
      <span><FontAwesomeIcon className="mr-2" icon="checkCircle" />{falseText}</span>;
  } else {
    trueText = <FontAwesomeIcon icon="check" />;
    falseText = <FontAwesomeIcon icon="times" />;
  }

  let addClassAlt = classNames({
    ['button-toggle-inner-text']: isText,
    ['button-toggle-danger']: danger,
    [`button-toggle-${size}`]: size
  }, 'button-toggle', className);

  let addInnerClassAlt = classNames({
    ['button-toggle-true']: value,
    ['button-toggle-danger']: danger,
    [`button-toggle-${size}`]: size
  }, 'button-toggle', className);

  addClass = className !== undefined && className ? addClass.concat(className) : addClass;
  addClass = value ? addClass.concat('button-toggle-true') : addClass.concat('button-toggle-false');
  addClass = danger !== undefined && danger ? addClass.concat('button-toggle-danger') : addClass;
  addClass = size !== undefined && size ? addClass.concat('button-toggle-' + size) : addClass;
  addClass = addClass.join(' ');
  addInnerClass = addInnerClass.join(' ');

  let updateFunc = () => update(numeric !== undefined ? (value ? 0 : 1) : (value ? false : true));
  let select = null;

  if (register !== undefined && setValue !== undefined) {
    select = (
      <Form.Select
        className="d-none"
        defaultValue={defaultValue}
        {...register(registerName)}
      >
        <option value={numeric !== undefined ? 1 : true}>True</option>
        <option value={numeric !== undefined ? 0 : false}>False</option>
      </Form.Select>
    );
    updateFunc = () => {
      if( update ){
        update(numeric !== undefined ? (value ? 0 : 1) : !value);
      }
      setValue(registerName, numeric !== undefined ? (value ? 0 : 1) : !value);
    }
  }

  if (star !== undefined) {

    addClass = ['star-toggle'];
    addClass = className !== undefined && className ? addClass.concat(className) : addClass;
    addClass = value ? addClass.concat('star-toggle-true') : addClass.concat('star-toggle-false');
    addClass = size !== undefined && size ? addClass.concat('star-toggle-' + size) : addClass;
    addClass = addClass.join(' ');

    return (
      <div id={id !== undefined ? id : ''} class={addClass} onClick={
        () => update(
          numeric !== undefined
            ? (value === 1 ? 0 : 1)
            : !value
        )
      }>
        {
          value ? <FontAwesomeIcon className="text-warning" icon="star" /> :
            <FontAwesomeIcon className="text-warning" icon="star-o" />
        }
        {select}
      </div>
    )

  }

  if (ban !== undefined) {

    addClass = ['ban-toggle'];
    addClass = className !== undefined && className ? addClass.concat(className) : addClass;
    addClass = value ? addClass.concat('ban-toggle-true') : addClass.concat('ban-toggle-false');
    addClass = size !== undefined && size ? addClass.concat('ban-toggle-' + size) : addClass;
    addClass = addClass.join(' ');

    return (
      <div id={id !== undefined ? id : ''} class={addClass} onClick={
        () => update(
          numeric !== undefined
            ? (value === 1 ? 0 : 1)
            : !value
        )
      }>
        {
          value ? <FontAwesomeIcon className="text-danger" icon="ban" /> :
            <FontAwesomeIcon className="text-muted" icon="ban" />
        }
        {select}
      </div>
    )

  }

  if (single !== undefined) {

    addClass = ['single-toggle'];
    addClass = className !== undefined && className ? addClass.concat(className) : addClass;
    addClass = value ? addClass.concat('single-toggle-true') : addClass.concat('single-toggle-false');
    addClass = size !== undefined && size ? addClass.concat('single-toggle-' + size) : addClass;
    addClass = addClass.join(' ');

    return (
      <div id={id !== undefined ? id : ''} class={addClass} onClick={
        () => update(
          numeric !== undefined
            ? (value === 1 ? 0 : 1)
            : !value
        )
      }>
        {
          value ? <FontAwesomeIcon icon="check" /> : <FontAwesomeIcon icon="minus" />
        }
        {select}
      </div>
    )

  }

  return (
    <>
      <ButtonGroup id={id !== undefined ? id : ''} className={`d-block ${addClass}`}>
        <Button variant={falseColor} onClick={updateFunc}><span class={addInnerClass}>{falseText}</span></Button>
        <Button variant={trueColor} onClick={updateFunc}><span class={addInnerClass}>{trueText}</span></Button>
      </ButtonGroup>
      {select}
    </>
  );
}

export default Toggle;

Toggle.propTypes = {
  trueText: PropTypes.string,
  falseText: PropTypes.string,
  trueColor: PropTypes.string,
  falseColor: PropTypes.string,
  value: PropTypes.string,
  danger: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  update: PropTypes.func,
  numeric: PropTypes.bool,
  register: PropTypes.func,
  setValue: PropTypes.func,
  defaultValue: PropTypes.string,
  registerName: PropTypes.string,
  ban: PropTypes.bool,
  star: PropTypes.bool,
  single: PropTypes.bool
};