import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Form, Row, Col } from 'react-bootstrap';
import Divider from 'components/common/Divider';
import SignInWithGoogle from './SignInWithGoogle';
import Api from 'api/api';
import { errorMessage, getURLParameter, successMessage } from 'helpers/utils';

const LoginForm = ({ hasLabel, layout }) => {
  const [error, setError] = useState('');

  // State
  const [formData, setFormData] = useState({
    email: getURLParameter('rplogin'),
    password: '',
    remember: false
  });

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    if(!formData.email){
      errorMessage('Please enter an email address');
    }

    if(!formData.password){
      errorMessage('Please enter a password');
    }

    Api.doRequest('accounts', 'login', 'update', formData).then(result => {
      successMessage(`Successfully logged in as ${formData.email}`);
      window.location.href = '/' + window.location.search;
    }).catch(error => {
      errorMessage(error.message);
      setError(error.message);
    });

  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <SignInWithGoogle />
      <Divider className="mt-4 mb-4">OR</Divider>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>Email address</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Email address' : ''}
            value={formData.email}
            name="email"
            onChange={handleFieldChange}
            type="email"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Password' : ''}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>

        <Row className="justify-content-between align-items-center">
          <Col xs="auto">
            <Form.Check type="checkbox" id="rememberMe">
              <Form.Check.Input
                type="checkbox"
                name="remember"
                checked={formData.remember}
                onChange={e =>
                  setFormData({
                    ...formData,
                    remember: e.target.checked
                  })
                }
              />
              <Form.Check.Label className="ms-2 mb-0">
                Remember Me
              </Form.Check.Label>
            </Form.Check>
          </Col>

          <Col xs="auto">
            <Link
              className="fs--1 mb-0"
              to={`/forgot-password`}
            >
              Forgot Your Password?
            </Link>
          </Col>
        </Row>

        <Form.Group>
          <Button
            type="submit"
            color="primary"
            className="mt-3 w-100"
            disabled={!formData.email || !formData.password}
          >
            Log in
          </Button>
        </Form.Group>
        { error ? <div className="text-center mt-2"><span className="badge badge-soft-danger animate fadeIn">{error}</span></div> : null }
      </Form>
    </>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
