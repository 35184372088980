import React, {useContext, useState, useEffect} from 'react';
import { Col, Row } from 'react-bootstrap';
import { isSuper, timestampToDate } from '../../helpers/utils';
import { AccountContext } from '../../context/Context';
import Api from '../../api/api';

const Footer = () => {
  const { accountState } = useContext(AccountContext);
  const connectedEmail = accountState?.connected_email !== undefined ? accountState.connected_email : {
    id: '',
    email: '',
    provider: '',
    status: '',
    active: '',
    offering: ''
  };

  const [cycles, setCycles] = useState([]);
  const [cycle, setCycle] = useState({
    id: '',
    start: '',
    next: '',
    number: '',
    sent: '',
    limit: '',
    conversations: '',
    conversations_commitment: '',
    conversations_limit: '',
    google: '',
    microsoft: '',
    zoho: '',
    other: ''
  });

  if( isSuper() ){
    useEffect(() => {
      Api.doRequest('cycle', 'get', 'get', null, {params:[{param: 'id', value: connectedEmail.id}, {param: 'complete', value: 0}]}).then(result => {
        setCycles(result?.data);
        if(result?.data[0] !== undefined){
          setCycle(result.data[0]);
        }
      }).catch(error => {

      });
    }, []);
  }

  return (
    <footer className="footer">
      <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
        <Col sm="auto">
          <p className="mb-0 text-600" />
          { isSuper() ? (
            <>
              <div className="position-fixed super-info width-100 bg-white bottom-0 left-0 border-top">
                <div className="d-flex justify-content-between p-1">
                  <span><strong>Account:</strong> {window.SSSettings.account}</span>
                  <span><strong>Parent:</strong> {window.SSSettings.parent}</span>
                  <span><strong>Connected Email:</strong> {connectedEmail.id}</span>
                  <span><strong>Provider:</strong> {connectedEmail.provider}</span>
                  <span><strong>Offering:</strong> {connectedEmail.offering}</span>
                  <span><strong>Status:</strong> {connectedEmail.status}</span>
                  <span><strong>Active:</strong> {connectedEmail.active}</span>
                </div>
                {
                  cycle.id ? (
                    <div className="d-flex justify-content-between p-1">
                      <span><strong>id:</strong> {cycle.id}</span>
                      <span><strong>start:</strong> {timestampToDate(cycle.start, false, 'YYYY-MM-DD HH:mm')}</span>
                      <span><strong>next:</strong> {timestampToDate(cycle.next, false, 'YYYY-MM-DD HH:mm')}</span>
                      <span><strong>number:</strong> {cycle.number}</span>
                      <span><strong>sent:</strong> {cycle.sent}</span>
                      <span><strong>limit:</strong> {cycle.limit}</span>
                      <span><strong>conversations:</strong> {cycle.conversations}</span>
                      <span><strong>conv_commitment:</strong> {cycle.conversations_commitment}</span>
                      <span><strong>conv_limit:</strong> {cycle.conversations_limit}</span>
                      <span><strong>google:</strong> {cycle.google}</span>
                      <span><strong>microsoft:</strong> {cycle.microsoft}</span>
                      <span><strong>zoho:</strong> {cycle.zoho}</span>
                      <span><strong>other:</strong> {cycle.other}</span>
                    </div>
                  ) : null
                }
              </div>
            </>
          ) : null }
        </Col>
      </Row>
    </footer>
  );
}

export default Footer;
