import React from 'react';

const PlatformNotice = () => {
  return window.SSSettings.migrationWindowInterface ? (
    <>
      <div className="fade modal-backdrop show" />
      <div role="dialog" aria-modal="true" className="fade modal show" tabIndex="-1"
           aria-labelledby="contained-modal-title-vcenter" style={{ display: 'block', paddingRight: '15px' }}>
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4" id="contained-modal-title-vcenter">{ window.SSSettings.migrationWindowHeader }</div>
            </div>
            <div className="modal-body">
              <p>
                { window.SSSettings.migrationWindowMessage }
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null
}

export default PlatformNotice;
