import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import ConnectForm from './ConnectForm';
import Checklist from '../../common/Checklist';
import { errorMessage, getExternalLinks, successMessage, isSuper, isEmail, ucFirst } from 'helpers/utils';
import API from '../../../api/api';
import { toast } from 'react-toastify';
import LoaderButton from '../../common/LoaderButton';
import microsoftAuth from 'assets/img/email/microsoft-oauth.svg';
import TooltipItem from '../../common/TooltipItem';

const MicrosoftConnect = ({
  titleOverwrite,
  connection,
  saving,
  setSaving,
  provider,
  offering,
  email = '',
  reconnect = 0,
  ...rest
}) => {
  const [customSMTPActive, setCustomSMTPActive] = useState(!!(connection.connection.smtp?.gateway !== undefined && connection.connection.smtp.gateway));

  const [mAdmin, mUsers] = getExternalLinks([
    {
      href: 'https://admin.microsoft.com/',
      anchor: 'Microsoft 365 admin center',
      strong: true
    },
    {
      href: 'https://admin.microsoft.com/Adminportal/Home#/users',
      anchor: 'Active Users',
      strong: true
    }
  ]);
  let checklist = [
    <span>Open the {mAdmin} and go to Users > {mUsers}.</span>,
    <>Select the user</>,
    <>In the flyout that appears, click Mail</>,
    <>In the Email apps section, click Manage email apps</>,
    <>Check the box next to Authenticated SMTP</>,
    <>When you're finished, click Save changes</>,
    <>Complete this form and click "Sign in with Microsoft"</>,
    <>You may be presented with a screen to authorize access and must click "Yes"</>
  ];

  if( offering === 'outlook' ){
    checklist = [
      <>Complete this form and click "Sign in with Microsoft"</>,
      <>You may be presented with a screen to authorize access and must click "Yes"</>
    ]
  }

  const onSubmit = () => {
    if( ! email && ( ! connection.email || ! isEmail(connection.email) ) ){
      errorMessage('Must include a valid email address in the "Email" field');
      return;
    }

    if( customSMTPActive && ! email && (connection.connection.imap?.email === undefined || ! connection.connection.imap.email || ! isEmail(connection.connection.imap.email)) ){
      errorMessage('Must include a valid ' + ucFirst(offering) + ' email address as the IMAP Email value');
      return;
    }

    let update = {
      ...connection,
      provider,
      offering,
      customSMTPActive,
      reconnect
    };

    if( email ){
      if( customSMTPActive ){
        update.connection = {
          ...update.connection,
          imap: {
            email: email
          }
        }
      } else {
        update.email = email;
      }
    }

    setSaving(true);

    API.doRequest('connected_emails', 'oauth', 'create', {update}).then(result => {
      successMessage('Email is connecting!');
      setSaving(false);
      //result is a url to authenticate
      window.location.href = result.data;
    }).catch(error => {
      errorMessage(false, error);
      setSaving(false);
    })
  }

  const submit = (
    <div className="text-center">
      <TooltipItem tooltip="Make sure you are currently logged into the Microsoft account that you're trying to connect.">
        <LoaderButton onClick={onSubmit} variant="link" className="w-100 mt-3 d-inline p-0" loading={saving} loaderVariant="dark">
          <img src={microsoftAuth} alt="microsoft-oauth" />
        </LoaderButton>
      </TooltipItem>
    </div>
  )

  return (
    <>
      <h4>{ titleOverwrite ? titleOverwrite : 'How to Connect' }</h4>
      <Row className="provider-connect my-4">
        <Col className="provider-instructions mb-3" md={6}>
          <Checklist checklist={checklist} />
        </Col>
        <Col className="provider-connection mb-3" md={6}>
          <ConnectForm
            configOverwrite={{
              password: {label: 'Login Password', show: false},
              email: {tooltip: customSMTPActive ? 'The email address of the custom SMTP email that you are warming up' : ''},
              imapEmail: {tooltip: 'The email address of your Microsoft inbox', label: ucFirst(offering) + ' Email'}
              //imapLabel: {show: true}
            }}
            showCustomSMTP={!!isSuper() || window.SSSettings?.account === 'acc637875d93a0fb263628909'}
            customSMTPActive={customSMTPActive}
            setCustomSMTPActive={setCustomSMTPActive}
            customSMTPTooltip="If you want to use a Microsoft inbox with a custom SMTP sender, like Sendgrid, use this option. Otherwise leave it disabled to warm up your Microsoft email."
            connection={connection}
            saving={saving}
            email={email}
            {...rest}
            buttonOverwrite={submit}
          />
        </Col>
      </Row>
    </>
  );
}

MicrosoftConnect.propTypes = {
  connection: PropTypes.object.isRequired,
  updateConnection: PropTypes.func.isRequired,
  saveConnection: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired
};

export default MicrosoftConnect;
