import React, { useState, useEffect, useContext } from 'react';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import Section from 'components/common/Sec';
import { AppContext } from "context/Context";
import API from 'api/api';
import FormInfo from '../components/common/FormInfo';
import Toggle from '../components/common/Toggle';
import { errorMessage, successMessage } from '../helpers/utils';

const Reconnect = () => {
  const { appConfirm } = useContext(AppContext);

  const [page, setPage] = useState(1);
  const [results, setResults] = useState([]);
  const limit = 1;

  const reconnect = (thePage, theResults) => {
    if( thePage === undefined ) thePage = page;
    if( theResults === undefined ) theResults = [];

    const offset = (thePage - 1) * limit;

    let params = [
      { param: 'super', value: 'platform' },
      { param: 'limit', value: limit },
      { param: 'offset', value: offset }
    ];

    console.log({page, thePage, limit, offset});

    API.doRequest('super', 'reconnect_all', 'get', null, {params}).then(result => {
      console.log(result);
      theResults = theResults.concat({...result.data, page: thePage});
      setResults(theResults)
      setPage(thePage + 1);

      if( results.length >= 500 ){
        alert('done with batch');
      } else {
        reconnect(thePage + 1, theResults);
      }

    }).catch(error => {
      console.log(error);
      alert('done');
    });
  };

  return (
    <Section size="xxlg">
      <Card className="mb-3">
        <Card.Header as="h5">Reconnect Emails</Card.Header>
        <Card.Body className="bg-light">
          <Row>
            <Col>
              <Form.Group>
                <Form.Label for="sendCronLimit">
                  Set Page
                </Form.Label>
                <div className="mt-5">
                  <Form.Control
                    placeholder={1}
                    value={page}
                    name="page"
                    onChange={(e) => setPage(e.target.value)}
                    type="number"
                  />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label for="sendCronLimit">
                  Reconnect all emails
                  <FormInfo id="sendCronLimit">
                    If there is a backlog of submit webhooks, or Send cron requests are timing out, we can decrease this. 20 should be the default
                  </FormInfo>
                </Form.Label>
                <div className="mt-5">
                  <Button variant="primary" onClick={() => reconnect(page, results)}>Start Reconnecting</Button>
                </div>
                <div>
                  <h3>Results</h3>
                  <table className="table">
                    {
                      results.map(each => {
                        return (<tr>
                          <td>{each.page}</td>
                          <td>{each.id}</td>
                          <td>{each.email}</td>
                          <td>{each.success ? 'yes' : 'no'}</td>
                        </tr>)
                      })
                    }
                  </table>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Section>
  );
};

Reconnect.propTypes = {};

export default Reconnect;