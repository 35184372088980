import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';

const CustomNavbarItem = ({
  route
}) => {
  return (
    <Flex alignItems="center">
      {route.icon && (
        <span className="nav-link-icon">
          <FontAwesomeIcon icon={route.icon} />
        </span>
      )}
      <span className="nav-link-text">{route.name}</span>
      {route.badge !== undefined && (
        <SoftBadge pill={route.badge.pill} bg={route.badge.bg} className={classNames('ms-auto', route.badge.className)}>
          {route.badge.value}
        </SoftBadge>
      )}
    </Flex>
  );
};

// prop-types
const badgeShape = {
  pill: PropTypes.bool,
  bg: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.value
}

const routeShape = {
  active: PropTypes.bool,
  value: PropTypes.string.isRequired,
  to: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  badge: PropTypes.shape(badgeShape),
};
routeShape.children = PropTypes.arrayOf(PropTypes.shape(routeShape));

CustomNavbarItem.propTypes = {
  route: PropTypes.shape(routeShape).isRequired,
};

export default React.memo(CustomNavbarItem);
