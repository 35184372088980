import React from 'react';
import PropTypes from 'prop-types';
import AppProvider from 'providers/AppProvider';

const Main = props => {
  return (
    <AppProvider>
      {props.children}
    </AppProvider>
  );
};

Main.propTypes = { children: PropTypes.node };

export default Main;
