import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const IconCircle = ({
  variant,
  circleSize = 'md',
  circleClass = '',
  ...rest
}) => {
  return (
    <div className={`badge-soft-${variant} icon-circle icon-circle-${circleSize} ${circleClass}`}>
      <FontAwesomeIcon {...rest} />
    </div>
  );
};

IconCircle.propTypes = {
  variant: PropTypes.string,
  circleSize: PropTypes.string
};

export default IconCircle;
