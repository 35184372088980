import API from '../../api/api';
import { generateId } from '../../helpers/utils';

export const loadPlansStructure = (updateRedux, updateState) => {
  return new Promise((resolve, reject) => {
    API.doRequest('plans_structure').then(result => {
      console.log('plans structure result', result);

      if( result.data?.length ){
        const items = result.data.map(each => {
          return {
            ...each,
            open: false,
            uniqueId: generateId('struc')
          }
        });

        updateRedux({
          type: 'SET_PLANS_STRUCTURE',
          payload: items
        });
        updateState(items);
        resolve(items);
      }

    }).catch(error => {
      reject(error);
    });
  });
};

export const loadPlans = (updateRedux, updateState) => {
  return new Promise((resolve, reject) => {
    API.doRequest('plans', 'get_all').then(result => {
      console.log('plans result', result);

      if( result.data?.length ){
        const items = result.data.map(each => {
          return {
            ...each,
            open: false,
            uniqueId: generateId('plan')
          }
        });

        updateRedux({
          type: 'SET_PLANS',
          payload: items
        });
        updateState(items);
        resolve(items);
      }

    }).catch(error => {
      reject(error);
    });
  });
}

export const loadPlansAccounts = (updateRedux, updateState, params = []) => {
  return new Promise((resolve, reject) => {
    API.doRequest('plans_accounts', 'get', 'get', null, {params}).then(result => {
      console.log('plans accounts result', result);

      if( result.data?.length ){
        updateRedux({
          type: 'SET_PLANS_ACCOUNTS',
          payload: result.data
        });
        updateState(result.data);
        resolve(result.data);
      }

    }).catch(error => {
      reject(error);
    });
  });
}

export const loadUsage = (updateRedux, updateState, params = []) => {
  return new Promise((resolve, reject) => {
    API.doRequest('usage', 'get', 'get', null, {params}).then(result => {
      console.log('usage result', result);

      if( result.data?.length ){
        updateRedux({
          type: 'SET_PLANS_ACCOUNTS',
          payload: result.data
        });
        updateState(result.data);
        resolve(result.data);
      }

    }).catch(error => {
      reject(error);
    });
  });
}

export const loadCalculateFunctions = (updateState) => {
  return new Promise((resolve, reject) => {
    API.doRequest('super', 'calculation_functions', 'get', null, {
      params: [
        { param: 'super', value: 'usage' }
      ]
    }).then(result => {
      if( result.data?.length ){
        updateState(result.data);
        resolve(result.data);
      }

    }).catch(error => {
      reject(error);
    });
  });
}