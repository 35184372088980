import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { arrayUnique, ucFirst } from '../../helpers/utils';
import StatusDropdown from './StatusDropdown.js';
import MultiSelectDropdown from './MultiSelectDropdown.js';
import TooltipItem from './TooltipItem.js';
import Pagination from './Pagination.js';

import DateRangePicker from '@wojtekmaj/react-daterange-picker'
//import 'react-daterange-picker/dist/css/react-calendar.css'
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import FormInfo from './FormInfo';
const moment = extendMoment(originalMoment);

const FilterTable = ({
  data,
  columns,
  idCol = 'id',
  content = false,
  cutoff,
  pagination,
  version = 1,
  stats = [],
  groups,
  groupby,
  hasSearch = false,
  onSearchUpdate,
  shown
}) => {

  const numCol = columns.length;

  const colWidth = columns.reduce((acc, each) => acc + (
          each.minWidth !== undefined && each.minWidth
            ? 0
            : (
              each.size !== undefined && each.size !== 'full'
                ? parseInt( each.size, 10 )
                : ( each.size !== 'full' ? 1 : 0 )
            )
      ),
    0 );

  const colMin = columns
    .reduce((acc, each) => acc + (
      each.minWidth !== undefined
        ? parseInt( each.minWidth, 10 )
        : 0
    ), 0 ) + ( content ? 50 : 0 );

  const hasContent = !!content;

  const searchFilters = columns.filter(each => each.searchFilter !== undefined && each.searchFilter ).map(each => each.id);

  const generateFilters = () => {
    const cols = columns.reduce((acc, each) => each.filterable !== undefined && each.filterable ? acc.concat({
      id: each.id !== undefined ? each.id : '',
      name: each.name !== undefined ? each.name : '',
      type: each.filterable, //date, multi
      full: each
    }) : acc, []);

    return cols.map((each) => {
      let items = data.reduce((acc, row) => {
        return ! acc.includes( row[each.id] ) ? acc.concat( row[each.id] ) : acc;
      }, []);

      if(each.full.filterDefaults !== undefined && each.full.filterDefaults.length){
        items = items.concat(each.full.filterDefaults);
        items = arrayUnique(items);
      }

      return {
        id: each.id,
        name: each.name,
        type: each.type,
        placeholder: each.name,
        itemNameFunc: each.full.itemNameFunc !== undefined && each.full.itemNameFunc ? each.full.itemNameFunc : false,
        hideFromAll: each.full.hideFromAll !== undefined && each.full.hideFromAll ? each.full.hideFromAll : false,
        onFilterUpdate: each.full.onFilterUpdate !== undefined && each.full.onFilterUpdate ? each.full.onFilterUpdate: false,
        filterDefaultValue: each.full.filterDefaultValue !== undefined && each.full.filterDefaultValue ? each.full.filterDefaultValue: '',
        items: items
      };
    });
  }

  const [contentOpen, setContentOpen] = useState(false);
  const [filters, setFilters] = useState(generateFilters());
  const [activeFilters, setActiveFilters] = useState({});
  const [showAll, setShowAll] = useState(!!cutoff);
  const [show, setShow] = useState(!!cutoff);
  const [showGroup, setShowGroup] = useState({});
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(pagination?.limit !== undefined ? pagination.limit : 25);

  const updateLimit = (limit) => {
    setLimit(limit);
    setPage(1);
  }

  const getPagination = (items, margin) => {
    if( pagination === undefined ) return null;

    return <Pagination
      items={ items }
      limit={ pagination.limit !== undefined ? pagination.limit : 25 }
      page={ page }
      currentLimit={ limit }
      updatePage={ (page) => setPage(page) }
      updateLimit={ (limit) => updateLimit(limit) }
      margin={ margin }
      displayGroups={ pagination.displayGroups !== undefined ? pagination.displayGroups : [pagination.limit !== undefined ? pagination.limit : 25,100,250,500] }
    />;
  }

  const getColWidth = (column) => {
    if( column.size === 'full' ) return '100%';

    const size = ( column || column === 0 ) && column.size !== undefined ? parseInt( column.size, 10 ) : 1;
    let width = ( size / colWidth * 100 ) + '%';

    if( colMin ){
      if( column.minWidth !== undefined && column.minWidth ){
        width = column.minWidth + 'px'
      } else {
        let px = ( colMin * size / colWidth ) + 'px';
        width = `calc(${width} - ${px})`;
      }
    }

    return width;
  }

  const tableColumn = (column, value, row) => {
    if( column.size === 0 ) return null;

    const type = column && column.type !== undefined && column.type ? column.type : 'div';
    let className = column && column.className !== undefined ? column.className : '';
    const theValue = column && column.valueFunction !== undefined ? column.valueFunction(value, row) : value;
    const width = getColWidth(column);

    let style = {width: width};

    if( theValue === null && column.size === 'full' ) return null;

    if( column.right !== undefined && column.right ) className += ' d-inline-flex justify-content-end';

    return React.createElement(
      type,
      {
        children: theValue,
        className: 'filter-table-column p-2 ' + className,
        style: style
      }
    )
  }

  const tableContent = (row) => {
    if( row[idCol] === undefined || contentOpen !== row[idCol] ) return null;

    return (
      <div className="filter-table-content bg-white p-4 animated fadeInLeft">
        { content( row ) }
      </div>
    )
  }

  const tableRow = (item) => {
    let open = '';

    if( hasContent ) open += ' open';

    const id = idCol !== undefined && item[idCol] !== undefined ? item[idCol] : (item.id !== undefined ? item.id : '');

    return (
      <div className={ `filter-table-row${open}` } id={ id ? `id-${id}` : null }>
        { hasContent
          ? (
            <TooltipItem tooltip={ contentOpen === id ? 'Hide' : 'Show' } id={ `open-fit-${id}` }>
              <span id={ `open-fit-${id}` } className="filter-table-open bg-info" onClick={ () => setContentOpen(contentOpen === id ? false : id) }>
                  { contentOpen === id ? <i className="fa fa-minus" /> : <i className="fa fa-plus" /> }
              </span>
            </TooltipItem>
          ) : null }
        { columns.map(each => tableColumn( each, item[each.id], item ) ) }
        { tableContent(item) }
      </div>
    )
  }

  const tableHeaderColumn = (column, columnId) => {
    if( column.size === 0 ) return null;
    if( column.size === 'full' ) return null;

    let style = {width: getColWidth(column)};

    if( column.minWidth !== undefined && column.minWidth ) style.minWidth = column.minWidth;

    const id = 'ft-col-' + ( column.name !== undefined ? ucFirst(column.name.replace(/\s/g, '-')) : columnId );

    const columnName = column.name !== undefined ? column.name : ucFirst(columnId.replace('_', ' '));
    let columnChildren = columnName;

    if( column.tooltip !== undefined ){
      columnChildren = <>{columnName} <FormInfo>{column.tooltip}</FormInfo></>
    }

    const colHead = React.createElement(
      'div',
      {
        id: id,
        children: columnChildren,
        className: 'filter-table-column filter-table-header-column p-2',
        style: style
      }
    );

    return colHead;
  }

  const tableHeaderRow = (cols) => {
    return (
      <div className="filter-table-row filter-table-header-row">
        { hasContent ? <span className="filter-table-column filter-table-header-column p-2 filter-table-open" /> : null }
        { cols.map(each => tableHeaderColumn( each, each.id ) ) }
      </div>
    )
  }

  const runFilters = (data, group = false) => {

    Object.keys(activeFilters).forEach(each => {
      if( typeof activeFilters[each] === 'object' ){
        //It's a multi filter
        //Get all of the active values
        const active = Object.keys(activeFilters[each]).filter(key => activeFilters[each][key] === true);

        //See if the active values include the value of the row's column
        if( active.length ){
          data = data.filter(d => active.includes(d[each]));
        }

        //It's a date range
        if( ! active.length && activeFilters[each].start !== undefined && activeFilters[each].end !== undefined ){
          const dateFilter = Object.assign({}, activeFilters[each]);
          data = data.filter(d => d[each] > dateFilter.start.unix() && d[each] < dateFilter.end.unix() )
        }
      } else {
        //It's a single value filter
        if( activeFilters[each] !== '' ){
          data = data.filter(d => d[each] === activeFilters[each]);
        }
      }
    });

    if( search && searchFilters.length ){
      data = data.filter(d => {
        let includesSearch = false;
        searchFilters.forEach(sf => {
          if( d[sf].toLowerCase().includes(search.toLowerCase()) ) includesSearch = true;
        });
        return includesSearch;
      })
    }

    //Hide values that shouldn't be shown in "All" view
    filters.map(each => {
      if( each.hideFromAll.length ){
        if(
          activeFilters[each.id] === undefined
          || ! activeFilters[each.id]
          || ( typeof activeFilters[each.id] === 'object' && ! Object.keys(activeFilters[each.id]).filter(key => activeFilters[each.id][key] === true).length )
        ){
          data = data.filter(d => ! each.hideFromAll.includes(d[each.id]));
        }
      }
    });

    return data;
  }

  const runShowAll = (data, group = false) => {
    if( ! showAll ){
      if( group && ( showGroup[group] === undefined || ! showGroup[group] ) ){
        data = data.slice(0, cutoff);
      }
      if( ! group && ! show ){
        data = data.slice(0, cutoff);
      }
    }

    return data;
  }

  const updateSearch = (value) => {
    setSearch(value);
    if( onSearchUpdate !== undefined ){
      onSearchUpdate(value);
    }
  }

  const updateFilter = (filter, value) => {
    setActiveFilters({
      ...activeFilters,
      [filter]: value
    });

    const theFilter = filters.find(each => each.id === filter);

    if( theFilter?.onFilterUpdate !== undefined ){
      theFilter.onFilterUpdate(value);
    }
  }

  const updateFilterMulti = (filter, item, value) => {
    setActiveFilters({
      ...activeFilters,
      [filter]: {
        ...activeFilters[filter],
        [item]: value
      }
    });

    const theFilter = filters.find(each => each.id === filter);

    if( theFilter?.onFilterUpdate !== undefined ){
      theFilter.onFilterUpdate({
        ...activeFilters[filter],
        [item]: value
      });
    }
  }

  const toggleDateFilter = (filter, value = false) => {
    setFilters(filters.map(each => {
      if( each.id === filter.id ){
        if( value !== false ){
          each.open = value === 1;
        } else {
          each.open = each.open !== undefined ? ! each.open : true;
        }
      }
      return each;
    }))
  }

  const closeDateFilter = (filter) => {
    toggleDateFilter(filter, 0);
    setTimeout(() => {
      updateFilter(filter.id, '');
    }, 200);
  }


  const getSearchFilter = (filter) => {
    return (
      <div className="me-3">
        <Form.Control
          type="text"
          placeholder={ filter.placeholder }
          defaultValue={ '' }
          onChange={(e) => updateFilter(filter.id, e.target.value)}
        />
      </div>
    );
  }

  const getMultiFilter = (filter) => {
    const options = filter.items.map(e => {
      return {
        name: filter.itemNameFunc ? filter.itemNameFunc(e) : ( typeof e === 'string' ? ucFirst(e) : e ),
        color: 'info-3',
        icon: '',
        value: e
      }
    });

    return (
      <div className="me-3">
        <MultiSelectDropdown name={ name } options={ options } update={ (prop, val) => updateFilterMulti(filter.id, prop, val) } />
      </div>
    );
  }

  const getDateFilter = (filter) => {
    let dateFilterColor = activeFilters[filter.id] !== undefined && activeFilters[filter.id] ? 'info-1' : 'info-3';

    return (
      <div className="me-3">
        <div className="dropdown">
              <span data-toggle="dropdown" aria-expanded="false" aria-haspopup="true" class="">
                <div className={ `crm-status ${dateFilterColor} type-user d-flex justify-content-between align-items-center` }>
                  <span style={{width: '100%'}} onClick={ () => toggleDateFilter(filter) }>
                      { activeFilters[filter.id] !== undefined && activeFilters[filter.id] ? activeFilters[filter.id].start.format('YYYY-MM-DD') + ' - ' + activeFilters[filter.id].end.format('YYYY-MM-DD') : 'Date Range' }
                  </span>
                  <i className="fa fa-remove ms-auto" onClick={ () => closeDateFilter(filter) } />
                </div>
              </span>
        </div>
        {
          filter.open !== undefined && filter.open ? (
            <div className="date-range-dropdown">
              <div className="date-range-quick d-flex">
                <div className="date-range-quick-button" onClick={ () => updateFilter( filter.id, moment.range(moment().clone().subtract(30, "days"), moment().clone()) )}>Last 30</div>
                <div className="date-range-quick-button" onClick={ () => updateFilter( filter.id, moment.range(moment().clone().subtract(60, "days"), moment().subtract(30, "days").clone()) )}>Prev 30</div>
                <div className="date-range-quick-button" onClick={ () => updateFilter( filter.id, moment.range(moment().clone().subtract(90, "days"), moment().clone()) )}>Last 90</div>
                <div className="date-range-quick-button" onClick={ () => updateFilter( filter.id, moment.range(moment().clone().subtract(180, "days"), moment().subtract(30, "days").clone()) )}>Prev 90</div>
              </div>
              <DateRangePicker
                onSelect={ (dates) => updateFilter(filter.id, dates) }
                value={ activeFilters[filter.id] !== undefined ? activeFilters[filter.id] : moment.range(moment().clone().subtract(7, "days"), moment().clone()) }
              />
              <div className="date-range-close text-center text-primary p-2 cursor-pointer" onClick={() => toggleDateFilter(filter)}>
                Close
              </div>
            </div>
          ) : null
        }
      </div>
    )
  }

  const getFilters = () => {
    return filters.map(each => {
      const name = each.name !== undefined && each.name ? ucFirst(each.name.replace('_', ' ')) : ucFirst(each.id.replace('_', ' '));

      switch( each.type ){
        case 'search':
          return getSearchFilter(each);
        case 'multi':
          return getMultiFilter(each);
        case 'date':
          return getDateFilter(each);
        default:
          const statuses = each.items.map(e => {
            e = typeof e === 'string' ? e : ( typeof e === 'number' ? e : '' );

            return {
              name: each.itemNameFunc ? each.itemNameFunc(e) : ( typeof e === 'string' ? ucFirst(e) : e ),
              color: 'soft-info',
              icon: '',
              value: e
            }
          });

          return (
            <div className="me-3">
              <StatusDropdown value={ activeFilters[each.id] !== undefined ? activeFilters[each.id] : (each.filterDefaultValue !== undefined ? each.filterDefaultValue : false) } statuses={ statuses } update={ (val) => updateFilter(each.id, val) } all={ 1 } allName={ `${name} (All)` } type={ each.id } />
            </div>
          )
      }
    })
  }

  const getSearch = () => {
    return (
      <div className="me-3">
        <Form.Control className="filter-input" type="text" placeholder="Search..." value={ search } onChange={ (e) => updateSearch(e.target.value) } />
      </div>
    )
  }

  const getGroups = () => {
    return groups.map(each => {
      let items = data.filter(e => e[groupby] === each.type);

      //const originalCount = items.length;
      items = runFilters(items, each.type);
      const filteredCount = items.length;
      items = runShowAll(items, each.type);

      if( items === undefined || ! items.length ) return null;

      return (
        <div className="mb-5">
          <h4>{ each.name !== undefined ? each.name : '' }</h4>
          <p>{ each.description }</p>
          {
            getRegular(items, each.type)
          }
          {
            ! showAll && filteredCount > cutoff && ( showGroup[each.type] === undefined || ! showGroup[each.type] )
              ? <div className="btn btn-text text-primary mt-2" onClick={ () => setShowGroup({...showGroup, [each.type]: true}) }>Show all {filteredCount} items...</div>
              : null
          }
          {
            ! showAll && filteredCount > cutoff && showGroup[each.type] !== undefined && showGroup[each.type]
              ? <div className="btn btn-text text-primary mt-2" onClick={ () => setShowGroup({...showGroup, [each.type]: false}) }>Hide {filteredCount - cutoff} items...</div>
              : null
          }
        </div>
      )
    })
  }

  const getRegular = (data) => {
    const filtered = runShowAll(data);

    return (
      <div className="filter-table-rows">
        { tableHeaderRow(columns) }
        {
          filtered.map(each => idCol
            ? <div key={each[idCol]}>{ tableRow(each) }</div>
            : ( each.id !== undefined ? <div key={each.id}>{ tableRow(each) }</div> : tableRow(each) )
          )
        }
        {
          ! showAll && data.length > cutoff
            ? <div className="btn btn-text text-primary mt-2" onClick={ () => setShowAll(true) }>Show all {data.length} items...</div>
            : null
        }
        {
          showAll && data.length > cutoff
            ? <div className="btn btn-text text-primary mt-2" onClick={ () => setShowAll(false) }>Hide {data.length - cutoff} items...</div>
            : null
        }
      </div>
    )
  }

  const getStats = (data) => {
    return stats.map(each => {
      let reduceStart = each.reduceStart;

      if( typeof each.reduceStart === 'object' ){
        reduceStart = Object.assign({}, each.reduceStart);
      } else if( typeof each.reduceStart === 'number' ){
        reduceStart = 0 + each.reduceStart;
      }

      const total = data.reduce(each.reduceFunction, reduceStart);

      return each.valueFunction( total );

    })
  }

  useEffect(() => {
    setFilters(generateFilters())
  }, [version]);

  const filteredOrig = runFilters(data);
  let filtered = filteredOrig;

  if( pagination !== undefined ){
    filtered = filtered.filter((each, index) => {
      return index >= ( page - 1 ) * limit && index < page * limit;
    })
  }

  const records = filtered?.length;
  const total = data?.length;

  return (
    <div className="filter-table">
      <div className="filter-table-filters d-flex mb-4 align-items-center">
        { hasSearch ? getSearch() : null }
        { getFilters() }
      </div>
      {
        pagination === undefined && shown !== undefined && shown && groupby === undefined
          ? <p>{ `Showing ${records} of ${total}` }</p>
          : null
      }
      {
        stats.length ? (
          <div className="filter-table-stats">
            { getStats( filtered ) }
          </div>
        ) : null
      }
      {
        getPagination(filteredOrig, 'b')
      }
      {
        groupby !== undefined ? getGroups() : getRegular( filtered )
      }
      {
        getPagination(filteredOrig, 't')
      }
    </div>
  );

}

FilterTable.propTypes = {
  data: PropTypes.array.isRequired,
  idCol: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    tooltip: PropTypes.bool,
    minWidth: PropTypes.number,
    size: PropTypes.number,
    filterable: PropTypes.bool,
    searchFilter: PropTypes.bool,
    onFilterUpdate: PropTypes.func,
    filterDefaultValue: PropTypes.string,
    type: PropTypes.string,
    valueFunction: PropTypes.func,
    itemNameFunc: PropTypes.func,
    hideFromAll: PropTypes.bool
  })).isRequired,
  content: PropTypes.func,
  cutoff: PropTypes.number,
  pagination: PropTypes.bool,
  version: PropTypes.number,
  stats: PropTypes.arrayOf(PropTypes.shape({
    reduceStart: PropTypes.oneOf(['object', 'number']),
    reduceFunction: PropTypes.func,
    valueFunction: PropTypes.func
  })),
  groups: PropTypes.array,
  groupby: PropTypes.string,
  hasSearch: PropTypes.bool,
  onSearchUpdate: PropTypes.func,
  shown: PropTypes.bool
};

export default FilterTable;