import React, {useState, useContext, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Panel from '../common/Panel';
import gmail from 'assets/img/email/favicon-gmail.jpg';
import outlook from 'assets/img/email/favicon-outlook.jpg';
import zoho from 'assets/img/email/favicon-zoho.jpg';
import Google from './connect/Google';
import GoogleConnect from './connect/GoogleConnect';
import API from 'api/api';
import emailConfigurations from 'helpers/emailConfigurations';
import ProviderChoice from './connect/ProviderChoice';
import Microsoft from './connect/Microsoft';
import MicrosoftConnect from './connect/MicrosoftConnect';
import Zoho from './connect/Zoho';
import ZohoConnect from './connect/ZohoConnect';
import SMTPConnect from './connect/SMTPConnect';
import { AppContext, AccountContext } from '../../context/Context';
import FormInfo from '../common/FormInfo';
import cookie from 'react-cookies';
import { successMessage, errorMessage, getURLParameter, getMetaValue } from '../../helpers/utils';
//import Api from '../../api/api';

const Connect = ({
  reconnect = 0,
  startingConnection = {},
  email = '',
  connectedEmail = false,
  title = 'Connect New Email',
  cancelRestart = null
}) => {
  const { accountState } = useContext(AccountContext);

  const {
    config: { embed }
  } = useContext(AppContext);

  //whether the user has acknowledged adding an email
  const [adding, setAdding] = useState(false);

  //connection settings
  const [connection, setConnection] = useState(startingConnection);

  //saving status
  const [saving, setSaving] = useState(false);

  //google/microsoft
  const [provider, setProvider] = useState( connectedEmail && connectedEmail.provider !== undefined ? connectedEmail.provider : '' );

  //gmail/workspace outlook/office365
  const [offering, setOffering] = useState( connectedEmail && connectedEmail.offering !== undefined ? connectedEmail.offering : '' );

  //Page in the setup instructions
  const [page, setPage] = useState(1);

  useEffect(() => {
    const theError = getURLParameter('error');
    if(theError){
      if(theError === 'mismatch'){
        const email1 = getURLParameter('email1');
        const email2 = getURLParameter('email2');
        errorMessage(`You are trying to connect ${email1}, but you are logged into ${email2}. Please login to ${email1} to connect.`, false, {autoClose: false});
      }
    }
  }, []);

  useEffect(() => {
    if( connectedEmail ){
      updateConnectionMultiple({
        firstName: getMetaValue(connectedEmail, 'first_name'),
        lastName: getMetaValue(connectedEmail, 'last_name')
      });
    }
  }, []);

  const providers = [
    {
      name: 'Google/Gmail',
      img: gmail,
      id: 'google'
    },
    {
      name: 'Microsoft/Outlook/365',
      img: outlook,
      id: 'microsoft'
    },
    {
      name: 'Zoho',
      img: zoho,
      id: 'zoho'
    },
    {
      name: 'SMTP/IMAP',
      img: false,
      id: 'smtp'
    }
  ];

  let saveConnection = (e) => {
    e.preventDefault();

    setSaving(true);

    API.doRequest('connected_emails', 'create', 'create', {update: {
      provider,
      offering,
      reconnect: 0,
      ...connection
    }}).then(result => {
      setSaving(false);
      cookie.save(SSSettings.namelower + '_connected_email', result.data.id, { path: '/', maxAge: (1000 * 60 * 60 * 24) });
      successMessage('Email is connecting!');

      setTimeout(() => {
        window.location.href = '/email/settings';
      }, 200)
    }).catch(error => {
      errorMessage(false, error);
      setSaving(false);
    })
  }

  if( connectedEmail ){
    saveConnection = (e) => {
      e.preventDefault();

      setSaving(true);

      API.doRequest('connected_emails', 'update_full', 'update', {update: {
          provider,
          offering,
          reconnect,
          email: email,
          ...connection
        }}).then(result => {
        successMessage('Email is connecting!');
        setSaving(false);
        //cookie.save(SSSettings.namelower + '_connected_email', result.id, { path: '/', maxAge: (1000 * 60 * 60 * 24) });
        //window.location.href = '/email/overview';
        setTimeout(() => {
          window.location.href = '/emails';
        }, 1000);
      }).catch(error => {
        errorMessage('Email could not be connected');
        setSaving(false);
      })
    }
  }

  const updateProvider = (provider) => {
    setProvider(provider);
    setOffering('');

    if(provider === 'smtp'){
      updateConnection('connection', emailConfigurations[provider] )
    } else {
      setConnection('');
    }
  }

  const updateOffering = (offering) => {
    setOffering(offering);
    updateConnection('connection', emailConfigurations[provider][offering] )
  }

  const updateConnectionMultiple = (update) => {
    setConnection({
      ...connection,
      ...update
    })
  }

  const updateConnection = (key, val) => {
    setConnection({
      ...connection,
      [key]: val
    })
  }

  const updateSMTP = (key, val) => {
    setConnection({
      ...connection,
      connection: {
        ...connection.connection,
        smtp: {
          ...connection.connection.smtp,
          [key]: val
        }
      }
    })
  }

  const updateIMAP = (key, val) => {
    setConnection({
      ...connection,
      connection: {
        ...connection.connection,
        imap: {
          ...connection.connection.imap,
          [key]: val
        }
      }
    })
  }

  const propsToPass = {
    connection,
    offering,
    provider,
    saveConnection,
    updateConnection,
    saving,
    updateOffering,
    setSaving,
    updateSMTP,
    updateIMAP,
    email,
    reconnect
  }

  let overageAllowed = accountState?.usage?.emails?.overage;
  overageAllowed = overageAllowed !== undefined ? parseInt(overageAllowed, 10) : 0;

  let max = accountState?.usage?.emails?.max;
  max = max !== undefined ? parseInt(max, 10) : 0;

  let amount = accountState?.usage?.emails?.amount;
  amount = amount !== undefined ? parseInt(amount, 10) : 0;

  let peak = accountState?.usage?.emails?.peak;
  peak = peak !== undefined ? parseInt(peak, 10) : 0;

  const frag = max ? 'additional ' : '';

  return (
    <Panel title={title}>
      <h4>
        <span className="d-inline-block">Select Your Email Provider</span>
        { cancelRestart }
      </h4>
      <ProviderChoice value={provider} update={updateProvider} providers={providers} />
      {
        provider && amount >= max && ! overageAllowed && ! embed ? (
          <>
            <h4>Insufficient Credits</h4>
            <p>Please visit the <Link to="/settings/plan">plans</Link> page and choose a plan. That will allow you to connect an {frag}email.</p>
          </>
        ) : null
      }
      {
        provider && amount >= max && ! overageAllowed && embed ? (
          <>
            <h4>There was an issue</h4>
            <p>Please contact support.</p>
          </>
        ) : null
      }
      {
        provider && amount >= max && overageAllowed && ! adding && ! embed && ! reconnect ? (
          <>
            <h4>Add another email to your account?</h4>
            {
              amount + 1 <= peak
                ? <p>Billing is based on your peak number of simultaneously connected emails during a period, {peak}. You currently have {amount}. Your bill will not be affected by connecting an additional email. <FormInfo>This is because, after connecting another email, the total number of simultaneously connected emails, {amount + 1}, will be less than or equal to {peak}</FormInfo></p>
                : <p>You will be charged an additional $9 per month once the email is connected <FormInfo>If you have 11-25 emails, the cost is $8/mo/email. If you have 26-100, the cost is $7/mo/email</FormInfo></p>
            }
            <div>
              <Button onClick={() => setAdding(true)}>Yes!</Button>
            </div>
          </>
        ) : null
      }
      {
        amount < max || adding || reconnect ? (
          <div className="provider-instructions">
            { provider === 'google' ? <Google {...propsToPass} /> : null }
            { provider === 'google' && offering ? <GoogleConnect {...propsToPass} /> : null }
            { provider === 'microsoft' ? <Microsoft {...propsToPass} /> : null }
            { provider === 'microsoft' && offering ? <MicrosoftConnect {...propsToPass} /> : null }
            { provider === 'zoho' ? <Zoho {...propsToPass} /> : null }
            { provider === 'zoho' && offering ? <ZohoConnect {...propsToPass} /> : null }
            { provider === 'smtp' ? <SMTPConnect {...propsToPass} /> : null }
          </div>
        ) : null
      }
    </Panel>
  );
}

Connect.propTypes = {

};

export default Connect;
