import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import ConnectForm from './ConnectForm';
import Checklist from '../../common/Checklist';
import { getExternalLinks } from 'helpers/utils';

const GoogleConnect = ({
  titleOverwrite,
  ...rest
}) => {
  const [twofa, appPass] = getExternalLinks([
    {
      href: 'https://myaccount.google.com/signinoptions/two-step-verification/enroll-welcome',
      anchor: 'here',
      strong: true
    },
    {
      href: 'https://myaccount.google.com/apppasswords',
      anchor: 'here',
      strong: true
    }
  ]);
  const checklist = [
    <span>Make sure you have 2-Factor Authentication active {twofa}.</span>,
    <span>Then create an app password {appPass}, with App: Mail, Device: Other, and Custom Name: EmailWarmup</span>,
    <span><strong>IMPORTANT</strong>: Copy the password that is generated. This should be used for the "App Password" value.</span>
  ];

  return (
    <>
      <h4>{ titleOverwrite ? titleOverwrite : 'How to Connect' }</h4>
      <Row className="provider-connect my-4">
        <Col className="provider-instructions mb-3" md={6}>
          <Checklist checklist={checklist} />
          {/*<div>
            <strong>Troubleshooting: Make sure Enable IMAP is selected</strong>
            <ul>
              <li>In Gmail, click the gear icon to go to Settings</li>
              <li>Click the Forwarding and POP/IMAP tab</li>
              <li>Scroll down to “IMAP access” and make sure Enable IMAP is selected</li>
              <li>Click Save Changes at the bottom of the screen</li>
            </ul>
          </div>*/}
        </Col>
        <Col className="provider-connection mb-3" md={6}>
          <ConnectForm configOverwrite={{password: {label: 'App Password'}}} {...rest} />
        </Col>
      </Row>
    </>
  );
}

GoogleConnect.propTypes = {
  connection: PropTypes.object.isRequired,
  updateConnection: PropTypes.func.isRequired,
  saveConnection: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired
};

export default GoogleConnect;
