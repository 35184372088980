import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import SettingsPage from './SettingsPage';
import { AccountContext } from 'context/Context';
import { loadPlans, loadPlansAccounts } from 'load/plans'
//import LoaderButton from '../../common/LoaderButton';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getURLParameter, hasUserPermission } from '../../../helpers/utils';
import PlanAddon from './plan/PlanAddon';
import PlanMain from './plan/PlanMain';
import PlanCurrent from './plan/PlanCurrent';
import { Link } from 'react-router-dom';

const Lifetime = () => {
  const [overages, setOverages] = useState({});
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [accountPlans, setAccountPlans] = useState([]);
  const { accountState } = useContext(AccountContext);

  const updateItems = (items) => {
    setItems(items.filter(each => each.period === 'lifetime'));
  }

  //The empty array as the second argument makes this run like componentDidMount
  //https://reacttraining.com/blog/useEffect-is-not-the-new-componentDidMount/
  useEffect(() => {
    loadPlansAccounts(setAccountPlans).then(result => {
      const accountPlanIds = result.reduce((acc, cur) => {
        acc.push(cur.plan);
        return acc;
      }, []);
      loadPlans(updateItems, accountPlanIds).then(result2 => {
        setLoading(false);
      }).catch(error => {
        setLoading(false);
      })
    }).catch(error => {
      loadPlans(updateItems).then(result2 => {
        setLoading(false);
      }).catch(error => {
        setLoading(false);
      })
    });
  }, []);

  const updateOverage = (planId, overageItem, val) => {
    setOverages({
      ...overages,
      [planId]: {
        ...overages[planId],
        [overageItem]: val
      }
    });
  }

  if( ! hasUserPermission('plan') ){
    return (
      <SettingsPage title="Lifetime" loading={loading} onSave={null}>
        <div className="alert alert-danger">You do not have permission to edit the plan for your account. Please speak with the account's admin.</div>
      </SettingsPage>
    )
  }

  if( ! loading && ! items.length ){
    return (
      <SettingsPage title="Lifetime" loading={loading} onSave={null}>
        <div>{ getURLParameter('pSubscribe') ? 'This plan is not available.' : 'There are no lifetime plans available for sale.' }</div>
        <div className="mt-3">
          <Link className="text-white text-decoration-none" to="/settings/plan">
              <span className="btn btn-primary">
                View Plans
              </span>
          </Link>
        </div>
      </SettingsPage>
    )
  }

  const planTitle = items.length && accountPlans.filter(each => ! each.cancel_id && each.period === 'lifetime').length
    ? <span>Lifetime <a className="fs--2" href="#current-plan">(View Current Plan)</a></span>
    : 'Plan';

  return (
    <SettingsPage title={planTitle} loading={loading} onSave={null}>
      <Row>
        {
          items.map(each => {
            switch(each.type){
              case 'addOn':
                return <Col md={6}><PlanAddon updateOverage={updateOverage} overages={overages} accountPlans={accountPlans} {...each} /></Col>;
              case 'main':
                return <Col md={6}><PlanMain updateOverage={updateOverage} overages={overages} accountPlans={accountPlans} {...each} /></Col>;
            }
          })
        }
      </Row>
      { items.length && accountPlans.length ? <PlanCurrent period="lifetime" plans={items} accountPlans={accountPlans} /> : null }
    </SettingsPage>
  );
};

export default Lifetime;
