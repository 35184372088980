import React from 'react';
import LogoutContent from 'components/authentication/custom/LogoutContent';
import AuthCardLayout from 'components/authentication/custom/AuthCardLayout';

const Logout = () => {
  return (
    <AuthCardLayout>
      <div className="text-center">
        <LogoutContent />
      </div>
    </AuthCardLayout>
  );
};

export default Logout;
