import React, { useState, useEffect, useContext } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import Section from 'components/common/Sec';
import { AppContext } from "context/Context";
import cookie from 'react-cookies';
import FilterTable from 'components/common/FilterTable';
import API from 'api/api';
import CustomPagination from 'components/common/Pagination';
import { timestampToDate } from 'helpers/utils';
import StatCard from 'components/common/StatCard';
import RevenueChart from './RevenueChart';

const PlansAccounts = () => {
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [table, setTable] = useState({
    revenue: [],
    plans: [],
    usages: {},
    days: []
  });
  const [period, setPeriod] = useState('monthly')

  let params = [
    { param: 'super', value: 'user_plans' },
    { param: 'limit', value: limit },
    { param: 'offset', value: (page - 1) * limit }
  ];

  useEffect(() => {
    API.doRequest('super', 'get', 'get', null, {params}).then(result => {
      setItems(result.data);
    });
  }, [limit, page]);

  let params2 = [
    { param: 'super', value: 'revenue' }
  ];

  useEffect(() => {
    API.doRequest('super', 'get', 'get', null, {params: params2}).then(result => {
      setTable(result.data);
    });
  }, []);

  console.log(table);

  const cols = [
    {
      id: 'account',
      name: 'Account',
      type: 'div',
      size: 3,
      valueFunction: (val, row) => {
        return <div className="fs--1">
          <div>{ row.account }</div>
          <div>{ row.billing_email }</div>
        </div>;
      }
    },
    {
      id: 'created',
      name: 'Created',
      type: 'div',
      size: 3,
      valueFunction: (val, row) => {
        return (
          <div className="fs--1">
            <div>Created: { timestampToDate(row.created) }</div>
            <div>Next: { timestampToDate(row.next) }</div>
          </div>
        )
        return timestampToDate(val);
      }
    },
    {
      id: 'current_usage_name',
      name: 'Current',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return Object.keys(val).map(name => {
          return <div className="fs--1">{name}: {val[name]}</div>
        })
      }
    },
    {
      id: 'peak_usage_name',
      name: 'Peak',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return Object.keys(val).map(name => {
          return <div className="fs--1">{name}: {val[name]}</div>
        })
      }
    },
    {
      id: 'allowances_name',
      name: 'Allowance',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return Object.keys(val).map(name => {
          return <div className="fs--1">{name}: {val[name]}</div>
        })
      }
    },
    {
      id: 'reductions_name',
      name: 'Reductions',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return Object.keys(val).map(name => {
          return <div className="fs--1">{name}: {val[name]}</div>
        })
      }
    },
    {
      id: 'report_units_name',
      name: 'Report',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return Object.keys(val).map(name => {
          return <div className="fs--1">{name}: {val[name]}</div>
        })
      }
    },
    {
      id: 'revenue',
      name: 'Revenue',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return '$' + (Math.round(parseInt(val, 10) / 100 ))
      }
    },
  ];

  return (
    <Section size="xxlg">
      <Card className="mb-3">
        <Card.Header as="h5">Plans</Card.Header>
        {
          items[period] === undefined ? (
            <Card.Body className="bg-light">Loading...</Card.Body>
          ) : (
            <Card.Body className="bg-light">
              <Row className="mb-3">
                <Col md={12} lg={4}>
                  <StatCard groups={[
                    {
                      number: items[period].plans_num,
                      label: 'Total Plans'
                    }
                  ]} color="primary" icon="user" label="Plans" />
                </Col>
                <Col md={12} lg={4}>
                  <StatCard groups={[
                    {
                      number: items[period].current_usage?.emails,
                      label: 'Total Emails'
                    },
                    {
                      number: items[period].peak_usage?.emails,
                      label: 'Total Peak'
                    }
                  ]} color="info" icon="inbox" label="Emails" />
                </Col>
                <Col md={12} lg={4}>
                  <StatCard groups={[
                    {
                      number: '$' + (items[period].revenue !== undefined ? Math.round(parseInt(items[period].revenue, 10) / 100) : 0),
                      label: 'Total Expected Revenue'
                    }
                  ]} color="success" icon="dollar-sign" label="Revenue" />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  {
                    items[period].plans === undefined ? <div>Loading...</div> : Object.keys(items[period].plans).map(planId => {
                      return (
                        <div className="mt-5">
                          <h6>{items[period].plan_names[planId] !== undefined ? items[period].plan_names[planId] : ''}</h6>
                          <FilterTable
                            data={ items[period].plans[planId] !== undefined ? items[period].plans[planId] : [] }
                            columns={cols}
                          />
                        </div>
                      )
                    })
                  }
                  {/*<CustomPagination
                    total={ items[period].plans !== undefined ? items[period].plans : [] }
                    limit={ 10 }
                    page={ page }
                    currentLimit={ limit }
                    updatePage={ (page) => setPage(page) }
                    updateLimit={ (limit) => setLimit(limit) }
                    margin="t"
                  />*/}
                </Col>
              </Row>
              <Row className="mt-5">
                <Col>
                  <RevenueChart revenue={table.revenue} plans={table.plans} days={table.days} usage={table.usages} />
                </Col>
              </Row>
            </Card.Body>
          )
        }
      </Card>
    </Section>
  );
};

PlansAccounts.propTypes = {};

export default PlansAccounts;