import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button, Form, Card, Nav, ProgressBar } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import SpamCheckerContent from './SpamCheckerContent';
import SpamCheckerDomain from './SpamCheckerDomain';
import { SpamCheckerContext } from 'context/Context';
import SpamCheckerRegister from './SpamCheckerRegister';

const SpamCheckerWizard = () => {
  const { step, setStep } = useContext(SpamCheckerContext);

  const navItems = [
    {
      icon: 'pencil-alt',
      label: 'Email Content'
    },
    {
      icon: 'globe',
      label: 'Domain'
    },
    {
      icon: 'chart-pie',
      label: 'Deliverability'
    }
  ];

  const handleNavs = targetStep => {
    setStep(targetStep);
  };

  const numSteps = 3;

  return (
    <>
      <Card className="theme-wizard">
        <Card.Header className="bg-light pb-2">
          <Nav className="justify-content-center">
            {
              navItems.map((item, index) => (
                <NavItem
                  key={item.label}
                  index={index + 1}
                  step={step}
                  handleNavs={handleNavs}
                  icon={item.icon}
                  label={item.label}
                  numSteps={numSteps}
                />
              ))
            }
          </Nav>
        </Card.Header>
        <Card.Body className="fw-normal px-md-6 py-4">
          {step === 1 && (
            <SpamCheckerContent />
          )}
          {step === 2 && (
            <SpamCheckerDomain />
          )}
          {step === numSteps && (
            <SpamCheckerRegister />
          )}
        </Card.Body>
        <Card.Footer
          className="px-md-6 bg-light d-flex">
          <IconButton
            variant="link"
            icon="chevron-left"
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semi-bold', {
              'd-none': step === 1
            })}
            onClick={() => {
              setStep( ( step > 1 ? step - 1 : step ) );
            }}
          >
            Prev
          </IconButton>

          {
            step !== numSteps ? (
              <IconButton
                variant="primary"
                className="ms-auto px-5"
                type="submit"
                icon="chevron-right"
                iconAlign="right"
                transform="down-1 shrink-4"
                onClick={() => {
                  setStep( ( step < numSteps ? step + 1 : step ) );
                }}
              >
                Next
              </IconButton>
            ) : null
          }
        </Card.Footer>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label, numSteps }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index <= numSteps ? step >= index : step >= numSteps - 1
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

SpamCheckerWizard.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  numSteps: PropTypes.number
};

export default SpamCheckerWizard;
