import React from 'react';

const PlanLineInfo = ({
  display,
  item,
  name,
  overage = 0,
  overage_controls = 0,
  overage_cost = 0,
  overage_per = '',
  plan,
  style,
  tooltip,
  type,
  value
}) => {
  return (
    <>
      {value}
    </>
  );
};

export default PlanLineInfo;
