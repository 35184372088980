import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Panel from './Panel';
import { AccountContext } from 'context/Context';
import ReconnectNotice from './ReconnectNotice';

const EmailPanel = ({
  email,
  children,
  title,
  ...rest
}) => {
  const { accountState, accountDispatch } = useContext(AccountContext);
  const connectedEmail = accountState?.connected_email !== undefined ? accountState.connected_email : {};

  let notice = null;

  if( ( connectedEmail.status === 'disconnected' || connectedEmail.status === 'reconnecting' || connectedEmail.status === 'error' || connectedEmail.status === 'smtpError' ) && title !== 'Reconnect' ){
    notice = <ReconnectNotice />
  }

  return (
    <Panel title={title} rightTitle={connectedEmail.email} {...rest}>
      { notice }
      { children }
    </Panel>
  )
};

EmailPanel.propTypes = {
  email: PropTypes.string,
  title: PropTypes.string
};

export default EmailPanel;
