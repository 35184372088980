/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-case-declarations */

export const accountReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'UPDATE':
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.user
        },
        account: {
          ...state.account,
          ...payload.account
        },
        plan: {
          ...state.plan,
          ...payload.plan
        },
        utility: {
          ...state.utility,
          ...payload.utility
        },
        connected_email: {
          ...state.connected_email,
          ...payload.connected_email
        },
        usage: {
          ...state.usage,
          ...payload.usage
        },
        is_appsumo: payload.account_parents_meta !== undefined ? payload.account_parents_meta.reduce((acc, cur) => {
            if(cur.metakey === 'appsumo_uuid'){
              acc = true;
            }
            return acc;
          }, false) : false,
        permissions: payload.permissions !== undefined ? payload.permissions : (state.permissions !== undefined ? state.permissions : []),
        plans_accounts: payload.plans_accounts !== undefined ? payload.plans_accounts : (state.plans_accounts !== undefined ? state.plans_accounts : []),
        memberships: payload.memberships !== undefined ? payload.memberships : (state.memberships !== undefined ? state.memberships : []),
        is_owner: payload.account_parents_meta !== undefined ? payload.account_parents_meta.reduce((acc, cur) => {
          if(cur.metakey === 'owner' && payload.user.id === cur.value){
            acc = true;
          }
          return acc;
        }, false) : false
      };
    case 'UPDATE_CONNECTED_EMAIL':
      return {
        ...state,
        connected_email: {
          ...state.connected_email,
          ...payload
        }
      };
    case 'UPDATE_USER':
      return {
        ...state,
        user: {
          ...state.user,
          ...payload
        }
      };
    case 'UPDATE_ACCOUNT':
      return {
        ...state,
        account: {
          ...state.account,
          ...payload
        }
      };
    case 'UPDATE_PLAN':
      return {
        ...state,
        plan: {
          ...state.plan,
          ...payload
        }
      };
    case 'UPDATE_UTILITY':
      return {
        ...state,
        utility: {
          ...state.utility,
          ...payload
        }
      };
    default:
      return state;
  }
};
