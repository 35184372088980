import React from 'react';
import AccountProvider from 'providers/AccountProvider';
import MainLayout from './MainLayout';

const MainLayoutWrap = () => {
  return (
    <AccountProvider>
      <MainLayout />
    </AccountProvider>
  );
};

export default MainLayoutWrap;
