import React from 'react';
import {Redirect} from 'react-router-dom';

const Dashboard = () => {
  const redirect = '/emails' + window.location.search

  return <Redirect to={redirect} />;
};

export default Dashboard;
