import React, {useState} from 'react';
import Panel from '../../common/Panel';
import { SpamCheckerContext } from 'context/Context';
import SpamCheckerContent from 'projects/spam/SpamCheckerContent';

const SpamWordChecker = ({}) => {
  const [textHTML, setTextHTML] = useState('text');
  const [emailContent, setEmailContent] = useState('');
  const [emailHTML, setEmailHTML] = useState('');
  const [userIP, setUserIP] = useState(false);
  const [disabled, setDisabled] = useState(false);

  return (
    <Panel title="Spam Word Checker">
      <SpamCheckerContext.Provider
        value={{
          textHTML, setTextHTML,
          emailContent, setEmailContent,
          emailHTML, setEmailHTML,
          userIP, setUserIP,
          disabled, setDisabled
        }}>
        <SpamCheckerContent />
      </SpamCheckerContext.Provider>
    </Panel>
  )
};

SpamWordChecker.propTypes = {};

export default SpamWordChecker;
