import React, {useState, useEffect, useContext} from 'react';
import SettingsPage from './SettingsPage';
import { AccountContext } from 'context/Context';
import { hasUserPermission } from '../../../helpers/utils';
import Api from '../../../api/api';
import IFrame from '../../common/IFrame';

const Billing = () => {
  const [loading, setLoading] = useState(true);
  const [billingKey, setBillingKey] = useState('');
  const { accountState } = useContext(AccountContext);

  useEffect(() => {
    Api.doRequest('accounts', 'billing' ).then(result => {
      setBillingKey(result.data);
      setLoading(false);
    }).catch(error => {
      setLoading(false);
    });

    const script = document.createElement("script");

    script.src = "https://app.paykickstart.com/billing-portal/js/iHoQZSayVjeSMJZtlWKJlMieMyq7Kp8BeEDX7HHfjOwhrUYSd2";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  if( ! hasUserPermission('billing') ){
    return (
      <SettingsPage title="Billing" loading={loading} onSave={null}>
        <div className="alert alert-danger">You do not have permission to edit the billing info for your account. Please speak with the account's admin.</div>
      </SettingsPage>
    )
  }

  if( ! billingKey ){
    return (
      <SettingsPage title="Billing" loading={ loading } onSave={null}>
        There are no transactions to show for this account.
      </SettingsPage>
    );
  }

  return (
    <SettingsPage title="Billing" loading={ loading } onSave={null}>
      <IFrame src={ `https://app.paykickstart.com/billing?portal=iHoQZSayVjeSMJZtlWKJlMieMyq7Kp8BeEDX7HHfjOwhrUYSd2&secret=${billingKey}` } onLoad={null} />
    </SettingsPage>
  );
};

export default Billing;
