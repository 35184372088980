import React, {useContext, useState} from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import StatusTest from '../main/StatusTest';
import { AccountContext, AppContext } from 'context/Context';
import TooltipItem from './TooltipItem';

const ReconnectNotice = ({}) => {
  const { accountState } = useContext(AccountContext);
  const connectedEmail = accountState?.connected_email !== undefined ? accountState.connected_email : {};
  const { appConfirm } = useContext(AppContext);
  const [reconnected, setReconnected] = useState(false);

  const handleStatusTestResult = (result) => {
    if(result.newStatus !== 'reconnecting' && result.newStatus !== 'error' && result.newStatus !== 'smtpError' && result.newStatus !== 'disconnected'){
      setReconnected(true);
    }
  }

  const statusTest = () => {
    appConfirm({
      type: 'message',
      confirmText: 'Done',
      confirmColor: 'text',
      header: 'Status Check',
      body: (
        <div>
          <StatusTest
            connectedId={connectedEmail.id}
            current={connectedEmail.status}
            title="Checking status..."
            removeTitleOnFinish={true}
            onCheckUpdate={true}
            onCheckFinish={handleStatusTestResult}
          />
        </div>
      )
    })
  }

  if( reconnected ){
    return (
      <div className="alert alert-success d-flex align-items-center mb-3">
        <span>
          This email has been reconnected. You can now refresh this page.
        </span>
        <Button
          variant="primary"
          className="ms-auto"
          onClick={() => window.location.reload()}
        >Refresh</Button>
      </div>
    )
  }

  return (
    <div className="alert alert-danger d-flex align-items-center mb-3">
      <span>
        This email has experienced a connection error{ connectedEmail.status === 'reconnecting' ? ' and Boxward is trying to reconnect it.' : '. It may need to be reconnected manually.' }
      </span>
      <div className="ms-auto d-flex align-items-center">
        <TooltipItem tooltip="Boxward will test the connection and try to automatically reconnect">
          <Button
            variant="primary"
            className="me-2"
            onClick={statusTest}
          >Auto Reconnect</Button>
        </TooltipItem>
        <TooltipItem tooltip="If Auto Reconnect is unsuccessful, you may have to edit connection details">
          <Button
            as={Link}
            variant="text"
            className="ms-2"
            to="/email/reconnect"
          >Edit Connection</Button>
        </TooltipItem>
      </div>
    </div>
  )
};

export default ReconnectNotice;
