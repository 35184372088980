export const settingsRoutes = [
  {
    name: 'Profile',
    to: '/settings/profile',
    exact: true,
    includeInProfileDropdown: true
  },
  {
    name: 'Plan',
    to: '/settings/plan',
    exact: true,
    includeInProfileDropdown: true
  },
  {
    name: 'Lifetime',
    to: '/settings/lifetime',
    /*badge: {
      pill: null,
      bg: 'danger',
      value: 'Ended',
      className: ''
    },*/
    exact: true,
    includeInProfileDropdown: true,
    permission: 'has_lifetime'
  },
  {
    name: 'Redeem',
    to: '/settings/redeem',
    exact: true,
    includeInProfileDropdown: true
  },
  {
    name: 'Billing',
    to: '/settings/billing',
    exact: true,
    includeInProfileDropdown: true
  },
  {
    name: 'Team',
    to: '/settings/team',
    exact: true,
    includeInProfileDropdown: true
  },
  {
    name: 'Embed',
    to: '/settings/embed-code',
    exact: true,
    permission: 'embed',
    includeInProfileDropdown: true
  }
];

export default settingsRoutes;
