import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Row, Col, Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import Api from 'api/api';
import { errorMessage, getURLParameter, successMessage } from 'helpers/utils';
import { Redirect } from 'react-router-dom';

const PasswordResetForm = ({
  hasLabel,
  addName = false,
  autoLogin = false,
  buttonText = 'Set Password'
}) => {
  // State
  const [pass1, setPass1] = useState('');
  const [pass2, setPass2] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [redirect, setRedirect] = useState(false);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    Api.doRequest('accounts', 'verify_reset_password', 'update', {
      update: {
        pass1, pass2, firstName, lastName
      }
    }, {
      params: [
        {
          param: 'rpkey',
          value: getURLParameter('rpkey')
        },
        {
          param: 'rplogin',
          value: getURLParameter('rplogin')
        }
      ]
    }).then(result => {
      successMessage('Password was reset!');

      if( autoLogin ){
        const formData = {
          email: decodeURIComponent(getURLParameter('rplogin')),
          password: pass1,
          remember: false
        }

        Api.doRequest('accounts', 'login', 'update', formData).then(result => {
          successMessage(`Successfully logged in as ${formData.email}`);
          window.location.href = '/emails';
        }).catch(error => {
          setTimeout(() => {
            setRedirect(true);
          }, 1000);
        });
      } else {
        setTimeout(() => {
          setRedirect(true);
        }, 1000);
      }

    }).catch(error => {
      errorMessage(false, error);
    });
  };

  if(redirect){
    const login = decodeURIComponent(getURLParameter('rplogin'));
    return <Redirect to={ `/signin?rplogin=${login}` }  />
  }

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit}
    >

      {
        addName ? (
          <Row>
            <Col>
              <Form.Group className="mb-3">
                {hasLabel && <Form.Label>First Name</Form.Label>}
                <Form.Control
                  placeholder={!hasLabel ? 'First' : ''}
                  value={firstName}
                  name="firstName"
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Last Name</Form.Label>}
                <Form.Control
                  placeholder={!hasLabel ? 'Last' : ''}
                  value={lastName}
                  name="lastName"
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
        ) : null
      }

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>New Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'New Password' : ''}
          name="password"
          value={pass1}
          onChange={(e) => setPass1(e.target.value)}
          type="password"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirm Password' : ''}
          name="confirmPassword"
          value={pass2}
          onChange={(e) => setPass2(e.target.value)}
          type="password"
        />
      </Form.Group>

      <Button
        type="submit"
        className="w-100"
        disabled={!pass1 || !pass2}
      >
        { buttonText }
      </Button>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetForm;
