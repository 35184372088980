import React, {useState, useEffect, useContext} from 'react';
import Api from '../../api/api';
import { getURLParameter } from '../../helpers/utils';
import Panel from '../common/Panel';
import { AccountContext } from '../../context/Context';

const Verify = () => {
  // State
  const [verified, setVerified] = useState(false);
  const [verificationError, setVerificationError] = useState('');
  const { accountState } = useContext(AccountContext);
  const isAppSumo = accountState?.is_appsumo !== undefined ? accountState.is_appsumo : false;

  useEffect(() => {
    const params = [
      {param: 'verification_data', value: getURLParameter('data')},
      {param: 'verification_sig', value: getURLParameter('sig')}
    ];

    Api.doRequest('user', 'validate', 'get', null, {params}).then(result => {
      setVerified(1);
    }).catch(error => {
      setVerified(0);
      setVerificationError(error.message);
    });
  }, []);

  return (
    <Panel title="Verify Account" bypassVerification={ verified !== false }>
      {
        verified === 1 ? (
          <>
            <div>Your account has been verified!</div>
            {
              isAppSumo ? (
                <div className="mt-3"><a className="btn btn-primary" href="/settings/lifetime">Continue to Account</a></div>
              ) : (
                <div className="mt-3"><a className="btn btn-primary" href="/emails">Continue to Account</a></div>
              )
            }
          </>
        ) : null
      }
      {
        verified === 0 ? (
          <p>{ verificationError }</p>
        ) : null
      }
    </Panel>
  );
};

export default Verify;
