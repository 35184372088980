import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Accordion, Button, Card, Form, Row, Col } from 'react-bootstrap';
import Section from 'components/common/Sec';
import { AppContext, AdminContext } from "../context/Context";
import FilterTable from 'components/common/FilterTable';
import { loadConnectedEmails, loadConnectedEmailsSearch } from './load/connected_emails';
import { errorMessage, getStatusArray, isJson, successMessage, timestampToDate } from '../helpers/utils';
import TreeviewObject from 'components/common/TreeviewObject';
import API from 'api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipItem from '../components/common/TooltipItem';

let searchTimer = null;

const ConnectedEmails = () => {
  const { adminDispatch } = useContext(AdminContext);
  const { appConfirm } = useContext(AppContext);
  const [limit, setLimit] = useState(100);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [provider, setProvider] = useState('');
  const [items, setItems] = useState([]);
  const [item, setItem] = useState(false);
  const [connectedEmail, setConnectedEmail] = useState(false);
  const [testSender, setTestSender] = useState('');
  const [test, setTest] = useState({});
  const [testData, setTestData] = useState([]);
  const [nextData, setNextData] = useState([]);
  const [viewNext, setViewNext] = useState(false);
  const [viewTooManyOther, setViewTooManyOther] = useState(false);
  const [tooManyOtherData, setTooManyOtherData] = useState([]);
  const [newStatus, setNewStatus] = useState('');

  const itemRef = useRef(item);
  itemRef.current = item;

  //The empty array as the second argument makes this run like componentDidMount
  //https://reacttraining.com/blog/useEffect-is-not-the-new-componentDidMount/
  useEffect(() => {
    loadConnectedEmails(adminDispatch, setItems, {limit: 100})
  }, []);

  useEffect(() => {
    loadConnectedEmails(adminDispatch, setItems, {limit, search, status, provider})
  }, [limit, search, status, provider]);

  const loadSearch = (search) => {
    clearTimeout(searchTimer);

    searchTimer = setTimeout(() => {
      setSearch(search);
    }, 500);
  }

  const chooseConnectedEmail = (email) => {
    setConnectedEmail(email);
    setViewTooManyOther(false);
    setViewNext(false);
    loadConnectedEmails(adminDispatch, setItem, {id: email.id}).then(result => {
      API.doRequest('super', 'get_connected_email_all', 'get', null, {
        params: [
          { param: 'super', value: 'connected_email' },
          { param: 'connected_email', value: email.id },
        ]
      }).then(result => {
        setItem({
          ...itemRef.current,
          mailboxes: result?.data?.mailboxes,
          inbox: result?.data?.inbox,
          keepWarm: result?.data?.keep_warm,
          junk: result?.data?.junk
        })
      }).catch(error => {
        errorMessage('', error);
      });
    });
  }

  const getInbox = (page, limit = 100) => {
    API.doRequest('super', 'get_connected_email_inbox', 'get', null, {
      params: [
        { param: 'super', value: 'connected_email' },
        { param: 'connected_email', value: item.id },
        { param: 'page', value: page}
      ]
    }).then(result => {
      if( ! result?.data ){
        errorMessage('Could not load');
      } else {
        setItem({
          ...itemRef.current,
          inbox: result?.data,
        })
      }
    }).catch(error => {
      errorMessage('', error);
    });
  };

  const cleanupInbox = () => {
    API.doRequest('super', 'cleanup_inbox', 'get', null, {
      params: [
        { param: 'super', value: 'connected_email' },
        { param: 'page', value: 1 },
        { param: 'connected_email', value: connectedEmail.id },
      ]
    }).then(result => {
      successMessage('Cleaned ' + result.data + ' emails');
    }).catch(error => {
      errorMessage('', error);
    });
  }

  const createTest = () => {
    API.doRequest('super', 'create_test', 'update', { update: {
      'connected_email': connectedEmail.id
      } }, {
      params: [
        { param: 'super', value: 'connected_email' }
      ]
    }).then(result => {
      console.log(result);
      setTest(result.data.test);
    }).catch(error => {
      errorMessage('', error);
    });
  }

  const getTest = () => {
    API.doRequest('super', 'monitor_test', 'update', null, {
      params: [
        { param: 'super', value: 'connected_email' },
        { param: 'test_id', value: test.id },
        { param: 'connected_email', value: connectedEmail.id }
      ]
    }).then(result => {
      console.log(result);
      if( result.data.found ){
        successMessage('Found!')
      } else {
        successMessage( 'Not found yet. Try again in a little while' );
      }
      setTestData(result.data.messages);
    }).catch(error => {
      errorMessage('', error);
    });
  }

  const getNext = () => {
    setViewNext(true);

    API.doRequest('super', 'get_next', 'update', null, {
      params: [
        { param: 'super', value: 'connected_email' }
      ]
    }).then(result => {
      console.log(result);
      setNextData(result.data);
    }).catch(error => {
      errorMessage('', error);
    });
  }

  const getTooManyOther = () => {
    setViewTooManyOther(true);

    if( ! tooManyOtherData.length ){
      API.doRequest('super', 'get_too_many_other', 'update', null, {
        params: [
          { param: 'super', value: 'connected_email' }
        ]
      }).then(result => {
        console.log(result);
        setTooManyOtherData(result.data);

        if( ! result.data.length ) successMessage('None found');
      }).catch(error => {
        errorMessage('', error);
      });
    }
  }

  const updateEmail = (update) => {
    API.doRequest('super', 'update_email', 'update', {update}, {
      params: [
        { param: 'super', value: 'connected_email' },
        { param: 'connected_email', value: connectedEmail.id }
      ]
    }).then(result => {
      console.log('updateEmail result', result);
      successMessage('', result);

      API.doRequest('super', 'get_email', 'get', null, {
        params: [
          { param: 'super', value: 'connected_email' },
          { param: 'connected_email', value: connectedEmail.id },
          { param: 'email', value: update.id }
        ]
      }).then(result => {
        console.log('getEmail result', result, item);
        setItem({
          ...item,
          inbox: {
            ...item.inbox,
            messages: item.inbox.messages.map(each => {
              if( each.id !== update.id || ! result.data ) return each;

              return result.data;
            })
          }
        })
      }).catch(error => {
        console.log('error', error);
        errorMessage('', error);
      });
    }).catch(error => {
      errorMessage('', error);
    });
  }

  const updateConnectedEmailStatus = () => {
    const update = {status: newStatus};

    if( newStatus === 'warming' ) update.active = 1;

    API.doRequest('super', 'update_connected_email', 'update', {update}, {
      params: [
        { param: 'super', value: 'connected_email' },
        { param: 'connected_email', value: connectedEmail.id }
      ]
    }).then(result => {
      successMessage('', result);
      setConnectedEmail({
        ...connectedEmail,
        status: newStatus
      })
    }).catch(error => {
      errorMessage('', error);
    })
  }

  const cols = [
    {
      id: 'id',
      name: 'Info',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return (
          <div className="fs--1">
            <div>{row.id}</div>
            <div>{row.account}</div>
            <div>{timestampToDate(row.timestamp)}</div>
          </div>
        )
      }
    },

    {
      id: 'email',
      name: 'Email',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return (
          <div className="fs--1">
            <div><strong>{row.email}</strong></div>
            <div>Provider: {row.provider}</div>
            <div>Offering: {row.offering}</div>
            { row.num_other !== undefined ? <div className="text-danger"><strong>Number Other: {row.num_other}</strong></div> : null }
          </div>
        )
      }
    },
    {
      id: 'status',
      name: 'Status',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return (
          <div className="fs--1">
            <div>Routine: {row.routine}</div>
            <div>Status: {row.status}</div>
            <div>Active: {row.active}</div>
            <div>Score: {row.score}</div>
            <div>Score Status: {row.score_status}</div>
          </div>
        )
      }
    },
    {
      id: 'id',
      name: '',
      type: 'div',
      size: 1,
      right: true,
      valueFunction: (val, row) => {
        return <button className="btn btn-primary" onClick={() => chooseConnectedEmail(row)}>View</button>
      }
    }
  ];

  const emailCols = [
    {
      id: 'timestamp',
      name: 'Time',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return timestampToDate(row.timestamp, false, 'YYYY-MM-DD HH:mm')
      }
    },
    {
      id: 'message_id',
      name: 'Message ID',
      type: 'div',
      size: 1,
      minWidth: 200,
      valueFunction: (val) => (<div className="fs--2">{val}</div> )
    },
    {
      id: 'result',
      name: 'Result',
      type: 'div',
      size: 1
    },
    {
      id: 'ee_id',
      name: 'EE ID',
      type: 'div',
      size: 1
    },
    {
      id: 'move_fail',
      name: 'Move Fail',
      type: 'div',
      size: 1
    },
  ];

  const cycleCols = [
    {
      id: 'number',
      name: 'Number',
      type: 'div',
      size: 1
    },
    {
      id: 'start',
      name: 'Start',
      type: 'div',
      size: 3,
      valueFunction: (val, row) => {
        return timestampToDate(val, false, 'YYYY-MM-DD HH:mm')
      }
    },
    {
      id: 'next',
      name: 'Next',
      type: 'div',
      size: 3,
      valueFunction: (val, row) => {
        return timestampToDate(val, false, 'YYYY-MM-DD HH:mm')
      }
    },
    {
      id: 'sent',
      name: 'Sent',
      type: 'div',
      size: 1
    },
    {
      id: 'limit',
      name: 'Limit',
      type: 'div',
      size: 1
    },
    {
      id: 'complete',
      name: 'Complete',
      type: 'div',
      size: 1
    }
  ];

  const nextCols = [
    {
      id: 'email',
      name: 'Email',
      type: 'div',
      size: 1
    },
    {
      id: 'members',
      name: 'Members',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return val.map(each => {
          return <div>{each.user_email}</div>
        })
      }
    },
    {
      id: 'number',
      name: 'Cycle',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return (
          <>
            <div><strong>Number</strong>: {row.number}</div>
            <div><strong>Start</strong>: {timestampToDate(row.start, false, 'YYYY-MM-DD HH:mm')}</div>
            <div><strong>Next</strong>: {timestampToDate(row.next, false, 'YYYY-MM-DD HH:mm')}</div>
            <div><strong>Sent</strong>: {row.sent}</div>
            <div><strong>Limit</strong>: {row.limit}</div>
            <div><strong>Complete</strong>: {row.complete}</div>
            <div><strong>Next Start</strong>: {timestampToDate(row.next_start, false, 'YYYY-MM-DD HH:mm')}</div>
          </>
        )
      }
    },
    {
      id: 'logs',
      name: 'Logs',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return val.map(each => {
          if( each.includes('Next Timestamp:') ){
            const timestamp = each.replace('Next Timestamp: ', '');
            return (
              <>
                <div>{each}</div>
                <div>Next Date: {timestampToDate(timestamp, false, 'YYYY-MM-DD HH:mm')}</div>
              </>
            )
          }

          return <div>{each}</div>
        })
      }
    },
  ]

  const logCols = [
    {
      id: 'timestamp',
      name: 'Time',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return timestampToDate(row.timestamp, false, 'YYYY-MM-DD HH:mm')
      }
    },
    {
      id: 'category',
      name: 'Category',
      type: 'div',
      size: 1
    },
    {
      id: 'action',
      name: 'Action',
      type: 'div',
      size: 1
    },
    {
      id: 'value',
      name: 'Value',
      type: 'div',
      size: 3,
      valueFunction: (val, row) => {
        if(isJson(val)){
          let json = JSON.parse(val);

          return (
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>JSON</Accordion.Header>
                <Accordion.Body>
                  <TreeviewObject
                    data={json}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )
        } else {
          return val;
        }
      }
    },
  ];

  const inboxCols = [
    {
      id: 'id',
      name: 'ID',
      type: 'div',
      size: 1,
      minWidth: 100,
      valueFunction: (val) => (<div className="fs--2">{val}</div> )
    },
    {
      id: 'messageId',
      name: 'Message ID',
      type: 'div',
      size: 1,
      minWidth: 200,
      valueFunction: (val) => (<div className="fs--2">{val}</div> )
    },
    {
      id: 'flags',
      name: 'Flags',
      type: 'div',
      size: 2,
      valueFunction: (val) => {
        if( val === undefined ) return '-';

        return val.map(each => <div>{each}</div>)
      }
    },
    {
      id: 'labels',
      name: 'Labels',
      type: 'div',
      size: 2,
      valueFunction: (val) => {
        if( val === undefined ) return '-';

        return val.map(each => <div>{each}</div>)
      }
    },
    {
      id: 'from',
      name: 'From',
      type: 'div',
      size: 3,
      valueFunction: (val) => {
        if( val === undefined ) return '-';

        return val.address
      }
    },
    {
      id: 'date',
      name: 'Date',
      type: 'div',
      size: 3,
      valueFunction: (val) => (<div className="fs--2">{val}</div> )
    },
    {
      id: 'subject',
      name: 'Subject',
      type: 'div',
      size: 3
    },
    {
      id: 'unseen',
      name: 'Seen',
      type: 'div',
      size: 1,
      minWidth: 55,
      right: true,
      valueFunction: (val, row) => {
        if( val !== undefined ) {
          return <FontAwesomeIcon className={classNames('cursor-pointer', {
            ['text-success']: ! val,
            ['text-danger']: val
          })} icon={val ? 'times' : 'check' } />
        }

        if( row.flags !== undefined && row.flags.includes("\\Seen") ) return <FontAwesomeIcon className="cursor-pointer text-success" icon="check" />;

        return <FontAwesomeIcon className="cursor-pointer text-danger" icon="times" />
      }
    },
    {
      id: '',
      name: 'Controls',
      type: 'div',
      size: 1,
      minWidth: 100,
      valueFunction: (val, row) => {
        const seen = row.unseen !== undefined ? ! row.unseen : ( row.flags !== undefined && row.flags.includes("\\Seen") ? true : false );

        return (
          <div className="d-flex align-items-center">
            { seen
              ? <FontAwesomeIcon className="cursor-pointer text-success me-3" icon="envelope-open" onClick={() => updateEmail({id: row.id, seen: 0})} />
              : <FontAwesomeIcon className="cursor-pointer text-danger me-3" icon="envelope" onClick={() => updateEmail({id: row.id, seen: 1})} />
            }
            { <FontAwesomeIcon className="cursor-pointer text-success me-3" icon="folder-plus" onClick={() => updateEmail({id: row.id, folder: 1})} /> }
          </div>
        )
      }
    }
  ];

  const mailboxCols = [
    {
      id: 'path',
      name: 'Path',
      type: 'div',
      size: 1
    },
    {
      id: 'name',
      name: 'Name',
      type: 'div',
      size: 1
    },
    {
      id: 'parentPath',
      name: 'Parent',
      type: 'div',
      size: 1,
      valueFunction: (val) => {
        if( val === undefined ) return '-';

        return val;
      }
    }
  ];

  const bounceCols = [
    {
      id: 'timestamp',
      name: 'Time',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return timestampToDate(row.timestamp, false, 'YYYY-MM-DD HH:mm')
      }
    },
    {
      id: 'connected_email',
      name: 'To',
      type: 'div',
      size: 1
    },
    {
      id: 'connected_email_from',
      name: 'From',
      type: 'div',
      size: 1
    },
    {
      id: 'message',
      name: 'Message',
      type: 'div',
      size: 3
    },
    {
      id: 'code',
      name: 'Code',
      type: 'div',
      size: 1
    }
  ];

  console.log('item', item);

  return (
    <Section size="xxlg">
      <Card className="mb-3">
        <Card.Header as="h5">Connected Emails</Card.Header>
        <Card.Body className="bg-light">
          <Row className="mb-3">
            <Col>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Search..."
                    defaultValue={search}
                    onChange={(e) => loadSearch(e.target.value)}
                  />
                </Col>
                <Col>
                  <Form.Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="">Choose Status...</option>
                    {
                      ['connecting', 'warming', 'ready', 'reconnecting', 'disconnected', 'error', 'smtpError', 'bounce', 'senderBounce'].map(each => (
                        <option value={each}>{each}</option>
                      ))
                    }
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Select
                    value={provider}
                    onChange={(e) => setProvider(e.target.value)}
                  >
                    <option value="">Choose Provider...</option>
                    {
                      ['microsoft', 'google', 'zoho', 'smtp'].map(each => (
                        <option value={each}>{each}</option>
                      ))
                    }
                  </Form.Select>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div className="d-flex">
                    {
                      viewNext ? <Button onClick={() => setViewNext(false) }>Back</Button> : (
                        ! viewTooManyOther ? (
                          <TooltipItem tooltip="Shows connected emails that have missed their sending time">
                            <Button onClick={getNext}>View Past Next</Button>
                          </TooltipItem>
                        ): null)
                    }
                    {
                      viewTooManyOther ? <Button onClick={() => setViewTooManyOther(false) }>Back</Button> : (
                        ! viewNext ? (
                          <TooltipItem tooltip="Shows connected emails that have a lot of 'other' results">
                            <Button className="ms-3" onClick={getTooManyOther}>View Too Many Other</Button>
                          </TooltipItem>
                        ) : null)
                    }
                  </div>
                </Col>
              </Row>
              {
                viewNext ? (
                  <div className="mt-5">
                    <h5><strong>Emails with Next Values in the Past</strong></h5>
                    <FilterTable data={nextData} columns={nextCols} />
                  </div>
                ) : null
              }
              {
                viewTooManyOther ? (
                  <div className="mt-5">
                    <h5><strong>Emails with Too Many Other</strong></h5>
                    <FilterTable data={tooManyOtherData} columns={cols} />
                  </div>
                ) : null
              }
              { ! viewNext && ! viewTooManyOther ? (
                  <div className="mt-5">
                    {
                      item ? (
                        <div>
                          <Button variant="primary" onClick={() => {
                            setConnectedEmail(false)
                            setItem(false)
                          }}>Back</Button>
                          <h4 className="mt-3">{connectedEmail.email}</h4>
                          <div><strong>{connectedEmail.id}</strong></div>
                          <div><strong>{connectedEmail.status}</strong></div>
                          <div className="d-flex align-items-center">
                            <div>
                              <Form.Select
                                defaultValue=""
                                onChange={(e) => setNewStatus(e.target.value)}
                              >
                                <option value="">Set new status...</option>
                                {
                                  getStatusArray().map(each => <option value={each}>{each}</option> )
                                }
                              </Form.Select>
                            </div>
                            <Button variant="text" onClick={updateConnectedEmailStatus}>Save New Status</Button>
                          </div>
                          <Row className="mt-5">
                            <Col md={3}>
                              <h5><strong>Meta</strong></h5>
                              {
                                item.meta !== undefined ? Object.keys(item.meta).map(each => {
                                  return <div><strong>{each}</strong>: {item.meta[each]}</div>
                                }) : null
                              }
                            </Col>
                            <Col md={3}>
                              <h5><strong>Cycle</strong></h5>
                              {
                                item.cycle !== undefined && item.cycle ? Object.keys(item.cycle).map(each => {
                                  if( each === 'start' || each === 'next' ){
                                    return <div><strong>{each}</strong>: {timestampToDate(item.cycle[each], false, 'YYYY-MM-DD HH:mm')}</div>
                                  }

                                  return <div><strong>{each}</strong>: {item.cycle[each]}</div>
                                }) : null
                              }
                              <div className="mt-3"><strong>Next Start</strong>: {timestampToDate(item.next_start, false, 'YYYY-MM-DD HH:mm')}</div>
                              <div className=""><strong>Next Start New</strong>: {timestampToDate(item.next_start_new, false, 'YYYY-MM-DD HH:mm')}</div>
                            </Col>
                            <Col md={3}>
                              <h5><strong>Schedule</strong></h5>
                              {
                                item.schedule !== undefined && item.schedule ? Object.keys(item.schedule).map(each => {
                                  let val = item.schedule[each];

                                  return <div><strong>{each}</strong>: {val}</div>
                                }) : null
                              }
                            </Col>
                            <Col md={3}>
                              <h5><strong>Projection</strong></h5>
                              <Row>
                                <Col md={6}>
                                  <ol>
                                    {
                                      item.times.map(each => <li className="fs--1">{ timestampToDate(each, false, 'YYYY-MM-DD HH:mm') }</li>)
                                    }
                                  </ol>
                                </Col>
                                <Col md={6}>
                                  <ol>
                                    {
                                      item.times_new.map(each => <li className="fs--1">{ timestampToDate(each, false, 'YYYY-MM-DD HH:mm') }</li>)
                                    }
                                  </ol>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row className="mt-5">
                            <h5><strong>Emails Sent Past 24 hrs ({item.emails.length})</strong></h5>
                            <FilterTable data={ item.emails } columns={ emailCols }  />
                          </Row>
                          <Row className="mt-5">
                            <h5><strong>Emails Received Past 24 hrs ({item.received.length})</strong></h5>
                            <FilterTable data={ item.received } columns={ emailCols }  />
                          </Row>
                          <Row className="mt-5">
                            <h5><strong>Last 10 Cycles</strong></h5>
                            <FilterTable data={ item.cycles } columns={ cycleCols }  />
                          </Row>
                          <Row className="mt-5">
                            <h5><strong>Bounces To</strong></h5>
                            <FilterTable data={ item.bounces_to } columns={ bounceCols }  />
                          </Row>
                          <Row className="mt-5">
                            <h5><strong>Bounces From</strong></h5>
                            <FilterTable data={ item.bounces_from } columns={ bounceCols }  />
                          </Row>
                          <Row className="mt-5">
                            <div className="d-flex align-items-center">
                              <h5><strong>Inbox</strong> ({item.inbox?.total} messages, {item.inbox?.page}/{item.inbox?.pages})</h5>
                              {
                                parseInt(item.inbox?.pages, 10) > 1 ? (
                                  <>
                                    {
                                      parseInt(item.inbox?.page, 10) > 0 ? (
                                        <Button size="sm" className="ms-3" onClick={() => getInbox(parseInt(item.inbox?.page, 10) - 1)}>Prev</Button>
                                      ) : null
                                    }
                                    {
                                      parseInt(item.inbox?.page, 10) < parseInt(item.inbox?.pages, 10) ? (
                                        <Button size="sm" className="ms-3" onClick={() => getInbox(parseInt(item.inbox?.page, 10) + 1)}>Next</Button>
                                      ) : null
                                    }
                                  </>
                                ) : null
                              }
                            </div>
                            { item.inbox !== undefined && item.inbox ? <FilterTable data={ item.inbox?.messages } columns={ inboxCols } /> : null }
                          </Row>
                          <Row className="mt-5">
                            <h5><strong>Keep Warm</strong> ({item.keepWarm?.total} messages, {item.keepWarm?.page}/{item.keepWarm?.pages})</h5>
                            { item.keepWarm !== undefined && item.keepWarm ? <FilterTable data={ item.keepWarm?.messages !== undefined ? item.keepWarm?.messages : [] } columns={ inboxCols } /> : null }
                          </Row>
                          <Row className="mt-5">
                            <h5><strong>Junk</strong> ({item.junk?.total} messages, {item.junk?.page}/{item.junk?.pages})</h5>
                            { item.junk !== undefined && item.junk ? <FilterTable data={ item.junk?.messages !== undefined ? item.junk.messages : [] } columns={ inboxCols } /> : null }
                          </Row>
                          <Row className="mt-5">
                            <h5><strong>Mailboxes</strong></h5>
                            { item.mailboxes !== undefined && item.mailboxes ? <FilterTable data={ item.mailboxes } columns={ mailboxCols } /> : null }
                          </Row>
                          <div className="mt-5">
                            <h5><strong>Logs</strong></h5>
                            {
                              item.logs !== undefined ? <FilterTable data={ item.logs } columns={ logCols }  /> : null
                            }
                          </div>
                          <div className="mt-5">
                            <Button variant="danger" onClick={ cleanupInbox }>Cleanup Inbox</Button>
                          </div>
                          <div className="mt-5">
                            <h3>Testing</h3>
                            <Button variant="danger" onClick={ createTest }>Create Test</Button>
                            <div className="mt-3">
                              <TreeviewObject data={test} />
                            </div>
                            <div className="mt-3">
                              <Button variant="danger" onClick={ getTest }>Monitor Test</Button>
                              <div className="list-group">
                                {
                                  testData.length ? testData.map(each => {
                                    return (
                                      <div className={ classNames('list-group-item d-flex', {
                                        ['bg-success']: each.found
                                      })
                                      }>
                                        <div>{each.subject}</div>
                                        <div className="ms-auto">{each.ee_id}</div>
                                      </div>
                                    )
                                  }) : null
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : <FilterTable data={ items } columns={ cols } />
                    }
                  </div>
                ) : null
              }
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Section>
  );
};

ConnectedEmails.propTypes = {};

export default ConnectedEmails;
