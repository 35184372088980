import React, { useState, useEffect, useContext } from 'react';
//import PropTypes from 'prop-types';
//import classNames from 'classnames';
import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import Section from 'components/common/Sec';
import { AppContext, AdminContext } from "../context/Context";
import { loadUsers } from './load/users';
import FilterTable from '../components/common/FilterTable';
import { loadPlans } from './load/plans';
import { loadPlansAccounts } from './load/plans';
import { errorMessage, successMessage, timestampToDate } from '../helpers/utils';
import API from '../api/api';

const ModalForm = ({
  createPlan = false,
  updatePlan = false,
  planAccountId = '',
  planId = '',
  plan = {}
}) => {

  const [newPlan, setNewPlan] = useState({
    number: 1,
    coupon: plan.coupon !== undefined && plan.coupon ? plan.coupon : '',
    product_id: plan.product_id !== undefined && plan.product_id ? plan.product_id : '',
    subscription_id: plan.subscription_id !== undefined && plan.subscription_id ? plan.subscription_id : '',
    billing_email: plan.billing_email !== undefined && plan.billing_email ? plan.billing_email : '',
    affiliate_id: plan.affiliate_id !== undefined && plan.affiliate_id ? plan.affiliate_id : '',
    affiliate_email: plan.affiliate_email !== undefined && plan.affiliate_email ? plan.affiliate_email : '',
    allowances: plan.allowances !== undefined && plan.allowances ? plan.allowances : {},
  });

  console.log({
    planStructure: plan.structure,
    newPlanAllowances: newPlan.allowances,
    newPlan, plan
  })

  const setAllowance = (allowance, val) => {
    setNewPlan({
      ...newPlan,
      allowances: {
        ...newPlan.allowances,
        [allowance]: val
      }
    })
  }

  const set = (prop, val) => {
    setNewPlan({
      ...newPlan,
      [prop]: val
    })
  }

  const max = parseInt(plan.max, 10);

  return (
    <Form>
      {
        //If plan.plan is defined then we're editing an existing subscription/account_plan
        max > 1 && ( plan.plan === undefined || ! plan.plan ) ? (
          <Form.Group className="mb-3">
            <Form.Label>Number</Form.Label>
            <Form.Control
              placeholder={1}
              value={newPlan.number}
              name="number"
              onChange={(e) => e.target.value <= max && e.target.value > 0 ? set('number', e.target.value) : null}
              type="number"
            />
          </Form.Group>
        ) : null
      }
      <Form.Group className="mb-3">
        <Form.Label>Coupon</Form.Label>
        <Form.Control
          placeholder={''}
          value={newPlan.coupon}
          name="coupon"
          onChange={(e) => set('coupon', e.target.value)}
          type="text"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Product ID</Form.Label>
        <Form.Control
          placeholder={''}
          value={newPlan.product_id}
          name="product_id"
          onChange={(e) => set('product_id', e.target.value)}
          type="text"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Subscription ID</Form.Label>
        <Form.Control
          placeholder={''}
          value={newPlan.subscription_id}
          name="subscription_id"
          onChange={(e) => set('subscription_id', e.target.value)}
          type="text"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Billing Email</Form.Label>
        <Form.Control
          placeholder={''}
          value={newPlan.billing_email}
          name="billing_email"
          onChange={(e) => set('billing_email', e.target.value)}
          type="text"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Affiliate ID</Form.Label>
        <Form.Control
          placeholder={''}
          value={newPlan.affiliate_id}
          name="affiliate_id"
          onChange={(e) => set('affiliate_id', e.target.value)}
          type="text"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Affiliate Email</Form.Label>
        <Form.Control
          placeholder={''}
          value={newPlan.affiliate_email}
          name="affiliate_email"
          onChange={(e) => set('affiliate_email', e.target.value)}
          type="text"
        />
      </Form.Group>
      <h5>Allowances</h5>
      {
        plan.structure.map(each => {
          if( each.overage === undefined || ! parseInt(each.overage, 10) ) return null;

          const allowance = newPlan.allowances[each.id];

          return (
            <Form.Group className="mb-3">
              <Form.Label>{each.name}</Form.Label>
              <Form.Control
                placeholder={1}
                defaultValue={ allowance !== undefined ? parseInt(allowance, 10) : 0 }
                name="number"
                onChange={(e) => setAllowance(each.id, e.target.value)}
                type="number"
              />
            </Form.Group>
          )
        })
      }
      <Button variant="primary" onClick={() => {
        if( createPlan !== false ){
          createPlan(planId, newPlan);
        } else if( updatePlan !== false ){
          updatePlan(planAccountId, newPlan);
        }
      }}>{ createPlan !== false ? 'Add' : 'Update' }</Button>
    </Form>
  )
}

const Subscriptions = () => {
  const { adminDispatch } = useContext(AdminContext);
  const { appConfirm } = useContext(AppContext);
  const [search, setSearch] = useState('');
  const [user, setUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [plans, setPlans] = useState([]);
  const [userPlans, setUserPlans] = useState([]);
  const [planId, setPlanId] = useState('');
  const [cancelPlanId, setCancelPlanId] = useState('');
  const [newPlan, setNewPlan] = useState({
    number: 1,
    coupon: '',
    product_id: '',
    subscription_id: '',
    billing_email: '',
    affiliate_id: '',
    affiliate_email: '',
    allowances: '',
  });

  console.log({newPlan, planId, userPlans, plans});

  //The empty array as the second argument makes this run like componentDidMount
  //https://reacttraining.com/blog/useEffect-is-not-the-new-componentDidMount/
  useEffect(() => {
    loadUsers(adminDispatch, setUsers)
  }, []);

  useEffect(() => {
    loadPlans(adminDispatch, setPlans)
  }, []);

  let searchTimer = null;

  const loadSearch = (search) => {
    setSearch(search);
    clearTimeout(searchTimer);

    searchTimer = setTimeout(() => {
      loadUsers(adminDispatch, setUsers, search);
    }, 500);
  }

  const chooseAccount = (val) => {
    console.log(val);
    setUser(val);

    const params = [
      {
        param: 'user',
        value: val
      }
    ];

    loadPlansAccounts(adminDispatch, setUserPlans, params);
  }

  const createPlan = (thePlanId, theNewPlan) => {
    setNewPlan(theNewPlan);

    const createPlan = {
      ...theNewPlan,
      plan: thePlanId,
      user: user
    };

    API.doRequest('plans_accounts', 'create', 'create', {update: createPlan}).then(result => {
      successMessage('Plan Added');

      const params = [
        {
          param: 'user',
          value: user
        }
      ];

      loadPlansAccounts(adminDispatch, setUserPlans, params);
    }).catch(error => {
      errorMessage('Plan could not be added');
    })
  }

  const addPlan = (plan) => {
    setPlanId(plan.id);

    appConfirm({
      type: 'message',
      confirmText: 'Done',
      confirmColor: 'text',
      header: 'Add ' + plan.name,
      confirm: null,
      body: <ModalForm plan={plan} planId={plan.id} createPlan={createPlan} />,
    })
  }

  const maybeCancelPlan = (val) => {
    console.log(val);
    setCancelPlanId(val.id);

    appConfirm({
      type: 'confirm',
      confirm: () => cancelPlan(val.id, val.account),
      confirmText: 'Cancel',
      confirmColor: 'danger',
      cancelText: 'Don\'t Cancel',
      cancelColor: '',
      header: 'Cancel ' + getPlanDetail(val.plan) + ' Plan?',
      body: 'Are you sure you want to cancel this plan for the account?',
    });
  }

  const cancelPlan = (val, account) => {
    API.doRequest('plans_accounts', 'cancel', 'update', {item: val}, {params: [{param: 'parent', value: account}]}).then(result => {
      successMessage('Plan Cancelled');

      const params = [
        {
          param: 'user',
          value: user
        }
      ];

      loadPlansAccounts(adminDispatch, setUserPlans, params);
    }).catch(error => {
      errorMessage('Plan could not be cancelled');
    })
  }

  const editPlan = (plan) => {
    setPlanId(plan.plan);

    plan.max = getPlanDetail(plan.plan, 'max');
    plan.structure = getPlanDetail(plan.plan, 'structure');

    appConfirm({
      type: 'message',
      confirmText: 'Done',
      confirmColor: 'text',
      header: 'Edit ' + getPlanDetail(plan.plan),
      confirm: null,
      body: <ModalForm plan={plan} planId={plan.plan} planAccountId={plan.id} updatePlan={updatePlan} />,
    })
  }

  const updatePlan = (thePlanId, theNewPlan) => {
    setNewPlan(theNewPlan);

    const createPlan = {
      ...theNewPlan,
      id: thePlanId,
      user: user
    };

    API.doRequest('plans_accounts', 'update', 'update', {update: createPlan}).then(result => {
      successMessage('Plan Updated');

      const params = [
        {
          param: 'user',
          value: user
        }
      ];

      loadPlansAccounts(adminDispatch, setUserPlans, params);
    }).catch(error => {
      errorMessage('Plan could not be updated');
    })
  }

  const getPlanDetail = (id, prop = 'name') => {
    return plans.reduce((acc, cur) => {
      if(cur.id === id){
        acc = cur[prop];
      }
      return acc;
    }, '');
  }

  const cols = [
    {
      id: 'first_name',
      name: 'First Name',
      type: 'div',
      size: 1
    },
    {
      id: 'last_name',
      name: 'Last Name',
      type: 'div',
      size: 1
    },
    {
      id: 'user_email',
      name: 'Email',
      type: 'div',
      size: 1
    },
    {
      id: 'id',
      name: '',
      type: 'div',
      size: 1,
      right: true,
      valueFunction: (val, row) => {
        return <button className="btn btn-primary" onClick={() => chooseAccount(row.id)}>Switch</button>
      }
    }
  ];

  const plansCols = [
    {
      id: 'id',
      name: 'ID',
      type: 'div',
      size: 1
    },
    {
      id: 'name',
      name: 'Name',
      type: 'div',
      size: 1
    },
    {
      id: 'price',
      name: 'Price',
      type: 'div',
      size: 1
    },
    {
      id: 'type',
      name: 'Type',
      type: 'div',
      size: 1
    },
    {
      id: 'period',
      name: 'Period',
      type: 'div',
      size: 1
    },
    {
      id: 'id',
      name: '',
      type: 'div',
      size: 1,
      right: true,
      valueFunction: (val, row) => {
        return <button className="btn btn-primary" onClick={() => addPlan(row)}>Add...</button>
      }
    }
  ];

  const plansAccountsCols = [
    {
      id: 'id',
      name: 'Subscription',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return (
          <>
            <div><strong>{getPlanDetail(row.plan, 'name')}</strong></div>
            <div>Type: {getPlanDetail(row.plan, 'type')}</div>
            <div>Coupon: {row.coupon}</div>
            <div>{row.billing_email}</div>
          </>
        );
      }
    },
    {
      id: 'product_id',
      name: 'Subscription Info',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return (
          <>
            <div>Product ID: {row.product_id}</div>
            <div>Subscription ID: {row.subscription_id}</div>
          </>
        );
      }
    },
    {
      id: 'created',
      name: 'Details',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        console.log('created', row);
        return (
          <>
            <div>Created: {parseInt(row.created, 10) ? timestampToDate(row.created) : '-'}</div>
            <div>Next: {parseInt(row.next, 10) ? timestampToDate(row.next) : '-'}</div>
            <div>Expires: {parseInt(row.expires, 10) ? timestampToDate(row.expires) : '-'}</div>
            {
              parseInt(row.cancelled, 10) ? (
                <div>Cancelled: {timestampToDate(row.cancelled)}</div>
              ) : null
            }
          </>
        );
      }
    },
    {
      id: 'id',
      name: '',
      type: 'div',
      size: 1,
      right: true,
      valueFunction: (val, row) => {
        return parseInt(row.cancelled, 10) ? <div className="alert alert-danger mb-0">Cancelled</div> : (
          <>
            <Button className="me-2" variant="primary" onClick={() => editPlan(row)}>Edit...</Button>
            <Button variant="danger" onClick={() => maybeCancelPlan(row)}>Cancel...</Button>
          </>
        );
      }
    }
  ];

  return (
    <Section size="lg">
      <Card className="mb-3">
        <Card.Header as="h5">Load User</Card.Header>
        <Card.Body className="bg-light">
          <Row className="mb-3">
            <Col>
              <Form.Control
                type="text"
                placeholder="Search..."
                defaultValue={search}
                onChange={(e) => loadSearch(e.target.value)}
              />
              <div className="mt-5">
                <FilterTable data={ users } columns={ cols } />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {
        user ? (
          <Card className="mb-3">
            <Card.Header as="h5">Choose Plans</Card.Header>
            <Card.Body className="bg-light">
              <Row className="mb-3">
                <Col>
                  <FilterTable data={ plans } columns={ plansCols } />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ) : null
      }
      {
        user ? (
          <Card className="mb-3">
            <Card.Header as="h5">Current Plans</Card.Header>
            <Card.Body className="bg-light">
              <Row className="mb-3">
                <Col>
                  <FilterTable data={ userPlans } columns={ plansAccountsCols } />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ) : null
      }
    </Section>
  );
};

Subscriptions.propTypes = {};

export default Subscriptions;
