import API from '../../api/api';

export const loadConnectedEmails = (updateRedux, updateState, {id = '', search = '', offset = 0, limit = 50, status = '', provider = ''}) => {
  return new Promise((resolve, reject) => {
    let params = [
      {
        param: 'limit',
        value: limit
      },
      {
        param: 'offset',
        value: offset
      },
      {
        param: 'super',
        value: 'connected_email'
      }
    ];

    if( search ){
      params.push({
        param: 'search',
        value: search
      });
    }

    if( status ){
      params.push({
        param: 'status',
        value: status
      });
    }

    if( provider ){
      params.push({
        param: 'provider',
        value: provider
      });
    }

    let update = {};

    if( id ){
      update.id = id;
    }

    console.log({id, search, offset, limit, update, params});

    API.doRequest('super', 'get_connected_emails', 'update', {update}, {params}).then(result => {
      console.log('connected_emails search result', result);

      if( result.data !== undefined ){
        const items = result.data;

        updateRedux({
          type: 'SET_CONNECTED_EMAILS',
          payload: items
        });
        updateState(items);
        resolve(items);
      }
    });
  });
};