import React from 'react';
import Project from '../Project';
import SpamChecker from './SpamChecker';

const SpamCheckerWrap = () => {
  return (
    <Project>
      <div className="width-100">
        <SpamChecker />
      </div>
    </Project>
  );
};

export default SpamCheckerWrap;
