import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Checklist = ({
  checklist,
  icon = 'check',
  color = 'success',
  spacing = '2'
}) => {
  const theIcon = <FontAwesomeIcon className={`text-${color} me-3 mt-1`} icon={icon} />

  return (
    <ul className="list-unstyled mt-3">
      {checklist.map((each, i) => <li className={classNames('d-flex', {[`mt-${spacing}`]: i !== 0})}>{theIcon} {each}</li>)}
    </ul>
  );
}

Checklist.propTypes = {
  checklist: PropTypes.array.isRequired,
  icon: PropTypes.string
};

export default Checklist;
