import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from 'context/Context';
import { settings } from 'config';
import { getColor, getItemFromStore, getURLParameter } from 'helpers/utils';
import { configReducer } from 'reducers/configReducer';
import { appReducer } from 'reducers/appReducer';
import { initialAppState } from 'data/initialize';
import useToggleStyle from 'hooks/useToggleStyle';

const AppProvider = props => {
  const configState = {
    isFluid: true,
    isRTL: getItemFromStore('isRTL', settings.isRTL),
    isDark: getItemFromStore('isDark', settings.isDark),
    navbarPosition: 'top',
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: 'transparent',
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false,
    embed: parseInt( getURLParameter('ifembed'), 10 ) === 1
  };

  const [config, configDispatch] = useReducer(configReducer, configState);

  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    configDispatch
  );

  const setConfig = (key, value) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isFluid',
          'isRTL',
          'isDark',
          'navbarPosition',
          'isNavbarVerticalCollapsed',
          'navbarStyle'
        ].includes(key)
      }
    });
  };

  const [app, appDispatch] = useReducer(appReducer, initialAppState);

  const appConfirm = (options) => {
    appDispatch({
      type: 'CONFIRM_MODAL',
      payload: {
        ...initialAppState.confirmModal,
        ...options,
        show: true
      }
    })
  };

  const appConfirmDismiss = () => {
    appDispatch({
      type: 'CONFIRM_MODAL',
      payload: initialAppState.confirmModal
    });
  }

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor('dark') : getColor('light')
        }}
      />
    );
  }

  return (
    <AppContext.Provider value={{ config, setConfig, app, appConfirm, appConfirmDismiss }}>
      {props.children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = { children: PropTypes.node };

export default AppProvider;
