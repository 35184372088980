import React from 'react';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';

const LoaderButton = ({
  iconAlign = 'left',
  variant = 'primary',
  loading = false,
  loaderVariant = 'light',
  children,
  ...rest
}) => {
  return (
    <IconButton
      variant={variant}
      iconAlign={iconAlign}
      {...rest}
    >
      {
        loading ? <Spinner animation="border" variant={loaderVariant} size="sm" /> : children
      }
    </IconButton>
  );
};

LoaderButton.propTypes = {
  ...Button.propTypes,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  children: PropTypes.any.isRequired,
  iconAlign: PropTypes.oneOf(['left', 'right']),
  variant: PropTypes.string,
  loading: PropTypes.bool
};

export default LoaderButton;
