import React, { useState, useEffect, useContext } from 'react';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import Section from 'components/common/Sec';
import { AppContext } from "context/Context";
import API from 'api/api';
import FormInfo from '../components/common/FormInfo';
import Toggle from '../components/common/Toggle';
import { errorMessage, successMessage } from '../helpers/utils';

const Platform = () => {
  const { appConfirm } = useContext(AppContext);
  const defaultSettings = {
    sendCronLimit: 20,
    disableSending: 0,
    disableWebhooks: 0
  }

  const [settings, setSettings] = useState(defaultSettings);

  let params = [
    { param: 'super', value: 'platform' }
  ];

  useEffect(() => {
    API.doRequest('super', 'get_settings', 'get', null, {params}).then(result => {
      setSettings(result.data);
    });
  }, []);

  const updateSettings = () => {
    API.doRequest('super', 'update_settings', 'update', {update: settings}, {params}).then(result => {
      successMessage('Saved!');
    }).catch(error => {
      errorMessage('Could not save');
    });
  }

  const updateSetting = (which, value) => {
    setSettings({
      ...settings,
      [which]: value
    })
  }

  console.log(settings);

  return (
    <Section size="xxlg">
      <Card className="mb-3">
        <Card.Header as="h5">Platform Settings</Card.Header>
        <Card.Body className="bg-light">
          <Row>
            <Col>
              <Form.Group>
                <Form.Label for="sendCronLimit">
                  Send Cron Limit
                  <FormInfo id="sendCronLimit">
                    If there is a backlog of submit webhooks, or Send cron requests are timing out, we can decrease this. 20 should be the default
                  </FormInfo>
                </Form.Label>
                <Form.Control
                  placeholder=""
                  value={settings.sendCronLimit}
                  name="sendCronLimit"
                  onChange={(e) => updateSetting('sendCronLimit', e.target.value)}
                  type="number"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group className="mt-3">
                <Form.Label for="disableSending">
                  Turn Off Sending
                  <FormInfo id="disableSending">
                    If enabled, sending will be turned off
                  </FormInfo>
                </Form.Label>
                <Toggle value={settings.disableSending} numeric update={(value) => updateSetting('disableSending', value)} />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mt-3">
                <Form.Label for="disableWebhooks">
                  Turn Off Webhook Processing
                  <FormInfo id="disableWebhooks">
                    If enabled, webhooks from EE will no longer be processes
                  </FormInfo>
                </Form.Label>
                <Toggle value={settings.disableWebhooks} numeric update={(value) => updateSetting('disableWebhooks', value)} />
              </Form.Group>
            </Col>
          </Row>
          <div className="mt-5">
            <Button variant="primary" onClick={updateSettings}>Save</Button>
          </div>
        </Card.Body>
      </Card>
    </Section>
  );
};

Platform.propTypes = {};

export default Platform;