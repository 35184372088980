import React, { useContext } from 'react';

//https://react-select.com/async
import AsyncSelect from 'react-select/async';
import { loadConnectedEmailSearch } from 'load/connected_emails';
import cookie from 'react-cookies';
import { AccountContext } from 'context/Context';
import API from 'api/api';

const CustomNavbarSearch = () => {
  const defaultOptions = loadConnectedEmailSearch('', 'dropdown');
  const { accountState } = useContext(AccountContext);
  const connectedEmail = accountState?.connected_email?.id !== undefined ? accountState.connected_email : false;

  const loadOptions = (search = '') => {
    if (search.length < 3) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(defaultOptions)
        }, 1);
      })
    } else {
      return new Promise((resolve, reject) => {
        let params = [
          { param: 'limit', value: 10 },
          { param: 'offset', value: 0 },
          { param: 'search', value: search }
        ];

        API.doRequest('connected_emails', 'get', 'get', null, { params: params }).then(result => {

          if (result.data !== undefined) {
            const exists = connectedEmail ? result.data.find(each => each.id === connectedEmail.id) : false;

            if (!exists && connectedEmail?.id !== undefined && connectedEmail.id) {
              result.data.push(connectedEmail)
            }

            resolve(result.data.map(each => {
              return { value: each.id, label: each.email }
            }));
          }

        }).catch(error => {
          reject(error);
        });
      })
    }
  }

  const chooseConnectedEmail = (option) => {
    if( option === null || ! option ){
      cookie.remove(SSSettings.namelower + '_connected_email', { path: '/' })
      window.location.href = '/';
      return;
    }

    cookie.save(SSSettings.namelower + '_connected_email', option.value, { path: '/', maxAge: (1000 * 60 * 60 * 24) });
    window.location.href = '/email/overview';
  }

  return (
    <div className="ps-5 pe-3">
      <AsyncSelect
        id="connected-email-dropdown"
        className="ce-dropdown"
        classNamePrefix="ce-dropdown"
        isClearable={true}
        styles={{ input: (base) => ({ ...base, minWidth: 180 }) }}
        defaultOptions={true}
        loadOptions={loadOptions}
        placeholder="Select an email..."
        onChange={chooseConnectedEmail}
        value={connectedEmail ? { label: connectedEmail.email, value: connectedEmail.id } : ''}
      />
    </div>
  );
};

export default CustomNavbarSearch;
