import React, { useState, useEffect } from 'react';
import { Button, Accordion } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Api from 'api/api';
import { errorMessage, isSuper, successMessage, timestampToDate } from 'helpers/utils';
import FormInfo from '../common/FormInfo';

const HealthCheck = ({
  connectedId,
  showEELink = false,
  title = 'Health Check',
  onCheckUpdate = false,
  onCheckFinish = false,
  current
}) => {

  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(false);

  const checks = [
    'imap',
    'smtp',
    'verification',
    'blacklist',
    'mx',
    'spf',
    'dkim',
    'dmarc'
  ];

  const checkLegend = {
    imap: {
      name: 'IMAP'
    },
    smtp: {
      name: 'SMTP'
    },
    blacklist: {
      name: 'Blacklist',
      tooltip: 'Checks to see if your email address is listed on any blacklists'
    },
    verification: {
      name: 'Email Verification',
      tooltip: 'Does a general check to see if email is deliverable to your address. Not 100% accurate, so do not worry about a fail result unless the email is failing other checks as well.'
    },
    mx: {
      name: 'MX Records'
    },
    spf: {
      name: 'SPF Record'
    },
    dkim: {
      name: 'DKIM Record'
    },
    dmarc: {
      name: 'DMARC Record'
    }
  }

  const finish = (result = false) => {
    setLoading(false);

    if( ! result ) return;

    setResults(result);
    let newStatus = false;

    if( result?.meta?.imap_result === 'fail' || result?.meta?.smtp_result === 'fail' ){
      if( ['new', 'connecting', 'connected', 'ready', 'reconnecting'].includes(current) ){
        if( result?.meta?.imap_result === 'fail' ) newStatus = 'error';
        else if( result?.meta?.smtp_result === 'fail' ) newStatus = 'smtpError';
      }
      if( ['error', 'authenticationError'].includes(current) && result?.meta?.smtp_result === 'fail' && result?.meta?.imap_result !== 'fail' ) newStatus = 'smtpError';
      if( ['smtpError'].includes(current) && result?.meta?.imap_result === 'fail' ) newStatus = 'error';
      if( ['warming'].includes(current) ) newStatus = 'disconnected';
    } else if(result?.meta?.imap_result === 'pass'){
      if( ['new', 'connecting', 'authenticationError', 'error', 'smtpError'].includes(current) ) newStatus = 'ready';
      if( ['disconnected', 'reconnecting'].includes(current) ) newStatus = 'warming';
    } else if(result?.meta?.imap_result === 'wait'){
      if( ['new'].includes(current) ) newStatus = 'connecting';
    }

    if( onCheckUpdate === true && newStatus ){
      Api.doRequest('connected_emails', 'update_status', 'update', {update:{
          status: newStatus,
          id: connectedId
        }})
    }

    if( onCheckFinish !== false ){
      onCheckFinish({
        connectedId, newStatus
      });
    }
  }

  //Create the check - 'connected_email_check', 'create'
  //Check imap - 'connected_email_check', 'check_status'
  //Create smtp test - 'connected_email_check', 'create_delivery_test'
  //Check smtp test results - 'connected_email_check', 'get_delivery_test'
  useEffect(() => {
    getCheck();
  }, []);

  const getCheck = () => {
    setLoading(true);
    Api.doRequest('connected_email_check', 'get_recent_health_check', 'update', {update: {
        id: connectedId
      }}).then(result => {
      finish(result.data);
    }).catch(error => {
      console.log(error);
      errorMessage('', error);
      finish();
    })
  }

  const newCheck = () => {
    setLoading(true);
    Api.doRequest('connected_email_check', 'create_health_check', 'update', {update: {
        id: connectedId
      }}).then(result => {
      finish(result.data);
    }).catch(error => {
      console.log(error);
      errorMessage('', error);
      finish();
    })
  }

  const titleElement = title ? <div className="mb-2"><strong>{title}</strong></div> : null;

  return (
    <>
      { titleElement }
      {
        <Accordion>
          {
            checks.map((each, index) => {
              const result = results.meta !== undefined ? results.meta[`${each}_result`] : null;
              const message = results.meta !== undefined ? results.meta[`${each}_message`] : null;
              let badge;

              if( result === undefined ){
                badge = <div className="badge badge-soft-gray-300 me-3">wait</div>;
              } else {
                badge = (
                  <div>
                    <div
                      className={classNames('badge me-3', {
                        ['badge-soft-gray-300']: result === 'wait' || result === 'skipped',
                        ['badge-soft-danger']: result === 'fail' || result === 'softfail',
                        ['badge-soft-success']: result === 'pass'
                      })}
                    >{result}</div>
                  </div>
                )
              }

              if( loading ) badge = <FontAwesomeIcon icon="sync-alt" className="fa-spin text-primary me-3" />;

              return (
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>
                    <div className="d-flex align-items-center justify-content-between width-100">
                      <span>
                        <span>{checkLegend[each].name}</span>
                        {
                          result === 'wait' || result === undefined ? (
                            <FormInfo>Please check back in 10 minutes</FormInfo>
                          ) : ( checkLegend[each].tooltip !== undefined ? <FormInfo>{checkLegend[each].tooltip}</FormInfo> : null )
                        }
                      </span>
                      { badge }
                    </div>
                  </Accordion.Header>
                  <Accordion.Body style={{overflowWrap: "break-word"}}>
                    { message !== undefined && message ? message : 'No info yet' }
                  </Accordion.Body>
                </Accordion.Item>
              )
            })
          }
        </Accordion>
      }
      { results && results.timestamp ? <div className="mt-3 fs--1"><em>Last health check: { timestampToDate(results.timestamp, 'US/Eastern', 'YYYY-MM-DD HH:mm') }</em></div> : null }
      <div className="mt-3">
        <Button variant="primary" onClick={newCheck}>Run New Health Check</Button>
      </div>
      { isSuper() || showEELink ? <div className="mt-3"><a target="_blank" href={ `https://engine2.emailsapi.com/admin/accounts/${connectedId}` }>EE Link</a></div> : null }
    </>
  )
}

export default HealthCheck;