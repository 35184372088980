import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Accordion, Button, Card, Form, Row, Col } from 'react-bootstrap';
import Section from 'components/common/Sec';
import FilterTable from 'components/common/FilterTable';
import { errorMessage, getStatusArray, isJson, secondsToTime, successMessage, timestampToDate } from '../helpers/utils';
import TreeviewObject from 'components/common/TreeviewObject';
import API from 'api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipItem from '../components/common/TooltipItem';
import HealthCheck from '../components/main/HealthCheck';

let searchTimer = null;

const Bounces = () => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('bounce');
  const [items, setItems] = useState([]);
  const [health, setHealth] = useState([]);

  const loadConnectedEmailBounce = () => {
    API.doRequest('super', 'get_connected_emails_bounce', 'get', null, {
      params: [
        { param: 'super', value: 'connected_email' },
        { param: 'search', value: search },
        { param: 'status', value: status },
        { param: 'limit', value: limit },
        { param: 'offset', value: (page - 1) * limit },
      ]
    }).then(result => {
      setItems(result.data)
    }).catch(error => {
      errorMessage('', error);
    })
  }

  useEffect(() => {
    loadConnectedEmailBounce();
  }, []);

  useEffect(() => {
    loadConnectedEmailBounce();
  }, [limit, search, status]);

  const getHealth = (id) => {
    setHealth(health.concat(id));
  }

  const loadSearch = (search) => {
    clearTimeout(searchTimer);

    searchTimer = setTimeout(() => {
      setSearch(search);
    }, 500);
  }

  const updateConnectedEmailStatus = (id, status) => {
    const update = {status};

    if( status === 'warming' ) update.active = 1;

    API.doRequest('super', 'update_connected_email', 'update', {update}, {
      params: [
        { param: 'super', value: 'connected_email' },
        { param: 'connected_email', value: id }
      ]
    }).then(result => {
      successMessage('', result);

      setItems(items.map((each) => {
          if(each.id === id){
            each = {
              ...each,
              ...update
            }
          }
          return each;
        })
      );

    }).catch(error => {
      errorMessage('', error);
    })
  }

  const bounceCols = [
    {
      id: 'timestamp',
      name: 'Time',
      type: 'div',
      size: 2,
      valueFunction: (val, row) => {
        const bounce = parseInt( row.timestamp, 10 );
        const sent = parseInt( row.email_timestamp, 10 );
        const difference = secondsToTime( bounce - sent );

        return (
          <>
            <div className="fs--1"><strong>Bounce</strong>: { timestampToDate(bounce, false, 'YYYY-MM-DD HH:mm') }</div>
            <div className="fs--1"><strong>Sent</strong>: { sent ? timestampToDate(sent, false, 'YYYY-MM-DD HH:mm') : '-' }</div>
            <div className="fs--1"><strong>Difference</strong>: { sent ? difference : '-' }</div>
          </>
        )
      }
    },
    {
      id: 'connected_email',
      name: 'To',
      type: 'div',
      size: 2,
      valueFunction: (val, row) => {
        return (
          <>
            <div className="fs--1">{row.to_email}</div>
            <div className="fs--2">{val}</div>
          </>
        )
      }
    },
    {
      id: 'connected_email_from',
      name: 'From',
      type: 'div',
      size: 2,
      valueFunction: (val, row) => {
        return (
          <>
            <div className="fs--1">{row.from_email}</div>
            <div className="fs--2">{val}</div>
          </>
        )
      }
    },
    {
      id: 'message',
      name: 'Message',
      type: 'div',
      size: 4,
      valueFunction: (val, row) => {
        return <div className="fs--1">{val}</div>
      }
    },
    {
      id: 'code',
      name: 'Code',
      type: 'div',
      size: 1
    }
  ];

  const cols = [
    {
      id: 'id',
      name: 'Info',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return (
          <div className="fs--1">
            <div>{row.id}</div>
            <div>{row.account}</div>
            <div>{timestampToDate(row.timestamp)}</div>
          </div>
        )
      }
    },
    {
      id: 'email',
      name: 'Email',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return (
          <div className="fs--1">
            <div><strong>{row.email}</strong></div>
            <div>IMAP Email: {row.imap_email}</div>
            <div>Provider: {row.provider}</div>
            <div>Offering: {row.offering}</div>
            { row.num_other !== undefined ? <div className="text-danger"><strong>Number Other: {row.num_other}</strong></div> : null }
          </div>
        )
      }
    },
    {
      id: 'status_timestamp',
      name: 'Disabled',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return (
          <div className="fs--1">
            { timestampToDate(val, 'US/Eastern', 'YYYY-MM-DD HH:mm') }
          </div>
        )
      }
    },
    {
      id: 'status',
      name: 'Status',
      type: 'div',
      size: 1,
      valueFunction: (val, row) => {
        return (
          <div className="fs--1">
            <div>Routine: {row.routine}</div>
            <div>Status: {row.status}</div>
            <div>Active: {row.active}</div>
            <div>Score: {row.score}</div>
            <div>Score Status: {row.score_status}</div>
          </div>
        )
      }
    },
    {
      id: 'id',
      name: '',
      type: 'div',
      size: 1,
      right: true,
      valueFunction: (val, row) => {
        return (
          <Form.Select
            defaultValue={row.status}
            onChange={(e) => updateConnectedEmailStatus(row.id, e.target.value)}
          >
            <option value="">Set new status...</option>
            {
              getStatusArray().map(each => <option value={each}>{each}</option> )
            }
          </Form.Select>
        )
      }
    },
    {
      id: 'id',
      name: '',
      type: 'div',
      size: 'full',
      valueFunction: (val, row) => {
        return (
          <div className="p-4 border border-300 border-1 mb-5">
            <div>
              <div><strong>Bounces To</strong></div>
              <FilterTable data={ row.bounces_to } columns={ bounceCols }  />
            </div>
            <div className="mt-4">
              <div><strong>Bounces From</strong></div>
              <FilterTable data={ row.bounces_from } columns={ bounceCols }  />
            </div>
            <div className="mt-4">
              {
                health.length && health.includes(row.id) ? (
                  <HealthCheck
                    connectedId={row.id}
                    current={row.status}
                    title="Health Check"
                    showEELink={true}
                    onCheckUpdate={false}
                    onCheckFinish={false} />
                ) : <Button onClick={() => getHealth(row.id)}>Get Health</Button>
              }
            </div>
          </div>
        )
      }
    }
  ];

  console.log('items', items);

  return (
    <Section size="xxlg">
      <Card className="mb-3">
        <Card.Header as="h5">Bounce Disabled Emails</Card.Header>
        <Card.Body className="bg-light">
          <Row className="mb-3">
            <Col>
              <Row>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Search..."
                    defaultValue={search}
                    onChange={(e) => loadSearch(e.target.value)}
                  />
                </Col>
                <Col>
                  <Form.Select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="">Choose Status...</option>
                    {
                      ['bounce', 'senderBounce'].map(each => (
                        <option value={each}>{each}</option>
                      ))
                    }
                  </Form.Select>
                </Col>
              </Row>
              <div className="mt-5">
                <FilterTable data={ items } columns={ cols } />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Section>
  );
};

Bounces.propTypes = {};

export default Bounces;
