import React from 'react';
import PropTypes from 'prop-types';
import ProviderChoice from './ProviderChoice';

const Google = ({
  offering,
  updateOffering
}) => {
  const providers = [
    {
      name: 'Google Workspace',
      img: false,
      id: 'workspace'
    },
    {
      name: 'Personal Gmail (@gmail.com)',
      img: false,
      id: 'gmail'
    }
  ];

  return (
    <>
      <h4>Choose Type of Google Email</h4>
      <ProviderChoice value={offering} update={updateOffering} providers={providers} />
    </>
  );
}

Google.propTypes = {
  offering: PropTypes.string.required,
  updateOffering: PropTypes.func.required
};

export default Google;
