import API from '../../api/api';

export const loadUsersSearch = (updateRedux, updateState, search) => {
  return new Promise((resolve, reject) => {
    const params = [
      {
        param: 'limit',
        value: 50
      },
      {
        param: 'search',
        value: search
      }
    ]

    API.doRequest('users', 'search', 'get', null, {params}).then(result => {
      console.log('users search result', result);

      if( result.data !== undefined ){
        const items = result.data;

        updateRedux({
          type: 'SET_USERS',
          payload: items
        });
        updateState(items);
        resolve(items);
      }

    }).catch(error => {
      reject(error);
    });
  });
};

export const loadUsers = (updateRedux, updateState, search = '') => {
  return new Promise((resolve, reject) => {
    let params = [
      {
        param: 'limit',
        value: 50
      }
    ];

    if( search ){
      params.push({
        param: 'search',
        value: search
      });
    }

    API.doRequest('users', 'get', 'get', null, {params}).then(result => {
      console.log('users result', result);

      if( result.data?.length ){
        const items = result.data;

        updateRedux({
          type: 'SET_USERS',
          payload: items
        });
        updateState(items);
        resolve(items);
      }

    }).catch(error => {
      reject(error);
    });
  });
}

export const loadFullUser = (updateRedux, updateState, userId) => {
  return new Promise((resolve, reject) => {
    let params = [
      {
        param: 'user',
        value: userId
      }
    ];

    API.doRequest('user', 'get_full_account', 'get', null, {params}).then(result => {
      console.log('get_full_account result', result);

      if( result.data !== undefined ){
        updateRedux({
          type: 'SET_FULL_USER',
          payload: result.data
        });
        updateState(result.data);
        resolve(result.data);
      }

    }).catch(error => {
      reject(error);
    });
  });
}