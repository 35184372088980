import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import TooltipItem from './TooltipItem';

const ClickToCopy = ({
  className = 'text-primary',
  border = true,
  text
}) => {

  let classes = classNames(className, 'click-to-copy d-inline-block', {
    ['dash-border']: border
  });

  return (
    <TooltipItem tooltip="Click to copy">
      <span className={ classes } onClick={() => {navigator.clipboard.writeText(text)}}>{text}</span>
    </TooltipItem>
  )

}

ClickToCopy.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
};

export default ClickToCopy;