import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

const MultiSelectDropdown = ({
  update,
  name = 'Select',
  options = []
}) => {
  let startingOptions = {};
  options.forEach(each => {
    if( typeof each === 'string' ){
      startingOptions[each] = false;
    } else {
      startingOptions[each.value] = false
    }
  });

  const [theOptions, setOptions] = useState(startingOptions);
  const [open, setOpen] = useState(false);

  const doUpdate = (prop, val) => {
    if( prop ){
      setOptions({
        ...options,
        [prop]: val
      });
      update(prop, val)
    }
  }
  let selected = [];

  let optionItems = options.map(option => {
    const val = option.value ? option.value : (typeof option !== 'object' ? option : '');
    const name = option.name ? option.name : ( typeof option !== 'object' ? option : '' );

    if( theOptions[val] && val ) selected.push(val);

    return {
      name: name,
      color: theOptions[val] ? 'success' : 'info-1',
      icon: theOptions[val] ? 'check' : 'minus',
      value: val
    }
  }).filter(option => option.value);

  optionItems = [{
    name: name,
    color: '',
    icon: '',
    value: ''
  }].concat(optionItems);

  let filterColor = 'info-3';

  if( selected.length ) {
    filterColor = 'info-1';
    name += ` (${selected.length})`;
  }

  if( typeof options === 'object' && ! Array.isArray(options) ) options = [options];

  return (
    <Dropdown className="multi-select-dropdown" isOpen={open} toggle={() => setOpen(!open)}>
      <Dropdown.Toggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={open}
      >
        <div className={ `crm-status ${filterColor} d-flex justify-content-between align-items-center` }>
          <span>{ name }</span>
          <i className="fa fa-chevron-down" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu right>
        {
          optionItems.map(each => {
            return (
              <Dropdown.Item className={ `crm-status ${each.color}` } onClick={() => doUpdate(each.value, theOptions[each.value])}>
                <i className={ `fa fa-${each.icon} mr-2` } /> {each.name}
              </Dropdown.Item>
            )
          })
        }
      </Dropdown.Menu>
    </Dropdown>
  );
}

MultiSelectDropdown.propTypes = {
  update: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.array
};

export default MultiSelectDropdown;