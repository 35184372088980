import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import ConnectForm from './ConnectForm';
import Checklist from '../../common/Checklist';

const SMTPConnect = ({
  titleOverwrite,
  ...rest
}) => {

  const checklist = [
    <>Look up SMTP and IMAP settings for your email provider. Generally, a Google search for "[your email provider] smtp imap settings" will find it.</>,
    <div>
      <span>Enter the provided values for your email:</span>
      <ul>
        <li><strong>Username</strong>: use your email unless your provider instructs differently</li>
        <li><strong>Password</strong>: use your login password unless your provider instructs differently</li>
        <li><strong>Host</strong>: generally a domain with subdomain</li>
        <li><strong>Port</strong>: generally 587 for SMTP and 993 for IMAP</li>
      </ul>
    </div>,
    <div>
      <strong>Troubleshooting:</strong>
      <ul>
        <li>Verify you're using the correct username and password</li>
        <li>Try ports 587, 465, and 25 for SMTP</li>
        <li>Try both port 993 and port 143 for IMAP</li>
        <li>Look up whether your email provider requires an App Password instead of your regular email account login password</li>
      </ul>
    </div>
  ];

  return (
    <>
      <h4>{ titleOverwrite ? titleOverwrite : 'Connect Your Email With SMTP/IMAP' }</h4>
      <Row className="provider-connect my-4">
        <Col className="provider-instructions mb-3" md={6}>
          <Checklist checklist={checklist} />
        </Col>
        <Col className="provider-connection mb-3" md={6}>
          <ConnectForm configOverwrite={
            {
              password: {show: false},
              allSMTP: true,
              allIMAP: true
            }
          } {...rest} />
        </Col>
      </Row>
    </>
  );
}

SMTPConnect.propTypes = {
  connection: PropTypes.object.isRequired,
  updateConnection: PropTypes.func.isRequired,
  saveConnection: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired
};

export default SMTPConnect;
