/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-case-declarations */
import { toast } from 'react-toastify';

export const appReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'CONFIRM_MODAL':
      return {
        ...state,
        confirmModal: {
          ...state.confirmModal,
          ...payload
        }
      };
    case 'APPLY_PROMO': {
      const code = true;
      if (code) {
        toast.success(<span>Congratulations, You got a discount!</span>);
      } else {
        toast.error('Promo code is not valid! Try again.');
      }
      return {
        ...state,
        promo: code
      };
    }
    default:
      return state;
  }
};
