export const adminRoutes = [
  {
    name: 'Dashboard',
    to: '/',
    exact: true,
  },
  {
    name: 'Platform',
    children: [
      {
        name: 'Settings',
        to: '/super/platform/settings',
      },
      {
        name: 'Reconnect',
        to: '/super/platform/reconnect',
      }
    ]
  },
  {
    name: 'Database',
    children: [
      {
        name: 'Info',
        to: '/super/database/info',
      },
      {
        name: 'Prefix',
        to: '/super/database/prefix',
      },
      {
        name: 'Updates',
        to: '/super/database/updates',
      },
      {
        name: 'Logs',
        to: '/super/database/logs',
      }
    ]
  },
  {
    name: 'Accounts',
    to: '/super/accounts',
    children: [
      {
        name: 'Accounts',
        to: '/super/accounts'
      },
      {
        name: 'Users',
        to: '/super/users'
      },
      {
        name: 'Connected Emails',
        to: '/super/emails',
      },
      {
        name: 'Bounces',
        to: '/super/bounces',
      }
    ]
  },
  {
    name: 'Plans',
    children: [
      {
        name: 'Manage Plans',
        to: '/super/plans/manage'
      },
      {
        name: 'Manage Plan Structure',
        to: '/super/plans/structure'
      },
      {
        name: 'Subscriptions',
        to: '/super/plans/subscriptions'
      },
      {
        name: 'Plans Accounts',
        to: '/super/plans/plans_accounts'
      },
      {
        name: 'Lifetime Codes',
        to: '/super/codes'
      }
    ]
  }
];

export default adminRoutes;
