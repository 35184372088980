import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from './Flex';
import classNames from 'classnames';

const DropdownItemFilter = ({
  filter,
  currentFilter,
  className,
  children,
  ...rest
}) => {
  return (
    <Dropdown.Item
      as={Flex}
      justifyContent="between"
      className={classNames('cursor-pointer', className, {
        active: filter === currentFilter
      })}
      {...rest}
    >
      <>{children}</>
      {filter === currentFilter && (
        <FontAwesomeIcon icon="check" transform="down-4 shrink-4" />
      )}
    </Dropdown.Item>
  );
};

const DropdownFilter = ({
  filters,
  handleFilter,
  currentFilter,
  icon = 'sort',
  size = '',
  variant = 'white',
  block = false
}) => {
  const current = filters.find(each => {
    const filterValue = each.value !== undefined ? each.value : each;

    return filterValue === currentFilter;
  });

  const currentName = current?.name !== undefined ? current?.name : currentFilter;
  const currentValue = current?.value !== undefined ? current?.value : currentFilter;

  return (
    <Dropdown
      className={
        classNames({
          ['d-grid']: block
        })
      }
      style={{ '--sstart-dropdown-content': 'none' }}
    >
      <Dropdown.Toggle
        variant={variant}
        className={
          classNames({
            ['text-start']: block,
            ['d-flex']: block,
            ['align-items-center']: block,
            ['justify-content-between']: block
          }, "text-600 dropdown-caret-none")
        }
        size={size}
      >
        {currentName && <span className="me-2">{currentName}</span>}
        <FontAwesomeIcon icon={icon} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="border py-2">
        {filters.map((filter, index) => {
          const filterName = filter.name !== undefined ? filter.name : filter;
          const filterValue = filter.value !== undefined ? filter.value : filter;

          return (
            <DropdownItemFilter
              currentFilter={currentFilter}
              onClick={() => {
                handleFilter(filterValue);
              }}
              filter={filterValue}
              className="text-capitalize"
              key={index}
            >
              {filterName}
            </DropdownItemFilter>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

DropdownItemFilter.propTypes = {
  filter: PropTypes.string.isRequired,
  currentFilter: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.node
};

DropdownFilter.propTypes = {
  filters: PropTypes.array,
  handleFilter: PropTypes.func.isRequired,
  currentFilter: PropTypes.string.isRequired,
  icon: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  block: PropTypes.bool,
};

export default DropdownFilter;
