/* eslint-disable react/prop-types */
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ManagePlans from 'admin/ManagePlans';
import ManagePlansStructure from 'admin/ManagePlansStructure';
import Subscriptions from 'admin/Subscriptions';
import LifetimeCodes from 'admin/LifetimeCodes';
import DatabaseUpdates from 'admin/DatabaseUpdates';
import DatabaseInfo from 'admin/DatabaseInfo';
import DatabasePrefix from 'admin/DatabasePrefix';
import Logs from 'admin/Logs';
import UserInfo from 'admin/UserInfo';
import ConnectedEmails from 'admin/ConnectedEmails';
import Bounces from 'admin/Bounces';
import Accounts from 'admin/Accounts';
import PlansAccounts from 'admin/PlansAccounts';
import Platform from 'admin/Platform';
import Reconnect from 'admin/Reconnect';

const AdminRoutes = () => (
  <Switch>
    {/*Database*/}

    <Route path="/super/platform/settings" exact component={Platform} />
    <Route path="/super/platform/reconnect" exact component={Reconnect} />

    {/*Database*/}

    <Route path="/super/database/info" exact component={DatabaseInfo} />
    <Route path="/super/database/updates" exact component={DatabaseUpdates} />
    <Route path="/super/database/prefix" exact component={DatabasePrefix} />
    <Route path="/super/database/logs" exact component={Logs} />

    {/*Users*/}

    <Route path="/super/accounts" exact component={Accounts} />
    <Route path="/super/users" exact component={UserInfo} />
    <Route path="/super/emails" exact component={ConnectedEmails} />
    <Route path="/super/bounces" exact component={Bounces} />

    {/*Plans*/}

    <Route path="/super/plans/manage" exact component={ManagePlans} />
    <Route path="/super/plans/structure" exact component={ManagePlansStructure} />
    <Route path="/super/plans/subscriptions" exact component={Subscriptions} />
    <Route path="/super/plans/plans_accounts" exact component={PlansAccounts} />
    <Route path="/super/codes" exact component={LifetimeCodes} />

    {/*Redirect*/}
    <Redirect to="/errors/404" />
  </Switch>
);

export default AdminRoutes;
