import React, {useState, useEffect} from 'react';
import AuthCardLayout from 'components/authentication/custom/AuthCardLayout';
import { Form, Row, Col, Button } from 'react-bootstrap';
//import SignInWithGoogle from '../SignInWithGoogle';
//import Divider from '../../common/Divider';
import Api from 'api/api';
import { toast } from 'react-toastify';
import { getURLParameter, isEmail } from 'helpers/utils';

const Invite = () => {
  // State
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    invite: '',
    isAccepted: false
  });

  const hasLabel = true;

  useEffect(() => {
    const params = [
      {param: 'invite_data', value: getURLParameter('data')},
      {param: 'invite_sig', value: getURLParameter('sig')}
    ];

    Api.doRequest('team_invites', 'validate', 'get', null, {params}).then(result => {
      console.log('team_invites', result);
      setFormData({
        ...formData,
        email: result.data.email,
        invite: result.data.key
      });
    }).catch(error => {
      toast.error(error.message);
    });
  }, []);

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    if(!formData.email){
      toast.error('Please enter an email address');
    }

    if(!isEmail(formData.email)){
      toast.error('Please enter a valid email address');
    }

    if(!formData.password){
      toast.error('Please enter a password');
    }

    Api.doRequest('accounts', 'create', 'create', formData).then(result => {
      toast.success(`Successfully registered as ${formData.email}`);
      window.location.href = '/';
    }).catch(error => {
      toast.error(error.message);
    });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <AuthCardLayout
      leftSideContent={
        <p className="pt-3">
          You've been invited to create an account!
        </p>
      }
      footer={false}
    >
      {
        formData.invite ? (
          <>
            <h3 className="mb-4 text-center">Complete Your Account Info</h3>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    {hasLabel && <Form.Label>First Name</Form.Label>}
                    <Form.Control
                      placeholder={!hasLabel ? 'First' : ''}
                      value={formData.firstName}
                      name="firstName"
                      onChange={handleFieldChange}
                      type="text"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    {hasLabel && <Form.Label>Last Name</Form.Label>}
                    <Form.Control
                      placeholder={!hasLabel ? 'Last' : ''}
                      value={formData.lastName}
                      name="lastName"
                      onChange={handleFieldChange}
                      type="text"
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Email address</Form.Label>}
                <Form.Control
                  placeholder={!hasLabel ? 'Email address' : ''}
                  value={formData.email}
                  name="email"
                  disabled
                  type="text"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                {hasLabel && <Form.Label>Create a Password</Form.Label>}
                <Form.Control
                  placeholder={!hasLabel ? 'Password' : ''}
                  value={formData.password}
                  name="password"
                  onChange={handleFieldChange}
                  type="password"
                />
              </Form.Group>

              <Form.Group className="mb-4">
                <Button
                  className="w-100"
                  type="submit"
                >
                  Register
                </Button>
              </Form.Group>
            </Form>
          </>
        ) : (
          <div className="alert alert-danger">
            There was a problem with this invite
          </div>
        )
      }
    </AuthCardLayout>
  );
};

export default Invite;
