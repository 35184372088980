import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Dashboard from 'components/dashboards/custom/Dashboard';
import AdminDashboard from 'admin/AdminDashboard';
import AdminProvider from '../providers/AdminProvider';
import { AppContext, AccountContext } from 'context/Context';
import MainRoutes from './MainRoutes';
import AdminRoutes from './AdminRoutes';
import Footer from 'components/footer/Footer';
import classNames from 'classnames';
import mainRoutes from '../routes/routes';
import adminRoutes from '../routes/adminRoutes';
import CustomNavbar from '../components/navbar/custom/CustomNavbar';
import SettingsRoutes from './SettingsRoutes';
import EmailRoutes from './EmailRoutes';
import Loader from '../components/common/Loader';

const MainLayout = () => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');
  // const isChat = pathname.includes('chat');

  const {
    config: { isFluid, embed }
  } = useContext(AppContext);

  const { accountState } = useContext(AccountContext);

  const routes = accountState.utility?.super_admin ? adminRoutes : mainRoutes;

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if( accountState.utility?.environment === undefined ){
    return <Loader isFluid={isFluid} full={true} />;
  }

  const embedPaths = [
    '/email/overview',
    '/email/warmup',
    '/email/history',
    '/email/settings',
    '/connect',
    '/errors/404'
  ];

  if(embed && ! embedPaths.includes(pathname)){
    return (
      <Switch>
        <Redirect to="/errors/embed-404?ifembed=1" />
      </Switch>
    );
  }

  const content = (
    <Switch>
      <Route exact path="/">
        <Dashboard />
      </Route>
      <Route path="/email/:emailId">
        <EmailRoutes />
      </Route>
      <Route path="/settings/:page">
        <SettingsRoutes />
      </Route>
      <Route path="/">
        <MainRoutes />
      </Route>
      <Redirect to="/errors/404" />
    </Switch>
  );

  if( embed ) return content;

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      <div className={classNames('content', { 'pb-0': isKanban })}>
        <CustomNavbar
          routes={routes}
          logo={true}
          id="main-nav"
          bg="white"
          orientation="horizontal"
          navbarClass="navbar-top border-bottom border-300 fs--1 mb-5"
          navClass=""
          collapse={true}
          navItemClass="text-600 text-900-hover py-2 px-3 d-inline-block"
          navItemActiveClass="text-900"
          sticky={true}
          isMain={true}
        />
        {accountState.utility?.super_admin ? (
          <AdminProvider>
            <Switch>
              <Route path="/" exact component={AdminDashboard} />
              <AdminRoutes />
            </Switch>
          </AdminProvider>
        ) : content}
        {!isKanban && <Footer />}
      </div>
    </div>
  );
};

export default MainLayout;
