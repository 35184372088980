import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import Audience from '../components/dashboards/analytics/audience/Audience';
import { audienceChart } from 'data/dashboard/analytics';
import { AdminContext } from '../context/Context';

const AdminDashboard = () => {
  const admin = useContext(AdminContext);

  console.log('Dashboard admin context', admin);

  return (
    <>
      <Row className="g-3 mb-3">
        <Col xxl={12}>
          <Audience chartData={audienceChart} className={''} />
        </Col>
      </Row>
    </>
  );
};

AdminDashboard.propTypes = {};

export default AdminDashboard;
