import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PlanLinePermission = ({
  display,
  item,
  name,
  overage = 0,
  overage_controls = 0,
  overage_cost = 0,
  overage_per = '',
  plan,
  style,
  tooltip,
  type,
  value
}) => {
  if( style === 'hidden' ) return null;

  return (
    <>
      <FontAwesomeIcon className={classNames('me-3', {
        ['text-success']: parseInt(value, 10),
        ['text-danger']: ! parseInt(value, 10),
      })} icon={ parseInt(value, 10) ? 'check' : 'times' } />
      <span className="plan-line-permission">{ display ? display : name }</span>
    </>
  );
};

export default PlanLinePermission;
