import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import CustomNavbarItem from './CustomNavbarItem';

const CustomNavbarItemWrap = ({
  route,
  roundedItem,
  orientation,
  active = false,
  navItemClass = 'text-700 text-900-hover p-2',
  navItemActiveClass
}) => {

  let item;

  if( route.show !== undefined && ! route.show() ) return null;

  if( route.custom !== undefined ) {
    item = route.custom({active});
  } else {
    item = (
      <NavLink
        exact={route.exact}
        to={route.to !== undefined ? route.to : ''}
        isActive={match => {
          if (active) return true;
          if (!match) return false;
          return route.to !== '#!';
        }}
        className={
          classNames('nav-link', navItemClass, {
            rounded: roundedItem,
            ['d-block']: orientation === 'vertical',
            ['d-inline-block']: orientation === 'horizontal'
          })
        }
        activeClassName={navItemActiveClass}
      >
        <CustomNavbarItem route={route} />
      </NavLink>
    );
  }



  return (
    <Nav.Item as="li" key={route.name}>
      {item}
    </Nav.Item>
  );
};

// prop-types
CustomNavbarItem.propTypes = {
  route: PropTypes.object.isRequired,
  badge: PropTypes.object,
  roundedItem: PropTypes.bool,
  active: PropTypes.bool,
  orientation: PropTypes.string,
  navItemClass: PropTypes.string,
  navItemActiveClass: PropTypes.string
};

export default React.memo(CustomNavbarItemWrap);
