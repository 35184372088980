import React, {useState} from 'react';
import SpamCheckerWizard from './SpamCheckerWizard';
import { SpamCheckerContext } from 'context/Context';

const SpamChecker = () => {
  const domainHealthDefault = {
    records: false,
    blacklists: false,
    whitelists: false,
    has_records: false,
    spf_domains: false,
    spf_match: false,
    errors: false,
    is_domain: false,
    is_email: false,
    is_ip: false
  };
  const [step, setStep] = useState(1);
  const [textHTML, setTextHTML] = useState('text');
  const [emailContent, setEmailContent] = useState('');
  const [emailHTML, setEmailHTML] = useState('');
  const [domainIP, setDomainIP] = useState('');
  const [analyzingDomain, setAnalyzingDomain] = useState(false);
  const [domainHealth, setDomainHealth] = useState(domainHealthDefault);
  const [userIP, setUserIP] = useState(false);
  const [disabled, setDisabled] = useState(false);

  return (
    <div>
      <SpamCheckerContext.Provider
        value={{
          step, setStep,
          textHTML, setTextHTML,
          emailContent, setEmailContent,
          emailHTML, setEmailHTML,
          domainIP, setDomainIP,
          analyzingDomain, setAnalyzingDomain,
          domainHealth, setDomainHealth, domainHealthDefault,
          userIP, setUserIP,
          disabled, setDisabled
        }}>
        <SpamCheckerWizard />
      </SpamCheckerContext.Provider>
    </div>
  );
};

export default SpamChecker;
