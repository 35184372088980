import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Button } from 'react-bootstrap';
import LoaderButton from 'components/common/LoaderButton';
import _merge from 'lodash/merge';
import FormInfo from 'components/common/FormInfo';
import { isEmail } from 'helpers/utils';
import Toggle from '../../common/Toggle';

const ConnectForm = ({
  connection,
  updateConnection,
  updateSMTP,
  updateIMAP,
  saveConnection,
  saving,
  configOverwrite,
  buttonOverwrite = false,
  showCustomSMTP = false,
  customSMTPActive = false,
  customSMTPTooltip = false,
  setCustomSMTPActive = null,
  email = ''
}) => {
  let config = {
    firstName: {
      label: 'First Name',
      show: true
    },
    lastName: {
      label: 'Last Name',
      show: true
    },
    email: {
      label: 'Email',
      show: true
    },
    password: {
      label: 'Password (will be encrypted)',
      show: true
    },
    smtpUsername: {
      label: 'SMTP Username',
      show: false,
      tooltip: 'Often just your email address'
    },
    smtpPassword: {
      label: 'SMTP Password',
      show: false,
      tooltip: 'Password will be encrypted'
    },
    smtpPort: {
      label: 'SMTP Port',
      show: false,
    },
    smtpHost: {
      label: 'SMTP Host',
      show: false
    },
    smtpInsecure: {
      label: 'SMTP Allow Insecure',
      show: false,
      tooltip: 'If you are getting self signed certificate errors, toggle this on'
    },
    imapLabel: {
      show: false,
    },
    imapUsername: {
      label: 'IMAP Username',
      show: false,
      tooltip: 'Often just your email address'
    },
    imapEmail: {
      label: 'IMAP Email',
      show: false,
      tooltip: 'An email address for your inbox, when different from the sending email address you are warming up or IMAP username is not an email'
    },
    imapPassword: {
      label: 'IMAP Password',
      show: false,
      tooltip: 'Password will be encrypted'
    },
    imapPort: {
      label: 'IMAP Port',
      show: false,
    },
    imapHost: {
      label: 'IMAP Host',
      show: false
    },
    allSMTP: false,
    allIMAP: false
  }

  config = _merge(config, configOverwrite);
  const connectionDetails = connection.connection !== undefined ? connection.connection : connection;

  console.log({connection, connectionDetails});

  return (
    <Form onSubmit={saveConnection}>
      <Row>
        {
          config.firstName.show ? (
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  {config.firstName.label}
                  {config.firstName.tooltip !== undefined && config.firstName.tooltip ? (
                    <FormInfo id="tooltip-firstName">
                      { config.firstName.tooltip }
                    </FormInfo>
                  ) : null}
                </Form.Label>
                <Form.Control
                  placeholder={config.firstName.label}
                  value={connection.firstName}
                  name="firstName"
                  onChange={(e) => updateConnection('firstName', e.target.value)}
                  type="text"
                />
              </Form.Group>
            </Col>
          ) : null
        }
        {
          config.lastName.show ? (
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  {config.lastName.label}
                  {config.lastName.tooltip !== undefined && config.lastName.tooltip ? (
                    <FormInfo id="tooltip-lastName">
                      { config.lastName.tooltip }
                    </FormInfo>
                  ) : null}
                </Form.Label>
                <Form.Control
                  placeholder={config.firstName.label}
                  value={connection.lastName}
                  name="lastName"
                  onChange={(e) => updateConnection('lastName', e.target.value)}
                  type="text"
                />
              </Form.Group>
            </Col>
          ) : null
        }
        {
          config.email.show ? (
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>
                  {config.email.label}
                  {config.email.tooltip !== undefined && config.email.tooltip ? (
                    <FormInfo id="tooltip-email">
                      { config.email.tooltip }
                    </FormInfo>
                  ) : null}
                </Form.Label>
                {
                  email && ! customSMTPActive ? <div className="mt-2">{ email }</div> : (
                    <Form.Control
                      placeholder="you@domain.com"
                      value={connection.email}
                      name="email"
                      onChange={(e) => updateConnection('email', e.target.value)}
                      type="email"
                    />
                  )
                }
              </Form.Group>
            </Col>
          ) : null
        }
        {
          config.password.show ? (
            <Col sm={12}>
              <Form.Group className="mb-3">
                <Form.Label>
                  {config.password.label}
                  {config.password.tooltip !== undefined && config.password.tooltip ? (
                    <FormInfo id="tooltip-firstName">
                      { config.password.tooltip }
                    </FormInfo>
                  ) : null}
                </Form.Label>
                <Form.Control
                  placeholder="xxxxxxxxxxxxxxxx"
                  value={connection.password}
                  name="password"
                  onChange={(e) => updateConnection('password', e.target.value)}
                  type="password"
                />
              </Form.Group>
            </Col>
          ) : null
        }
        {
          config.allSMTP || (showCustomSMTP && customSMTPActive) ? <h5 className="my-4">SMTP Settings</h5> : null
        }
        {
          showCustomSMTP ? (
              <Form.Group className="mb-3">
                <Form.Label>
                  Use Custom SMTP (Advanced)
                  {customSMTPTooltip !== undefined && customSMTPTooltip ? (
                    <FormInfo id="tooltip-smtpCustom">
                      { customSMTPTooltip }
                    </FormInfo>
                  ) : null}
                </Form.Label>
                <Toggle value={customSMTPActive} update={(value) => setCustomSMTPActive(value)} />
              </Form.Group>
          ) : null
        }
        {
          config.allSMTP || config.smtpHost.show || customSMTPActive ? (
            <Col md={8}>
              <Form.Group className="mb-3">
                <Form.Label>
                  {config.smtpHost.label}
                  {config.smtpHost.tooltip !== undefined && config.smtpHost.tooltip ? (
                    <FormInfo id="tooltip-smtpHost">
                      { config.smtpHost.tooltip }
                    </FormInfo>
                  ) : null}
                </Form.Label>
                <Form.Control
                  placeholder="smtp.host.com"
                  value={connectionDetails.smtp?.host !== undefined ? connectionDetails.smtp.host : ''}
                  name="smtpHost"
                  onChange={(e) => updateSMTP('host', e.target.value)}
                  type="text"
                />
              </Form.Group>
            </Col>
          ) : null
        }
        {
          config.allSMTP || config.smtpPort.show || customSMTPActive ? (
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>
                  {config.smtpPort.label}
                  {config.smtpPort.tooltip !== undefined && config.smtpPort.tooltip ? (
                    <FormInfo id="tooltip-lastName">
                      { config.smtpPort.tooltip }
                    </FormInfo>
                  ) : null}
                </Form.Label>
                <Form.Control
                  placeholder="587"
                  value={connectionDetails.smtp?.port !== undefined ? connectionDetails.smtp.port : ''}
                  name="smtpPort"
                  onChange={(e) => updateSMTP('port', e.target.value)}
                  type="text"
                />
              </Form.Group>
            </Col>
          ) : null
        }
        {
          config.allSMTP || config.smtpUsername.show || customSMTPActive ? (
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  {config.smtpUsername.label}
                  {config.smtpUsername.tooltip !== undefined && config.smtpUsername.tooltip ? (
                    <FormInfo id="tooltip-lastName">
                      { config.smtpUsername.tooltip }
                    </FormInfo>
                  ) : null}
                </Form.Label>
                <Form.Control
                  placeholder="you@domain.com"
                  value={connectionDetails.smtp?.username !== undefined ? connectionDetails.smtp.username : ''}
                  name="smtpUsername"
                  onChange={(e) => updateSMTP('username', e.target.value)}
                  type="text"
                />
              </Form.Group>
            </Col>
          ) : null
        }
        {
          config.allSMTP || config.smtpPassword.show || customSMTPActive ? (
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  {config.smtpPassword.label}
                  {config.smtpPassword.tooltip !== undefined && config.smtpPassword.tooltip ? (
                    <FormInfo id="tooltip-lastName">
                      { config.smtpPassword.tooltip }
                    </FormInfo>
                  ) : null}
                </Form.Label>
                <Form.Control
                  placeholder="xxxxxxxxxxxxxxxx"
                  value={connectionDetails.smtp?.password !== undefined ? connectionDetails.smtp.password : ''}
                  name="smtpPassword"
                  onChange={(e) => updateSMTP('password', e.target.value)}
                  type="password"
                />
              </Form.Group>
            </Col>
          ) : null
        }
        {
          config.allSMTP || config.smtpInsecure.show || customSMTPActive ? (
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Allow Insecure SMTP (Not Recommended)
                  {config.smtpInsecure.tooltip !== undefined && config.smtpInsecure.tooltip ? (
                    <FormInfo id="tooltip-smtpCustom">
                      { config.smtpInsecure.tooltip }
                    </FormInfo>
                  ) : null}
                </Form.Label>
                <Toggle value={connectionDetails.smtp?.allowInsecure !== undefined ? connectionDetails.smtp.allowInsecure : ''} update={(value) => updateSMTP('allowInsecure', value)} />
              </Form.Group>
            </Col>
          ) : null
        }
        {
          config.allIMAP || config.imapLabel.show || ( showCustomSMTP && customSMTPActive ) ? <h5 className="my-4">IMAP Settings</h5> : null
        }
        {
          config.allIMAP || config.imapHost.show ? (
            <Col md={8}>
              <Form.Group className="mb-3">
                <Form.Label>
                  {config.imapHost.label}
                  {config.imapHost.tooltip !== undefined && config.imapHost.tooltip ? (
                    <FormInfo id="tooltip-lastName">
                      { config.imapHost.tooltip }
                    </FormInfo>
                  ) : null}
                </Form.Label>
                <Form.Control
                  placeholder="imap.host.com"
                  value={connectionDetails.imap?.host !== undefined ? connectionDetails.imap.host : ''}
                  name="imapHost"
                  onChange={(e) => updateIMAP('host', e.target.value)}
                  type="text"
                />
              </Form.Group>
            </Col>
          ) : null
        }
        {
          config.allIMAP || config.imapPort.show ? (
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>
                  {config.imapPort.label}
                  {config.imapPort.tooltip !== undefined && config.imapPort.tooltip ? (
                    <FormInfo id="tooltip-lastName">
                      { config.imapPort.tooltip }
                    </FormInfo>
                  ) : null}
                </Form.Label>
                <Form.Control
                  placeholder="993"
                  value={connectionDetails.imap?.port !== undefined ? connectionDetails.imap.port : ''}
                  name="imapPort"
                  onChange={(e) => updateIMAP('port', e.target.value)}
                  type="text"
                />
              </Form.Group>
            </Col>
          ) : null
        }
        {
          config.allIMAP || config.imapUsername.show ? (
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  {config.imapUsername.label}
                  {config.imapUsername.tooltip !== undefined && config.imapUsername.tooltip ? (
                    <FormInfo id="tooltip-lastName">
                      { config.imapUsername.tooltip }
                    </FormInfo>
                  ) : null}
                </Form.Label>
                <Form.Control
                  placeholder="you@domain.com"
                  value={connectionDetails.imap?.username !== undefined ? connectionDetails.imap.username : ''}
                  name="imapUsername"
                  onChange={(e) => updateIMAP('username', e.target.value)}
                  type="text"
                />
              </Form.Group>
            </Col>
          ) : null
        }
        {
          config.allIMAP || config.imapPassword.show ? (
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  {config.imapPassword.label}
                  {config.imapPassword.tooltip !== undefined && config.imapPassword.tooltip ? (
                    <FormInfo id="tooltip-lastName">
                      { config.imapPassword.tooltip }
                    </FormInfo>
                  ) : null}
                </Form.Label>
                <Form.Control
                  placeholder="xxxxxxxxxxxxxxxx"
                  value={connectionDetails.imap?.password !== undefined ? connectionDetails.imap.password : ''}
                  name="imapPassword"
                  onChange={(e) => updateIMAP('password', e.target.value)}
                  type="password"
                />
              </Form.Group>
            </Col>
          ) : null
        }
        {
          config.imapEmail.show
          || ( connectionDetails.imap !== undefined
            && ( connectionDetails.imap.username && connectionDetails.imap.username.length > 4
              && ( connectionDetails.imap.username !== connectionDetails.smtp.username || ! isEmail(connectionDetails.imap.username) )
            )
          )
          || ( showCustomSMTP && customSMTPActive )
            ? (
            <Col md={12}>
              <div className="alert alert-gray-200">
                <Form.Group className="mb-3">
                  <Form.Label>
                    {config.imapEmail.label}
                    {config.imapEmail.tooltip !== undefined && config.imapEmail.tooltip ? (
                      <FormInfo id="tooltip-lastName">
                        { config.imapEmail.tooltip }
                      </FormInfo>
                    ) : null}
                  </Form.Label>
                  {
                    email && customSMTPActive ? <div className="mt-2">{ email }</div> : (
                      <Form.Control
                        placeholder="you@inbox.com"
                        value={connectionDetails.imap?.email !== undefined ? connectionDetails.imap.email : ''}
                        name="imapEmail"
                        onChange={(e) => updateIMAP('email', e.target.value)}
                        type="email"
                      />
                    )
                  }
                </Form.Group>
              </div>
            </Col>
          ) : null
        }
      </Row>
      <Form.Group className="mb-4">
        {
          buttonOverwrite ? buttonOverwrite : (
            <LoaderButton className="w-100" type="submit" loading={saving}>
              Connect
            </LoaderButton>
          )
        }
      </Form.Group>
    </Form>
  );
}

ConnectForm.propTypes = {
  connection: PropTypes.object.isRequired,
  updateConnection: PropTypes.func.isRequired,
  updateSMTP: PropTypes.func.isRequired,
  updateIMAP: PropTypes.func.isRequired,
  saveConnection: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  configOverwrite: PropTypes.object,
  buttonOverwrite: PropTypes.node
};

export default ConnectForm;
