/*
 INIT: ensure Babel/Eslint/Flow is configured for ES Class Fields & Static Properties
 JSX USAGE: <Iframe src='http://web.site' onLoad={myOnloadFunction}/>
 */
import React, {useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';

const Iframe = ({
  onLoad = null,
  ...rest
}) => {

  const iframeRef = useRef(null);

  useEffect(() => {
    let iframe = ReactDOM.findDOMNode(iframeRef.current);
    iframe.addEventListener('load', onLoad);
  }, []);

  const iframeStyle = {
    width: '100%',
    height: '100%',
    minHeight: '500px',
    border: '0'
  };

  return (
    <iframe
      ref={iframeRef}
      {...rest}
      frameBorder={'0'}
      width={'100%'}
      height={'100%'}
      style={iframeStyle}
    />
  )

}

export default Iframe