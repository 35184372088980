import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from 'components/authentication/custom/LoginForm';
import AuthCardLayout from 'components/authentication/custom/AuthCardLayout';

const Login = () => {
  return (
    <AuthCardLayout
      leftSideContent={
        <p>
          Don't have an account?
          <br />
          <Link className="text-decoration-underline" to={ `/register${window.location.search}` }>
            Get started!
          </Link>
        </p>
      }
      footer={false}
    >
      <h3 className="mb-4">Login</h3>
      {
        window.SSSettings.migrationWindowInterface
          ? <div className="alert alert-primary">{ window.SSSettings.migrationWindowMessage }</div>
          : <LoginForm layout="card" hasLabel />
      }
    </AuthCardLayout>
  );
};

export default Login;
