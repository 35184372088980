import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import RegistrationForm from 'components/authentication/custom/RegistrationForm';
import dashboard from 'assets/img/product/dashboard-slant.png';

const SpamCheckerRegister = () => {
  const maxWidth = '800px';
  const maxWidthStyle = {maxWidth};

  return (
    <div>
      <div className="mt-5 d-flex justify-content-center">
        <Card className="mb-3 width-100" style={ maxWidthStyle }>
          <Card.Body className="p-0">
            <Row className="m-0">
              <Col md={8} className="py-4 px-5">
                <h4>Monitor and Improve your Email Deliverability</h4>
                <p>Boxward improves email reputation and gives you an accurate picture of your email health.</p>
                <h5 className="mb-4">Start below:</h5>
                <div style={ maxWidthStyle } className="bg-gray-100 border border-gray-200 px-4 pt-4 pb-2 rounded">
                  <RegistrationForm layout="card" iframe={true} hasLabel />
                </div>
              </Col>
              <Col className="border-start" style={{
                background: `url('${dashboard}')`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }} md={4}>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default SpamCheckerRegister;
