import React, {useContext} from 'react';
import { AccountContext } from 'context/Context';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import cookie from 'react-cookies';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';

dayjs.extend(duration);

//===============================
// Permissions
//===============================

export const hasUserPermission = (permission) => {
  const { accountState } = useContext(AccountContext);
  const role = accountState?.user?.role;

  if( role === 'admin' ) return true;

  const permissions = {
    member: {
      deleteUser: false,
      invites: false,
      plan: false,
      billing: false
    }
  };

  return permissions[role] !== undefined && permissions[role][permission] !== undefined ? permissions[role][permission] : true;
}

export const hasPermission = (permission) => {
  const { accountState } = useContext(AccountContext);
  const permissions = accountState?.permissions !== undefined ? accountState.permissions : [];

  return permissions.includes(permission);
}

export const isVerified = () => {
  const { accountState } = useContext(AccountContext);
  const verified = accountState?.user?.verified;

  return parseInt(verified, 10) === 1;
}

export const isSuper = () => {
  const { accountState } = useContext(AccountContext);

  return accountState?.utility?.is_super ? cookie.load(SSSettings.namelower + '_admin_user') : false;
}

//===============================
// Breakpoints
//===============================
export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540
};

export const getItemFromStore = (key, defaultValue, store = localStorage) => {
  try {
    return JSON.parse(store.getItem(key)) || defaultValue;
  } catch (e) {
    return store.getItem(key) || defaultValue;
  }
};

export const setItemToStore = (key, payload, store = localStorage) =>
  store.setItem(key, payload);

export const getStoreSpace = (store = localStorage) =>
  parseFloat(
    (
      escape(encodeURIComponent(JSON.stringify(store))).length /
      (1024 * 1024)
    ).toFixed(2)
  );

//===============================
// Cookie
//===============================
export const getCookieValue = name => {
  const value = document.cookie.match(
    '(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)'
  );
  return value ? value.pop() : null;
};

export const createCookie = (name, value, cookieExpireTime) => {
  const date = new Date();
  date.setTime(date.getTime() + cookieExpireTime);
  const expires = '; expires=' + date.toUTCString();
  document.cookie = name + '=' + value + expires + '; path=/';
};

export const numberFormatter = (number, fixed = 2) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(number)) >= 1.0e9
    ? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + 'B'
    : // Six Zeroes for Millions
    Math.abs(Number(number)) >= 1.0e6
    ? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + 'M'
    : // Three Zeroes for Thousands
    Math.abs(Number(number)) >= 1.0e3
    ? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + 'K'
    : Math.abs(Number(number)).toFixed(fixed);
};

//===============================
// Colors
//===============================
export const hexToRgb = hexValue => {
  let hex;
  hexValue.indexOf('#') === 0
    ? (hex = hexValue.substring(1))
    : (hex = hexValue);
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
  );
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ]
    : null;
};

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) =>
  `rgba(${hexToRgb(color)},${alpha})`;

export const colors = [
  '#2c7be5',
  '#00d97e',
  '#e63757',
  '#39afd1',
  '#fd7e14',
  '#02a8b5',
  '#727cf5',
  '#6b5eae',
  '#ff679b',
  '#f6c343'
];

export const themeColors = {
  primary: '#FC7A57',
  secondary: '#748194',
  success: '#6EC495',
  info: '#924631',
  warning: '#FCBE58',
  danger: '#C33149',
  light: '#F8F9FF',
  dark: '#262B40'
};

export const grays = {
  white: '#fff',
  100: '#f9fafd',
  200: '#edf2f9',
  300: '#d8e2ef',
  400: '#b6c1d2',
  500: '#9da9bb',
  600: '#748194',
  700: '#5e6e82',
  800: '#4d5969',
  900: '#344050',
  1000: '#232e3c',
  1100: '#0b1727',
  black: '#000'
};

export const darkGrays = {
  white: '#fff',
  1100: '#f9fafd',
  1000: '#edf2f9',
  900: '#d8e2ef',
  800: '#b6c1d2',
  700: '#9da9bb',
  600: '#748194',
  500: '#5e6e82',
  400: '#4d5969',
  300: '#344050',
  200: '#232e3c',
  100: '#0b1727',
  black: '#000'
};

export const getGrays = isDark => (isDark ? darkGrays : grays);

export const rgbColors = colors.map(color => rgbColor(color));
export const rgbaColors = colors.map(color => rgbaColor(color));

export const getColor = (name, dom = document.documentElement) => {
  return getComputedStyle(dom).getPropertyValue(`--sstart-${name}`).trim();
};

//===============================

// Echarts
//===============================
export const getPosition = (pos, params, dom, rect, size) => ({
  top: pos[1] - size.contentSize[1] - 10,
  left: pos[0] - size.contentSize[0] / 2
});

//===============================
// Pricing
//===============================
export const getPaymentPeriod = (period, className = 'fs--1 text-700', Element = 'small') => {
  switch(period){
    case 'monthly':
      return <Element className={className}>/ month</Element>;
    case 'annual':
      return <Element className={className}>/ year</Element>;
    case 'lifetime':
      return null;
    case 'expires':
      return null;
  }
}

export const getPeriodText = (text, className = 'fs--1 text-700', Element = 'small') => {
  return <Element className={className}>{text}</Element>
}

export const getCurrentPlansCost = (plan, accountPlan) => {
  let price = parseInt(plan.price, 10) / 100;
  let additional = 0;

  plan.structure = plan.structure.map(each => {
    let overageUnitCost = parseInt(each.overage_cost, 10) / 100;
    if(accountPlan.allowances && accountPlan.allowances[each.id] !== undefined){
      const num = parseInt(accountPlan.allowances[each.id], 10);
      additional = num * overageUnitCost;
      if( each.overage_tiers !== undefined && each.overage_tiers.length ){
        each.overage_tiers.forEach(ot => {
          const min = parseInt(ot.min, 10);
          const max = parseInt(ot.max, 10);
          if(num >= min && num <= max){
            overageUnitCost = parseInt(ot.cost, 10) / 100
            additional = num * overageUnitCost;
          }
        });
      }
      price = price + additional;
    }
    each.overage_unit_cost = overageUnitCost;
    return each;
  });

  return {
    price, additional, base: plan.price
  }
}

export const getExpirationFormat = () => {
  return '__expiration-';
}

export const costPerMonthFromNumberEmails = (numEmails) => {
  numEmails = parseInt(numEmails, 10);

  switch(numEmails){
    case numEmails < 10:
      return 9 * numEmails;

    case numEmails < 25:
      return 8 * numEmails;

    default:
      return 7 * numEmails;
  }
}

//===============================
// E-Commerce
//===============================
export const calculateSale = (base, less = 0, fix = 2) =>
  (base - base * (less / 100)).toFixed(fix);
export const getTotalPrice = (cart, baseItems) =>
  cart.reduce((accumulator, currentValue) => {
    const { id, quantity } = currentValue;
    const { price, sale } = baseItems.find(item => item.id === id);
    return accumulator + calculateSale(price, sale) * quantity;
  }, 0);
export const getSubtotal = items =>
  items.reduce((acc, curr) => curr.price * curr.quantity + acc, 0);
export const getDiscountPrice = (total, discount) =>
  total - total * (discount / 100);

export const getProductsQuantity = products =>
  products.reduce((acc, product) => product.quantity + acc, 0);

//===============================
// Helpers
//===============================
export const isIterableArray = array => Array.isArray(array) && !!array.length;

export const isJson = (val) => {
  if( ! typeof val === 'string' ) return false;
  if( typeof val === 'number' ) return false;

  try {
    JSON.parse(val);
  } catch (e) {
    return false;
  }
  return true;
}

export const getPaginationArray = (totalSize, sizePerPage) => {
  const noOfPages = Math.ceil(totalSize / sizePerPage);
  const array = [];
  let pageNo = 1;
  while (pageNo <= noOfPages) {
    array.push(pageNo);
    pageNo = pageNo + 1;
  }
  return array;
};

export const capitalize = str =>
  (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');

export const ucFirst = (str, all) => {
  if(all){
    return str.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
  }

  return str.charAt(0).toUpperCase() + str.substring(1)
}

export const camelize = str => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const dashed = str => {
  return str.toLowerCase().replaceAll(' ', '-');
};

//routes helper

export const flatRoutes = childrens => {
  const allChilds = [];

  const flatChild = childrens => {
    childrens.forEach(child => {
      if (child.children) {
        flatChild(child.children);
      } else {
        allChilds.push(child);
      }
    });
  };
  flatChild(childrens);

  return allChilds;
};

export const getFlatRoutes = children =>
  children.reduce(
    (acc, val) => {
      if (val.children) {
        return {
          ...acc,
          [camelize(val.name)]: flatRoutes(val.children)
        };
      } else {
        return {
          ...acc,
          unTitled: [...acc.unTitled, val]
        };
      }
    },
    { unTitled: [] }
  );

export const routesSlicer = ({ routes, columns = 3, rows }) => {
  const routesCollection = [];
  routes.map(route => {
    if (route.children) {
      return route.children.map(item => {
        if (item.children) {
          return routesCollection.push(...item.children);
        }
        return routesCollection.push(item);
      });
    }
    return routesCollection.push(route);
  });

  const totalRoutes = routesCollection.length;
  const calculatedRows = rows || Math.ceil(totalRoutes / columns);
  const routesChunks = [];
  for (let i = 0; i < totalRoutes; i += calculatedRows) {
    routesChunks.push(routesCollection.slice(i, i + calculatedRows));
  }
  return routesChunks;
};

export const getPageName = pageName => {
  return window.location.pathname.split('/').slice(-1)[0] === pageName;
};

export const copyToClipBoard = textFieldRef => {
  const textField = textFieldRef.current;
  textField.focus();
  textField.select();
  document.execCommand('copy');
};

export const reactBootstrapDocsUrl = 'https://react-bootstrap.github.io';

export const pagination = (currentPage, size) => {
  const pages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  let prev = currentPage - 1 - Math.floor(size / 2);

  if (currentPage - 1 - Math.floor(size / 2) < 0) {
    prev = 0;
  }
  if (currentPage - 1 - Math.floor(size / 2) > pages.length - size) {
    prev = pages.length - size;
  }
  const next = prev + size;

  return pages.slice(prev, next);
};

export const tooltipFormatter = params => {
  let tooltipItem = ``;
  params.forEach(el => {
    tooltipItem =
      tooltipItem +
      `<div class='ms-1'> 
        <h6 class="text-700"><span class="fas fa-circle me-1 fs--2" style="color:${
          el.borderColor ? el.borderColor : el.color
        }"></span>
          ${el.seriesName} : ${
        typeof el.value === 'object' ? el.value[1] : el.value
      }
        </h6>
      </div>`;
  });
  return `<div>
            <p class='mb-2 text-600'>
              ${
                dayjs(params[0].axisValue).isValid()
                  ? dayjs(params[0].axisValue).format('MMMM DD')
                  : params[0].axisValue
              }
            </p>
            ${tooltipItem}
          </div>`;
};

export const addIdField = items => {
  return items.map((item, index) => ({
    id: index + 1,
    ...item
  }));
};

// get file size

export const getSize = size => {
  if (size < 1024) {
    return `${size} Byte`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} KB`;
  } else {
    return `${(size / (1024 * 1024)).toFixed(2)} MB`;
  }
};

/* Get A Random Number */
export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const getExternalLinks = (links, strong = false) => {
  if(!links.length) return [];

  return links.map(each => getExternalLink(each.href, each.anchor, strong));
};

export const getExternalLink = (href, anchor = 'here', strong = false) => {
  return (
    <a target="_blank" rel="noreferrer noopener" href={href}>
      { strong ? <strong>{anchor}</strong> : anchor }
    </a>
  )
};

//===============================
// Dates and Time
//===============================

export const timestampToDate = (timestamp, timezone = 'US/Eastern', format = 'YYYY-MM-DD') => {
  timezone = timezone !== undefined && timezone ? timezone : 'US/Eastern';

  return moment.unix(parseInt(timestamp, 10)).tz(timezone).format(format)
};

/* get Dates between */

export const getDates = (
  startDate,
  endDate,
  interval = 1000 * 60 * 60 * 24
) => {
  const duration = endDate - startDate;
  const steps = duration / interval;
  return Array.from(
    { length: steps + 1 },
    (v, i) => new Date(startDate.valueOf() + interval * i)
  );
};

/* Get Past Dates */
export const getPastDates = duration => {
  let days;

  switch (duration) {
    case 'week':
      days = 7;
      break;
    case 'month':
      days = 30;
      break;
    case 'year':
      days = 365;
      break;

    default:
      days = duration;
  }

  const date = new Date();
  const endDate = date;
  const startDate = new Date(new Date().setDate(date.getDate() - (days - 1)));
  return getDates(startDate, endDate);
};

export const getCurrentTimestamp = () => {
  return moment().unix();
}

export const getRelativeTimestamp = (seconds) => {
  const currentTimestamp = getCurrentTimestamp();

  return currentTimestamp + seconds;
}

export const timestampInRange = (timestamp, start = false, end = false) => {
  if( timestamp === false ) timestamp = getCurrentTimestamp();
  else timestamp = parseInt(timestamp, 10);

  if( start === false ) start = getCurrentTimestamp();
  else start = parseInt(start, 10);

  if( end === false ) end = getCurrentTimestamp();
  else end = parseInt(end, 10);

  return timestamp >= start && timestamp <= end;
}

export const timestampInRangeRelative = (timestamp, start = false, end = false) => {
  if( timestamp === false ) timestamp = getCurrentTimestamp();
  else timestamp = parseInt(timestamp, 10);

  if( start === false ) start = 0;
  else start = parseInt(start, 10);

  if( end === false ) end = 0;
  else end = parseInt(end, 10);

  start = getCurrentTimestamp() + start;
  end = getCurrentTimestamp() + end;

  return timestampInRange(timestamp, start, end);
}

export const timestampNotInRange = (timestamp, start = false, end = false) => {
  return ! timestampInRange(timestamp, start, end);
}

export const timestampNotInRangeRelative = (timestamp, start = false, end = false) => {
  return ! timestampInRangeRelative(timestamp, start, end);
}

export const secondsToTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return `${hours.toString().padStart(2, '0')}:${minutes
  .toString()
  .padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
}

//===============================
// Messages
//===============================

export const errorMessage = (message = '', error = false, options = {}) => {
  if( ! error ){
    toast.error(message, options);
    return;
  }

  error.message !== undefined && error.message ? toast.error(error.message, options) : (message ? toast.error(message, options) : toast.error('There was an error', options));
}

export const successMessage = (message = '', result = false) => {
  if( ! result ){
    toast.success(message);
    return;
  }

  result.message !== undefined && result.message ? toast.success(result.message) : (message ? toast.success(message) : toast.success('Success!'));
}

//===============================
// URL Helpers
//===============================

export const getURLParameter = (param, url = false) => {
  url = url ? url : window.location.search;
  const match = RegExp('[?&]' + param + '=([^&]*)').exec(url);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};

//===============================
// Utilities
//===============================

// Add id to items in array
export const addId = items =>
  items.map((item, index) => ({
    id: index + 1,
    ...item
  }));

//
export const getTimeDuration = (startDate, endDate, format = '') => {
  return dayjs.duration(endDate.diff(startDate)).format(format);
};

// Get Percentage
export const getPercentage = (number, percent) => {
  return (Number(number) / 100) * Number(percent);
};

//get chunk from array
export const chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr];
  if (chunkSize <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, chunkSize));
  return cache;
};

//===============================
// Custom Utilities
//===============================

/**
 *
 * @param accountState
 * @param prop string account/user/plan/usage/connected_email/utility
 * @param sub string
 *
 * User
 * ID: "8"
 * created: "1661538930"
 * display_name: "The Name"
 * first_name: "The"
 * gravatar: "https://www.gravatar.com/avatar/"
 * id: "user6309567028340"
 * last_name: "Name"
 * loginid: "1181981"
 * role: "admin"
 * user_activation_key: ""
 * user_email: "name@domain"
 * user_login: "1181981"
 * user_nicename: "1181981"
 * user_pass: "stuff"
 * user_registered: "2022-08-26 18:35:30"
 * user_status: "0"
 * user_url: ""
 * verification_key: "Y20a9Q"
 * verified: "1"
 * wpid: "8"
 *
 * Account
 * api_key: "4C1N702b2"
 * created: "1661538930"
 * id: "acc6238"
 * name: ""
 * parent: "pacc6309"
 */
export const getAccountState = (accountState, prop, sub) => {
  if( accountState[prop] !== undefined && accountState[prop][sub] !== undefined ) return accountState[prop][sub];

  return false;
};

export const generateId = (prefix = '', length = 16) => {
  return prefix + Math.random().toString(36).substring(2, 2 + length)
};

/**
 * IMPORTANT: Doesn't seem to work
 *
 * @param which
 * @param prop
 * @param val
 */
export const updateAccountStateVal = (which, prop, val) => {
  const { accountDispatch, accountState } = useContext(AccountContext);

  accountDispatch({
    type: 'UPDATE',
    payload: {
      ...accountState,
      [which]: {
        ...accountState[which],
        [prop]: val
      }
    }
  });
}

export const htmlToPlaintext = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  return doc.body.textContent || '';
}

//===============================
// Array/Object Utilities
//===============================

export const getFromObject = (obj, props, returnType = 'string') => {
  let toReturn = false;

  if( props.length ){
    props.forEach(each => {
      toReturn = obj[each]
    })
  }

  return toReturn;
};

export const arrayToKeyedObject = (arr, key = 'id') => {
  let newObj = {};

  arr.forEach((each, i) => {
    if( each[key] !== undefined ){
      newObj[each[key]] = each;
    } else {
      newObj[i] = each;
      console.log('error in arrayToKeyedObject');
    }
  });

  return newObj;
}

export const obfuscate = (val, equalReplace = 'aI8j') => {
  if( ! val ) return '';

  val = val.split("").reverse().join("");
  val = btoa(val);
  val = val.replace('==', equalReplace + '2');
  val = val.replace('=', equalReplace);
  return val;
}

export const unobfuscate = (val, equalReplace = 'aI8j') => {
  if( ! val ) return '';

  val = val.replace(equalReplace + '2', '==');
  val = val.replace(equalReplace, '=');
  try{
    val = atob(val);
  } catch(e){
    return '';
  }
  return val.split("").reverse().join("");
}

//===============================
// Checks
//===============================

export const arrayUnique = (items) => {
  return [...new Set(items)];
}

export const isDomain = (str) => {
  if( ! str ) return false;
  return /^(?!:\/\/)(?!-)([a-zA-Z0-9-]+\.){0,2}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,14}?$/i.test(str.trim());
};
export const onlyAlpha = (str) => {
  return str.replace(/\W/gi, '').replace(/\d/gi, '');
};
export const onlyNumeric = (str) => {
  return str.replace(/\D/gi, '');
};
export const onlyAlphanumeric = (str) => {
  return str.replace(/\W/gi, '');
};

export const isEmail = (str) => {
  if( ! str ) return false;
  const parts = str.trim().split('@');

  if( parts.length !== 2 ) return false;
  if( ! isDomain(parts[1]) ) return false;

  return /^[a-z0-9._%+-]+$/i.test(parts[0].toLowerCase());
}

export const getUserIP = async function() {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', 'https://api.ipify.org?format=json');
    xhr.onload = () => {
      if (xhr.status === 200) {
        const data = JSON.parse(xhr.responseText);
        resolve(data.ip);
      } else {
        reject('Unable to get IP address');
      }
    };
    xhr.onerror = () => {
      reject('Unable to get IP address');
    };
    xhr.send();
  });
}

//===============================
// Connected Emails
//===============================

export const shouldNotToggleWarming = (status) => {
  return ['reconnecting', 'disconnected', 'error', 'authenticationError', 'smtpError', 'bounce', 'senderBounce'].includes(status);
}

export const getStatusArray = () => {
  return ['connecting', 'warming', 'ready', 'reconnecting', 'disconnected', 'error', 'smtpError', 'bounce', 'senderBounce'];
}

export const getMetaValue = (item, field, metaProp = 'meta') => {
  if( item[metaProp] === undefined || ! item[metaProp].length ) return false;

  return item[metaProp].reduce((acc, cur) => {
    if( cur.metakey === field ) acc = cur.value;
    return acc;
  }, false);
}

export const getConnectionFromEmail = (connectedEmail) => {
  let startingConnection = {};

  if( connectedEmail ){
    startingConnection.email = connectedEmail.email;
    startingConnection.connection = {};
    connectedEmail.meta.forEach(each => {
      if(each.metakey === 'first_name') startingConnection.firstName = each.value;
      if(each.metakey === 'last_name') startingConnection.lastName = each.value;
      if(each.metakey.includes('imap_')){
        if(startingConnection.connection.imap === undefined) startingConnection.connection.imap = {}
        startingConnection.connection.imap[each.metakey.replace('imap_', '')] = each.value;
      }
      if(each.metakey.includes('smtp_')){
        if(startingConnection.connection.smtp === undefined) startingConnection.connection.smtp = {}
        startingConnection.connection.smtp[each.metakey.replace('smtp_', '')] = each.value;
      }
    })
  }

  return startingConnection;
}

//===============================
// Email Status
//===============================

export const getEmailStatusMessage = ({score_status, score_timestamp, meta, sent, score}) => {
  let message = 'This email does not have enough recent sending volume to determine a score.';

  if(score_status === 'amazing' || score_status === 'great') message = `Your score: ${score}. This email is in great shape`;
  if(score_status === 'good') message = `Your score: ${score}. This email is in good shape`;
  if(score_status === 'okay') message = `Your score: ${score}. This email is okay, but does have significant deliverability issues.`;
  if(score_status === 'bad' || score_status === 'critical') message = `Your score: ${score}. This email is in bad shape.`;

  if( sent !== undefined && sent ){
    if( sent / 7 > 30 ){
      if(score_status === 'amazing' || score_status === 'great') message += ' and should be able to handle sending 50+ emails per day. Additionally, we recommend the "Maintain" warmup routine.';
      if(score_status === 'good') message += ' and should be able to handle sending 30+ emails per day. Additionally, we recommend the "Maintain" warmup routine.';
      if(score_status === 'okay') message += ' We recommend stopping cold outreach and running the "Distressed" warmup routine.';
      if(score_status === 'bad' || score_status === 'critical') message += ' We recommend stopping cold outreach and running the "Rehab" warmup routine.';
    } else {
      if(score_status === 'amazing' || score_status === 'great') message += '. Keep going!';
      if(score_status === 'good') message += '. Keep going!';
      if(score_status === 'okay') message += ' If the score doesn\'t improve, we recommend stopping cold outreach and running the "Distressed" warmup routine.';
      if(score_status === 'bad' || score_status === 'critical') message += ' If the score doesn\'t improve, we recommend stopping cold outreach and running the "Rehab" warmup routine.';
    }
  } else {
    if(score_status === 'amazing' || score_status === 'great') message += '. Once you\'ve reached at least 35 emails per day, we recommend the "Maintain" warmup routine.';
    if(score_status === 'good') message += '. Once you\'ve reached at least 35 emails per day, we recommend the "Maintain" warmup routine.';
    if(score_status === 'okay') message += ' We recommend stopping or reducing cold outreach. Additionally, if you\'ve reached at least 35 emails per day, run the "Distressed" warmup routine.';
    if(score_status === 'bad' || score_status === 'critical') message += ' We recommend stopping cold outreach. Additionally, if you\'ve reached at least 35 emails per day, run the "Rehab" warmup routine.';
  }

  if( score_timestamp ){
    const timezone = meta?.timezone !== undefined ? meta.timezone : false;
    message += ' Last Updated: ' + timestampToDate(score_timestamp, timezone, 'YYYY-MM-DD HH:mm');
  }

  return message;
}

export const getEmailStatusColor = ({score_status}) => {
  if(score_status === 'amazing') return 'success';
  if(score_status === 'great') return 'success';
  if(score_status === 'good') return 'okay';
  if(score_status === 'okay') return 'warning';
  if(score_status === 'bad') return 'danger';
  if(score_status === 'critical') return 'danger';
  return 'gray-500';
}