import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import { AccountContext } from '../../../context/Context';
import API from '../../../api/api';
import { toast } from 'react-toastify';
import routes from 'routes/settingsRoutes';
import { hasPermission } from 'helpers/utils';

const ProfileDropdown = () => {
  const { accountState } = useContext(AccountContext);

  const logout = () => {
    API.doRequest('accounts', 'logout', 'update', {logoutKey: SSSettings.logout}).then(success => {
      window.location.href = '/signout';
    }).catch(error => {
      toast.error(error.message);
    });
  }

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={accountState.user?.gravatar} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          {
            routes
              .filter(each => each.includeInProfileDropdown === undefined || each.includeInProfileDropdown)
              .filter(each => each.permission === undefined || hasPermission(each.permission))
              .map(each => <Dropdown.Item as={Link} to={each.to}>{each.name}</Dropdown.Item>)
          }
          <Dropdown.Divider />
          <Dropdown.Item onClick={logout}>
            <FontAwesomeIcon icon="lock" className="me-2" /> Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
