import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import ConnectForm from './ConnectForm';
import Checklist from '../../common/Checklist';
import { getExternalLinks } from 'helpers/utils';

const ZohoConnect = ({
  titleOverwrite,
  ...rest
}) => {

  const [login, settings, accounts] = getExternalLinks([
    {
      href: 'https://www.zoho.com/mail/login.html',
      anchor: 'Zoho Mail',
      strong: true
    },
    {
      href: 'https://mail.zoho.com/zm/#settings/all',
      anchor: 'Settings',
      strong: true
    },
    {
      href: 'https://mail.zoho.com/zm/#settings/all/mailaccounts',
      anchor: 'Mail Accounts',
      strong: true
    },
  ]);

  const checklist = [
    <div>
      <span>Make sure IMAP is enabled for your account:</span>
      <ul>
        <li>Login to {login}</li>
        <li>Go to {settings}</li>
        <li>Navigate to {accounts} and click the respective email address from the left listing</li>
        <li>Under the <strong>IMAP</strong> section, enable <strong>IMAP Access</strong></li>
      </ul>
    </div>
  ];

  return (
    <>
      <h4>{ titleOverwrite ? titleOverwrite : 'How to Connect' }</h4>
      <Row className="provider-connect my-4">
        <Col className="provider-instructions mb-3" md={6}>
          <Checklist checklist={checklist} />
        </Col>
        <Col className="provider-connection mb-3" md={6}>
          <ConnectForm configOverwrite={{
            password: {label: 'Login Password (will be encrypted)'}
          }} {...rest} />
        </Col>
      </Row>
    </>
  );
}

ZohoConnect.propTypes = {
  connection: PropTypes.object.isRequired,
  updateConnection: PropTypes.func.isRequired,
  saveConnection: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired
};

export default ZohoConnect;
