import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Toggle from '../components/common/Toggle';
import classNames from 'classnames';
import MultiSelect from '../components/common/MultiSelect';
import FormInfo from '../components/common/FormInfo';
import IconButton from '../components/common/IconButton';
import ClickToCopy from '../components/common/ClickToCopy';
import { obfuscate, getExpirationFormat } from '../helpers/utils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const ManagePlansItem = ({
  items,
  index,
  handleRemove,
  handleChange,
  handleMetaChange,
  handleStructureChange,
  handleAddOverageTier,
  handleOverageTierChange,
  handleOverageTierRemove,
  id,
  uniqueId,
  name,
  type,
  meta,
  period,
  max,
  is_default,
  show,
  enabled,
  price,
  full_price,
  product_id,
  trigger,
  structure,
  open
}) => {
  const [code, setCode] = useState('');
  const [expiration, setExpiration] = useState('');
  const expirationTimestamp = expiration ? moment(expiration).unix() : 0;

  const plans = items.filter(each => each.id !== undefined && each.id && each.type === 'main').map(each => {
    return {
      value: each.id,
      label: each.name
    }
  });
  let addOnFor = [];

  if( meta?.addOnFor !== undefined ){
    const addOnForArray = meta?.addOnFor.split(',')
    addOnFor = plans.filter(each => addOnForArray.includes(each.value));
    console.log({
      plans,
      addOnForArray,
      addOnFor
    })
  }

  const afterPricingPeriod = meta?.afterPricingPeriod !== undefined ? meta.afterPricingPeriod : '';

  let obfuscateId = id;

  if( expirationTimestamp ) obfuscateId += getExpirationFormat() + expirationTimestamp;

  obfuscateId = obfuscate(obfuscateId);

  return (
    <div className="bg-white border dark__bg-1100 p-3 position-relative rounded-1 mb-2">
      <div className="mb-3 d-flex align-items-center justify-content-between border-bottom border-gray-200">
        <small>{ id ? `Plan ID: ${id}` : `Temp ID: ${uniqueId}` }</small>
        <Button
          size="sm"
          variant="link"
          className="p-0"
          onClick={() => handleRemove(uniqueId, id)}
        >
          <FontAwesomeIcon className="text-danger" icon="times" />
        </Button>
      </div>
      <div className="d-flex align-items-center">
        <Button
          size="sm"
          variant="link"
          className="p-0"
          onClick={() => handleChange(uniqueId, 'open', !open)}
        >
          <FontAwesomeIcon icon={open ? 'minus' : 'plus'} />
        </Button>
        <strong className="d-inline-block ms-3">{name}</strong>
        <Badge className="ms-3" pill bg={classNames({
          primary: type === 'addOn',
          success: type === 'main',
          danger: type === 'oneTime',
          info: type === 'usage'
        })}>
          {type}
        </Badge>
        <Badge className="ms-3" pill bg={classNames({
          primary: period === 'monthly',
          success: period === 'annual',
          danger: period === 'expires',
          info: period === 'lifetime'
        })}>
          {period}
        </Badge>
      </div>
      {open && (
        <Row className="gx-2 gy-3 mt-3">
          <Col md="12">
            <Form.Group controlId={`name${index}`}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                defaultValue={name}
                onChange={(e) => handleChange(uniqueId, 'name', e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md="2">
            <Form.Group controlId={`type${index}`}>
              <Form.Label>Type</Form.Label>
              <Form.Select
                defaultValue={type}
                onChange={(e) => handleChange(uniqueId, 'type', e.target.value)}
              >
                <option value="">Choose Type...</option>
                <option value="main">Main</option>
                <option value="addOn">Add On</option>
                <option value="oneTime">One Time</option>
                <option value="usage">Usage</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group controlId={`period${index}`}>
              <Form.Label>Period</Form.Label>
              <Form.Select
                defaultValue={period}
                onChange={(e) => handleChange(uniqueId, 'period', e.target.value)}
              >
                <option value="">Choose Period...</option>
                <option value="monthly">Monthly</option>
                <option value="annual">Annual</option>
                <option value="lifetime">Lifetime</option>
                <option value="expires">Expires</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md="2">
            <Form.Group controlId={`max${index}`}>
              <Form.Label>Max</Form.Label>
              <Form.Control
                type="number"
                placeholder="1"
                defaultValue={max}
                onChange={(e) => handleChange(uniqueId, 'max', parseInt(e.target.value, 10))}
              />
            </Form.Group>
          </Col>
          <Col md="2">
            <Form.Group controlId={`is_default${index}`}>
              <Form.Label>Default</Form.Label>
              <Toggle numeric value={parseInt(is_default, 10)} update={(value) => handleChange(uniqueId, 'is_default', parseInt(value, 10))} />
            </Form.Group>
          </Col>
          <Col md="2">
            <Form.Group controlId={`show${index}`}>
              <Form.Label>Show</Form.Label>
              <Toggle numeric value={parseInt(show, 10)} update={(value) => handleChange(uniqueId, 'show', parseInt(value, 10))} />
            </Form.Group>
          </Col>
          <Col md="2">
            <Form.Group controlId={`enabled${index}`}>
              <Form.Label>Enabled</Form.Label>
              <Toggle numeric value={parseInt(enabled, 10)} update={(value) => handleChange(uniqueId, 'enabled', parseInt(value, 10))} />
            </Form.Group>
          </Col>
          <Col md="5">
            <Form.Group controlId={`price${index}`}>
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="7900"
                defaultValue={price}
                onChange={(e) => handleChange(uniqueId, 'price', e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md="5">
            <Form.Group controlId={`full_price${index}`}>
              <Form.Label>Full Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="9900"
                defaultValue={full_price}
                onChange={(e) => handleChange(uniqueId, 'full_price', e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId={`product_id${index}`}>
              <Form.Label>Product ID</Form.Label>
              <span className="fs--2 ms-3">For AppSumo, make the Product ID "appsumo". For other lifetime, make the Product ID the prefix of the code, like "lt1" if the code is lt1_xxxx.</span>
              <Form.Control
                type="text"
                placeholder="Product ID"
                defaultValue={product_id}
                onChange={(e) => handleChange(uniqueId, 'product_id', e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId={`trigger${index}`}>
              <Form.Label>Trigger ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Trigger"
                defaultValue={trigger}
                onChange={(e) => handleChange(uniqueId, 'trigger', e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group controlId={`after_pricing_period${index}`}>
              <Form.Label>After Pricing Period</Form.Label>
              <Form.Control
                type="text"
                placeholder=" / email"
                defaultValue={afterPricingPeriod}
                onChange={(e) => handleMetaChange(uniqueId, 'afterPricingPeriod', e.target.value)}
              />
            </Form.Group>
          </Col>
          {
            type === 'addOn' ? (
              <Col md="12">
                <Form.Group controlId={`addon_for${index}`}>
                  <Form.Label>Add On For</Form.Label>
                  <MultiSelect options={plans} value={addOnFor} onChange={(value) => handleMetaChange(uniqueId, 'addOnFor', value.map(each => each.value).join(','))} />
                </Form.Group>
              </Col>
            ) : null
          }
          <Col md="6">
            <Form.Group controlId={`addon_for${index}`}>
              <Form.Label>Sign Up to Checkout Link</Form.Label>
              <span className="fs--2 ms-3">This field doesn't save anything. Just helps generate the signup flow link below.</span>
              <Form.Control
                type="text"
                placeholder="Add Coupon Code..."
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId={`addon_for${index}`}>
              <Form.Label>Add Expires in Link</Form.Label>
              <span className="fs--2 ms-3">This field doesn't save anything. Just helps generate the signup flow link below.</span>
              <DatePicker
                selected={ expiration }
                onChange={ (date) => setExpiration(date) }
                formatWeekDay={day => day.slice(0, 3)}
                className='form-control'
                placeholderText="Select Date & Time"
                timeIntervals={5}
                dateFormat="yyyy-MM-dd HH:mm:ss"
                showTimeSelect
                fixedHeight
              />
            </Form.Group>
          </Col>
          <Col md="12">
            <div className="mt-3"><strong>Register</strong>: <ClickToCopy text={ `${window.SSSettings.appUrl}/register?pType=${period === 'lifetime' ? 'lifetime' : 'plan'}&pSubscribe=${obfuscateId}${code ? `&pCc=${code}` : ''}` } /></div>
            <div className="mt-1"><strong>Already Registered</strong>: <ClickToCopy text={ `${window.SSSettings.appUrl}/settings/${period === 'lifetime' ? 'lifetime' : 'plan'}?pSubscribe=${obfuscateId}${code ? `&pCc=${code}` : ''}` } /></div>
          </Col>
          <Col md="12">
            {
              structure.map(each => (
                <Row className="gx-2 gy-3 mt-3 ps-2 pe-2 pb-3 bg-gray-100">
                  <Col md="12">
                    <strong>{each.name}</strong>
                    <Badge className="ms-3" pill bg={classNames({
                      primary: each.type === 'allowance',
                      danger: each.type === 'permission',
                      info: each.type === 'info',
                      ['gray-900']: each.type === 'heading'
                    })}>
                      {each.type}
                    </Badge>
                  </Col>
                  <Col md="6">
                    <Form.Group controlId={`value-${each.item}-${index}`}>
                      <Form.Label>{each.type === 'permission' ? 'Has Permission' : 'Value'}</Form.Label>
                      {
                        each.type === 'permission' ? (
                          <Toggle value={each.value} numeric
                                  update={(value) => handleStructureChange(uniqueId, each.item, 'value', value)} />
                        ) : (
                          <Form.Control
                            type="number"
                            placeholder="100"
                            defaultValue={each.value}
                            onChange={(e) => handleStructureChange(uniqueId, each.item, 'value', e.target.value)}
                          />
                        )
                      }
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group controlId={`style-${each.item}-${index}`}>
                      <Form.Label>Style</Form.Label>
                      {
                        each.style === 'heading' ? <div>Heading</div> : (
                          <Form.Select
                            defaultValue={each.style}
                            onChange={(e) => handleStructureChange(uniqueId, each.item, 'style', e.target.value)}
                          >
                            <option value="default">Default</option>
                            <option value="muted">Muted</option>
                            <option value="bold">Bold</option>
                            <option value="success">Success</option>
                            <option value="danger">Danger</option>
                            <option value="hidden">Hidden</option>
                          </Form.Select>
                        )
                      }
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group controlId={`display-${each.item}-${index}`}>
                      <Form.Label>Display</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Coming Soon..."
                        defaultValue={each.display}
                        onChange={(e) => handleStructureChange(uniqueId, each.item, 'display', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group controlId={`tooltip-${each.item}-${index}`}>
                      <Form.Label>Tooltip</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="More info about this..."
                        defaultValue={each.tooltip}
                        onChange={(e) => handleStructureChange(uniqueId, each.item, 'tooltip', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  {
                    each.type === 'allowance' ? (
                      <>
                        <Col md="12">
                          <h6>Overage</h6>
                        </Col>
                        <Col md="2">
                          <Form.Group controlId={`overage-${each.item}-${index}`}>
                            <Form.Label>Allow</Form.Label>
                            <Toggle numeric value={parseInt(each.overage, 10)} update={(value) => handleStructureChange(uniqueId, each.item, 'overage', parseInt(value, 10))} />
                          </Form.Group>
                        </Col>
                        {
                          parseInt(each.overage, 10) ? (
                            <Col md="2">
                              <Form.Group controlId={`overage-controls-${each.item}-${index}`}>
                                <Form.Label>
                                  <span>Controls</span>
                                  <FormInfo id="overage_controls">
                                    Show controls for the overage quantity on the plans page
                                  </FormInfo>
                                </Form.Label>
                                <Form.Select
                                  defaultValue={each.overage_controls}
                                  onChange={(e) => handleStructureChange(uniqueId, each.item, 'overage_controls', e.target.value)}
                                >
                                  <option value="">Choose Controls...</option>
                                  <option value="usage">Usage</option>
                                  <option value="range">Range</option>
                                  <option value="number">Number</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                          ) : null
                        }
                        {
                          parseInt(each.overage, 10) ? (
                            <Col md="4">
                              <Form.Group controlId={`overage-cost-${each.item}-${index}`}>
                                <Form.Label>
                                  <span>Cost</span>
                                  <FormInfo id="overage_controls">
                                    This should match the unit cost in PK
                                  </FormInfo>
                                </Form.Label>
                                <Form.Control
                                  type="number"
                                  placeholder="100"
                                  defaultValue={each.overage_cost}
                                  onChange={(e) => handleStructureChange(uniqueId, each.item, 'overage_cost', e.target.value)}
                                />
                              </Form.Group>
                            </Col>
                          ) : null
                        }
                        {
                          parseInt(each.overage, 10) ? (
                            <Col md="4">
                              <Form.Group controlId={`overage-per-${each.item}-${index}`}>
                                <Form.Label>Cost Per</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Ex: 100 units"
                                  defaultValue={each.overage_per}
                                  onChange={(e) => handleStructureChange(uniqueId, each.item, 'overage_per', e.target.value)}
                                />
                              </Form.Group>
                            </Col>
                          ) : null
                        }
                      </>
                    ) : null
                  }
                  {
                    each.type === 'allowance' && parseInt(each.overage, 10) ? (
                      <>
                        <Col md="12">
                          <h6>Overage Tiers</h6>
                        </Col>
                        {
                          each.overage_tiers !== undefined && each.overage_tiers.length ?
                            each.overage_tiers.map((tier, ti) => (
                              <>
                                <Col md="3">
                                  <Form.Group controlId={`overage-tier-cost-${ti}`}>
                                    <Form.Label>Cost</Form.Label>
                                    <Form.Control
                                      type="number"
                                      placeholder="1000"
                                      defaultValue={ tier.cost }
                                      onChange={(e) => {
                                        handleOverageTierChange(uniqueId, each.item, (tier.id !== undefined ? tier.id : tier.tempId), 'cost', e.target.value);
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md="3">
                                  <Form.Group controlId={`overage-tier-min-${ti}`}>
                                    <Form.Label>Min</Form.Label>
                                    <Form.Control
                                      type="number"
                                      placeholder="0"
                                      defaultValue={ tier.min }
                                      onChange={(e) => {
                                        handleOverageTierChange(uniqueId, each.item, (tier.id !== undefined ? tier.id : tier.tempId), 'min', e.target.value);
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md="3">
                                  <Form.Group controlId={`overage-tier-max-${ti}`}>
                                    <Form.Label>Max</Form.Label>
                                    <Form.Control
                                      type="number"
                                      placeholder="100"
                                      defaultValue={ tier.max }
                                      onChange={(e) => {
                                        handleOverageTierChange(uniqueId, each.item, (tier.id !== undefined ? tier.id : tier.tempId), 'max', e.target.value);
                                      }}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md="3" className="d-flex align-items-end">
                                  <Button
                                    size="sm"
                                    variant="link"
                                    className="ms-3 mb-2 ms-auto p-0"
                                    onClick={() => handleOverageTierRemove(uniqueId, each.item, (tier.id !== undefined ? tier.id : tier.tempId))}
                                  >
                                    <FontAwesomeIcon className="text-danger" icon="times" />
                                  </Button>
                                </Col>
                              </>
                            ))
                          : null
                        }
                        <Col md="12">
                          <IconButton
                            onClick={() => handleAddOverageTier(uniqueId, each.item)}
                            variant="gray-200"
                            size="sm"
                            icon="plus"
                            transform="shrink-3"
                          >
                            Add Overage Tier
                          </IconButton>
                        </Col>
                      </>
                    ) : null
                  }
                </Row>
              ))
            }
          </Col>
        </Row>
      )}
    </div>
  );
};
export default ManagePlansItem;

ManagePlansItem.propTypes = {
  index: PropTypes.number,
  handleRemove: PropTypes.func,
  handleChange: PropTypes.func,
  handleStructureChange: PropTypes.func,
  id: PropTypes.string,
  uniqueId: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  period: PropTypes.string,
  is_default: PropTypes.number,
  show: PropTypes.number,
  price: PropTypes.number,
  full_price: PropTypes.number,
  product_id: PropTypes.string,
  trigger: PropTypes.string,
  structure: PropTypes.array,
  open: PropTypes.bool
};
