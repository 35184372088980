import React, {useEffect, useContext, useState} from 'react';
//import PropTypes from 'prop-types';
import API from '../../../api/api';
import { AccountContext } from '../../../context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomPagination from '../../common/Pagination';
import { timestampToDate, isSuper } from '../../../helpers/utils';
import IconCircle from '../../common/IconCircle';
import TooltipItem from '../../common/TooltipItem';

const EmailsSentReceived = ({
  which = 'sent'
}) => {
  const { accountState } = useContext(AccountContext);

  let getFunc = 'get';
  let getPagesFunc = 'get_pages';
  let title = 'Emails Sent';

  if( which === 'received' ){
    getFunc = 'get_received';
    getPagesFunc = 'get_received_pages';
    title = 'Emails Received';
  }

  const connectedEmail = accountState?.connected_email !== undefined ? accountState.connected_email : {};
  const connectedEmailMeta = connectedEmail?.meta;

  const timezoneObj = connectedEmailMeta !== undefined ? connectedEmailMeta.find(each => each.metakey === 'timezone') : undefined;
  const timezone = timezoneObj !== undefined ? timezoneObj.value : 'US/Eastern';

  const [emails, setEmails] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(100);

  useEffect(() => {
    //Get super/database updates, merge with super/platform and update the adminReducer
    API.doRequest('emails_sent', getFunc, 'get', null, {
      params: [
        { param: 'limit', value: limit },
        { param: 'offset', value: (page - 1) * limit },
      ]
    }).then(results => {
      setEmails(results.data);
    }).catch(error => {

    });
  }, [page, limit]);

  useEffect(() => {
    //Get super/database updates, merge with super/platform and update the adminReducer
    API.doRequest('emails_sent', getPagesFunc).then(results => {
      setTotalPages(results.data);
    }).catch(error => {

    });
  }, []);

  let currentDay = '';
  let counter = 0;

  return (
    <>
      <div className="list-group">
        {emails.map((item, index) => {
          const { timestamp, result, from_provider, to_provider, is_conversation, is_inbox, is_categories, is_junk, initiator_email, responder_email, reply } = item;

          //Don't include failed emails
          //if( result === 'failed' ) return null;

          //Since we're getting the email addresses from the conversation,
          // this particular email may have been sent by the initiator_email or responder email
          // and it may have been received by the initiator_email or responder_email
          // We just want otherEmail to be the address that isn't the connected_email being looked at right now
          const otherEmail = initiator_email === connectedEmail.email ? responder_email : initiator_email;
          const fromEmail = item.from_email !== undefined && item.from_email ? item.from_email : ( which === 'sent' ? connectedEmail.email : otherEmail );
          const toEmail = item.to_email !== undefined && item.to_email ? item.to_email : ( which === 'sent' ? otherEmail : connectedEmail.email );

          let thisDay = timestampToDate(timestamp, timezone, 'YYYY-MM-DD' );
          let heading = null;

          if( thisDay !== currentDay ){
            currentDay = thisDay;

            heading = (
              <div className="list-group-item p-0 border-top-0 border-start-0 border-end-0 mt-4 pb-2">
                <h5>{currentDay}</h5>
              </div>
            );

            counter = 0;
          }

          counter++;

          return (
            <>
              { heading }
              <div className="list-group-item ms-5">
                <div className="list-group-counter">{counter}</div>
                <div className="d-flex align-items-center">
                  <div>
                    <span className="me-3">{ timestampToDate(timestamp, timezone, 'YYYY-MM-DD HH:mm' ) }</span>
                    { <span>{fromEmail ? fromEmail : 'INACTIVE'} ({from_provider === 'smtp' ? 'other' : from_provider})</span> }
                    <FontAwesomeIcon icon="long-arrow-alt-right" className="ms-3 me-3 text-gray-500" />
                    { <span>{toEmail ? toEmail : 'INACTIVE'} ({to_provider === 'smtp' ? 'other' : to_provider})</span> }
                  </div>
                  {
                    result === 'failed' && which === 'sent' ? <TooltipItem tooltip="This email could not be sent"><div className="badge badge-danger">Failed</div></TooltipItem> : (
                      <div className={`ms-auto d-flex result-${result}`}>
                        {
                          parseInt(reply, 10) ? <TooltipItem tooltip="This is a reply in an existing thread"><IconCircle variant="info" icon="reply" circleClass="me-2" /></TooltipItem> : false
                        }
                        {
                          parseInt(is_conversation, 10) ? <TooltipItem tooltip="Part of back-and-forth conversation"><IconCircle variant="primary" icon="comments" circleClass="me-2" /></TooltipItem> : false
                        }
                        {
                          parseInt(is_inbox, 10) ? <TooltipItem tooltip="Inbox"><IconCircle variant="success" icon="check" /></TooltipItem> : false
                        }
                        {
                          parseInt(is_categories, 10) && ! parseInt(is_inbox, 10) ? <TooltipItem tooltip="Categories"><IconCircle variant="warning" icon="exclamation" /></TooltipItem> : false
                        }
                        {
                          parseInt(is_junk, 10) ? <TooltipItem tooltip="Junk"><IconCircle variant="danger" icon="ban" /></TooltipItem> : false
                        }
                        {
                          ! result || result === 'other' ? <TooltipItem tooltip="Other/No Result"><IconCircle variant="gray-500" icon="ellipsis-h" /></TooltipItem> : false
                        }
                      </div>
                    )
                  }
                </div>
                {
                  isSuper() ? (
                    <div className="mt-2 fs--2" style={{width: '100%'}}>
                      <strong>ID</strong>: {item.id}
                      <strong className="d-inline-block ms-3">Conversation</strong>: {item.conversation}
                      <strong className="d-inline-block ms-3">EE</strong>: {item.ee_id}
                      <strong className="d-inline-block ms-3">MessageID</strong>: {item.message_id}
                    </div>
                  ) : null
                }
              </div>
            </>
          );
        })}
      </div>
      <CustomPagination
        total={ totalPages }
        limit={ 100 }
        page={ page }
        currentLimit={ limit }
        updatePage={ (page) => setPage(page) }
        updateLimit={ (limit) => setLimit(limit) }
        margin="t"
        displayGroups={[100, 250, 500]}
      />
    </>
  )
};

EmailsSentReceived.propTypes = {

};

export default EmailsSentReceived;
