import React, {useState, useEffect, useContext} from 'react';
import SettingsPage from './SettingsPage';
import { AccountContext } from 'context/Context';
import { hasPermission } from '../../../helpers/utils';
import Api from '../../../api/api';

const Embed = () => {
  const [loading, setLoading] = useState(true);
  const { accountState } = useContext(AccountContext);

  useEffect(() => {
    /*Api.doRequest('accounts', 'billing' ).then(result => {
      setLoading(false);
    }).catch(error => {
      setLoading(false);
    });*/
  }, []);

  if( ! hasPermission('embed') ){
    return (
      <SettingsPage title="Embed" loading={ loading } onSave={null}>
        <p>You do not have permission to access this page.</p>
      </SettingsPage>
    );
  }

  return (
    <SettingsPage title="Embed" loading={ loading } onSave={null}>
      <p>Offer email warmup to your customers by embedding Boxward in your app.</p>

    </SettingsPage>
  );
};

export default Embed;
