import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';

const App = () => {
  //https://create-react-app.dev/docs/adding-custom-environment-variables/
  return (
    <Router basename={'/'}>
      <Layout />
    </Router>
  );
};

export default App;
