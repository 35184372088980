import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col, Card } from 'react-bootstrap';

const ProviderChoice = ({
  providers,
  value,
  update
}) => {
  return (
    <Row className="providers my-4">
      {
        providers.map(each => {
          return (
            <Col className="mb-3" md={6}>
              <Card className={classNames('provider cursor-pointer height-100 hover-bg-100 transition-bg', {['border border-2 border-primary']: value === each.id})} onClick={() => update(each.id)}>
                <Card.Body className="d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    { each.img ? <img className="pull-left provider-img me-4" src={each.img} alt={each.name} width={48} /> : null }
                    <span className="provider-name fw-bold">{each.name}</span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )
        })
      }
    </Row>
  );
}

ProviderChoice.propTypes = {
  providers: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired
};

export default ProviderChoice;
