import React, { useEffect, useState, useContext } from 'react';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import Section from 'components/common/Sec';
import API from 'api/api';
import { errorMessage, isEmail, successMessage } from '../helpers/utils';
import { AppContext } from '../context/Context';

const LifetimeCodes = () => {
  const { appConfirm } = useContext(AppContext);

  const [email, setEmail] = useState('');
  const [prefix, setPrefix] = useState('');
  const [filter, setFilter] = useState('');
  const [limit, setLimit] = useState(10000);
  const [offset, setOffset] = useState(0);

  const [codes, setCodes] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getTotal();
  }, []);

  const getTotal = () => {
    API.doRequest('super', 'get_total', 'get', null, {
      params: [
        { param: 'super', value: 'appsumo_codes' },
        { param: 'prefix', value: prefix },
        { param: 'filt', value: filter },
      ]
    }).then(result => {
      setTotal(result.data);
    });
  }

  //The empty array as the second argument makes this run like componentDidMount
  //https://reacttraining.com/blog/useEffect-is-not-the-new-componentDidMount/
  const getCodes = () => {
    API.doRequest('super', 'get', 'get', null, {
      params: [
        { param: 'super', value: 'appsumo_codes' },
        { param: 'prefix', value: prefix },
        { param: 'filt', value: filter },
        { param: 'limit', value: limit },
        { param: 'offset', value: offset },
      ]
    }).then(result => {
      setCodes(result.data);
    });
  };

  const maybeCreateCodes = (name) => {
    appConfirm({
      type: 'confirm',
      confirm: () => createCodes(),
      confirmText: 'Create',
      confirmColor: 'danger',
      header: 'Create ' + limit + ' Codes?',
      body: 'Are you sure you want to create ' + limit + ' new codes with the prefix: ' + ( prefix ? prefix : '[NO PREFIX]' ) + '?',
    });
  }

  const createCodes = () => {
    API.doRequest('super', 'create', 'get', null, {
      params: [
        { param: 'super', value: 'appsumo_codes' },
        { param: 'prefix', value: prefix },
        { param: 'limit', value: limit }
      ]
    }).then(result => {
      successMessage('Codes created');
      setCodes(result.data);
    }).catch(error => {
      console.log('error', error);
      errorMessage('Codes not created');
    });
  }

  const changeUsedStatus = (code) => {
    const theCode = code.code !== undefined ? code.code : code;
    const val = code.used !== undefined ? parseInt(code.used, 10) : 0;
    const newVal = val === 1 ? 0 : 1;

    API.doRequest('super', 'change_status', 'update', {
      update: {
        code: theCode,
        used: newVal
      }
    }, {
      params: [
        { param: 'super', value: 'appsumo_codes' }
      ]
    }).then(result => {
      successMessage('Status updated');
      setCodes(codes.map(each => {
        if( each.code !== undefined && each.code === theCode || each === theCode ){
          each = {
            code: theCode,
            used: newVal
          }
        }
        return each;
      }))
    }).catch(error => {
      console.log('error', error);
      errorMessage('Status not updated');
    });
  }

  const sendCode = (code) => {
    const theCode = code.code !== undefined ? code.code : code;

    if( ! email || ! isEmail(email) ) {
      errorMessage('Enter email to send code to');
      return;
    }

    API.doRequest('super', 'send_code', 'update', {
      update: {
        code: theCode,
        email: email
      }
    }, {
      params: [
        { param: 'super', value: 'appsumo_codes' }
      ]
    }).then(result => {
      successMessage('Code emailed');
      setCodes(codes.map(each => {
        if( each.code !== undefined && each.code === theCode || each === theCode ){
          each = {
            code: theCode,
            email: email
          }
        }
        return each;
      }))
    }).catch(error => {
      console.log('error', error);
      errorMessage(false, error);
    });
  }

  //value must be 5 characters or less
  const prefixes = [
    {
      name: 'Postaga',
      value: 'ltp_'
    }
  ];

  return (
    <Section size="lg">
      <Card className="mb-3">
        <Card.Header as="h5">Lifetime Codes</Card.Header>
        <Card.Body className="bg-light">
          <Row className="mb-3">
            <Col md="3">
              <Form.Select
                defaultValue={prefix}
                onChange={(e) => setPrefix(e.target.value)}
              >
                <option value="">Select...</option>
                {
                  prefixes.map(each => <option value={each.value}>{each.name} ({each.value})</option>)
                }
              </Form.Select>
            </Col>
            <Col md="3">
              <Form.Select
                defaultValue={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="">Select...</option>
                {
                  prefixes.map(each => <option value={each.value}>{each.name} ({each.value})</option>)
                }
              </Form.Select>
            </Col>
            <Col md="3">
              <Form.Control
                type="number"
                placeholder={ 10000 }
                value={ limit }
                onChange={(e) => setLimit(e.target.value)}
              />
            </Col>
            <Col md="3">
              <Form.Control
                type="number"
                placeholder={ 0 }
                value={ offset }
                onChange={(e) => setOffset(e.target.value)}
              />
            </Col>
          </Row>
          <div className="d-flex align-items-center">
            <Button variant="primary" type="text" onClick={(e) => {
              getCodes();
              getTotal();
            }}>Get Codes</Button>
          </div>
          <Row className="mt-5 mb-3">
            <Col md="3">
              <Form.Select
                defaultValue={prefix}
                onChange={(e) => setPrefix(e.target.value)}
              >
                <option value="">Select...</option>
                {
                  prefixes.map(each => <option value={each.value}>{each.name} ({each.value})</option>)
                }
              </Form.Select>
            </Col>
            <Col md="3">
              <Form.Control
                type="number"
                placeholder={ 10000 }
                value={ limit }
                onChange={(e) => setLimit(e.target.value)}
              />
            </Col>
          </Row>
          <div className="d-flex align-items-center">
            <Button variant="danger" type="text" onClick={(e) => maybeCreateCodes()}>Create Codes</Button>
          </div>
          <div className="mt-5">
            <Form.Control
              type="text"
              placeholder="email to send code to..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mt-5">
            <div><strong>Total Codes: {total}</strong></div>
            {
              codes.map(each => (
                <div>
                  <span className={ each.used !== undefined && parseInt(each.used, 10) ? 'text-danger cursor-pointer d-inline-block' : 'cursor-pointer d-inline-block' } onClick={() => changeUsedStatus(each)}>
                    {each.code !== undefined ? each.code : each}
                  </span>
                  {
                    each.email !== undefined && each.email
                      ? <span className="d-inline-block ms-3">{each.email}</span>
                      : ( parseInt(each.used, 10) ? null : <span className="d-inline-block ms-3 cursor-pointer text-primary" onClick={() => sendCode(each)}>Email</span> )
                  }
                </div>
              ))
            }
          </div>
        </Card.Body>
      </Card>
    </Section>
  );
};

export default LifetimeCodes;
