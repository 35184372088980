import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import API from '../../api/api';
import { errorMessage, successMessage } from '../../helpers/utils';

const EmailModal = ({
  testEmailTo,
  setTestEmailTo,
  connectedId
}) => {
  const [email, setEmail] = useState(testEmailTo);
  const updateEmail = (val) => {
    setEmail(val);
    setTestEmailTo(val);
  }

  const send = () => {
    API.doRequest('connected_emails', 'send_test_email', 'update', {
      update: {
        testEmailTo: email, id: connectedId
      }
    }).then(result => {
      successMessage('Test email sent!');
    }).catch(error => {
      errorMessage(error.message);
    })
  }

  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label>Send a test email from this address to verify SMTP connection. Send the email to:</Form.Label>
        <div className="input-group">
          <Form.Control
            placeholder={'email@domain.com'}
            value={email}
            name="email"
            onChange={(e) => updateEmail(e.target.value)}
            type="email"
          />
          <Button variant="primary" onClick={send}>Send</Button>
        </div>
      </Form.Group>
    </Form>
  )
}

export default EmailModal;