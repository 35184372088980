import React, {useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
//import PropTypes from 'prop-types';
//import classNames from 'classnames';
import { Col, Row, Form, Button } from 'react-bootstrap';
import EmailPanel from '../common/EmailPanel';
import StatCard from '../common/StatCard';
import { AccountContext } from '../../context/Context';
import OverviewChart from './OverviewChart';
import API from '../../api/api';
import HealthScore from './HealthScore';
import StatusTest from '../main/StatusTest';
import HealthCheck from '../main/HealthCheck';

const Overview = ({

}) => {
  const { accountState } = useContext(AccountContext);
  const connectedEmail = accountState?.connected_email !== undefined ? accountState.connected_email : {score: 0, score_status: ''};

  const [days, setDays] = useState(0);
  const [period, setPeriod] = useState(14);
  const [provider, setProvider] = useState('all');

  const [overview, setOverview] = useState({
    all: {
      sent_today: 0,
      sent: 0,
      received_today: 0,
      received: 0,
      spam_percentage_today: 0,
      spam_percentage: 0,
      categories_percentage_today: 0,
      categories_percentage: 0,
      inbox_percentage_today: 0,
      inbox_percentage: 0,
      sent_by_day: [],
      spam_by_day: [],
      other_by_day: [],
      categories_by_day: [],
      received_by_day: [],
      conversations_by_day: [],
      inbox_by_day: []
    }
  });

  const [scores, setScores] = useState([]);

  useEffect(() => {
    //Get super/database updates, merge with super/platform and update the adminReducer
    API.doRequest('emails_sent', 'get_overview', 'get', null, {
      params: [
        { param: 'connected_email', value: connectedEmail.id },
        { param: 'period', value: period }
      ]
    }).then(({data}) => {
      setDays(data.days);
      setOverview(data.overview);
      setScores(data.scores);
    }).catch(error => {

    });

    API.doRequest('emails_sent', 'get_overview', 'get', null, {
      params: [
        { param: 'connected_email', value: connectedEmail.id },
        { param: 'period', value: period }
      ]
    }).then(({data}) => {
      setDays(data.days);
      setOverview(data.overview);
    }).catch(error => {

    });
  }, [period]);

  const periods = [
    {
      name: 'Past Week',
      value: 7
    },
    {
      name: 'Past 2 Weeks',
      value: 14
    },
    {
      name: 'Past 30 days',
      value: 30
    },
    {
      name: 'Past 90 days',
      value: 90
    }
  ];

  const providers = [
    {
      name: 'All Providers',
      value: 'all'
    },
    {
      name: 'Google',
      value: 'google'
    },
    {
      name: 'Microsoft',
      value: 'microsoft'
    },
    {
      name: 'Zoho',
      value: 'zoho'
    },
    {
      name: 'Other',
      value: 'other'
    }
  ];

  const {
    sent,
    sent_today,
    received,
    received_today,
    inbox_percentage,
    inbox_percentage_today,
    conversations_percentage,
    conversations_today,
    spam_percentage,
    spam_percentage_today,
    categories_percentage,
    categories_percentage_today,
    sent_by_day,
    spam_by_day,
    other_by_day,
    categories_by_day,
    received_by_day,
    conversations_by_day,
    inbox_by_day
  } = overview[provider];

  return (
    <EmailPanel title="Overview">
      {
        ! parseInt( connectedEmail.active, 10 ) ? (
          <div className="alert alert-danger">
            <div className="d-flex align-items-center">
              <span>This email is not currently active.</span>
              <Button
                as={Link}
                variant="primary"
                className="ms-auto"
                to="/email/warmup"
              >Activate</Button>
            </div>
          </div>
        ) : null
      }
      <div className="d-flex mb-3">
        <Form.Select className="ms-auto" style={{maxWidth: '200px'}} value={period} onChange={ (e) => {
          setPeriod(e.target.value)
        } }>
          {
            periods.map(each => <option value={ each.value }>{ each.name }</option>)
          }
        </Form.Select>
        <Form.Select className="ms-3" style={{maxWidth: '200px'}} value={provider} onChange={ (e) => {
          setProvider(e.target.value)
        } }>
          {
            providers.map(each => <option value={ each.value }>{ each.name }</option>)
          }
        </Form.Select>
      </div>
      <Row className="align-items-center">
        <Col md={12} lg={9}>
          <Row className="g-3">
            <Col md={12} lg={4}>
              <StatCard groups={[
                {
                  number: sent_today,
                  label: 'Today'
                },
                {
                  number: sent,
                  label: 'Overall'
                }
              ]} color="primary" icon="paper-plane" label="Sent" />
            </Col>
            <Col md={12} lg={4}>
              <StatCard groups={[
                {
                  number: received_today,
                  label: 'Today'
                },
                {
                  number: received,
                  label: 'Overall'
                }
              ]} color="secondary" icon="inbox" label="Received" />
            </Col>
            <Col md={12} lg={4}>
              <StatCard groups={[
                {
                  number: conversations_today,
                  label: 'Today'
                },
                {
                  number: conversations_percentage + '%',
                  label: 'Average'
                }
              ]} color="info" icon="comments" label="Conversations" tooltip="Emails sent in the context of a back-and-forth conversation" />
            </Col>
            <Col md={12} lg={4}>
              <StatCard groups={[
                {
                  number: inbox_percentage_today + '%',
                  label: 'Today'
                },
                {
                  number: inbox_percentage + '%',
                  label: 'Average'
                }
              ]} color="success" icon="check" label="Inbox" />
            </Col>
            <Col md={12} lg={4}>
              <StatCard groups={[
                {
                  number: categories_percentage_today + '%',
                  label: 'Today'
                },
                {
                  number: categories_percentage + '%',
                  label: 'Average'
                }
              ]} color="warning" icon="exclamation" label="Categories" tooltip="Emails that land in Categories tabs are automatically moved to the inbox" />
            </Col>
            <Col md={12} lg={4}>
              <StatCard groups={[
                {
                  number: spam_percentage_today + '%',
                  label: 'Today'
                },
                {
                  number: spam_percentage + '%',
                  label: 'Average'
                }
              ]} color="danger" icon="ban" label="Spam" tooltip="Emails that land in Spam are automatically moved to the inbox" />
            </Col>
          </Row>
        </Col>
        <Col md={12} lg={3}>
          <div className="d-flex justify-content-center">
            <HealthScore connectedEmail={connectedEmail} lineWidth={8} size="10rem" titleFontSize={16} tooltipPlacement="left" />
          </div>
        </Col>
      </Row>
      <Row className="g-3 mt-4 align-items-center">
        <Col md={12}>
          <OverviewChart
            sent={sent_by_day}
            spam={spam_by_day}
            other={other_by_day}
            categories={categories_by_day}
            received={received_by_day}
            conversations={conversations_by_day}
            inbox={inbox_by_day}
            days={days}
            scores={scores}
          />
        </Col>
      </Row>
      <div className="mt-5">
        {
          providers.map(each => {
            if( each.value === 'all' ) return null;
            if( overview[each.value] === undefined ) return null;

            const {
              sent,
              spam_percentage
            } = overview[each.value];

            //None sent
            if( ! sent ) return null;

            //Not enough data
            if( sent < 30 ) return <div className="mt-3 alert alert-gray-300">{ `Not enough data to determine deliverability to ${each.name} inboxes (only ${sent} sent). However, currently, ${spam_percentage}% are landing in junk.`}</div>;

            let notices = [];

            if( parseFloat(spam_percentage) >= 40 ){
              notices.push({
                color: 'danger',
                message: `Deliverability to ${each.name} inboxes is poor (${spam_percentage}% are landing in junk). Keep warming this email to try to improve it.`
              })
            }

            if( parseFloat(spam_percentage) >= 20 && parseFloat(spam_percentage) < 40 ){
              notices.push({
                color: 'warning',
                message: `Deliverability to ${each.name} inboxes is not great (${spam_percentage}% are landing in junk). Keep warming this email to try to improve it.`
              })
            }

            if( parseFloat(spam_percentage) >= 2 && parseFloat(spam_percentage) < 20 ){
              notices.push({
                color: 'okay',
                message: `Deliverability to ${each.name} inboxes is not perfect (${spam_percentage}% are landing in junk). Keep warming this email to try to improve it.`
              })
            }

            if( parseFloat(spam_percentage) < 2 ){
              notices.push({
                color: 'success',
                message: parseFloat(spam_percentage) === 0 ? `Deliverability to ${each.name} inboxes is perfect!` : `Deliverability to ${each.name} inboxes is great (only ${spam_percentage}% are landing in junk).`
              })
            }

            return notices.map(each => (
              <div className={ `mt-3 alert alert-${each.color}` }>{each.message}</div>
            ))
          })
        }
      </div>
      <div className="mt-5">
        <HealthCheck
          title="Email Health"
          connectedId={connectedEmail.id}
          current={connectedEmail.status}
          onCheckUpdate={false}
          onCheckFinish={false} />
      </div>
    </EmailPanel>
  )
};

Overview.propTypes = {

};

export default Overview;
