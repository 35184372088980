import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AccountContext } from '../context/Context';
import CustomNavbarSearch from '../components/navbar/custom/CustomNavbarSearch';
import CustomNavbarOffer from '../components/navbar/custom/CustomNavbarOffer';

const getConnectedEmail = () => {
  const { accountState } = useContext(AccountContext);
  return accountState?.connected_email?.id !== undefined ? accountState.connected_email : false;
}

export const mainRoutes = [
  {
    name: 'Emails',
    to: '/emails'
  },
  {
    name: 'Tools',
    children: [
      {
        name: 'Spam Word Checker',
        to: '/tools/spam',
      },
      {
        name: 'Domain Checker',
        to: '/tools/domain',
      }
    ]
  },
  {
    custom: () => {

      return (
        <div className="ps-3 pe-5 border-end border-gray-200">
          <Link className="text-white text-decoration-none" to="/connect">
            <span className="btn btn-primary">
              Connect Email
            </span>
          </Link>
        </div>
      )
    }
  },
  {
    custom: () => {
      return <CustomNavbarSearch />
    }
  },
  {
    name: 'Overview',
    to: '/email/overview',
    show: getConnectedEmail
  },
  {
    name: 'Warmup',
    to: '/email/warmup',
    show: getConnectedEmail
  },
  {
    name: 'History',
    to: '/email/history',
    show: getConnectedEmail
  },
  {
    name: 'Settings',
    to: '/email/settings',
    show: getConnectedEmail
  }
  /*{
    name: 'Find',
    children: [
      {
        name: 'Domain Search',
        to: '/finder/domain'
      },
      {
        name: 'People Search',
        to: '/finder/people'
      }
    ]
  }*/
];

export default mainRoutes;
