import API from '../api/api';
import { getURLParameter, unobfuscate, getExpirationFormat, getCurrentTimestamp } from '../helpers/utils';
import moment from 'moment';

export const loadPlans = (updateState, otherPlans = []) => {
  return new Promise((resolve, reject) => {
    let options = {};

    const pSubscribe = getURLParameter('pSubscribe');
    let onlyPlans = false;

    if( pSubscribe ){
      onlyPlans = pSubscribe.split(',').filter(each => {
        each = unobfuscate(each);

        if( ! each.includes(getExpirationFormat()) ) return true;

        const parts = each.split(getExpirationFormat());
        const expires = parts[1];

        return getCurrentTimestamp() < parseInt(expires, 10);
      }).map(each => {
        each = unobfuscate(each);

        if( each.includes(getExpirationFormat()) ){
          const parts = each.split(getExpirationFormat());
          each = parts[0];
        }

        return each;
      }).join(',')
    }

    if( pSubscribe ){
      options = {
        params: [
          { param: 'only_plans', value: onlyPlans }
        ]
      }
    } else if( otherPlans.length ){
      options = {
        params: [
          { param: 'plans', value: otherPlans.join(',') }
        ]
      }
    }

    API.doRequest('plans', 'get', 'get', null, options).then(result => {

      if( result.data !== undefined ){

        if( onlyPlans ){
          const onlyPlanIds = onlyPlans.split(",").map(each => unobfuscate(each));
          result.data = result.data.map(each => {
            if(onlyPlanIds.includes(each.id)){
              each.enabled = 1;
            }
            return each;
          })
        }

        updateState(result.data);
        resolve(result.data);
      }

    }).catch(error => {
      reject(error);
    });
  });
}

export const loadPlansAccounts = (updateState) => {
  return new Promise((resolve, reject) => {
    API.doRequest('plans_accounts').then(result => {

      if( result.data !== undefined ){
        updateState(result.data);
        resolve(result.data);
      }

    }).catch(error => {
      reject(error);
    });
  });
}