export const initialAppState = {
  confirmModal: {
    type: 'destructive',
    confirm: null,
    confirmText: 'Delete',
    confirmColor: '',
    cancel: null,
    cancelText: 'Cancel',
    cancelColor: '',
    header: 'Really delete this?',
    subheader: '',
    body: 'This action cannot be undone',
    size: 'md',
    centered: false,
    show: false,
    confirmWithText: false
  }
};