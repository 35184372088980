import React from 'react';
import Project from '../Project';
import SpamWords from './SpamWords';

const SpamWordsWrap = () => {
  return (
    <Project>
      <div className="width-100">
        <SpamWords />
      </div>
    </Project>
  );
};

export default SpamWordsWrap;
