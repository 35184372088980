import React from 'react';
import PropTypes from 'prop-types';
//import { Form, Button } from 'react-bootstrap';
//import classNames from 'classnames';

const Project = ({ children }) => {
  return (
    <div style={{width: '100%', background: 'white', zIndex: 1000}}>
      {children}
    </div>
  );
};

Project.propTypes = {
  children: PropTypes.node.isRequired
};

export default Project;
