import React, {useContext, useState} from 'react';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppContext } from '../../../../context/Context';
import FormInfo from '../../../common/FormInfo';

const NumberModal = ({
  update,
  name,
  min,
  max,
  value
}) => {
  return (
    <Form onSubmit={(e) => {
      e.preventDefault()
      update(value)
    }}>
      <Form.Group className="mb-3">
        <Form.Label>{`How many ${name} would you like?`}</Form.Label>
        <Form.Control
          placeholder={10}
          defaultValue={value}
          name="num"
          min={min}
          max={max}
          onChange={(e) => update(e.target.value)}
          type="number"
        />
      </Form.Group>
    </Form>
  )
}

const PlanLineAllowance = ({
  display,
  item,
  name,
  overage = 0,
  overage_tiers = [],
  overage_controls = '',
  overage_cost = 0,
  overage_per = '',
  plan,
  style,
  tooltip,
  type,
  value,
  quantity,
  planId,
  overages,
  updateOverage,
  overage_unit_cost = 0
}) => {
  if( style === 'hidden' ) return null;

  const { appConfirm } = useContext(AppContext);
  const [localOverage, setLocalOverage] = useState(0);

  const currentOverage = overages[planId] !== undefined && overages[planId][item] !== undefined ? parseInt(overages[planId][item]) : 0;

  let overageUnitCost = parseInt(overage_cost, 10) / 100;
  const num = parseInt(localOverage, 10);
  let additional = num * overageUnitCost;
  if( overage_tiers !== undefined && overage_tiers.length ){
    overage_tiers.forEach(ot => {
      const min = parseInt(ot.min, 10);
      const max = parseInt(ot.max, 10);
      if(num >= min && num <= max){
        overageUnitCost = parseInt(ot.cost, 10) / 100
        additional = num * overageUnitCost;
      }
    });
  }

  overage = parseInt(overage, 10);
  value = parseInt(value, 10);
  quantity = parseInt(quantity, 10);

  let min = 0;
  let max = 0;

  overage_tiers.forEach(each => {
    if( each.max > max ) max = parseInt(each.max, 10);
  })

  const launchNumberModal = () => {
    appConfirm({
      type: 'message',
      confirmText: 'Update',
      confirmColor: 'primary',
      header: display ? display : name,
      body: <NumberModal min={min} max={max} value={value} update={(val) => val > max ? updateOverage(planId, item, max) : ( val < min  ? updateOverage(planId, item, min) : updateOverage(planId, item, val) )} name={name} />,
    })
  }

  const showCalculator = true;

  return (
    <>
      <strong onClick={overage && overage_controls === 'number' ? launchNumberModal : null} className={classNames('plan-line-allowance-value me-3', {
        ['text-primary cursor-pointer']: overage && overage_controls === 'number'
      })
      }>{ (quantity * value) + currentOverage }</strong>
      <span className="plan-line-allowance">{ display ? display : name }</span>
      {
        overage && overage_controls === 'number' ? (
          <div className="d-flex align-items-center ms-auto me-auto mb-3 plan-line-overage bg-100 mt-2 border border-200 rounded">
            <button className="btn-sstart-square btn-square-danger btn-sm" onClick={() => updateOverage(planId, item, currentOverage - 1)}><FontAwesomeIcon icon="minus" /></button>
            <div className="ms-3 fs--1 fw-bold flex-fill">${overage_unit_cost}/{overage_per}</div>
            <button className="btn-sstart-square btn-square-success btn-sm" onClick={() => updateOverage(planId, item, currentOverage + 1)}><FontAwesomeIcon icon="plus" /></button>
          </div>
        ) : null
      }
      {
        overage && overage_controls === 'range' ? (
          <div className="d-flex align-items-center ms-auto me-auto mb-3 plan-line-overage plan-line-overage-range bg-100 py-1 px-2 mt-2 border border-200 rounded">
            <Form.Range value={currentOverage} min={min} max={max} onChange={(e) => updateOverage(planId, item, parseInt(e.target.value, 10))} />
            <div className="ms-3 fs--1 fw-bold">${overage_unit_cost}/{overage_per}</div>
          </div>
        ) : null
      }
      {
        showCalculator && overage && overage_controls === 'usage' ? (
          <div className="bg-100 p-2 mt-2 border border-200 rounded">
            <div className="mb-2"><strong>Cost Calculator</strong>{ tooltip ? <FormInfo>{tooltip}</FormInfo> : null }:</div>
            <div className="mb-2">{localOverage} Additional {name}: ${additional}</div>
            <div className="d-flex align-items-center ms-auto me-auto plan-line-overage plan-line-overage-range">
              <Form.Range value={localOverage} min={min} max={max} onChange={(e) => setLocalOverage(parseInt(e.target.value, 10))} />
              <div className="ms-3 fs--1 fw-bold">${overageUnitCost}/{overage_per}</div>
            </div>
          </div>
        ) : null
      }
      { currentOverage === max && max > 1 ? <div className="mt-2 text-primary">Need more?</div> : null }
    </>
  );
};

export default PlanLineAllowance;
