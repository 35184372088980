import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import LoaderButton from '../../common/LoaderButton';

const SettingsPage = ({
  title,
  children,
  onSave = false,
  onSaveText = 'Save',
  loading = false
}) => {
  return (
    <>
      <Card.Header>
        <h4>{title}</h4>
      </Card.Header>
      <Card.Body>
        {children}
      </Card.Body>
      {
        onSave && (
          <Card.Footer>
            <LoaderButton loading={loading} onClick={onSave}>
              {onSaveText}
            </LoaderButton>
          </Card.Footer>
        )
      }
    </>
  );
};

SettingsPage.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  loading: PropTypes.bool
};

export default SettingsPage;
