import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import Api from 'api/api';

const ForgetPasswordForm = () => {
  // State
  const [email, setEmail] = useState('');

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    //Check to see if account exists

    //If account is Google signin, let the user know that they should sign in with their Google account

    //If account is email, let the user know that an email has been sent with a link to reset their password

    e.preventDefault();

    if(!email){
      toast.error('Please enter an email address');
    }

    Api.doRequest('accounts', 'reset_password', 'update', {email}).then(result => {
      toast.success(result.message);
    }).catch(error => {
      toast.error(error.message);
    });
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Email address'}
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!email}>
          Send reset link
        </Button>
      </Form.Group>

      <Link className="fs--1 text-600" to="/signin">
        Back to login
        <span className="d-inline-block ms-1"> &rarr;</span>
      </Link>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
